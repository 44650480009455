export const APPLICATION_NAME = "Q100+";
export const BROADCAST_CHANNEL = "q100-channel";

export const Role = Object.freeze({
  Superadmin: "SA_Q100",
  Operator: "AR-046",
  Kabag: "AR-045",
  Kasie: "AR-049",
  OperatorRequestProduct: "AR-103",
  OperatorItem: "AR-092",
  OperatorOrganization: "AR-056",
  OperatorBuilding: "AR-057",
  OperatorFacility: "AR-058",
  OperatorGradeRoom: "AR-059",
  OperatorRoom: "AR-060",
  OperatorEquipment: "AR-064",
  OperatorMedia: "AR-076",
  OperatorMicroba: "AR-097",
  OperatorRequest: "AR-055",
  OperatorPersonnel: "AR-095",
  OperatorAuditor: "OP-AU",
  OperatorQA: "AR-094",
  OperatorQC: "AR-093",
  QCWM: "WM-QC",
  QAWM: "WM-QA",
  OperatorSamplingWM: "WM-OP-BS",
  OperatorSterilisasiWM: "WM-OP-BST",
  KabagQCWM: "WM-KBG-QC",
  KasiePemfasWM: "WM-KSI-PEMFAS",
  KasieOPWM: "WM-KSI-OP",
  KasieQCWM: "WM-KSI-QC",
  ReviewerOrganization: "AR-071",
  ReviewerBuilding: "AR-069",
  ReviewerFacility: "AR-070",
  ReviewerMicroba: "AR-098",
  ReviewerGradeRoom: "AR-068",
  ReviewerRoom: "AR-073",
  ReviewerEquipment: "AR-072",
  ReviewerAuditor: "RE-AU",
  KabagPemilikFasilitas: "AR-061",
  KabagProduksi: "AR-KBG-PROD",
  KasiePemilikFasilitas: "AR-052",
  KabagUji: "AR-051",
  AhliMudaUji: "AR-050",
  OperatorUji: "AR-053",
  OperatorSampling: "AR-054",
  ReviewerQAEM: "AR-063",
  MonitoringQAEM: "AR-074",
  OperatorProduction: "AR-099",
  KasieProduksi: "AR-100",
  OperatorConfigurationEquipmentGroup: "OP-CFG-EQG",
  OperatorConfigurationEquipmentActivity: "OP-CFG-EQA",
  OperatorConfigurationTestParameter: "OP-CFG-TPM",
  OperatorConfigurationUOM: "OP-CFG-UOM",
  OperatorConfigurationPresentation: "OP-CFG-PSTN",
  OperatorConfigurationDosageForm: "OP-CFG-DSF",
  OperatorConfigurationAuditorExternal: "OP-CFG-ADE",
  OperatorEMPhysical: "AR-OPR-EM-PHYSC-ROOM",
  ReviewEMPhysical: "AR-RVW-EM-PHYSC",
  ReviewKabagEMPhysical: "AR-RVW-KABAG-EM-PHYSC",
  ReviewQAEMPhysical: "AR-RVW-QA-EM-PHYSC",
  OperatorGudangKarantina: "RM-OP-GK",
  OperatorPenerimaanBarang: "RM-OP-BRG",
  ManagementKarantina: "RM-M-K",
  KepalaDepartemenKarantina: "RM-KDP-K",
  OperatorSamplingQCBaku: "RM-OP-S-QC-PMKF-BAKU",
  OperatorSamplingQCKemasan: "RM-OP-S-QC-PMKF",
  OperatorAuthority: "OP-MD-AUTHRTY",
  OperatorForm: "OP-MD-FORM",
  OperatorConfigurationFormBinding: "OP-CFG-FBD",
  OperatorRegulasiProduk: "OP-RG-PRD",
  PICDokumenRegulasiProduk: "PIC-DOC-RG-PRD",
  ConfigurationFormBinding: "CFG-FBD",
  OperatorKitAduit: "OP-KIT-AU",
  ReviewerKitAudit: "RE-KIT-AU",
  OperatorPackaging: "OP-PKG",
  KepalaDepartementBagianRM: "RM-KDP-B",
  KasieBagianRM: "RM-KSI-B",
  KabagMasterForm: "KABAG-FORM",
  KepalaDepartemenQCRM: "RM-KDP-QC",
  KepalaDivisiQCRM: "RM-KDV-QC",

  /**
   * @master_item
   */
  OperatorItemGeneral: "OP-ITM-GRL",
  OperatorItemDetail: "OP-ITM-DTL",
  OperatorItemQualityControl: "OP-ITM-QC",
  OperatorItemPackaging: "OP-ITM-PKG",
  ReviewerItemGeneral: "RV-ITM-GRL",
  ReviewerItemDetail: "RV-ITM-DTL",
  ReviewerItemQualityControl: "RV-ITM-QC",
  ReviewerItemPackaging: "RV-ITM-PKG",

  /**
   * @Data_Master_Production
   */
  OperatorMasterProduction: "OP-MST-PRD",
  ReviewerMasterProduction: "RV-MST-PRD",

  /**
   * @Production_Schedule, Execution, Deviation
   */
  OperatorProduksi: "OP-PRD",

  /**
   * @Production_Review
   */
  // KasieProduksi: "KS-PRD",
  // KabagProduksi: "KB-PRD",
  KadivProduksi: "KD-PRD",

  /**
   * @To_Do_Production
   */
  QAProduksi: "QA-PRD",

  /**
   * @Release_Product
   */
  BatchReviewer: "BT-RV",
  KabagQA: "KB-QA",
  KadivQA: "KD-QA",

  ViewerDashboardQA: "VW-DSB-QA",

  /**
   * @Release_Management_Production
   */
  OperatorRMProduction: "OP-RM-PRO",
  KabagRMProduction: "KB-RM-PRO",
  KadivRMProduction: "KD-RM-PRO",

  /**
   * @Quality_Control_Request_Product
   */
  // OperatorRequestProduct: "OP-REQ-PRD",
  KabagRequestProduct: "KB-REQ-PRD",

  /**
   * @Quality_Control_Sampling_Product
   */
  OperatorSamplingProduct: "OP-SPL-PRD",
  KabagSamplingProduct: "KB-SPL-PRD",
  KasieSamplingProduct: "KS-SPL-PRD",
  /**
   * @Quality_Control_Transfer_Product
   */
  OperatorTransferSendingProduct: "OP-TFS-PRD",
  OperatorTransferReceiveProduct: "OP-TFR-PRD",

  /**
   * @Quality_Testing_Product
   */
  OperatorPengujianProduct: "OP-PGJ-PRD",
  KasiePengujianProduct: "KS-PGJ-PRD",
  KabagPengujianProduct: "KB-PGJ-PRD",
  KadivPengujianProduct: "KD-PGJ-PRD",

  /**
   * @To_Do_Quality_Control_Product
   */
  QAPengujianProduct: "QA-PGJ-PRD",

  /**
   * @Quality_Control_Template
   */
  OperatorTemplatePengujian: "OP-TPL-PGJ",
  KasieTemplatePengujian: "KS-TPL-PGJ",

  /**
   * @Data_Master_Test_Type
   */
  OperatorJenisUji: "OP-JNU",
  ReviewerJenisUji: "RV-JNU",
  OperatorPemilikOven: "WM-OP-OP",
  KasieQCRMBaku: "RM-KSI-QC-BAKU",
  KasieQCRMKemasan: "RM-KSI-QC",
  KasieQC: "WM-KSI-QC",
  OperatorUjiQCPMKF: "RM-OP-U-QC-PMKF",
  OperatorTC: "OP-TC",
  ReviewerTC: "RV-TC",
  ReviewerKasieTestType: "RV-JNU",
  ReviewerKabagTestType: "RV-KBG-JNU",
  ReviewerKadivTestType: "RV-KDV-JNU",
});

export const Menu = Object.freeze({
  qcRequest: "qcRequest",
  qcCreate: "qcCreate",
});

// TODO : Remove Dashboard from Public Route
export const PublicRoutes = [
  "/login",
  "/error/404",
  "/error/500",
  "/error/403",
  "/",
];

export const CRUDStatus = Object.freeze({
  Create: "create",
  Update: "update",
  Edit: "edit",
  Detail: "detail",
  Delete: "delete",
});

export const WorkflowCodeStatus = Object.freeze({
  QCS_1: "QCS-1",
  QCS_2: "QCS-2",
  QCS_3: "QCS-3",
  QCS_4: "QCS-4",
  QCS_5: "QCS-5",
  QCS_6: "QCS-6",
});

export const EQUIPMENT_TYPE = {
  EQUIPMENT: 1,
  NON_DISPOSSABLE: 2,
  DISPOSSABLE: 3,
};

export const OPERATORS = [
  { value: 1, label: `\u003D` },
  { value: 2, label: `\u003E` },
  { value: 3, label: `\u003C` },
  { value: 4, label: `\u2264` },
  { value: 5, label: `\u2265` },
  { value: 6, label: `\u003C\ n \u003C\ ` },
  { value: 7, label: `\u2264 n \u2264 ` },
];

export const OPERATOR = {
  EQUAL_TO: 1,
  LESS_THAN: 2,
  LESS_THAN_EQUAL: 3,
  MORE_THAN: 4,
  MORE_THAN_EQUAL: 5,
  LESS_THAN_BETWEEN: 6,
  LESS_THAN_EQUAL_BETWEEN: 7,
};

// Based on OPERATORS
export const NEW_OPERATOR = {
  EQUAL_TO: 1,
  GREATER_THAN: 2,
  LESS_THAN: 3,
  LESS_THAN_OR_EQUAL_TO: 4,
  GREATER_THAN_OR_EQUAL_TO: 5,
  LESS_THAN_BETWEEN: 6,
  LESS_THAN_EQUAL_BETWEEN: 7,
};

export const DATA_TYPES = [
  { value: 1, label: `Integers` },
  { value: 2, label: `Positive` },
  { value: 3, label: `Negative` },
  { value: 4, label: `Decimal` },
];

export const DATA_TYPE = {
  INTEGERS: 1,
  POSITIVE: 2,
  NEGATIVE: 3,
  DECIMAL: 4,
};

export const TestList = Object.freeze({
  SamplingPoint: "Sampling Point",
});

export const TestParameter = {
  ContactAgar: "Contact Agar",
  SettlePLate: "Settle Plate",
  AirSampler: "Air Sampler",
  FingerDab: "Finger DAB",
  ParticleCounter05mm: "Particle Counter 0.5",
  ParticleCounter50mm: "Particle Counter 5.0",
  Gloves: "Gloves",
};

//Input Type Equipment List
export const EQUIPMENT_TYPE_LIST = [
  {
    value: 1,
    label: "Equipment",
  },
  {
    value: 2,
    label: "Non-Dispossable",
  },
  {
    value: 3,
    label: "Dispossable",
  },
];

export const INPUT_TYPE_COMPONENT_NAMES = {
  LongText: "long_text",
  ShortText: "short_text",
  Default: "default",
  Date: "date",
  Time: "time",
  DateAndTime: "date_and_time",
  Boolean: "boolean",
  Checklist: "checklist",
  RadioButton: "radio_button",
  Material: "material",
  Equipment: "equipment",
  Number: "number",
  Formula: "formula",
  Room: "room",
  Table: "table",
  Dropdown: "dropdown",
};

export const errors = {
  required: "Required",
};

export const MAX_FILE_SIZE = 2000000;
export const VALID_FILE_EXTENSIONS = [
  "jpg",
  "png",
  "jpeg",
  "pdf",
  "csv",
  "xlsx",
];
