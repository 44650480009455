import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  deviations: [],
};

export const productionDeviationProcessSlice = createSlice({
  name: "productionDeviationProcess",
  initialState,
  reducers: {
    setDeviations(state, action) {
      state.deviations = action.payload;
    },
  },
});

export const { setDeviations } = productionDeviationProcessSlice.actions;
export default productionDeviationProcessSlice.reducer;
