import {
  Box,
  Flex,
  Link,
  ListIcon,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { FaCircle } from "react-icons/fa";
import { useState } from "react";
import { MdOutlineRadioButtonChecked } from "react-icons/md";
import PropTypes from "prop-types";

const blue = "#46A2E1";

export const SideBar = ({
  t,
  onMenuClick,
  findingDetail,
  activeScope,
  setActiveScope,
  auditCategory,
  getAssignedAuditors,
}) => {
  const [scope] = useState(findingDetail?.inspectionScopes || []);

  const handleScopeClick = (scope) => {
    if (typeof scope === "string") {
      setActiveScope(scope);
      onMenuClick(scope);
    } else {
      if (auditCategory === 1) {
        getAssignedAuditors(scope);
      } else {
        setActiveScope(scope.id);
        onMenuClick(scope.id);
      }
    }
  };

  return (
    <Box p={5}>
      <UnorderedList fontSize={12} styleType={"none"}>
        <ListItem mb={1} color={activeScope === "General" ? blue : "black"}>
          <Flex alignItems="center">
            {activeScope === "General" ? (
              <ListIcon
                as={MdOutlineRadioButtonChecked}
                boxSize="8px"
                color={blue}
              />
            ) : (
              <ListIcon as={FaCircle} boxSize="5px" color="black" />
            )}
            <Link
              onClick={() => handleScopeClick("General")}
              color={activeScope === "General" ? blue : "black"}
              fontWeight={activeScope === "General" ? "bold" : "normal"}
            >
              General
            </Link>
          </Flex>
        </ListItem>
        <ListItem mb={1}>
          <Flex alignItems="center">
            {activeScope === scope.id ? (
              <ListIcon
                as={MdOutlineRadioButtonChecked}
                boxSize="8px"
                color={blue}
              />
            ) : (
              <ListIcon as={FaCircle} boxSize="5px" color="black" />
            )}
            <Link
              color={activeScope === "Scope" ? blue : "black"}
              fontWeight={activeScope === "Scope" ? "bold" : "normal"}
            >
              Scope
            </Link>
          </Flex>
          <UnorderedList
            styleType={"none"}
            mt={1}
            color={activeScope === scope.id ? blue : "black"}
          >
            {scope.map((scope, index) => {
              return (
                <ListItem
                  key={scope.id}
                  color={activeScope === scope.id ? blue : "black"}
                  mb={1}
                >
                  <Flex alignItems="center">
                    {activeScope === scope.id ? (
                      <ListIcon
                        as={MdOutlineRadioButtonChecked}
                        boxSize="8px"
                        color={blue}
                      />
                    ) : (
                      <ListIcon as={FaCircle} boxSize="5px" color="black" />
                    )}
                    <Link
                      onClick={() => handleScopeClick(scope)}
                      color={activeScope === scope.id ? blue : "black"}
                      fontWeight={activeScope === scope.id ? "bold" : "normal"}
                    >
                      {scope.kitAudit.scope}
                    </Link>
                  </Flex>
                </ListItem>
              );
            })}
          </UnorderedList>
        </ListItem>
        <ListItem mb={1} color={activeScope === "General" ? blue : "black"}>
          <Flex alignItems="center">
            {activeScope === "Result" ? (
              <ListIcon
                as={MdOutlineRadioButtonChecked}
                boxSize="8px"
                color={blue}
              />
            ) : (
              <ListIcon as={FaCircle} boxSize="5px" color="black" />
            )}
            <Link
              onClick={() => handleScopeClick("Result")}
              color={activeScope === "Result" ? blue : "black"}
              fontWeight={activeScope === "Result" ? "bold" : "normal"}
            >
              Result
            </Link>
          </Flex>
        </ListItem>
      </UnorderedList>
    </Box>
  );
};

SideBar.propTypes = {
  t: PropTypes.func,
  onMenuClick: PropTypes.func.isRequired,
};
