import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Text,
  } from '@chakra-ui/react'
  import { useRef } from 'react'
  import moment from 'moment'
  import TableWorkflowHistory from '../table/TableWorkflowHistory'

  // Fungsi untuk memasukkan data kolom ke table History Workflow
  const columnsHistory = [
    {
      Header: "DATE TIME",
      accessor: "dateTimes",
      width: '10%',
      minWidth: '170px',
      Cell: row => {
        return (
          <>
            {moment(row.value).format('DD MMM YYYY HH:mm')}
          </>
        )
      }
    },
    {
      Header: "NIK",
      accessor: "nik",
      width: '10%',
    },
    {
      Header: "NAME",
      accessor: "name",
      width: '20%',
    },
    {
      Header: "POSITION",
      accessor: "position",
      width: '25%',
    },
    {
      Header: "ACTION",
      accessor: "action",
      width: '10%',
    },
    {
      Header: "NOTE",
      accessor: "note",
      width: '25%',
      Cell: row => {
        return <Text style={{ minWidth: '100px', maxWidth:'200px', wordWrap:'break-word' }}>{row.value ? row.value : '-'}</Text>
      }
    },
  ]
  
  const NewModalWorkflowHistory = ({isOpen, onClose, dataProps}) => {
    const initialRef = useRef()
    const finalRef = useRef()
  
    return (
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        id="workflow"
        size="6xl"
      >
        <ModalOverlay />
        <ModalContent>
            <ModalHeader className='p-4'>Workflow History</ModalHeader>
            <ModalCloseButton onClick={onClose} className='p-4' />

            <ModalBody className="o-table-review pt-0 pb-4" style={{ maxHeight: '75vh'}}>
                <TableWorkflowHistory isPaging={false} columns={columnsHistory} data={dataProps}/>
            </ModalBody>
        </ModalContent>
      </Modal>
    )
  }
  
  export default NewModalWorkflowHistory