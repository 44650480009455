import { Box } from "@chakra-ui/react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const MenuItem = ({
  parent,
  title,
  child,
  icon,
  style,
  svg,
  submenu,
  iconClass,
  isOpen,
  fullSidebar,
  code,
  ...otherProps
}) => {
  const idFromTitle = parent
    ? `${parent?.toLowerCase()?.split(" ")?.join("-")}-${title
        ?.toLowerCase()
        ?.split(" ")
        ?.join("-")}-menu`
    : `${title?.toLowerCase()?.split(" ")?.join("-")}-menu`;
  return (
    <Box className="sidebar-container" {...otherProps}>
      <Box className="nav-item m-0 sidebar-item disable-select">
        {!submenu && <Box className="nav-item-border"></Box>}
        {icon && <i className="material-icons-round sidebar-icon">{icon}</i>}
        <Box className={`sidebar-icon ${iconClass !== "-" ? iconClass : ""}`} />
        <span id={idFromTitle} className={`title text-medium hover-show`}>
          {title}
        </span>
        {submenu && fullSidebar ? (
          isOpen.includes(code) ? (
            <FaChevronUp />
          ) : (
            <FaChevronDown />
          )
        ) : null}
      </Box>
    </Box>
  );
};

export default MenuItem;
