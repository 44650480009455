import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  rejects: [],
};

export const cartRejectSlice = createSlice({
  name: "cartReject",
  initialState,
  reducers: {
    setRejects(state, action) {
      state.rejects = [...action.payload];
    },
  },
});

export const { setRejects } = cartRejectSlice.actions;
export default cartRejectSlice.reducer;
