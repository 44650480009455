import React from "react";
import { Field, ErrorMessage } from "formik";
import {
  InputGroup,
  InputRightAddon,
  InputLeftAddon,
  FormLabel,
} from "@chakra-ui/react";
import withTrans from "../../../config/i18n/withTrans";
import Select, { components } from "react-select";

const Option = (props) => {
  return (
    <>
      <components.Option {...props}>
        {props.data.value === "button-create" ? (
          <div
            style={{
              cursor: "pointer",
            }}
          >
            <div
              style={{
                color: "#1FB4D2",
                fontStyle: "italic",
              }}
            >
              {props.children}
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div>{props.children}</div>
            {!props.data.newData && <div style={{ color: "#1FB4D2" }}>NEW</div>}
          </div>
        )}
      </components.Option>
    </>
  );
};

const SelectField2 = ({
  t,
  title,
  required,
  type,
  name,
  id,
  leftAddon,
  rightAddon,
  leftIcon,
  rightIcon,
  toggle,
  disabled,
  width,
  data = [],
  index,
  hidden,
  bg,
  SelectedColor,
  ListDisabledOption,
  children,
  variant,
  className,
  parentMarginTop,
  leftAddOnBorderRadius,
  selectBorderRadius,
  isError,
  value,
  position,
  errorMessageAllowed = true,
  isFingerDab,
  createble = false,
  options = [],
  instanceId,
  inputId,
  zIndex = 1,
  multiValueMaxWidth,
  multiValueMaxHeight,
  ...props
}) => {
  const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      width: isFingerDab ? "200%" : "100%",
    }),
    option: (base, state) => ({
      ...base,
      color: state.isDisabled ? "#98a7b7" : "#000",
      zIndex: 1000,
      backgroundColor: state.isDisabled
        ? undefined
        : state.isSelected
        ? "#1FB4D2"
        : state.isFocused
        ? "rgba(70, 162, 225, 0.16)"
        : "#fff",
      ":active": {
        ...base[":active"],
        backgroundColor: !state.isDisabled
          ? state.isDisabled
            ? "#1FB4D2"
            : "rgba(70, 162, 225, 0.16)"
          : undefined,
      },
    }),
    control: (base, state) => ({
      ...base,
      background: disabled ? "#F8F8F8" : "#fff",
      borderColor: "#CBD5E0",
      borderRadius: selectBorderRadius
        ? selectBorderRadius
        : "0.375rem 0.375rem 0.375rem 0.375rem",
      width: width ?? "100%",
      position: position && "absolute",
      paddingBottom: isFingerDab ? "3px" : "",
      paddingLeft: isFingerDab ? "5px" : "",
    }),
    placeholder: (base, state) => ({
      ...base,
      color: state.isSelected ? "#000" : "#98a7b7",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    }),
    singleValue: (base, state) => ({
      ...base,
      color: "#000",
    }),
    multiValue: (styles) => ({
      ...styles,
      backgroundColor: "#fff",
      borderRadius: "0.375rem",
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      color: "#1FB4D2",
      backgroundColor: "#1fb4d226",
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: "#1FB4D2",
      display: "inline-block",
      ...(disabled ? { display: "none" } : {}),
      backgroundColor: "#1fb4d226",
      ":hover": {
        backgroundColor: "#1FB4D2",
        color: "white",
      },
    }),
    valueContainer: (base, props) => {
      const { isMulti, children } = props;
      const isMoreThanOne = children[0]?.length > 1;

      return {
        ...base,
        overflowY: isMulti && isMoreThanOne ? "scroll !important" : "hidden",
        pointerEvents: "auto !important",
        maxHeight: isMulti ? multiValueMaxHeight : "100%",
      };
    },
  };

  const optionsCreateable = [
    {
      value: "button-create",
      label: "Add New Sediaan",
    },
    ...options,
  ];

  return (
    <div
      style={{
        marginTop: title && !parentMarginTop ? 20 : parentMarginTop,
        width: width ?? "100%",
      }}
      hidden={hidden}
    >
      <Field name={name}>
        {({ field, form }) => (
          <>
            {title && (
              <FormLabel htmlFor={id} className="form-label">
                {title}{" "}
                {required ? <span style={{ color: "red" }}> *</span> : ""}
              </FormLabel>
            )}
            <InputGroup isDisabled={disabled} zIndex={zIndex}>
              {leftAddon && (
                <InputLeftAddon
                  backgroundColor={bg}
                  children={leftAddon}
                  onClick={toggle ? toggle : ""}
                  borderRadius={leftAddOnBorderRadius}
                  style={{ paddingRight: isFingerDab ? "19px" : "" }}
                />
              )}

              {leftIcon && <div onClick={toggle ? toggle : ""}>{leftIcon}</div>}
              <Select
                {...props}
                filterOption={(option, searchText) => {
                  if (
                    option?.data &&
                    option.data?.label
                      .toLowerCase()
                      .includes(searchText.toLowerCase())
                  ) {
                    return true;
                  } else {
                    return false;
                  }
                }}
                id={id}
                inputId={inputId}
                name={name}
                className={
                  form.errors[name] && form.touched[name]
                    ? `w100 ${className} is-invalid`
                    : `w100 ${className}`
                }
                styles={customStyles}
                isDisabled={disabled}
                value={value}
                menuPortalTarget={document.body}
                options={createble ? optionsCreateable : options}
                components={createble ? { Option } : {}}
              />

              {rightIcon && (
                <div onClick={toggle ? toggle : ""}>{rightIcon}</div>
              )}
              {rightAddon && (
                <InputRightAddon
                  children={rightAddon}
                  onClick={toggle ? toggle : ""}
                />
              )}
            </InputGroup>
            {errorMessageAllowed && (
              <ErrorMessage
                name={name}
                component="div"
                className="invalid-feedback"
              />
            )}
          </>
        )}
      </Field>
    </div>
  );
};

export default withTrans(SelectField2);
