import { Box } from "@chakra-ui/react"
import { useEffect } from "react";
import { useRef } from "react"
import style from './style.module.css'
import { gsap } from 'gsap'

let delay = '-=.1';
let pause = '+=.2';

const SquareLoading = () => {
  const firstBox = useRef()
  const secondBox = useRef()
  const thirdBox = useRef()
  let loading = gsap.timeline({ defaults: {duration: .4, ease: "back(2)"}, repeat: -1, repeatDelay: .25, paused: true});

  useEffect(() => {
    gsap.to('.square-container', {autoAlpha: 1, ease: 'linear'})
    loading.play();
    
    loading.to(secondBox.current, {top: 'calc(100% - 40px)'})
    loading.to(firstBox.current, {left: 'calc(100% - 40px)', top: 0},delay)
    loading.to(thirdBox.current, {top: 0},delay)
    loading.to(secondBox.current, {top: 'calc(100% - 40px)', left: 0, right: 'unset'}, pause)
    loading.to(firstBox.current, {top: 'calc(100% - 40px)'},delay)
    loading.to(thirdBox.current, {left:'calc(100% - 40px)'},delay)
    loading.to(secondBox.current, {top: 0}, pause)
    loading.to(firstBox.current, {left: 0},delay)
    loading.to(thirdBox.current, {top: 'calc(100% - 40px)'},delay)
    loading.to(secondBox.current, {left: 'calc(100% - 40px)'}, pause)
    loading.to(firstBox.current, {top: 0}, delay)
    loading.to(thirdBox.current, {left: 0}, delay)
  }, [])

  return <>
    <Box className={`${style.loading}`}>
      <Box className={`${style.square_container}`}>
        <Box ref={firstBox} className={`${style.square}`}></Box>
        <Box ref={secondBox} className={`${style.square}`}></Box>
        <Box ref={thirdBox} className={`${style.square}`}></Box>
      </Box>
    </Box>
  </>

}

export default SquareLoading