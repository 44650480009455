import { Box, Grid, GridItem, Button, Flex } from "@chakra-ui/react";
import { lazy, useState } from "react";
import { initialGeneralFormData } from "./utils/const";
import { Header } from "../Finding/layout";
import { ScopeForm } from "./forms/External/ScopeForm";
import { SideBar } from "./components/Sidebar";

const GeneralFormComponent = lazy(() => import("../Finding/forms/GeneralForm"));
const QAResultForm = lazy(() =>
  import("./forms/ExternalCategoryForm/ResultForm")
);

const FindingSheetDetail = ({ t }) => {
  const title = "Finding Sheet";
  const [activeMenu, setActiveMenu] = useState("General");

  const handleMenuClick = (menu) => {
    if (typeof menu === "string") {
      setActiveMenu(menu);
    } else {
      setActiveMenu("Scope");
    }
    console.log(setActiveMenu);
  };

  return (
    <Box bg="#fff" p={0} borderRadius="lg" w="100%">
      <Box borderBottom="1px" padding={5} borderColor="#CBD5E0">
        <Flex alignItems={"center"} justifyContent={"space-between"}>
          <Header title={title} />
          <Button
            title="History"
            borderColor={"#1FB4D2"}
            color={"#1FB4D2"}
            fontSize={12}
            borderWidth={2}
            marginRight={5}
            variant="outline"
          >
            History
          </Button>
        </Flex>
      </Box>
      {/* Wrapper */}
      <Box borderRadius="lg">
        <Grid templateColumns="repeat(6, 1fr)">
          <GridItem
            borderRight="1px"
            borderColor="#CBD5E0"
            rowSpan={2}
            colSpan={1}
          >
            <SideBar t={t} onMenuClick={handleMenuClick} />
          </GridItem>
          <GridItem colSpan={5}>
            {/* ContentData */}
            <Box>
              {activeMenu === "General" && (
                <GeneralFormComponent
                  formDataProps={initialGeneralFormData}
                  props={{ t }}
                />
              )}
              {activeMenu === "Scope" && <ScopeForm />}
              {activeMenu === "Result" && <QAResultForm />}
            </Box>
          </GridItem>
        </Grid>
      </Box>
    </Box>
  );
};

export default FindingSheetDetail;
