import pesAPIInstance from '../ApiPES'

export async function PostPresenceIn(Data) {
  try {
    const response = await pesAPIInstance.post(`/v1/UserPresence/Insert`, Data)
    return response
  } catch (error) {
    return error.response
  }
}

export async function PostPresenceOut(Data) {
  try {
    const response = await pesAPIInstance.post(`/v1/UserPresence/CheckOut`, Data)
    return response
  } catch (error) {
    return error.response
  }
}

export async function getListPresence(status, date) {
  try {
    const response = await pesAPIInstance.get(`/v1/UserPresence/List?type=`+ status + `&date=` + date)
    return response
  } catch (error) {
    return error.response
  }
}

export async function getFacilityList() {
  try {
    const response = await pesAPIInstance.get(`/v1/Facility/List`)
    return response
  } catch (error) {
    return error.response
  }
}
