import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Role } from "utils/ConstantGlobal";
import { getToken, getUserInfo, getUserRole, userHasAccess } from "utils/User";

export const useCheckRole = () => {
  const location = useLocation();
  const roles = useSelector(getUserRole);
  const token = useSelector(getToken);
  const userInfo = useSelector(getUserInfo);

  const isOpPenerimaanBarang = userHasAccess(
    roles,
    Role.OperatorPenerimaanBarang
  );

  const isOpGudangKarantina = userHasAccess(
    roles,
    Role.OperatorGudangKarantina
  );

  const isManagementKarantina = userHasAccess(roles, Role.ManagementKarantina);

  const isKadepKarantina = userHasAccess(roles, Role.KepalaDepartemenKarantina);

  const isOperatorSamplingQCBaku = userHasAccess(
    roles,
    Role.OperatorSamplingQCBaku
  );

  const isOperatorSamplingQCKemasan = userHasAccess(
    roles,
    Role.OperatorSamplingQCKemasan
  );

  const isOperatorSamplingQC =
    isOperatorSamplingQCBaku || isOperatorSamplingQCKemasan;

  const isOperatorUjiQCPMKF = userHasAccess(roles, Role.OperatorUjiQCPMKF);

  const isKasieBagian = userHasAccess(roles, Role.KasieBagianRM);

  const isKadepBagRM = userHasAccess(roles, Role.KepalaDepartementBagianRM);

  const isKadepQCRM = userHasAccess(roles, Role.KepalaDepartemenQCRM);

  const isKadivQCRM = userHasAccess(roles, Role.KepalaDivisiQCRM);

  const isAllowRejectProcedure = isKasieBagian || isKadepQCRM || isKadivQCRM;

  const getUserMenuRole = () => {
    if (isOperatorUjiQCPMKF) return Role.OperatorUjiQCPMKF;
    if (isKadepBagRM) return Role.KepalaDepartementBagianRM;
    if (isKasieBagian) return Role.KasieBagianRM;
    if (isKadepQCRM) return Role.KepalaDepartemenQCRM;
    if (isKadivQCRM) return Role.KepalaDivisiQCRM;

    if (isOperatorSamplingQC) {
      return isOperatorSamplingQCBaku
        ? Role.OperatorSamplingQCBaku
        : Role.OperatorSamplingQCKemasan;
    }

    return null;
  };

  const isReviewRoute = location.pathname.includes("review");

  return {
    token,
    userInfo,
    getUserMenuRole,
    isReviewRoute,
    isOpPenerimaanBarang,
    isOpGudangKarantina,
    isManagementKarantina,
    isKadepKarantina,
    isOperatorSamplingQC,
    isOperatorUjiQCPMKF,
    isKadepBagRM,
    isKasieBagian,
    isKadepQCRM,
    isKadivQCRM,
    isAllowRejectProcedure,
  };
};
