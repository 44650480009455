import { useState, useMemo } from "react";
import { Box, Flex, Input, InputGroup } from "@chakra-ui/react";
import { BiEditAlt } from "react-icons/bi";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { debounce } from "lodash";

import Button from "components/Button";
import TableDynamic from "components/Table/TableDynamic";
import ModalForm from "./ModalForm";
import ModalConfirmation from "./ModalConfirmation";

const ConfigurationList = ({
  columns,
  formTitle,
  data,
  isLoading,
  totalPage,
  currentPage,
  handleChangePage,
  handleSearch,
  onDelete,
  formComponent,
  allowedAction = { create: false, edit: false, delete: false },
}) => {
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [openedModalData, setOpenedModalData] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const actionColumn = {
    Header: "ACTION",
    accessor: "",
    minWidth: 100,
    width: 100,
    Cell: (row) => {
      return (
        <>
          {allowedAction.edit && (
            <button
              className="btn-cm-edit"
              title="Update"
              onClick={() => handleFormModal("update", row.row.original)}
            >
              <BiEditAlt color="inherit" />
            </button>
          )}
          {allowedAction.delete && (
            <button
              className="btn-cm-delete"
              title="Delete"
              onClick={() => handleConfirmationModal(row.row.original)}
            >
              <MdOutlineDeleteOutline color="inherit" />
            </button>
          )}
        </>
      );
    },
  };

  const handleFormModal = (type = "create", data) => {
    if (type === "create" || type === "close") {
      setIsFormModalOpen((prev) => !prev);
      setOpenedModalData({});
      setIsEdit(false);
      return;
    }

    if (type === "update") {
      setIsFormModalOpen(true);
      setOpenedModalData(data);
      setIsEdit(true);
      return;
    }
  };

  const handleConfirmationModal = (data) => {
    if (data) setOpenedModalData(data);
    setIsConfirmationModalOpen((prev) => !prev);
  };

  const handleDelete = () => {
    onDelete(openedModalData);
    setIsConfirmationModalOpen((prev) => !prev);
  };

  const handleDebounceSearch = useMemo(
    () => debounce((value) => handleSearch(value), 800),
    [handleSearch]
  );

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
    handleDebounceSearch(e.target.value);
  };

  return (
    <>
      <Box className="main-content-wrapper list-organization" overflowY="unset">
        <>
          <Flex
            align="center"
            justify="space-between"
            margin="0 15px 15px 15px"
          >
            <Flex align="flex-end">
              <InputGroup marginRight="5px">
                <Input
                  placeholder="Search..."
                  onChange={handleSearchChange}
                  value={searchValue}
                />
              </InputGroup>
            </Flex>
            {allowedAction.create && (
              <Flex align="right">
                <Button
                  color="light-blue"
                  onClick={() => handleFormModal("create")}
                >
                  Create
                </Button>
              </Flex>
            )}
          </Flex>
          <div className="table-container">
            <TableDynamic
              columns={[
                ...columns,
                ...(allowedAction.edit || allowedAction.delete
                  ? [actionColumn]
                  : []),
              ]}
              data={data}
              isLoading={isLoading}
              currentPage={currentPage}
              totalPage={totalPage}
              maxData={10}
              isPaging={true}
              handleChangePage={handleChangePage}
              tableMinHeight="calc(100vh - 325px)"
              tableMaxHeight="calc(100vh - 325px)"
            />
          </div>
        </>
      </Box>

      <ModalForm
        title={`${isEdit ? "Edit" : "Create"} ${formTitle}`}
        isOpen={isFormModalOpen}
        onClose={() => handleFormModal("close")}
      >
        {formComponent({
          openedData: openedModalData,
          isEdit,
          onClose: () => handleFormModal("close"),
        })}
      </ModalForm>

      <ModalConfirmation
        onClickYes={handleDelete}
        isOpen={isConfirmationModalOpen}
        onClose={handleConfirmationModal}
      />
    </>
  );
};

export default ConfigurationList;
