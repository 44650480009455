const NotificationBellSvg = (props) => {
  const { areThereNewData } = props;

  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_6411_9783)">
        <path
          d="M18 8.08594C18 6.49464 17.3679 4.96852 16.2426 3.8433C15.1174 2.71808 13.5913 2.08594 12 2.08594C10.4087 2.08594 8.88258 2.71808 7.75736 3.8433C6.63214 4.96852 6 6.49464 6 8.08594C6 15.0859 3 17.0859 3 17.0859H21C21 17.0859 18 15.0859 18 8.08594Z"
          fill="#404040"
        />
        <path
          d="M13.7295 21.0859C13.5537 21.389 13.3014 21.6406 12.9978 21.8155C12.6941 21.9903 12.3499 22.0824 11.9995 22.0824C11.6492 22.0824 11.3049 21.9903 11.0013 21.8155C10.6977 21.6406 10.4453 21.389 10.2695 21.0859"
          stroke="#404040"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        {areThereNewData ? (
          <ellipse
            cx="17.7015"
            cy="4.78446"
            rx="3.97196"
            ry="3.97196"
            fill="#BB4430"
          />
        ) : (
          <></>
        )}
      </g>
      <defs>
        <clipPath id="clip0_6411_9783">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.0859375)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default NotificationBellSvg;
