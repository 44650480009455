import { createSlice } from "@reduxjs/toolkit";

export const AuditManagementReducer = createSlice({
  name: "auditManagement",
  initialState: {
    auditCategory: [],
    auditType: [],
    objectStatus: [],
    findingObjectStatus: [],
    auditorRoleEnum: [],
    auditorQualificationEnum: [],
    auditScheduleNotificationReminderEnum: [],
    auditScheduleMethodEnum: [],
    organizationOptions: [],
    auditorExternalOptions: [],
    itemOptions: [],
    inspectionScopeOptions: [],
    inspectionGuidelineOptions: [],
    findingAuditAuditorPresenceEnum: [],
    findingAuditFindingClassificationEnum: [],
    findingAuditItemInspectionResultEnum: [],
    findingAuditMandatoryChecklistEnum: [],
    createScheduleReq: {
      category: null,
      type: null,
      method: null,
      auditeeId: null,
      auditorId: null,
      auditeeProducts: [],
      scheduleStartDate: null,
      scheduleEndDate: null,
      notificationReminder: null,
      internalAuditors: [],
      externalAuditors: [],
      inspectionGoals: [],
      scopeIds: [],
      inspectionScopes: [],
      isDraft: true,
      createdBy: null,
    },
    editScheduleReq: {
      id: null,
      category: null,
      type: null,
      method: null,
      auditeeId: null,
      auditorId: null,
      auditeeProducts: [],
      scheduleStartDate: null,
      scheduleEndDate: null,
      notificationReminder: 1,
      internalAuditors: [],
      externalAuditors: [],
      inspectionGoals: [],
      scopeIds: [],
      inspectionScopes: [],
      isDraft: true,
      createdBy: null,
    },
    permission: {
      canCreateSchedule: false,
      canViewListSchedule: false,
      canViewDetail: false,
      canCorrectSchedule: false,
      canCancelSchedule: false,
      canRejectSchedule: false,
      canApproveSchedule: false,
      isLeadAuditor: false,
      isReviewerOperator: false,
      isAuditor: false,
    },
  },
  reducers: {
    SET_AUDIT_CATEGORY: (state, data) => {
      state.auditCategory = data.payload;
    },
    SET_AUDIT_TYPE: (state, data) => {
      state.auditType = data.payload;
    },
    SET_AUDIT_OBJECT_STATUS: (state, data) => {
      state.objectStatus = data.payload;
    },
    SET_FINDING_AUDIT_OBJECT_STATUS: (state, data) => {
      state.findingObjectStatus = data.payload;
    },
    SET_CREATE_SCHEDULE_REQ: (state, data) => {
      for (const key in data.payload) {
        state.createScheduleReq[key] = data.payload[key];
      }
    },
    SET_ALL_ENUMS: (state, data) => {
      state.auditorRoleEnum = data.payload.auditorRoleEnum || [];
      state.auditorQualificationEnum =
        data.payload.auditorQualificationEnum || [];
      state.auditScheduleNotificationReminderEnum =
        data.payload.auditScheduleNotificationReminderEnum || [];
      state.auditScheduleMethodEnum =
        data.payload.auditScheduleMethodEnum || [];
      state.organizationOptions = data.payload?.organizationOptions || [];
      state.auditorExternalOptions = data.payload?.auditorExternalOptions || [];
      state.itemOptions = data.payload?.itemOptions || [];
      state.findingAuditAuditorPresenceEnum =
        data.payload?.findingAuditAuditorPresenceEnum || [];
      state.findingAuditFindingClassificationEnum =
        data.payload?.findingAuditFindingClassificationEnum || [];
      state.findingAuditItemInspectionResultEnum =
        data.payload?.findingAuditItemInspectionResultEnum || [];
      state.findingAuditMandatoryChecklistEnum =
        data.payload?.findingAuditMandatoryChecklistEnum || [];
    },
    SET_INSPECTION_SCOPE_OPTIONS: (state, data) => {
      state.inspectionScopeOptions = data.payload;
    },
    SET_INSPECTION_GUIDELINE_OPTIONS: (state, data) => {
      state.inspectionGuidelineOptions = data.payload;
    },
    SET_PERMISSION: (state, data) => {
      state.permission = data.payload;
    },
  },
});

export const {
  SET_AUDIT_CATEGORY,
  SET_AUDIT_TYPE,
  SET_AUDIT_OBJECT_STATUS,
  SET_FINDING_AUDIT_OBJECT_STATUS,
  SET_CREATE_SCHEDULE_REQ,
  SET_INSPECTION_SCOPE_OPTIONS,
  SET_INSPECTION_GUIDELINE_OPTIONS,
  SET_ALL_ENUMS,
  SET_PERMISSION,
} = AuditManagementReducer.actions;
