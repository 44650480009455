const { Box, Text } = require("@chakra-ui/react");

const Header = ({ title = "Finding Sheet" }) => {
  return (
    <Box>
      <Text fontSize="17px" fontWeight={"bold"}>
        {title}
      </Text>
    </Box>
  );
};

export default Header;
