import { useSelector } from "react-redux";

import { APPLICATION_NAME } from "./ConstantGlobal";
import { ConvertDateToString } from "./Helpers";

export const getUser = (state) => state.auth.userData;

export const getUserInfo = (state) => state.auth.userInfo;

export const getNameUser = (state) => state.auth.userInfo.Name;

export const getNikUser = (state) => state.auth.userInfo.NIK;

export const getNIK4asUsername = (state) => state.auth.userInfo.OldNIK;

export const getPositionId = (state) => state.auth.userInfo.PositionId;

export const getPositionName = (state) => state.auth.userInfo.PositionName;

export const getOrganizationId = (state) => state.auth.userInfo.OrganizationId;

export const getOrganizationName = (state) =>
  state.auth.userInfo.OrganizationName;

export const getUserNameLogin = (state) => state.auth.userName;

export const getUserAction = (state) => state.userCredentials.actions.data;

// TODO : DO THIS TO THE REST GLOBAL STATE
export const getOrganizationInfo = (state) => {
  return {
    orgId: state.auth.userInfo.OrganizationId,
    orgName: state.auth.userInfo,
  };
};

export const getToken = (state) => state.auth.userData.accessToken;

export const getRefreshToken = (state) => state.auth.userData.refreshToken;

export const getApplicationCode = (state) => {
  const appCode = state.auth.userInfo.Roles.find(
    (d) => d.ApplicationCode === process.env.REACT_APP_CODE
  );

  return appCode?.ApplicationCode;
};

export const GetRolesByApplicationCode = (state) => {
  const RolesApplication = state.userCredentials.roles.data;

  return RolesApplication.length !== 0 ? RolesApplication[0].roles : [];
};

export const getUserRole = (state) => {
  const RolesApplication = state.userCredentials.roles.data;

  let role = [];
  if (RolesApplication.length > 0) {
    if (RolesApplication[0].roles.length > 0) {
      for (let i = 0; i < RolesApplication[0].roles.length; i++) {
        role.push(RolesApplication[0].roles[i].roleCode);
      }
    } else {
      role = RolesApplication[0].roles[0].roleCode;
    }
  } else {
    role = null;
  }

  return role;
};

export const userHasAccess = (arr, roleCode) => {
  let valid = null;

  if (!arr) {
    return false;
  }

  if (
    arr.find((i) => i?.toLowerCase() === roleCode.toLowerCase()) === undefined
  ) {
    valid = false;
  } else {
    valid = true;
  }

  return valid;
};

//FUNGSI UNTUK INSIALISASI PAGES NUMBER
//KALAU ADA YANG INGIN MEMBUAT PAGES NUMBER TERSIMPAN, MANGGA DIUPDATE
export const setInitPagesNum = () => {
  let currPages = {
    report: 1,
    request: 1,
    sampling: 1,
    testing: 1,
    transfer: 1,
    lateTransfer: 1,
    release: 1,
    todo: 1,
    monitoring: 1,
  };
  localStorage.setItem("currentPage", JSON.stringify(currPages));
};

export const setPagesNum = (pageName, currentPage) => {
  const pagesData = JSON.parse(localStorage.getItem("currentPage"));
  let currPages;
  if (!pagesData) {
    setInitPagesNum();
  } else {
    if (pageName === "report") {
      currPages = {
        ...pagesData,
        report: currentPage,
      };
    }
    if (pageName === "request") {
      currPages = {
        ...pagesData,
        request: currentPage,
      };
    }
    if (pageName === "sampling") {
      currPages = {
        ...pagesData,
        sampling: currentPage,
      };
    }
    if (pageName === "testing") {
      currPages = {
        ...pagesData,
        testing: currentPage,
      };
    }
    if (pageName === "transfer") {
      currPages = {
        ...pagesData,
        transfer: currentPage,
      };
    }
    if (pageName === "late-transfer") {
      currPages = {
        ...pagesData,
        lateTransfer: currentPage,
      };
    }
    if (pageName === "release") {
      currPages = {
        ...pagesData,
        release: currentPage,
      };
    }
    if (pageName === "todo") {
      currPages = {
        ...pagesData,
        todo: currentPage,
      };
    }
    if (pageName === "monitoring") {
      currPages = {
        ...pagesData,
        monitoring: currentPage,
      };
    }
    localStorage.setItem("currentPage", JSON.stringify(currPages));
  }
};

export const setInitFilter = () => {
  let defaultFilter = {
    search: "",
    limit: 5,
    page: 1,
    startDate: ConvertDateToString(new Date(new Date().getFullYear(), 0, 1)),
    endDate: ConvertDateToString(new Date()),
    status: "",
    typeRequestId: "",
    organizationVal: null,
    samplingTypeVal: null,
  };
  let filter = {
    report: defaultFilter,
    request: defaultFilter,
    sampling: defaultFilter,
    testing: defaultFilter,
    transfer: defaultFilter,
    lateTransfer: defaultFilter,
    release: defaultFilter,
    todo: defaultFilter,
    monitoring: defaultFilter,
  };
  localStorage.setItem("filter", JSON.stringify(filter));
};

export const setFilter = (pageName, currentFilter) => {
  let currFilter;
  const filterData = JSON.parse(localStorage.getItem("filter"));
  if (!filterData) {
    setInitFilter(pageName);
  } else {
    if (pageName === "report") {
      currFilter = {
        ...filterData,
        report: currentFilter,
      };
    }
    if (pageName === "request") {
      currFilter = {
        ...filterData,
        request: currentFilter,
      };
    }
    if (pageName === "sampling") {
      currFilter = {
        ...filterData,
        sampling: currentFilter,
      };
    }
    if (pageName === "testing") {
      currFilter = {
        ...filterData,
        testing: currentFilter,
      };
    }
    if (pageName === "transfer") {
      currFilter = {
        ...filterData,
        transfer: currentFilter,
      };
    }
    if (pageName === "late-transfer") {
      currFilter = {
        ...filterData,
        lateTransfer: currentFilter,
      };
    }
    if (pageName === "release") {
      currFilter = {
        ...filterData,
        release: currentFilter,
      };
    }
    if (pageName === "todo") {
      currFilter = {
        ...filterData,
        todo: currentFilter,
      };
    }
    if (pageName === "monitoring") {
      currFilter = {
        ...filterData,
        monitoring: currentFilter,
      };
    }
    localStorage.setItem("filter", JSON.stringify(currFilter));
  }
};

export const useHasAllowedAction = (allowedActionCode) => {
  const userAction = useSelector(getUserAction);
  const matchAction = userAction.find(
    (action) => action.userActionCode === allowedActionCode
  );

  return !!matchAction?.status;
};
