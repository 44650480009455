import React, { useState, useEffect } from "react";
import "../../../../assets/css/Organization.css";
import CreateModalDialog from "./Create";
import ModalConfirmation from "../../../../components/Sections/modal/modalConfirmation";
import WorkflowCardDetailSkeleton from "../../../../components/loaders/skeletons/WorkflowCardDetailSkeleton";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  BiTrashAlt,
  BiEditAlt,
  BiChevronDown,
  BiArchiveIn,
} from "react-icons/bi";
import { IoIosArrowDropup } from "react-icons/io";
import { useTable, usePagination } from "react-table";
import { getNikUser, getUserRole, userHasAccess } from "../../../../utils/User";
import { Role } from "../../../../utils/ConstantGlobal";
import {
  Flex,
  Input,
  InputGroup,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  InputLeftElement,
  InputRightElement,
  Checkbox,
  CheckboxGroup,
  Stack,
  useDisclosure,
  Button,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import {
  GetListMedia,
  InsertMedia,
  UpdateMedia,
  ArchiveMedia,
  ActivateMedia,
  GetListActiveMedia,
} from "../../../../services/actions/QAS/Media";
import moment from "moment";

const ReleaseMedia = () => {
  const Roles = useSelector(getUserRole);
  const NIK = useSelector(getNikUser);
  const {
    isOpen: isOpenCRUD,
    onOpen: onOpenCRUD,
    onClose: onCloseCRUD,
  } = useDisclosure();
  const {
    isOpen: isOpenApproval,
    onOpen: onOpenApproval,
    onClose: onCloseApproval,
  } = useDisclosure();
  const [listData, setListData] = useState("");
  const [dataList, setDataList] = useState([]);
  const [dataFilterText, setDataFilterText] = useState([]);
  const [activeStatus, setActiveStatus] = useState([]);
  const [archiveStatus, setArchiveStatus] = useState([]);
  const [activeStatusText, setActiveStatusText] = useState(" Active");
  const [archiveStatusText, setArchiveStatusText] = useState("");
  const [filterMerge, setFilterMerge] = useState([]);
  const [loading_card_status, setLoading_card_status] = useState(false);
  const [shouldRequest, setShouldRequest] = useState(true);
  const [viewType, setViewType] = useState("create");
  const [viewTypeApproval, setViewTypeApproval] = useState("Archive");
  const [messageApproval, setMessageApproval] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [listActiveMedia, setListActiveMedia] = useState("");
  const [id, setId] = useState({
    id: "",
    approvalType: "",
  });
  const [isLoading, setIsloading] = useState(false);
  const [detail, setDetail] = useState({
    id: 0,
    dataId: "",
    dataName: "",
    objectId: "",
    objectStatus: "",
  });

  // Fungsi untuk mendapatkan List Organisasi dari API QAS
  async function GetList() {
    try {
      setLoading_card_status(true);
      const allData = await GetListMedia();
      const newArray = allData;

      setListData(newArray);

      const filterDraft = await allData.filter(
        (things) => !things.objectStatus.includes("DRAFT")
      );
      setDataList(filterDraft);

      const filterDraft2 = await allData.filter(
        (things) => !things.objectStatus.includes("DRAFT")
      );
      setDataFilterText(filterDraft2);

      const filterActiveStat = await allData.filter((things) =>
        things.objectStatus.includes("ACTIVE")
      );

      setActiveStatus(filterActiveStat);
    } catch (error) {}
    setTimeout(() => {
      setLoading_card_status(false);
    }, 2000);
  }

  // Fungsi untuk handle submit Create Data
  async function handleSubmitAdd(values) {
    values.itemBatch = values.itemBatch.replace(/\s{2,}/g, " ").trim();

    try {
      let completeValues = {};

      if (values.type === "create") {
        completeValues = {
          itemId: parseInt(values.itemId.value),
          batchNumber: values.itemBatch,
          quantity: values.quantity,
          expDate: values.itemDate,
          releaseDate: values.releaseDate,
          createdBy: NIK,
        };
        const response = await InsertMedia(completeValues);

        if (response.data?.statusCode === 200) {
          toast.success(response.data.message);

          setTimeout(() => {
            onCloseCRUD();
            GetList();
          }, 2000);
        } else {
          toast.error(response.response.data.message);
        }
      } else {
        completeValues = {
          id: values.id,
          itemId: parseInt(values.itemId.value),
          batchNumber: values.itemBatch,
          quantity: values.quantity,
          expDate: values.itemDate,
          releaseDate: values.releaseDate,
          updatedBy: NIK,
        };
        const response = await UpdateMedia(completeValues);

        if (response.data?.statusCode === 200) {
          toast.success(response.data.message);
          setTimeout(() => {
            onCloseCRUD();
            window.location = "/qas/release/media";
          }, 2000);
        } else {
          toast.error(response.response.data.message);
        }
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  }

  // Fungsi untuk Archive dan Activate Data
  const handleSubmitApproval = async (values) => {
    const payload = {
      id: values.id,
      updatedBy: NIK,
    };
    let response;

    try {
      if (values.approvalType === "archive") {
        response = await ArchiveMedia(payload);
      } else if (values.approvalType === "activate") {
        response = await ActivateMedia(payload);
      }

      toast.success(response.data.message);

      setTimeout(() => {
        setIsloading(false);
        onCloseApproval();
        window.location = "/qas/release/media";
      }, 2000);
    } catch (error) {
      toast.error(error.response.data.message);

      setTimeout(() => {
        setIsloading(false);
      }, 2000);
    }
  };

  // Fungsi untuk filter Data Code dan Data ID
  const TypeFilter = (e) => {
    let originalList2 = dataList;

    setDataFilterText(
      originalList2.filter(
        (things) =>
          things.itemCode
            .toLowerCase()
            .includes(e.target.value.toLowerCase()) ||
          things.batchNumber
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
      )
    );
  };

  // Fungsi untuk memasukkan data Active ke list
  const ActiveFilter = (e) => {
    const filterActive = dataList.filter((things) =>
      things.objectStatus.toLowerCase().includes(e.target.value.toLowerCase())
    );
    if (e.target.checked === true) {
      setActiveStatusText(" Active");
      setActiveStatus(filterActive);
    } else {
      setActiveStatusText("");
      setActiveStatus([]);
    }
  };

  // Fungsi untuk memasukkan data Archive ke list
  const ArchiveFilter = (e) => {
    const filterArchive = dataList.filter((things) =>
      things.objectStatus.toLowerCase().includes(e.target.value.toLowerCase())
    );
    if (e.target.checked === true) {
      setArchiveStatusText("Archive");
      setArchiveStatus(filterArchive);
    } else {
      setArchiveStatusText("");
      setArchiveStatus([]);
    }
  };

  // Fungsi untuk Menampilkan Table
  function Table({ columns, data }) {
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,
      canPreviousPage,
      canNextPage,
      state: { pageIndex },
    } = useTable(
      {
        columns,
        autoResetPage: false,
        data: data ? data : [{}],
        initialState: { pageIndex: currentPage },
      },
      usePagination
    );

    // Render the UI for your table
    return (
      <>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps({
                      style: { minWidth: column.minWidth, width: column.width },
                    })}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
            {page?.length < 1 && (
              <tr>
                <td
                  colSpan={
                    headerGroups ? headerGroups[0]?.headers?.length + 1 : 3
                  }
                  style={{ textAlign: "center" }}
                >
                  {listData.length === 0 ? (
                    <p>There are no records to display</p>
                  ) : (
                    <p>Data Not Found</p>
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div style={{ textAlign: "right" }}>
          <div className="table-pagination">
            <button
              onClick={() => {
                setCurrentPage(currentPage - 1);
              }}
              disabled={!canPreviousPage}
              style={{ borderRight: "1px solid #cccc" }}
              className={!canPreviousPage ? "page-disabled" : ""}
            >
              {"< "}
            </button>
            <span className="paging-number">{pageIndex + 1}</span>
            <button
              onClick={() => {
                setCurrentPage(currentPage + 1);
              }}
              disabled={!canNextPage}
              style={{ borderLeft: "1px solid #cccc" }}
              className={!canNextPage ? "page-disabled" : ""}
            >
              {" >"}
            </button>
          </div>
        </div>
      </>
    );
  }

  // Fungsi untuk memasukkan data kolom ke table List Organisasi
  const columns = [
    {
      Header: "ITEM ID",
      accessor: "itemCode",
      sortType: "basic",
      width: 200,
      minWidth: 150,
    },
    {
      Header: "ITEM NAME",
      accessor: "itemNameIdn",
      sortType: "basic",
      width: 200,
      minWidth: 150,
    },
    {
      Header: "BATCH NUMBER",
      accessor: "batchNumber",
      width: 300,
      minWidth: 250,
    },
    {
      Header: "RELEASE DATE",
      accessor: "releaseDate",
      width: 300,
      minWidth: 250,
      Cell: (row) => {
        return <>{moment(row.value).format("DD MMM YYYY")}</>;
      },
    },
    {
      Header: "EXPIRED DATE",
      accessor: "expDate",
      width: 300,
      minWidth: 250,
      Cell: (row) => {
        return <>{moment(row.value).format("DD MMM YYYY")}</>;
      },
    },
    {
      Header: "STATUS",
      accessor: "objectStatus",
      width: 150,
      minWidth: 100,
      Cell: (row) => {
        return row.value === "ACTIVE" ? (
          <div className="statActive">Active</div>
        ) : row.value === "ARCHIVE" ? (
          <div className="statArchive">Archive</div>
        ) : row.value === "DRAFT" ? (
          <div className="statDraft">Draft</div>
        ) : row.value === "CANCEL" ? (
          <div className="statCancel">Cancel</div>
        ) : null;
      },
    },
  ];

  if (userHasAccess(Roles, Role.OperatorMedia)) {
    columns.push({
      Header: "ACTION",
      id: "action",
      accessor: "objectStatus",
      width: 100,
      minWidth: 80,
      Cell: (row) => {
        return row.value === "ACTIVE" ? (
          <div>
            <button
              className={`btn-cm-edit`}
              title="Update"
              onClick={() => {
                const data = row.row.original;

                setDetail({
                  ...detail,
                  id: data.id,
                  itemId: data.itemId,
                  itemBatch: data.batchNumber,
                  quantity: data.itemMediaQuantity,
                  itemDate: data.expDate,
                  releaseDate: data.releaseDate,
                });

                setViewType("update");
                onOpenCRUD();
              }}
            >
              <BiEditAlt color="inherit" />
            </button>
            <button
              className="btn-cm-archive"
              title="Archive"
              onClick={() => {
                const message = `${row.row.original.itemNameIdn} - ${row.row.original.batchNumber}`;
                const rowObj = row.row.original.id;

                setId({
                  id: rowObj,
                  approvalType: "archive",
                });
                setViewTypeApproval("Archive");
                setMessageApproval(message);
                onOpenApproval();
              }}
            >
              <BiArchiveIn color="inherit" />
            </button>
          </div>
        ) : row.value === "ARCHIVE" ? (
          <div>
            <div
              className="btn-cm-activate"
              title="Activate"
              onClick={() => {
                const message = `${row.row.original.itemNameIdn} - ${row.row.original.batchNumber}`;
                const rowObj = row.row.original.id;

                setId({
                  id: rowObj,
                  approvalType: "activate",
                });
                setViewTypeApproval("Activate");
                setMessageApproval(message);
                onOpenApproval();
              }}
            >
              <IoIosArrowDropup color="inherit" />
            </div>
          </div>
        ) : row.value === "CANCEL" ? (
          <div>
            <div className="btn-cm-disabled" title="Edit">
              <BiEditAlt color="inherit" />
            </div>
            <div className="btn-cm-disabled" title="Delete">
              <BiTrashAlt color="inherit" />
            </div>
          </div>
        ) : (
          "-"
        );
      },
    });
  }

  // Fungsi untuk memasukkan data Merge semua Filter ke list
  const MergeAllFilter = () => {
    const mergeAllFilter = [
      ...dataFilterText,
      ...activeStatus,
      ...archiveStatus,
    ];

    // Fungsi untuk melakukan merge filter semua list
    const seen = new Set();
    const filteredArr1 = mergeAllFilter.filter((el) => {
      const duplicate = seen.has(el.id);
      seen.add(el.id);
      return duplicate;
    });

    const filteredArr23 = filteredArr1.reduce((acc, current) => {
      const x = acc.find((item) => item.id === current.id);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

    const sortedList = filteredArr23.sort(
      (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
    );

    setFilterMerge(filteredArr23);
    setCurrentPage(0);
  };

  //render search bar
  const renderSearchBar = () => {
    return (
      <Flex align="flex-end">
        <InputGroup style={{ marginRight: "5px" }}>
          <Input placeholder="Search..." onChange={TypeFilter} />
        </InputGroup>
        <div className="popupSearch-container">
          <Popover placement="bottom-start" matchWidth>
            <PopoverTrigger>
              <InputGroup style={{ marginLeft: "5px", color: "#000" }}>
                <InputLeftElement children="Status : " width="5rem" />
                <Input
                  value={`${activeStatusText}${
                    activeStatusText === ""
                      ? ""
                      : archiveStatusText !== ""
                      ? ","
                      : ""
                  } ${archiveStatusText}${archiveStatusText === "" ? "" : ""}`}
                  className="o-popupSearch"
                  paddingLeft="72px"
                  disabled
                />
                <InputRightElement children={<BiChevronDown color="black" />} />
              </InputGroup>
            </PopoverTrigger>
            <PopoverContent width="inherit">
              <PopoverBody>
                <CheckboxGroup colorScheme="blue" defaultValue={["Active"]}>
                  <Stack>
                    <Checkbox value="Active" onChange={ActiveFilter.bind(this)}>
                      Active
                    </Checkbox>
                    <Checkbox
                      value="Archive"
                      onChange={ArchiveFilter.bind(this)}
                    >
                      Archive
                    </Checkbox>
                  </Stack>
                </CheckboxGroup>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </div>
      </Flex>
    );
  };

  //render create
  const renderCreateBar = () => {
    return (
      <Flex align="right">
        {userHasAccess(Roles, Role.OperatorMedia) && (
          <Button
            title="Create Media"
            variant="solid"
            className="float-right c-btn-add"
            style={{
              backgroundColor: "#2AC0D9",
              color: "#fff",
              marginTop: "1px",
              display: "inline-block",
              fontWeight: "500",
            }}
            onClick={() => {
              setViewType("create");
              onOpenCRUD();
            }}
          >
            Create
          </Button>
        )}
      </Flex>
    );
  };

  // Fungsi untuk mendapatkan list Active Media
  async function GetActiveMedia() {
    try {
      const activeMedia = await GetListActiveMedia();

      const options = activeMedia.map((d) => ({
        value: d.id,
        label: `${d.itemCode} - ${d.itemNameIdn}`,
      }));

      setListActiveMedia(options);
      return;
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    MergeAllFilter();
  }, [dataFilterText, activeStatus, archiveStatus]);

  useEffect(() => {
    GetList();
    MergeAllFilter();
    GetActiveMedia();
  }, []);

  useEffect(() => {
    if (shouldRequest) {
      setShouldRequest(false);
    }
  }, [shouldRequest]);

  return (
    <div className="main-content-wrapper list-organization">
      {loading_card_status ? (
        <WorkflowCardDetailSkeleton />
      ) : (
        <>
          <Flex
            align="center"
            justify="space-between"
            style={{ margin: "0 15px 15px 15px" }}
          >
            {renderSearchBar()}
            {renderCreateBar()}
          </Flex>

          <div className="table-container">
            <Table
              columns={columns}
              data={listData.length === 0 ? listData : filterMerge}
            />
          </div>
          <CreateModalDialog
            mediaList={activeStatus}
            handleSubmitAdd={handleSubmitAdd}
            blockScrohnjllOnMount={false}
            data={detail}
            isOpen={isOpenCRUD}
            onClose={onCloseCRUD}
            viewType={viewType}
            listActiveMedia={listActiveMedia}
          />
          <ModalConfirmation
            onClickYes={() => {
              handleSubmitApproval(id);
            }}
            modalType={viewTypeApproval}
            message={messageApproval}
            isOpen={isOpenApproval}
            onClose={onCloseApproval}
            isLoading={isLoading}
            setIsloading={setIsloading}
          />
        </>
      )}
    </div>
  );
};

export default ReleaseMedia;
