import { lazy } from "react";
import { componentLoader } from "utils/Helpers";

/*
  Note: i cant find way to make promise function with params because the imports path cant be dynamic.
  maybe the problem is we still using webpack 4, because when i try dynamic imports path in NEXT with webpack 5, it works. Or maybe because NEXT JS is Server Side not Client Side like CRA.
*/

// QCS Components
// Request EM
export const ListQCRequest = lazy(() =>
  componentLoader(() => import("../../pages/QCS/Request/EM"))
);
export const FormRequest = lazy(() =>
  componentLoader(() => import("../../pages/QCS/Request/EM/FormRequest"))
);

// Request Production
export const ListQCRequestProduction = lazy(() =>
  componentLoader(() => import("../../pages/QCS/Request/Production"))
);
export const FormRequestProduction = lazy(() =>
  componentLoader(() =>
    import("../../pages/QCS/Request/Production/FormRequest")
  )
);

// Request Product QC
export const ListRequestQCProduct = lazy(() =>
  componentLoader(() => import("../../pages/QCS/Request/Product"))
);
export const FormRequestQCProduct = lazy(() =>
  componentLoader(() => import("../../pages/QCS/Request/Product/FormRequest"))
);

// Sampling EM
export const ListSampling = lazy(() =>
  componentLoader(() => import("../../pages/QCS/Sampling/EM"))
);
export const FormSampling = lazy(() =>
  componentLoader(() => import("../../pages/QCS/Sampling/EM/FormSampling"))
);

// Sampling Product
export const ListSamplingProduct = lazy(() =>
  componentLoader(() => import("../../pages/QCS/Sampling/Product"))
);
export const FormSamplingProduct = lazy(() =>
  componentLoader(() => import("../../pages/QCS/Sampling/Product/FormProduct"))
);

// Sampling Production
export const ListSamplingProduction = lazy(() =>
  componentLoader(() => import("../../pages/QCS/Sampling/Product"))
);
export const FormSamplingProduction = lazy(() =>
  componentLoader(() => import("../../pages/QCS/Sampling/Product/FormProduct"))
);

export const ListSamplingTransfer = lazy(() =>
  componentLoader(() => import("../../pages/QCS/Sampling/Transfer"))
);
export const FormSamplingTransfer = lazy(() =>
  componentLoader(() =>
    import("../../pages/QCS/Sampling/Transfer/FormSampling")
  )
);
export const ScannerSamplingTransfer = lazy(() =>
  componentLoader(() =>
    import("../../pages/QCS/Sampling/Transfer/LabelScanner")
  )
);

// export const ListSamplingProduction = lazy(() =>
//   componentLoader(() => import("../../pages/QCS/Sampling/Production"))
// );
// export const FormSamplingProduction = lazy(() =>
//   componentLoader(() =>
//     import("../../pages/QCS/Sampling/Production/FormSampling")
//   )
// );

// Transfer EM
export const ListSample = lazy(() =>
  componentLoader(() => import("../../pages/QCS/Transfer/EM"))
);
export const LabelScanner = lazy(() =>
  componentLoader(() => import("../../pages/QCS/Transfer/EM/LabelScanner"))
);
export const FormTransferedSample = lazy(() =>
  componentLoader(() =>
    import("../../pages/QCS/Transfer/EM/FormTransferedSample")
  )
);

// Transfer Production
export const ListSampleProduction = lazy(() =>
  componentLoader(() => import("../../pages/QCS/Transfer/Production"))
);
export const LabelScannerProduction = lazy(() =>
  componentLoader(() =>
    import("../../pages/QCS/Transfer/Production/LabelScanner")
  )
);
export const FormTransferProduction = lazy(() =>
  componentLoader(() =>
    import("../../pages/QCS/Transfer/Production/FormTransfer")
  )
);

// Testing EM
export const ListTesting = lazy(() =>
  componentLoader(() => import("../../pages/QCS/Testing/EM"))
);
export const FormQCTest = lazy(() =>
  componentLoader(() => import("../../pages/QCS/Testing/EM/FormQCTest"))
);
export const StartTest = lazy(() =>
  componentLoader(() => import("../../pages/QCS/Testing/EM/StartTestForm"))
);

// Testing Production
export const ListTestingProduction = lazy(() =>
  componentLoader(() => import("../../pages/QCS/Testing/Production"))
);
export const FormQCTestProduction = lazy(() =>
  componentLoader(() => import("../../pages/QCS/Testing/Production/FormQCTest"))
);
export const FormTestQCProduction = lazy(() =>
  componentLoader(() =>
    import("../../pages/QCS/Testing/Production/pages/FormTestQCProduction")
  )
);
export const StartTestQCProduction = lazy(() =>
  componentLoader(() =>
    import("../../pages/QCS/Testing/Production/pages/StartTestQC")
  )
);
export const OperatorTestingFormDetail = lazy(() =>
  componentLoader(() =>
    import("../../pages/QCS/Testing/Production/pages/FormTestQCDetail")
  )
);
export const StartTestProduction = lazy(() =>
  componentLoader(() =>
    import("../../pages/QCS/Testing/Production/StartTestForm")
  )
);

// Testing Water Monitoring
export const ListTestingWaterMonitoring = lazy(() =>
  componentLoader(() => import("../../pages/QCS/Testing/WaterMonitoring"))
);
export const FormQCTestWaterMonitoring = lazy(() =>
  componentLoader(() =>
    import("../../pages/QCS/Testing/WaterMonitoring/FormTest")
  )
);
export const StartTestWaterMonitoring = lazy(() =>
  componentLoader(() =>
    import("../../pages/QCS/Testing/WaterMonitoring/StartFormTest")
  )
);

//Review Water MOnitoring
export const ListReviewWaterMonitoring = lazy(() =>
  componentLoader(() => import("../../pages/QCS/Review/WaterMonitoring"))
);

//Deviation Water MOnitoring
export const ListDeviationsWaterMonitoring = lazy(() =>
  componentLoader(() => import("../../pages/QCS/Deviations/WaterMonitoring"))
);
export const ViewDeviationsWaterMonitoring = lazy(() =>
  componentLoader(() =>
    import("../../pages/QCS/Deviations/WaterMonitoring/View")
  )
);

// Report EM
export const ReportEM = lazy(() =>
  componentLoader(() => import("../../pages/QCS/Report/EM"))
);

// QCS Components Release
export const ReleaseQC = lazy(() =>
  componentLoader(() => import("../../pages/QCS/Release/QC"))
);

// QCS Components Monitoring
export const MonitoringQC = lazy(() =>
  componentLoader(() => import("../../pages/QCS/Monitoring/QC"))
);

// QCS Components Monitoring
export const TodoQC = lazy(() =>
  componentLoader(() => import("../../pages/QCS/Todo/QC"))
);
export const TodoSchedule = lazy(() =>
  componentLoader(() => import("../../pages/QCS/Todo/Schedule"))
);
export const TodoScheduleDetail = lazy(() =>
  componentLoader(() =>
    import("../../pages/QCS/Todo/Schedule/DetailScheduleTodo")
  )
);
export const TodoWaterMonitoring = lazy(() =>
  componentLoader(() => import("../../pages/QCS/Todo/WaterMonitoring"))
);
export const TodoWaterMonitoringDetail = lazy(() =>
  componentLoader(() => import("../../pages/QCS/Todo/WaterMonitoring/View"))
);
export const Scheduled = lazy(() =>
  componentLoader(() => import("../../pages/QCS/WaterMonitoring/Schedule"))
);
export const ScheduledForm = lazy(() =>
  componentLoader(() =>
    import("../../pages/QCS/WaterMonitoring/Schedule/Forms/FormWaterMonitoring")
  )
);
export const DepryogenationSterilization = lazy(() =>
  componentLoader(() =>
    import("../../pages/QCS/WaterMonitoring/DepryogenationSterilization")
  )
);
export const DepryogenationSterilizationForm = lazy(() =>
  componentLoader(() =>
    import(
      "../../pages/QCS/WaterMonitoring/DepryogenationSterilization/Forms/FormDepryogenationSterilization"
    )
  )
);

// QCS Components Detail Monitoring
export const DetailQCMonitoring = lazy(() =>
  componentLoader(() =>
    import("../../pages/QCS/Monitoring/QC/DetailQCMonitoring")
  )
);

// QCS Components Detail Todo
export const DetailQCTodo = lazy(() =>
  componentLoader(() => import("../../pages/QCS/Todo/QC/DetailQCTodo"))
);

// QCS Components Detail Release
export const DetailQCRelease = lazy(() =>
  componentLoader(() => import("../../pages/QCS/Release/QC/DetailQCRelease"))
);

// QCS Sample Management
export const ListSampleQC = lazy(() =>
  componentLoader(() => import("../../pages/QCS/Sample/Production"))
);
export const DetailListSampleQC = lazy(() =>
  componentLoader(() =>
    import("../../pages/QCS/Sample/Production/listPerBatch")
  )
);
export const FormCreateSample = lazy(() =>
  componentLoader(() => import("../../pages/QCS/Sample/Production/FormRequest"))
);
export const ListSampleMaster = lazy(() =>
  componentLoader(() => import("../../pages/QCS/Sample/Master"))
);
export const ListSampleTransaction = lazy(() =>
  componentLoader(() => import("../../pages/QCS/Sample/Transaction"))
);
export const ListSampleOnHand = lazy(() =>
  componentLoader(() => import("../../pages/QCS/Sample/OnHand"))
);
export const FormSampleManagement = lazy(() =>
  componentLoader(() => import("../../pages/QCS/Sample/FormSampleManagement"))
);

// GCS Template Testing
export const ListTemplateTesting = lazy(() =>
  componentLoader(() => import("../../pages/QCS/Testing/Template"))
);
export const FormTemplateTesting = lazy(() =>
  componentLoader(() => import("../../pages/QCS/Testing/Template/FormTemplate"))
);

// QCS Sampling Plan Raw Material
export const ListSamplingPlanRawMaterial = lazy(() =>
  componentLoader(() => import("../../pages/QCS/Sampling/RawMaterial"))
);

export const FormSamplingPlanRawMaterial = lazy(() =>
  componentLoader(() =>
    import("../../pages/QCS/Sampling/RawMaterial/FormSampling")
  )
);

// QCS Request Raw Material
export const ListQCRequestRawMaterial = lazy(() =>
  componentLoader(() => import("../../pages/QCS/Request/RawMaterial"))
);

export const FormQCResquestRawMaterial = lazy(() =>
  componentLoader(() =>
    import("../../pages/QCS/Request/RawMaterial/FormRequest")
  )
);

export const FormQCResquestSamplingRawMaterial = lazy(() =>
  componentLoader(() =>
    import("../../pages/QCS/Request/RawMaterial/FormRequestSampling")
  )
);

/** QCS Deviation Product */
// List
export const ListDeviationProduct = lazy(() =>
  componentLoader(() => import("../../pages/QCS/Deviations/Product"))
);
// Detail
export const DetailDeviationProduct = lazy(() =>
  componentLoader(() =>
    import("../../pages/QCS/Deviations/Product/DetailDeviation")
  )
);

// QCS Product Deviation
export const ListDeviation = lazy(() =>
  componentLoader(() => import("../../pages/QCS/Deviation/DeviationList"))
);
// QCS Sample Water Monitoring
export const ListSamplingWaterMonitoring = lazy(() =>
  componentLoader(() => import("../../pages/QCS/Sampling/WaterMonitoring"))
);
export const FormSamplingWaterMonitoring = lazy(() =>
  componentLoader(() =>
    import(
      "../../pages/QCS/Sampling/WaterMonitoring/Form/FormSamplingWaterMonitoring"
    )
  )
);

// QCS Request Water Monitoring
export const ListRequestWaterMonitoring = lazy(() =>
  componentLoader(() => import("../../pages/QCS/Request/WaterMonitoring"))
);
export const FormRequestWaterMonitoring = lazy(() =>
  componentLoader(() =>
    import(
      "../../pages/QCS/Request/WaterMonitoring/Form/FormRequestWaterMonitoring"
    )
  )
);

// QCS Sampling Raw Material
export const ListSamplingRawMaterial = lazy(() =>
  componentLoader(() => import("../../pages/QCS/Sampling/RawMaterial"))
);

export const FormSamplingRawMaterial = lazy(() =>
  componentLoader(() =>
    import("../../pages/QCS/Sampling/RawMaterial/FormSampling")
  )
);

//QCS transfer water monitoring
export const ListTransferWaterMonitoring = lazy(() =>
  componentLoader(() => import("../../pages/QCS/Transfer/WaterMonitoring"))
);

export const FormTransferWaterMonitoring = lazy(() =>
  componentLoader(() =>
    import("../../pages/QCS/Transfer/WaterMonitoring/FormTransfer")
  )
);

export const ListMonitoringWaterMonitoring = lazy(() =>
  componentLoader(() => import("../../pages/QCS/Monitoring/WaterMonitoring"))
);

export const ListSampleManagementWaterMonitoring = lazy(() =>
  componentLoader(() =>
    import("../../pages/QCS/SampleManagement/WaterMonitoring")
  )
);

export const DetailSampleManagementWaterMonitoring = lazy(() =>
  componentLoader(() =>
    import(
      "../../pages/QCS/SampleManagement/WaterMonitoring/Detail/DetailSampleManagementWaterMonitoring"
    )
  )
);

// QCS Sample Management Raw Material
export const ListSampleManagementRawMaterial = lazy(() =>
  componentLoader(() => import("../../pages/QCS/Sample/RawMaterial"))
);

export const ListSampleManagementRawMaterialMaster = lazy(() =>
  componentLoader(() =>
    import("../../pages/QCS/Sample/RawMaterial/SampleMaster")
  )
);

export const FormRetainedSample = lazy(() =>
  componentLoader(() =>
    import("../../pages/QCS/Sample/RawMaterial/FormRetainedSample")
  )
);

// QCS Testing Raw Material
export const ListTestingRawMaterial = lazy(() =>
  componentLoader(() => import("../../pages/QCS/Testing/RawMaterial"))
);

export const FormTestRawMaterial = lazy(() =>
  componentLoader(() => import("../../pages/QCS/Testing/RawMaterial/FormTest"))
);

export const FormStartTestRawMaterial = lazy(() =>
  componentLoader(() =>
    import("../../pages/QCS/Testing/RawMaterial/StartFormTest")
  )
);

// QCS Transfer Raw Material
export const ListTransferRawMaterial = lazy(() =>
  componentLoader(() => import("../../pages/QCS/Transfer/RawMaterial"))
);

export const FormTransferRawMaterial = lazy(() =>
  componentLoader(() =>
    import("../../pages/QCS/Transfer/RawMaterial/FormTransfer")
  )
);

// QCS Review Raw Material
export const ListReviewRawMaterial = lazy(() =>
  componentLoader(() => import("../../pages/QCS/Review/RawMaterial"))
);

export const DetailReviewMenu = lazy(() =>
  componentLoader(() =>
    import("../../pages/QCS/Review/RawMaterial/DetailReviewMenu")
  )
);

// QCS Deviation Raw Material
export const ListDeviationRawMaterial = lazy(() =>
  componentLoader(() => import("../../pages/QCS/Deviations/RawMaterial"))
);

export const ViewDeviationsRawMaterial = lazy(() =>
  componentLoader(() =>
    import("../../pages/QCS/Deviations/RawMaterial/DetailDeviation")
  )
);

// QCS Todo Raw Material
export const ListTodoRawMaterial = lazy(() =>
  componentLoader(() => import("../../pages/QCS/Todo/RawMaterial"))
);

export const DetailTodoRawMaterial = lazy(() =>
  componentLoader(() =>
    import("../../pages/QCS/Todo/RawMaterial/DetailTodoMenu")
  )
);
