import {
  SET_CURRENT_REQUEST_DATE,
  SET_CURRENT_REQUEST_ID,
  SET_CURRENT_REQUEST_NUMBER,
  SET_CURRENT_ORGANIZATION,
  SET_CURRENT_ITEM,
  SET_CURRENT_TYPE_FORM,
  SET_CURRENT_BATCHNUMBER,
  SET_CURRENT_REQUEST_TYPE,
  SET_CURRENT_PRODUCT_FORM,
  SET_CURRENT_PRODUCT_PHASE,
  SET_CURRENT_TEST_SCENARIO,
  SET_CURRENT_EM_PHASE,
  SET_CURRENT_STORAGE_TEMPERATURE,
  SET_CURRENT_PURPOSE,
  SET_CURRENT_EM_ROOM,
  SET_CURRENT_EM_ROOM_GRADE,
  SET_TEST_TYPE_QCS,
  SET_CURRENT_PRODUCTION_ROOM,
  SET_CURRENT_NEW_TEST_SCENARIO,
  SET_CURRENT_AHU,
  SET_CURRENT_FACILITY,
  SET_CURRENT_MULTI_ROOM,
  SET_CURRENT_PRODUCT_GROUP,
  SET_CURRENT_PRODUCT_PRESENTATION,
  SET_CURRENT_LOCATION,
  SET_CURRENT_ITEM_TEMPERATURE,
  SET_CURRENT_PROCESS,
  SET_CURRENT_BATCH,
  SET_CURRENT_LINES,
  TEST_DATA,
} from "./actionTypes";

const INITIAL_STATE = {
  currentRequestId: "",
  currentRequestNumber: "",
  currentRequestDate: new Date().toISOString().split("T")[0],
  currentOrganization: {
    id: "",
    name: "",
  },
  currentItem: {
    id: "",
    name: "",
  },
  currentTypeForm: {
    id: "",
    name: "",
  },
  currentBatchNumber: "",
  currentRequestTypes: {
    id: "",
    name: "",
  },
  currentProductForm: {
    id: "",
    name: "",
  },
  currentProductPhase: {
    id: "",
    name: "",
  },
  currentTestScenario: {
    id: "",
    name: "",
    label: "",
    testScenarioLabel: "",
    value: "",
  },
  currentEmPhase: {
    id: "",
    name: "",
  },
  currentStorageTemperature: {
    id: null,
    name: null,
  },
  currentPurpose: [],
  currentEmRoom: {
    id: 0,
    name: "",
  },
  currentProductionRoom: [],
  currentEmRoomGrade: {
    id: 0,
    name: "",
  },
  currentTestTypesQcs: [
    {
      orgId: "",
      orgName: "",
      sampleAmountCount: 0,
      sampleAmountUnit: "",
      sampleAmountVolume: 0,
      testTypeId: "",
      testTypeName: "",
      testParameterId: 0,
      testParameterName: "",
    },
  ],
  currentNewTestScenario: {
    id: "",
    name: "",
    label: "",
    testScenarioLabel: "",
    value: "",
  },
  currentFacility: {
    id: 0,
    name: "",
    label: "",
    code: "",
  },
  currentAHU: [],
  currentMultiRoom: [],
  currentProductGroup: {
    id: "",
    name: "",
  },
  currentProductPresentation: {
    id: "",
    name: "",
  },
  currentLocation: "",
  currentItemTemperature: "",
  currentProcess: {
    id: null,
    name: "",
    date: new Date().toISOString().split("T")[0],
  },
  currentBatch: {
    attachmentNotes: "",
    lines: [
      {
        itemName: "",
        noBatch: "",
        notes: "",
      },
    ],
    attachments: [
      {
        title: "",
        attachmentFile: "",
        fileName: "",
        fileType: "",
      },
    ],
  },
  currentLines: [],
};

function requestReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TEST_DATA:
      state = {
        ...state,
        loading: true,
      };
      break;
    case SET_CURRENT_REQUEST_DATE:
      return {
        ...state,
        currentRequestDate: action.payload,
      };
    case SET_CURRENT_REQUEST_ID:
      return {
        ...state,
        currentRequestId: parseInt(action.payload),
      };
    case SET_CURRENT_REQUEST_NUMBER:
      return {
        ...state,
        currentRequestNumber: action.payload,
      };
    case SET_CURRENT_ORGANIZATION:
      return {
        ...state,
        currentOrganization: {
          id: parseInt(action.payload.id),
          name: action.payload.name,
        },
      };
    case SET_CURRENT_ITEM:
      return {
        ...state,
        currentItem: {
          id:
            action.payload.id === ""
              ? action.payload.id
              : parseInt(action.payload.id),
          name: action.payload.name,
        },
      };
    case SET_CURRENT_TYPE_FORM:
      return {
        ...state,
        currentTypeForm: {
          id:
            action.payload.id === ""
              ? action.payload.id
              : parseInt(action.payload.id),
          name: action.payload.name,
        },
      };
    case SET_CURRENT_TEST_SCENARIO:
      return {
        ...state,
        currentTestScenario: {
          id:
            action.payload.id === ""
              ? action.payload.id
              : parseInt(action.payload.id),
          name: action.payload.name,
          label: action.payload.label,
          testScenarioLabel: action.payload.testScenarioLabel,
          value: action.payload.value,
        },
      };
    case SET_CURRENT_BATCHNUMBER:
      return {
        ...state,
        currentBatchNumber: action.payload,
      };
    case SET_CURRENT_REQUEST_TYPE:
      if (action.callback !== undefined) {
        action.callback();
      }

      return {
        ...state,
        currentRequestTypes: {
          id:
            action.payload.id === ""
              ? action.payload.id
              : parseInt(action.payload.id),
          name: action.payload.name,
        },
      };
    case SET_CURRENT_PRODUCT_FORM:
      return {
        ...state,
        currentProductForm: {
          id:
            action.payload.id === ""
              ? action.payload.id
              : parseInt(action.payload.id),
          name: action.payload.name,
        },
      };
    case SET_CURRENT_PRODUCT_PHASE:
      if (action.callback !== undefined) {
        action.callback();
      }

      return {
        ...state,
        currentProductPhase: {
          id:
            action.payload.id === ""
              ? action.payload.id
              : parseInt(action.payload.id),
          name: action.payload.name,
        },
        currentEmPhase: {
          id: "",
          name: "",
        },
      };
    case SET_CURRENT_EM_PHASE:
      if (action.callback !== undefined) {
        action.callback();
      }
      return {
        ...state,
        currentEmPhase: {
          id:
            action.payload.id === ""
              ? action.payload.id
              : parseInt(action.payload.id),
          name: action.payload.name,
        },
        currentProductPhase: {
          id: "",
          name: "",
        },
      };
    case SET_CURRENT_STORAGE_TEMPERATURE:
      return {
        ...state,
        currentStorageTemperature: {
          id:
            action.payload.id === ""
              ? action.payload.id
              : parseInt(action.payload.id),
          name: action.payload.name,
        },
      };
    case SET_CURRENT_PURPOSE:
      return {
        ...state,
        currentPurpose: action.payload,
      };
    case SET_CURRENT_EM_ROOM:
      return {
        ...state,
        currentEmRoom: {
          id:
            action.payload.id === ""
              ? action.payload.id
              : parseInt(action.payload.id),
          name: action.payload.name,
        },
      };
    case SET_CURRENT_EM_ROOM_GRADE:
      return {
        ...state,
        currentEmRoomGrade: {
          id:
            action.payload.id === ""
              ? action.payload.id
              : parseInt(action.payload.id),
          name: action.payload.name,
        },
      };
    case SET_TEST_TYPE_QCS:
      return {
        ...state,
        currentTestTypesQcs: action.payload,
      };
    case SET_CURRENT_PRODUCTION_ROOM:
      return {
        ...state,
        currentProductionRoom: action.payload,
      };
    case SET_CURRENT_NEW_TEST_SCENARIO:
      return {
        ...state,
        currentNewTestScenario: {
          id:
            action.payload.id === ""
              ? action.payload.id
              : parseInt(action.payload.id),
          name: action.payload.name,
          label: action.payload.label,
          testScenarioLabel: action.payload.testScenarioLabel,
          value: action.payload.value,
        },
      };
    case SET_CURRENT_FACILITY:
      return {
        ...state,
        currentFacility: {
          id: action.payload.id === "" ? 0 : parseInt(action.payload.id),
          name: action.payload.name,
          label: action.payload.label,
          code: action.payload.code,
        },
      };
    case SET_CURRENT_AHU:
      return {
        ...state,
        currentAHU: action.payload,
      };
    case SET_CURRENT_MULTI_ROOM:
      return {
        ...state,
        currentMultiRoom: action.payload,
      };
    case SET_CURRENT_PRODUCT_GROUP:
      return {
        ...state,
        currentProductGroup: {
          id: action.payload.id === "" ? 0 : parseInt(action.payload.id),
          name: action.payload.name,
        },
      };
    case SET_CURRENT_PRODUCT_PRESENTATION:
      return {
        ...state,
        currentProductPresentation: {
          id: action.payload.id === "" ? 0 : parseInt(action.payload.id),
          name: action.payload.name,
        },
      };
    case SET_CURRENT_LOCATION:
      return {
        ...state,
        currentLocation: action.payload,
      };
    case SET_CURRENT_ITEM_TEMPERATURE:
      return {
        ...state,
        currentItemTemperature: action.payload,
      };
    case SET_CURRENT_PROCESS:
      return {
        ...state,
        currentProcess: action.payload,
      };
    case SET_CURRENT_BATCH:
      return {
        ...state,
        currentBatch: action.payload,
      };
    case SET_CURRENT_LINES:
      return {
        ...state,
        currentLines: action.payload,
      };
    default:
      return state;
  }
}

export default requestReducer;
