import {
  Box,
  Button,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  MdAddCircleOutline,
  MdOutlineDoNotDisturbOn,
  MdOutlineRemoveRedEye,
} from "react-icons/md";
import { findingAuditUploadDocumentReport } from "services/actions/QAS/AuditManagement";

export const UploadFindingModal = ({
  showUploadModal,
  setShowUploadModal,
  initialAttachments,
  setParentAttachments,
  findingIdx,
}) => {
  const mappedInitalAttachments = initialAttachments.map((item) => ({
    ...item,
    isDeleted: false,
  }));

  const fileInputRef = useRef();
  const [attachments, setAttachments] = useState(mappedInitalAttachments || []);
  const [fileName, setFileName] = useState("");
  const [inputError, setInputError] = useState(false);
  const [showUploadInput, setShowUploadInput] = useState(
    attachments.length === 0
  );
  const [loadingUpload, setLoadingUpload] = useState(false);

  const handleFileUpload = async (event) => {
    try {
      setLoadingUpload(true);
      if (!fileName) {
        setInputError(true);
        return;
      }
      const file = event.target.files[0];
      const res = await findingAuditUploadDocumentReport(file, fileName);
      setAttachments([
        ...attachments,
        {
          objectName: res.data.objectName,
          fileName: res.data.fileName,
          isDeleted: false,
        },
      ]);

      // clear uploaded filename
      setFileName("");
      setShowUploadInput(false);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingUpload(false);
    }
  };
  console.log("mappedInitalAttachments", attachments);

  useEffect(() => {
    const mappedInitalAttachments = initialAttachments.map((item) => ({
      ...item,
      isDeleted: false,
    }));
    setAttachments(mappedInitalAttachments);
  }, [initialAttachments]);

  const triggerFileUpload = () => {
    fileInputRef.current.click();
  };

  const handleDeleteFile = (index) => {
    const newAttachments = [...attachments];
    newAttachments[index].isDeleted = true;

    setAttachments(newAttachments);
  };

  const handleSave = () => {
    setParentAttachments(attachments, findingIdx);
    setShowUploadModal(false);
  };

  return (
    <Modal isOpen={showUploadModal} onClose={() => setShowUploadModal(false)}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Attachment</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {attachments.map(
            (item, index) =>
              !item.isDeleted && (
                <Flex alignItems={"center"} key={item.id} marginBottom={2}>
                  <Input
                    type="text"
                    value={item.fileName}
                    disabled
                    bgColor={"#F5F5F5"}
                    marginRight={1}
                  />
                  <Button p={0} bgColor={"rgba(255, 193, 69, 0.16)"}>
                    <MdOutlineRemoveRedEye color="rgba(255, 193, 69, 1)" />
                  </Button>
                  <Box p={0} marginLeft={5}>
                    <IconButton
                      aria-label="Do not disturb"
                      icon={<MdOutlineDoNotDisturbOn color="red" size={25} />}
                      variant="ghost"
                      onClick={() => handleDeleteFile(index)}
                    />
                  </Box>
                </Flex>
              )
          )}

          {showUploadInput && (
            <Box mt={5}>
              <InputGroup>
                <Input
                  type="text"
                  value={fileName}
                  onChange={(e) => setFileName(e.target.value)}
                  bgColor={"#F5F5F5"}
                  placeholder="Insert file name here..."
                  borderStyle={"dashed"}
                  borderWidth={2}
                  borderColor={inputError ? "red" : "#E2E8F0"}
                  backgroundColor={"#FFF"}
                  disabled={loadingUpload}
                />
                <InputRightElement width="6.2rem">
                  <Box>
                    <Button
                      h="1.75rem"
                      size="sm"
                      bgColor={!fileName ? "#E2E8F0" : "#fff"}
                      borderColor={"#E2E8F0"}
                      borderWidth={2}
                      disabled={loadingUpload || !fileName}
                      onClick={triggerFileUpload}
                    >
                      Browse
                    </Button>
                    <input
                      type="file"
                      ref={fileInputRef}
                      onChange={handleFileUpload}
                      style={{ display: "none" }}
                    />
                  </Box>
                </InputRightElement>
              </InputGroup>
            </Box>
          )}
          {!showUploadInput && (
            <Box pb={5} pt={5} color={"#1FB4D2"}>
              <Flex alignItems={"center"} fontSize={"12px"}>
                <MdAddCircleOutline />
                <Text
                  ml={2}
                  as="button"
                  onClick={() => {
                    setShowUploadInput(true);
                  }}
                >
                  Add new
                </Text>
              </Flex>
            </Box>
          )}
        </ModalBody>
        <ModalFooter gap={2}>
          <Button
            borderColor={"#1FB4D2"}
            color={"#1FB4D2"}
            onClick={() => setShowUploadModal(false)}
            disabled={loadingUpload}
            isLoading={loadingUpload}
            variant="outline"
            borderWidth={2}
          >
            Close
          </Button>
          <Button
            colorScheme="blue"
            mr={3}
            bgColor={"#1FB4D2"}
            disabled={loadingUpload}
            isLoading={loadingUpload}
            onClick={() => handleSave()}
          >
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
