import { Box, useBreakpointValue } from "@chakra-ui/react";
import jwt_decode from "jwt-decode";
import moment from "moment-timezone";
import React, { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { isMobile } from "react-device-detect";
import { useIdleTimer } from "react-idle-timer";
import { useDispatch, useSelector } from "react-redux";
import { matchPath, useHistory, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ENTER_FULLSCREEN, EXIT_FULLSCREEN } from "store/actions/Layout";
import "../../assets/css/Layout.css";
import withTrans from "../../config/i18n/withTrans";
import { RefreshToken } from "../../services/actions/Auth/index";
import { REFRESH_TOKEN, SET_EXP_TOKEN } from "../../store/actions/Auth/index";
import { deleteUserCookies, removeUnwantedRefresh } from "../../utils/Helpers";
import { getRefreshToken, getToken } from "../../utils/User";
import Header from "../Sections/header";
import Sidebar from "../Sections/sidebar";
import { FULLSCREEN_PATHS } from "./constants";

const smVariant = { navigation: "drawer", navigationButton: true };
const mdVariant = { navigation: "sidebar", navigationButton: false };

const Layout = ({ children, hideHeader, t, title, breadcrumb }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [cookie, setCookie, removeCookie] = useCookies();

  const userInfo = useSelector((state) => state.auth.userInfo);
  const isLogin = useSelector((state) => state.auth.isLogin);
  const GlobalRefreshToken = useSelector(getRefreshToken);
  const GlobalToken = useSelector(getToken);
  const isSidebarDisplayed = useSelector(
    (state) => state.layout.isSidebarDisplayed
  );
  const isHeaderDisplayed = useSelector(
    (state) => state.layout.isSidebarDisplayed
  );

  const [showNav, setShowNav] = useState(false);
  const [fullSidebar, setFullSidebar] = useState(!isMobile);
  const [exp, setExp] = useState(
    JSON.parse(userInfo.exp === null ? null : userInfo.exp)
  );
  const sessionTimeoutRef = useRef();
  const refreshTokenRef = useRef(GlobalRefreshToken);
  const globalTokenRef = useRef(GlobalToken);
  const variants = useBreakpointValue({ base: smVariant, md: mdVariant });
  const currentRoute = FULLSCREEN_PATHS.find((route) =>
    matchPath(location.pathname, route)
  );

  // handle display fullscreen
  useEffect(() => {
    if (currentRoute) {
      if (!isHeaderDisplayed || !isSidebarDisplayed) return;
      return dispatch(ENTER_FULLSCREEN(false));
    }

    if (!isHeaderDisplayed || !isSidebarDisplayed) {
      dispatch(EXIT_FULLSCREEN(true));
    }
  }, [currentRoute, dispatch, isHeaderDisplayed, isSidebarDisplayed]);

  const toggle = () => {
    setShowNav(!showNav);
  };

  const toggleFullSidebar = () => {
    setFullSidebar(!fullSidebar);
  };

  useEffect(() => {
    if (exp === null) {
      deleteUserCookies();
    }
  }, [exp]);

  const handleRefreshStorageAndLogout = () => {
    removeUnwantedRefresh();
    deleteUserCookies();
    localStorage.clear();
  };

  // Idle Time Area
  const handleRefreshToken = async (payload) => {
    const response = await RefreshToken(payload);

    if (response.status === 200) {
      const accessToken = response.data.data[0].accessToken;

      const decodeAccessToken = jwt_decode(accessToken);

      const expAccessToken = decodeAccessToken.exp;

      setCookie("access_token", accessToken, {
        path: "/",
        expires: new Date(
          moment.unix(expAccessToken).tz("Asia/Jakarta").format()
        ),
      });

      setExp(expAccessToken);

      dispatch(
        REFRESH_TOKEN({ accessToken: accessToken, refreshToken: payload.token })
      );
      dispatch(
        SET_EXP_TOKEN({
          nbf: decodeAccessToken.nbf,
          exp: decodeAccessToken.exp,
          iat: decodeAccessToken.iat,
        })
      );
      return response;
    } else if (response.status === 409) {
      toast.error("Your account is used by another device");
    } else if (response.status === 401) {
      toast.error("Your session is expired");
    } else {
      toast.error("Internal server error, logging out!");
    }

    await handleRefreshStorageAndLogout();
    setTimeout(async () => {
      window.location = "/login";
    }, 1500);
  };

  const handleOnActive = async (event) => {
    clearTimeout(sessionTimeoutRef.current);
    localStorage.removeItem("autoLogoutTime");

    const payload = {
      token: refreshTokenRef.current,
    };

    await handleRefreshToken(payload);
    localStorage.setItem(
      "refreshTime",
      moment().add(30, "seconds").toISOString()
    );
  };

  const handleOnIdle = async (event) => {
    localStorage.removeItem("refreshTime");

    localStorage.setItem(
      "autoLogoutTime",
      new Date(moment().add(14, "minutes").toISOString())
    );
    sessionTimeoutRef.current = setInterval(() => {
      const autoLogoutTime = new Date(localStorage.getItem("autoLogoutTime"));

      if (isIdle() && isLogin && new Date() >= autoLogoutTime) {
        handleRefreshStorageAndLogout();
        toast.error("Your session is expired");
        return (window.location = "/login");
      }
    }, 3500);
  };

  const handleOnAction = (event) => {
    const refreshTime = localStorage.getItem("refreshTime");

    if (!isIdle() && isLogin && refreshTime) {
      checkDiffElapsedTime(refreshTime);
    } else if (!isIdle()) {
      localStorage.setItem(
        "refreshTime",
        moment().add(30, "seconds").toISOString()
      );

      checkDiffElapsedTime(localStorage.getItem("refreshTime"));
    } else {
      localStorage.removeItem("refreshTime");
    }
  };

  const checkDiffElapsedTime = async (refreshTime) => {
    const refreshTimeDate = new Date(refreshTime);
    const currentTime = new Date();

    if (!isIdle() && isLogin && currentTime >= refreshTimeDate) {
      const payload = {
        token: refreshTokenRef.current,
      };

      await handleRefreshToken(payload);
      localStorage.setItem(
        "refreshTime",
        moment(refreshTime).add(30, "seconds").toISOString()
      );
    }
  };

  useEffect(() => {
    if (GlobalRefreshToken) refreshTokenRef.current = GlobalRefreshToken;
  }, [GlobalRefreshToken]);

  useEffect(() => {
    if (GlobalToken) globalTokenRef.current = GlobalToken;
  }, [GlobalToken]);

  useEffect(() => {
    const refreshTime = localStorage.getItem("refreshTime");

    if (!isIdle() && isLogin && !refreshTime) {
      localStorage.setItem(
        "refreshTime",
        moment().add(30, "seconds").toISOString()
      );
      localStorage.removeItem("autoLogoutTime");
    }
  }, []);

  const { isIdle } = useIdleTimer({
    timeout: 30000,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    syncTimers: 0,
    crossTab: true,
    eventsThrottle: 2000,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
    ],
    name: "idle-timer",
  });
  // End

  return (
    <>
      <Box height="100%">
        {isSidebarDisplayed && (
          <Sidebar
            variant={variants?.navigation}
            visible={showNav}
            close={toggle}
            fullSidebar={fullSidebar}
            toggleFullSidebar={toggleFullSidebar}
            t={t}
          />
        )}
        <Box
          ml={
            !variants?.navigationButton &&
            isSidebarDisplayed &&
            fullSidebar &&
            200
          }
        >
          {isHeaderDisplayed && (
            <Box className="header-container">
              {!hideHeader && (
                <Header toggle={toggle} title={title} breadcrumb={breadcrumb} />
              )}
            </Box>
          )}
          <Box
            className="main-container"
            sx={{ paddingLeft: !isSidebarDisplayed && 0 }}
          >
            <Box className="content">
              {React.Children.map(children, (child) => {
                return React.cloneElement(child, { t }, null);
              })}
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Global Toast Container */}
      <ToastContainer
        theme="colored"
        position="top-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default withTrans(Layout);
