import TableDynamic from "components/Table/TableDynamic";
import { debounce } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getFindingAuditList } from "services/actions/QAS/AuditManagement";
import {
  getAuditScheduleCategoryEnum,
  getAuditScheduleTypeEnum,
  getFindingAuditObjectStatusEnum,
  storePermission,
} from "store/actions/AuditManagement/actions";
import PopoverFilter from "./components/PopoverFilter";
import { getUserRole } from "utils/User";
import { getPermission } from "../utils";

const { Box, Input, Flex, Button } = require("@chakra-ui/react");

const ListAuditManagementFinding = () => {
  const history = useHistory();
  const category = useSelector((state) => state.auditManagement.auditCategory);
  const type = useSelector((state) => state.auditManagement.auditType);
  const status = useSelector(
    (state) => state.auditManagement.findingObjectStatus
  );
  const hasPermission = useSelector(
    (state) => state.auditManagement.permission
  );

  // Sample getting permission
  const roles = useSelector(getUserRole);
  const permission = getPermission(roles);
  const columns = [
    {
      Header: "AUDIT ID",
      accessor: "auditSchedule.auditId",
      Cell: (row) => {
        return <Box paddingLeft={"16px"}>{row.value}</Box>;
      },
    },
    {
      Header: "AUDIT CATEGORY",
      accessor: "auditSchedule.category",
      Cell: (row) => {
        const data = category.find((item) => {
          return item.id === row.value;
        });
        if (data) {
          return <Box paddingLeft={"16px"}>{data.name}</Box>;
        } else {
          return null;
        }
      },
    },
    {
      Header: "AUDIT TYPE",
      accessor: "auditSchedule.type",
      Cell: (row) => {
        const data = type.find((item) => {
          return item.id === row.value;
        });
        if (data) {
          return <Box paddingLeft={"16px"}>{data.name}</Box>;
        } else {
          return null;
        }
      },
    },
    {
      Header: "SCHEDULE",
      accessor: "auditSchedule",
      Cell: (row) => {
        const startDate = moment(new Date(row.value.scheduleStartDate)).format(
          "DD MMM YYYY"
        );
        const endDate = moment(new Date(row.value.scheduleEndDate)).format(
          "DD MMM YYYY"
        );

        return (
          <Box paddingLeft={"16px"}>
            {startDate} - {endDate}
          </Box>
        );
      },
    },
    {
      Header: "STATUS",
      accessor: "objectStatus",
      Cell: (row) => {
        let statusColor = "";

        const label = status.find((item) => {
          return item.id === row.value;
        });
        switch (label.name) {
          case "Draft":
            statusColor = "#A7A7A7";
            break;
          case "Completed":
            statusColor = "#21C306";
            break;
          case "In Review QA":
            statusColor = "#46A2E1";
            break;
          case "In Progress":
            statusColor = "#46A2E1";
            break;
          case "Rejected":
            statusColor = "#F00";
            break;
          default:
            break;
        }
        return (
          <Box paddingLeft={"16px"} fontWeight={500} color={statusColor}>
            {label.name}
          </Box>
        );
      },
    },
  ];

  const [findingList, setFindingList] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [totalPage, setTotalPage] = useState(0);
  const [filterData, setFilterData] = useState(null);

  const getAuditList = async (payload) => {
    try {
      setIsLoading(true);
      storePermission(permission);
      await getAuditScheduleCategoryEnum();
      await getAuditScheduleTypeEnum();
      await getFindingAuditObjectStatusEnum();

      const response = await getFindingAuditList(payload);

      setTotalPage(response.meta.totalPages);

      if (response.data) {
        setFindingList(response.data);
      } else {
        setFindingList([]);
      }
      setIsLoading(false);
    } catch (error) {
      setFindingList([]);
      setIsLoading(false);
      return error;
    }
  };

  useEffect(() => {
    const payload = {
      search,
      limit: 5,
      page,
    };
    getAuditList(payload);
  }, []);

  const debouncedSetSearch = useCallback(
    debounce(async (search) => {
      setPage(1);
      const payload = {
        search,
        limit: 5,
        page,
        ...filterData,
      };
      const response = await getFindingAuditList(payload);
      if (response.data) {
        setFindingList(response.data);
      } else {
        setFindingList([]);
      }
      setIsLoading(false);
    }, 200),
    []
  );

  const handleChangeSearch = (value) => {
    setIsLoading(true);
    setPage(1);
    setSearch(value);
    debouncedSetSearch(search);
  };

  const handleChangePage = (value) => {
    setPage(value);
    setIsLoading(true);
    const payload = {
      search,
      limit: 5,
      page: value,
      ...filterData,
    };
    getFindingAuditList(payload)
      .then((res) => {
        if (res.data) {
          setFindingList(res.data);
        } else {
          setFindingList([]);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        return err;
      });
  };

  const handleClickCreate = () => {
    history.push({
      pathname: "/qas/auditmanagement/auditscheduler/create",
    });
  };

  const handleFilter = async (payload) => {
    try {
      setIsLoading(true);
      const res = await getFindingAuditList({
        search,
        limit: 5,
        page: 1,
        ...payload,
      });
      if (res.data) {
        setFindingList(res.data);
      } else {
        setFindingList([]);
      }
      setPage(1);
      setIsLoading(false);
    } catch (err) {
      return err;
    }
  };
  return (
    <Box
      bg="#fff"
      p={5}
      borderRadius={10}
      boxShadow={"0px 0px 71px 0px rgba(0, 0, 0, 0.02)"}
    >
      <Flex
        align="center"
        justify="space-between"
        gap={14}
        width={"100%"}
        marginBottom={"30px"}
      >
        <Flex align={"center"} gap={"14px"}>
          <Input
            borderRadius={"6px"}
            placeholder="Search..."
            onKeyUp={(e) => handleChangeSearch(e.target.value)}
            boxShadow={"0px 0px 2px 0px rgba(0, 0, 0, 0.56)"}
          />
          <PopoverFilter
            handleFilter={handleFilter}
            filterData={filterData}
            setFilterData={setFilterData}
          />
        </Flex>
      </Flex>

      <Flex direction={"column"} align={"center"}>
        <div style={{ width: "100%" }}>
          <TableDynamic
            columns={columns}
            data={findingList}
            isLoading={isLoading}
            tableMinHeight="calc(100vh - 300px)"
            tableMaxHeight="calc(100vh - 300px)"
            isPaging={true}
            currentPage={page}
            totalPage={totalPage}
            maxData={5}
            handleChangePage={handleChangePage}
            tableHeight={"100%"}
            handeClickRow={(e) => {
              const auditId = e.original.id;
              return history.push({
                pathname: `/qas/auditmanagement/findingaudit/${auditId}`,
              });
            }}
          />
        </div>
      </Flex>
    </Box>
  );
};

export default ListAuditManagementFinding;
