export const STATUS_PRESENCE_IN = 1;
export const STATUS_PRESENCE_IN_VALUE = "checkin";
export const STATUS_PRESENCE_OUT = 2;
export const STATUS_PRESENCE_OUT_VALUE = "checkout";
export const STATUS_ACTIVE = 3;
export const STATUS_ACTIVE_VALUE = "ACTIVE";
export const STATUS_DRAFT = 4;
export const STATUS_DRAFT_VALUE = "DRAFT";
export const STATUS_ARCHIVE = 5;
export const STATUS_ARCHIVE_VALUE = "ARCHIVE";
export const STATUS_CANCEL = 6;
export const STATUS_CANCEL_VALUE = "CANCEL";
export const STATUS_REJECT = "REJECT";

export const PROCEDURE_STATUSES = {
  NOT_STARTED: 1,
  IN_PROGRESS: 2,
  INCOMPLETE: 3,
  COMPLETE: 4,
};

export const PROCEDURE_STATUSES_COLOR = {
  BORDER_NOT_STARTED: "10px solid #C4C4C4",
  BORDER_IN_PROGRESS: "10px solid #3097DD",
  BORDER_COMPLETED: "10px solid #21C306",
  BORDER_INCOMPLETED: "10px solid #FFC145",
  BORDER_DEVIATION: "10px solid #F00",
  NOT_STARTED_COLOR: "#8E8E8E",
  IN_PROGRESS_COLOR: "#3097DD",
  COMPLETE_COLOR: "#21C306",
  INCOMPLETE_COLOR: "#FFC145",
  NOT_STARTED_BACKGROUND_COLOR: "rgba(158, 158, 158, 0.15)",
  IN_PROGRESS_BACKGROUND_COLOR: "rgba(48, 151, 221, 0.12)",
  COMPLETE_BACKGROUND_COLOR: "rgba(59, 215, 0, 0.12)",
  IN_COMPLETE_BACKGROUND_COLOR: "rgba(255, 168, 0, 0.14)",
};

export const PROCEDURE_STATUSES_TEXT = {
  NOT_STARTED: "Not Started",
  IN_PROGRESS: "In Progress",
  INCOMPLETE: "Incomplete",
  DEVIATION: "Deviation",
  COMPLETE: "Complete",
};
