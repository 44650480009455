import style from "./Table.module.css";
import { Table, Thead, Tbody, Tr, Td, Th, Box } from "@chakra-ui/react";

import React from "react";

import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useFilters,
  useResizeColumns,
  useFlexLayout,
  useExpanded,
  usePagination,
} from "react-table";

const ExpandedTable = ({
  columns: userColumns,
  data,
  renderRowSubComponent,
  rowOnClick,
  rowClickHandler,
  isPaging,
  totalPage,
  handleChangePage,
  currentPage,
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    useTable(
      {
        columns: userColumns,
        data,
        initialState: {
          pageIndex: currentPage ? currentPage : 1,
          pageSize: 999,
          expanded: data.reduce(
            (acc, val) => ({ ...acc, [val.index]: true }),
            {}
          ),
        },
        pageCount: totalPage,
        manualPagination: true,
      },
      useGlobalFilter,
      useFilters,
      useSortBy,
      useResizeColumns,
      useExpanded,
      usePagination,
      useFlexLayout
    );

  return (
    <Box>
      <Table className={`${style.expanded_table}`} {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup, i) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th
                  key={column.id}
                  className="column-fit-content "
                  {...column.getHeaderProps()}
                >
                  {column.render("Header")}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <>
                <Tr
                  {...row.getRowProps()}
                  onClick={
                    rowOnClick ? () => rowClickHandler(row.original) : () => ""
                  }
                >
                  {row.cells.map((cell) => {
                    return (
                      <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                    );
                  })}
                </Tr>
                {row.isExpanded ? (
                  <Tr borderLeft="6px solid rgb(31, 180, 210)">
                    <Td
                      p={0}
                      backgroundColor="rgba(31, 180, 210, 0.04)"
                      className={`${style.overflow_td}`}
                    >
                      {renderRowSubComponent({ row })}
                    </Td>
                  </Tr>
                ) : null}
              </>
            );
          })}
        </Tbody>
      </Table>
      {isPaging && (
        <div style={{ textAlign: "right", marginTop: "1em" }}>
          <div className="table-pagination">
            <button
              onClick={() => handleChangePage(currentPage - 1)}
              disabled={currentPage === 1}
              style={{ borderRight: "1px solid #cccc" }}
              className={currentPage === 1 ? "page-disabled" : ""}
            >
              {"< "}
            </button>
            <span className="paging-number">{currentPage}</span>
            <button
              onClick={() => handleChangePage(currentPage + 1)}
              disabled={currentPage >= totalPage}
              style={{ borderLeft: "1px solid #cccc" }}
              className={currentPage >= totalPage ? "page-disabled" : ""}
            >
              {" >"}
            </button>
          </div>
        </div>
      )}
    </Box>
  );
};

export default ExpandedTable;
