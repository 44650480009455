import React from "react";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import { BiHomeAlt, BiChevronRight } from "react-icons/bi";

export default function BreadcrumbSamplingRM({
  listPage = true,
  text = "Sampling Plan",
}) {
  return (
    <div className="breadcrumb-container">
      <Breadcrumb spacing="8px" separator={<BiChevronRight color="gray.500" />}>
        <BreadcrumbItem>
          &nbsp;{" "}
          <BreadcrumbLink as={Link} to="/">
            <BiHomeAlt color="gray.500" />
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="qcs/qc/request/raw-material/sampling">
            Quality Control
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="qcs/qc/request/raw-material/sampling">
            Sampling
          </BreadcrumbLink>
        </BreadcrumbItem>

        {listPage && (
          <BreadcrumbItem isCurrentPage={listPage}>
            <BreadcrumbLink to="qcs/qc/request/raw-material/sampling">
              Raw Material
            </BreadcrumbLink>
          </BreadcrumbItem>
        )}

        {!listPage && (
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="qcs/qc/request/raw-material/sampling">
              Raw Material
            </BreadcrumbLink>
          </BreadcrumbItem>
        )}

        {!listPage && (
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink to="qcs/qc/request/raw-material/sampling">
              {text}
            </BreadcrumbLink>
          </BreadcrumbItem>
        )}
      </Breadcrumb>
    </div>
  );
}
