import { Box, Grid, GridItem } from '@chakra-ui/react'
import Skeleton from 'react-loading-skeleton'

function ContentListSkeleton1Col() {
  return <Box mt={5}>
    <Grid templateColumns='repeat(1, 1fr)' gap={6}>
      <GridItem>
        <Skeleton height={35} />
      </GridItem>
      <GridItem>
        <Skeleton height={35} />
      </GridItem>
      <GridItem>
        <Skeleton height={35} />
      </GridItem>
      <GridItem>
        <Skeleton height={35} />
      </GridItem>
      <GridItem>
        <Skeleton height={35} />
      </GridItem>
      <GridItem>
        <Skeleton height={35} />
      </GridItem>
    </Grid>
  </Box>
}

export default ContentListSkeleton1Col
