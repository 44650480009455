import React from "react";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import { BiHomeAlt, BiChevronRight } from "react-icons/bi";

export default function BreadcrumbDetailReleaseProduction() {
  return (
    <div className="breadcrumb-container">
      <Breadcrumb
        spacing="8px"
        separator={<BiChevronRight color="gray.500" />}
        padding="0 0px"
      >
        <BreadcrumbItem>
          &nbsp;{" "}
          <BreadcrumbLink as={Link} to="/">
            <BiHomeAlt color="gray.500" />
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/qas/release/production">
            Release
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink to="/qas/release/production">
            Production
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
    </div>
  );
}
