import React, { useState, useEffect } from "react";
import "focus-visible/dist/focus-visible";
import { ChakraProvider } from "@chakra-ui/react";
import { Global, css } from "@emotion/react";
import "./assets/css/App.css";
import "./assets/css/Form.css";
import "./assets/css/icons.css";
import "./assets/css/Table.css";
import "./assets/css/responsive.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { Routes } from "./config";
import { Provider } from "react-redux";
import { persistor, store } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import Landing from "./pages/Auth/Landing";
import { toast } from "react-toastify";

require("dotenv").config();
const GlobalStyles = css`
  /*
    This will hide the focus indicator if the element receives focus    via the mouse,
      but it will still show up on keyboard focus.
  */
  .js-focus-visible :focus:not([data-focus-visible-added]) {
    outline: none;
    box-shadow: none;
  }
`;

const App = () => {
  const [renderSplashscreen, setRenderSplashscreen] = useState(true);
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    if (sessionStorage.getItem("splashScreen")) {
      setRenderSplashscreen(false);
    } else {
      if (window.innerWidth >= 1440) {
        setRenderSplashscreen(false);
      } else {
        setTimeout(() => {
          setRenderSplashscreen(false);
        }, 4000);
        sessionStorage.setItem("splashScreen", true);
      }
    }
  }, []);

  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      if (!navigator.onLine) {
        toast.error("No network detected!, going in offline mode", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else if (navigator.onLine) {
        toast.info("You are going online!", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }

      setIsOnline(navigator.onLine);
    };

    // Listen to the online status
    window.addEventListener("online", handleStatusChange);

    // Listen to the offline status
    window.addEventListener("offline", handleStatusChange);

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [isOnline]);

  if (renderSplashscreen) {
    return <Landing />;
  } else {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ChakraProvider>
            <Global styles={GlobalStyles} />
            <Routes />
          </ChakraProvider>
        </PersistGate>
      </Provider>
    );
  }
};

export default App;
