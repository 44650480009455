import { combineReducers } from "@reduxjs/toolkit";

import { AuthReducer } from "./Auth";
import QCSReducers from "./QCS";
import UserCredentialsReducers from "./UserCredentials";
import layoutReducer from "./Layout";
import cartRejectReducer from "pages/PES/Production/ReviewDetail/cartRejectReducer";
import productionReviewProcessReducer from "pages/PES/Production/ReviewDetail/processReducer";
import productionPlanReducer from "pages/PES/Production/form/productionPlanReducer";
import productionReviewApproveProcedureReducer from "pages/PES/Production/ReviewDetail/approveProcedureReducer";
import { AuditManagementReducer } from "./AuditManagement";
import productionDeviationProcessReducer from "pages/PES/Production/DeviationProcess/deviationProcessReducer";
import productionExecutionDetailReducer from "pages/PES/Production/executionDetailReducer";
import productionReviewDetailReducer from "pages/PES/Production/reviewDetailReducer";
import offlineModeReducer from "./OfflineMode";
import getDetailTestingReducer from "pages/QCS/Testing/Production/store/testingReducer";
import testingTemplateReducer from "pages/QCS/Testing/Production/store/testingTemplateReducer";
import getDetailProductionReducer from "pages/PES/Production/store/productionGetDetailReducer";

export default combineReducers({
  auth: AuthReducer.reducer,
  qcs: QCSReducers,
  userCredentials: UserCredentialsReducers,
  layout: layoutReducer,
  cartReject: cartRejectReducer,
  productionReviewProcess: productionReviewProcessReducer,
  productionPlan: productionPlanReducer,
  productionReviewApproveProcedure: productionReviewApproveProcedureReducer,
  auditManagement: AuditManagementReducer.reducer,
  productionDeviationProcess: productionDeviationProcessReducer,
  productionExecutionDetail: productionExecutionDetailReducer,
  productionReviewDetail: productionReviewDetailReducer,
  offlineMode: offlineModeReducer,
  getDetailTestingFunc: getDetailTestingReducer,
  testingTemplate: testingTemplateReducer,
  getDetailProductionFunc: getDetailProductionReducer,
});
