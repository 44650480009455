import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

import { Router } from "react-router";
import { ChakraProvider } from "@chakra-ui/react";
import { history } from "./utils/History";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

// Import the react editor TinyMCE component.
import { Editor } from "@tinymce/tinymce-react";

// Add jquery.
import $ from "jquery";

window.$ = $;

window.tinymce = require("tinymce"); // Expose the TinyMCE to the window.

ReactDOM.render(
  <ChakraProvider>
    <Router history={history}>
      <App />
    </Router>
  </ChakraProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.unregister();
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
