import React, { useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useCookies } from "react-cookie";
import { deleteUserCookies } from "utils/Helpers";
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";

import {
  POST_AUTH_LOGIN_SUCCESS,
  SET_INITIAL_URL,
  POST_AUTH_LOGOUT_SUCCESS,
} from "store/actions/Auth";
import Layout from "components/layout";
import { getUserRole } from "utils/User";

const PrivateRoute = ({
  component: Component,
  title,
  breadcrumb,
  path,
  allRole = false,
  ...rest
}) => {
  const isLogin = useSelector((state) => state.auth.isLogin);
  const dataUser = useSelector((state) => state.auth.userData);
  const userRoles = useSelector(getUserRole);

  const listRoutes = useSelector(
    (state) => state.userCredentials.menu.data.routes
  );
  const listMenu = useSelector(
    (state) => state.userCredentials.menu?.data?.menus
  );

  const dispatch = useDispatch();
  const [cookies] = useCookies(["access_token", "refresh_token"]);
  const [emptyRoutes, setEmptyRoutes] = useState(false);

  useEffect(() => {
    if (listRoutes.length === 0) {
      if (dataUser.accessToken !== "" && dataUser.accessToken !== "undefined") {
        const info = jwt_decode(dataUser.accessToken);
        setAvailableRoutes(info);
      } else if (cookies.access_token && cookies.access_token !== "undefined") {
        const info = jwt_decode(cookies.access_token);
        setAvailableRoutes(info);
      } else {
        setEmptyRoutes(true);
      }
    }
  }, []);

  const setAvailableRoutes = async (data) => {
    if (Object.entries(data).length !== 0) {
      const AppRoles = userRoles;

      if (AppRoles.length !== 0 && AppRoles[0].Roles.length !== 0) {
        if (listMenu) {
          setEmptyRoutes(false);
        } else {
          toast.success(`Logout Success`, { containerId: "logout" });
          deleteUserCookies();
          localStorage.removeItem("menu");
          setTimeout(function () {
            dispatch(POST_AUTH_LOGOUT_SUCCESS());
            window.location = "/login";
          }, 2000);
        }
      }
    }
  };

  return (
    <Route
      {...rest}
      render={(props) => {
        if (emptyRoutes) {
          return (
            <Redirect
              to={{ pathname: "/error/403", state: { from: props.location } }}
            />
          );
        }

        //check role authorization
        const isAllowed = listRoutes.includes(rest.computedMatch.path);

        if (!isLogin && dataUser.accessToken === "") {
          if (cookies.access_token && cookies.access_token !== "undefined") {
            const newUserData = {
              accessToken: cookies.access_token,
              endpoint: null,
              menu: null,
              refreshToken: cookies.refresh_token,
            };

            dispatch(POST_AUTH_LOGIN_SUCCESS(newUserData));

            if (!isAllowed && !allRole) {
              return (
                <Redirect
                  to={{
                    pathname: "/error/403",
                    state: { from: props.location },
                  }}
                />
              );
            }
          } else {
            dispatch(SET_INITIAL_URL(rest.computedMatch.path));

            return (
              <Redirect
                to={{ pathname: "/login", state: { from: props.location } }}
              />
            );
          }
        } else {
          if (!isAllowed && !allRole) {
            return (
              <Redirect
                to={{
                  pathname: "/error/403",
                  state: { from: props.location },
                }}
              />
            );
          }
        }

        return (
          <Layout title={title} breadcrumb={breadcrumb}>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );
};

export default PrivateRoute;
