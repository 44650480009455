import { useFormik } from "formik";
import { omitBy, isNil } from "lodash";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  findingAuditAssignmentCreate,
  findingAuditFindingInspect,
  findingAuditItemUpdate,
} from "services/actions/QAS/AuditManagement";
import { getUserInfo } from "utils/User";
import * as Yup from "yup";

export const useScopeAuditorAssignHook = ({ scopeId, auditId, auditeeId }) => {
  const userInfo = useSelector(getUserInfo);
  const formik = useFormik({
    initialValues: {
      auditors: [],
    },
    onSubmit: async (values) => {
      try {
        const mappedData = values.auditors.map((val) => val.id);

        const payload = {
          findingAuditAuditeeId: auditeeId || null,
          findingAuditInspectionScopeId: scopeId,
          findingAuditAuditorIds: mappedData,
          createdBy: userInfo.NIK,
        };

        await findingAuditAssignmentCreate(payload);
      } catch (error) {
        console.error(error);
        if (error?.response?.data?.message) {
          toast.error("Failed assign auditor");
        }
        return error;
      }
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({}),
  });

  return {
    ...formik,
  };
};

export const useScopeItemHook = ({ items, auditeeId = null, scopeId }) => {
  const userInfo = useSelector(getUserInfo);
  const mappedItems = items.map((items) => ({
    findingAuditItemId: items.id,
    inspectionResult: items.inspectionResult || null,
  }));

  const formik = useFormik({
    initialValues: {
      scopeItems: {
        items: mappedItems,
        updatedBy: userInfo.NIK,
      },
      auditeeId: auditeeId,
      findings: [],
    },
    onSubmit: async (values) => {
      try {
        const mappedData = values.scopeItems.items.map((items) => ({
          findingAuditItemId: items.findingAuditItemId,
          inspectionResult: items.inspectionResult || null,
        }));
        const inspectRequest = {
          findingAuditAuditeeId: auditeeId || null,
          findingAuditInspectionScopeId: scopeId,
          findings: [],
          createdOrUpdatedBy: userInfo.NIK,
        };

        if (values.findings.length > 0) {
          inspectRequest.findings = values.findings.map((finding) => {
            const payload = {
              id: finding.id || null,
              finding: finding.finding || null,
              products: finding.products || null,
              attachments: finding.attachments || null,
              isCompanyWide: finding.isCompanyWide || null,
              classification: finding.classification || null,
              prevFindingNumber: finding.prevFindingNumber || null,
              regulations: finding.regulations || null,
              mandatoryChecklist: finding.mandatoryChecklist || null,
              isDeleted: finding.isDeleted || false,
            };

            return omitBy(payload, isNil);
          });
        }

        const promises = [];

        if (mappedData.length > 0) {
          promises.push(
            findingAuditItemUpdate({
              items: mappedData,
              updatedBy: userInfo.NIK,
            })
          );
        }

        if (inspectRequest.findings.length > 0) {
          promises.push(findingAuditFindingInspect(inspectRequest));
        }
        await Promise.all(promises);
      } catch (error) {
        console.error(error);
        if (error?.response?.data?.message) {
          toast.error("Failed update scope items");
        }
        return error;
      }
    },
    onReset: () => {
      formik.setValues({
        scopeItems: {
          items: mappedItems,
          updatedBy: userInfo.NIK,
        },
        findings: [],
        auditeeId: auditeeId,
      });
    },
    enableReinitialize: true,
  });

  return {
    ...formik,
  };
};

export const useFindingsHooks = () => {
  const userInfo = useSelector(getUserInfo);
  const formik = useFormik({
    initialValues: {
      findingAuditAuditeeId: null,
      findingAuditInspectionScopeId: null,
      findings: [],
    },
    onSubmit: async (values) => {
      try {
        const payload = mapFindingReqObj(values);
        await findingAuditFindingInspect(payload);
      } catch (error) {
        if (error?.response?.data?.message) {
          toast.error("Failed create / update findings");
        }
        return error;
      }
    },
  });

  const mapFindingReqObj = (values) => {
    const mappedFindings = values.findings.map((finding) => ({
      findingAuditItemId: finding.id,
      finding: finding.finding || "",
      products: finding.products || [],
      attachments: finding.attachments || null,
      isCompanyWide: finding.isCompanyWide || null,
      classification: finding.classification || null,
      prevFindingNumber: finding.prevFindingNumber || "",
      regulation: finding.regulation || "",
      mandatoryChecklist: finding.mandatoryChecklist || null,
      isDeleted: finding.isDeleted || null,
    }));

    return {
      ...values,
      findings: mappedFindings(values.findings),
      createdOrUpdatedBy: userInfo.NIK,
    };
  };

  return {
    ...formik,
  };
};
