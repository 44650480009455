import {
  Button,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalContent,
  Text,
} from "@chakra-ui/react";
import { BsExclamationTriangle } from "react-icons/bs";

const ModalConfirmation = ({ onClickYes, isOpen, onClose, isLoading }) => {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={!isLoading}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody py={10} className="text-center">
            <BsExclamationTriangle
              size={80}
              style={{ color: "#dd0000" }}
              className="mx-auto mb-4"
            />
            <Text mb={2} fontSize={20} fontWeight="600">
              Delete this data?
              <br />
            </Text>
            <Text mb={6} color="#9b9b9b" fontSize={"sm"}>
              You will not be able to recover it
            </Text>

            <Button
              size="md"
              mr={3}
              width={100}
              onClick={!isLoading ? onClose : null}
            >
              Cancel
            </Button>
            <Button
              isLoading={isLoading}
              spinnerPlacement="start"
              size="md"
              className="btn-error-modal"
              width={100}
              onClick={onClickYes}
            >
              Delete
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalConfirmation;
