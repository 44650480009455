import axios from 'axios'
import {getCookieAccessToken} from "../../../utils/Helpers";

const authAPIInstance = axios.create({
    baseURL: process.env.REACT_APP_API_HOST_AUTH
})

authAPIInstance.interceptors.request.use(
    function (config) {
        config.headers = {
            Authorization: `Bearer ${getCookieAccessToken()}`,
        };
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

export default authAPIInstance
