import { createSlice } from "@reduxjs/toolkit";

const getDetailProductionFuncSlice = createSlice({
  name: "getDetailProductionFunc",
  initialState: null,
  reducers: {
    getDetailProductionRBEGlobalFunc: (state, action) => {
      return action.payload;
    },
    getCheckAckAllFunc: (state, action) => {
      return action.payload;
    },
  },
});

export const {
  getDetailProductionRBEGlobalFunc,
  getCheckAckAllFunc,
} = getDetailProductionFuncSlice.actions;

export default getDetailProductionFuncSlice.reducer;