import {
  POST_AUTH_REQUEST,
  POST_AUTH_LOGIN_SUCCESS,
  POST_AUTH_LOGIN_ERROR,
  POST_AUTH_LOGOUT_SUCCESS,
} from "./index";
import { SET_ACTIONS_DATA } from "../UserCredentials/Actions/actionTypes";
import { SET_MENU_DATA } from "../UserCredentials/Menu/actionTypes";
import { PostLogin, PostLogout } from "../../../services/actions/Auth";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import {
  deleteUserCookies,
  loginFireBase,
  logoutFireBase,
  removeUnwantedRefresh,
} from "../../../utils/Helpers";
import { PublicRoutes } from "../../../utils/ConstantGlobal";
import { uniqBy, sortBy } from "lodash";
import { SET_ROLES_DATA } from "../UserCredentials/Roles/actionTypes";

export const LoginAction = (props, payload, initialURL) => async (dispatch) => {
  const { t } = props;
  dispatch(POST_AUTH_REQUEST(true));

  const response = await PostLogin(payload);
  if (response.status && response.status === 200) {
    const userInfo = jwt_decode(response.data?.data[0].accessToken);
    const AppRoles = response.data?.data[0].role;

    if (response.data.data[0].menu.length === 0) {
      dispatch(POST_AUTH_LOGIN_ERROR());
      toast.error("Unauthorized", { autoClose: 1000, containerId: "login" });
    }

    if (AppRoles?.length !== 0 && AppRoles[0].roles.length !== 0) {
      const menuPayload = {
        applicationCode: AppRoles[0].applicationCode,
        roleCode: AppRoles[0].roles[0].roleCode,
      };

      if (response.data.data[0].menu.length !== 0) {
        const menuList = response.data.data[0].menu.filter(
          (d) => d.applicationCode === menuPayload.applicationCode
        );

        const roles = AppRoles[0].roles.map((role) => role.roleCode);
        const routes = getRouteList(menuList, roles);

        // TODO : ADD RECURSIVE UNIQUE CHECKER
        let uniqMenu = uniqBy(menuList, "menuCode");
        uniqMenu = uniqMenu.map((item) => {
          return {
            ...item,
            sequence: parseInt(item.sequence),
            subMenu: uniqBy(item.subMenu, "menuCode"),
          };
        });
        uniqMenu = sortBy(uniqMenu, "sequence");

        let newMenu = {
          routes,
          menus: uniqMenu,
        };

        // adding manual menu,todo : remove this when menu is ready add to api

        newMenu.routes.push(...PublicRoutes);

        const post_login_success = response.data.data.map((item) => {
          return {
            ...item,
            userName: payload.userName,
          };
        });

        await dispatch({
          type: SET_ACTIONS_DATA,
          payload: response.data?.data[0]?.userAction,
        });
        await dispatch({
          type: SET_MENU_DATA,
          payload: newMenu,
        });
        await dispatch({
          type: SET_ROLES_DATA,
          payload: AppRoles,
        });

        localStorage.setItem("userNameLogin", payload.userName);
        dispatch(POST_AUTH_LOGIN_SUCCESS(post_login_success[0]));
        toast.success(t("auth.login.success"), {
          autoClose: 1000,
          containerId: "login",
        });
        loginFireBase();

        if (initialURL !== "") {
          setTimeout(function () {
            props.history.push(initialURL);
          }, 2000);
        } else {
          setTimeout(function () {
            props.history.push("/dashboard");
          }, 2000);
        }
      } else {
        toast.error(`User dont have access to this application`, {
          containerId: "logout",
        });
        deleteUserCookies();
        localStorage.removeItem("menu");
      }
    }
  } else if (response.status === 401) {
    dispatch(POST_AUTH_LOGIN_ERROR());
    toast.error(t("auth.login.failed"), {
      autoClose: 1000,
      containerId: "login",
    });
  } else if (response.status === 403) {
    dispatch(POST_AUTH_LOGIN_ERROR());
    return true;
  } else {
    let message = response.data.message
      ? response.data.message
      : t("500Message");
    dispatch(POST_AUTH_LOGIN_ERROR());
    toast.error(message, { autoClose: 1000, containerId: "login" });
  }
};

export const LogoutAction = (payload, type) => async (dispatch) => {
  dispatch(POST_AUTH_REQUEST(true));
  removeUnwantedRefresh();

  payload.source = type ? type : "no-source";

  const response = await PostLogout(payload);
  deleteUserCookies();
  localStorage.clear();
  logoutFireBase();

  // TODO : UNCOMMENT WHEN IDLE AUTO LOGOUT ITS DONE
  if (response.status && response.status === 200) {
    if (type === "sessionExpire")
      toast.error("Your session is expired", { containerId: "logout" });
    else if (type === "auto");
    else toast.success("Successfully Logout", { containerId: "logout" });

    setTimeout(() => {
      dispatch(POST_AUTH_LOGOUT_SUCCESS());
      return (window.location = "/login");
    }, 1500);
  } else {
    if (type === "sessionExpire")
      toast.error("Your session is expired", { containerId: "logout" });
    else if (type === "auto");
    else toast.success("Successfully Logout", { containerId: "logout" });

    setTimeout(() => {
      dispatch(POST_AUTH_LOGOUT_SUCCESS());
      return (window.location = "/login");
    }, 1500);
  }
};

const getRouteList = (menuList, roleCode = [], routes = []) => {
  menuList.forEach((menu) => {
    const whitelistedPath = menu.whitelistedPaths?.reduce(
      (prevValue, currentValue) => {
        const unauthorized = currentValue.unauthorizedRole?.some((role) =>
          roleCode.includes(role.roleCode)
        );

        if (unauthorized) return prevValue;
        return [...prevValue, currentValue.url];
      },
      []
    );

    routes.push(menu.url);
    if (whitelistedPath) routes.push(...whitelistedPath);
    if (menu.subMenu.length !== 0) {
      getRouteList(menu.subMenu, roleCode, routes);
    }
  });

  return routes;
};
