import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  deviation: null,
  attachments: [],
  notes: [],
};

export const productionReviewApproveProcedureSlice = createSlice({
  name: "productionReviewApproveProcedure",
  initialState,
  reducers: {
    setDeviation(state, action) {
      state.deviation = action.payload;
    },
    setAttachments(state, action) {
      state.attachments = action.payload;
    },
    setNotes(state, action) {
      state.notes = action.payload;
    },
  },
});

export const { setDeviation, setAttachments, setNotes } =
  productionReviewApproveProcedureSlice.actions;
export default productionReviewApproveProcedureSlice.reducer;
