import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  Fragment,
} from "react";
import { withRouter, NavLink, useLocation } from "react-router-dom";
import MenuItem from "./MenuItem";
import Logo from "./Logo";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import { useClickaway } from "hooks/ClickawayHooks";
import { useSelector } from "react-redux";
import { Box } from "@chakra-ui/react";

const SideBarContent = ({ fullSidebar, openSubmenu, setOpenSubMenu }) => {
  let listMenu = useSelector((state) => state.userCredentials.menu.data.menus);

  const { pathname } = useLocation();

  const dropdownRef = useRef();

  const [y, setY] = useState(0);
  const [yPosition, setYPosition] = useState(0);

  // Function to check click position
  function showCoords(event) {
    const positionY = event.clientY;
    setYPosition(positionY);
  }

  const handleNavigation = useCallback(
    (e) => {
      const window = e.currentTarget;
      setY(window.scrollTop);
    },
    [y]
  );

  useClickaway(dropdownRef, (e) => {
    if (!fullSidebar) {
      setOpenSubMenu([""]);
    }
  });

  useEffect(() => {
    const getMenuY = document.getElementById("sidebar-item-wrapper");

    setY(getMenuY.scrollTop);
    getMenuY.addEventListener("scroll", handleNavigation);

    return () => {
      getMenuY.removeEventListener("scroll", handleNavigation);
    };
  }, [fullSidebar, handleNavigation]);

  const handleRenderSubMenu = (item, sequence, parent) => {
    return (
      <Box key={item.menuCode}>
        <Box
          className={`parent-menu ${
            pathname.includes(item.url) ? "active" : ""
          }`}
          onClick={(e) => showCoords(e)}
        >
          <MenuItem
            parent={parent}
            title={item.menuName}
            code={item.menuCode}
            submenu={item.subMenu.length !== 0 ? true : false}
            isOpen={openSubmenu}
            fullSidebar={fullSidebar}
            onClick={() => {
              let newOpenSubMenu = [...openSubmenu];
              newOpenSubMenu.splice(sequence + 1);
              let findIndex = newOpenSubMenu.findIndex(
                (newItem) => newItem == item.menuCode
              );

              if (findIndex > -1) {
                newOpenSubMenu.splice(findIndex, 1);
                setOpenSubMenu(newOpenSubMenu);
                return;
              }
              newOpenSubMenu.push(item.menuCode);
              setOpenSubMenu(newOpenSubMenu);
            }}
            iconClass={item.icon}
          />
        </Box>
        {openSubmenu.includes(item.menuCode) && (
          <Box
            id={fullSidebar ? "submenu" : "submenu-mini-id"}
            className={fullSidebar ? "submenu" : "submenu-mini"}
            style={{
              top: fullSidebar ? "auto" : `auto`,
              left: fullSidebar ? "" : "9.8em",
              transform: fullSidebar ? "" : "translateY(-100%)",
            }}
          >
            <Box
              className={pathname.includes(item.url) ? "submenu-active" : ""}
            >
              {item.subMenu.map((one) => {
                if (one.subMenu.length !== 0) {
                  return handleRenderSubMenu(one, sequence + 1, item.menuName);
                }
                if (one.url.length > 0 && one.url[0] === "/") {
                  return (
                    <NavLink key={one.menuCode} to={one.url}>
                      <MenuItem title={one.menuName} parent={item.menuName} />
                    </NavLink>
                  );
                } else {
                  return (
                    <NavLink
                      key={one.menuCode}
                      to={{ pathname: one.url }}
                      target="_blank"
                    >
                      <MenuItem title={one.menuName} parent={item.menuName} />
                    </NavLink>
                  );
                }
              })}
            </Box>
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Box
      id="sidebar-item-wrapper"
      className={`sidebar-item-wrapper ${
        fullSidebar ? "sidebar-fullscreen" : ""
      }`}
    >
      {listMenu.map((item) => {
        if (pathname.includes(item.url) && listMenu.length > 0) {
          localStorage.setItem("currentMenu", item?.menuCode);
        }

        return (
          <Fragment key={item.menuCode}>
            {item.subMenu.length !== 0 ? (
              <Box>
                <Box
                  className={`parent-menu ${
                    pathname.includes(item.url) ? "active" : ""
                  }`}
                  onClick={(e) => showCoords(e)}
                >
                  <MenuItem
                    title={item.menuName}
                    submenu={item.subMenu.length !== 0 ? true : false}
                    code={item.menuCode}
                    isOpen={openSubmenu}
                    fullSidebar={fullSidebar}
                    onClick={() => {
                      if (
                        openSubmenu.length > 0 &&
                        openSubmenu[0] === item.menuCode
                      ) {
                        setOpenSubMenu([""]);
                        return;
                      }
                      setOpenSubMenu([item.menuCode]);
                    }}
                    iconClass={item.icon}
                  />
                </Box>
                {openSubmenu.includes(item.menuCode) && (
                  <Box
                    id={fullSidebar ? "submenu" : "submenu-mini-id"}
                    className={fullSidebar ? "submenu" : "submenu-mini"}
                    style={{
                      top: fullSidebar ? "auto" : "auto",
                      left: fullSidebar ? "" : "80px",
                      transform: fullSidebar ? "" : "translateY(-60px)",
                    }}
                    ref={dropdownRef}
                  >
                    <Box
                      className={
                        pathname.includes(item.url) ? "submenu-active" : ""
                      }
                    >
                      {item.subMenu.map((one) => {
                        if (one.subMenu.length !== 0) {
                          return handleRenderSubMenu(one, 0, item.menuName);
                        }
                        if (one.url.length > 0 && one.url[0] === "/") {
                          return (
                            <NavLink key={one.menuCode} to={one.url}>
                              <MenuItem
                                title={one.menuName}
                                parent={item.menuName}
                              />
                            </NavLink>
                          );
                        } else {
                          return (
                            <NavLink
                              key={one.menuCode}
                              to={{ pathname: one.url }}
                              target="_blank"
                            >
                              <MenuItem title={one.menuName} />
                            </NavLink>
                          );
                        }
                      })}
                    </Box>
                  </Box>
                )}
              </Box>
            ) : item.url.length > 0 && item.url[0] === "/" ? (
              <NavLink to={item.url}>
                <MenuItem title={item.menuName} iconClass={item.icon} />
              </NavLink>
            ) : (
              <NavLink to={{ pathname: item.url }} target="_blank">
                <MenuItem title={item.menuName} iconClass={item.icon} />
              </NavLink>
            )}
          </Fragment>
        );
      })}
    </Box>
  );
};

const Sidebar = ({
  visible,
  close,
  fullSidebar,
  toggleFullSidebar,
  t,
  variant,
}) => {
  const [openSubmenu, setOpenSubMenu] = useState([]);

  useEffect(() => {
    const currentMenu = localStorage.getItem("currentMenu");
    if (fullSidebar && currentMenu)
      setOpenSubMenu([...openSubmenu, currentMenu]);
  }, [fullSidebar]);

  return (
    <Box
      className={`navbar-container ${fullSidebar ? "navbar-fullscreen" : ""}`}
    >
      <Box
        borderEndRadius="1.5rem"
        className={visible ? "sidebar sidebar-mini" : "sidebar sidebar-full"}
      >
        <Box className="sidebar-title">
          <label style={{ fontSize: 20 }}>
            <Logo />
          </label>
        </Box>
        <Box
          className="sidebar-button"
          onClick={() => {
            setOpenSubMenu([""]);
            toggleFullSidebar();
          }}
        >
          <Box className="sidebar-icon">
            {fullSidebar ? <FaChevronLeft /> : <FaChevronRight />}
          </Box>
        </Box>
        <SideBarContent
          fullSidebar={fullSidebar}
          openSubmenu={openSubmenu}
          setOpenSubMenu={setOpenSubMenu}
        />
      </Box>
    </Box>
  );
};

export default withRouter(Sidebar);
