import React, { useCallback } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalContent,
  Text,
} from "@chakra-ui/react";
import { BiArchiveIn } from "react-icons/bi";
import { BsExclamationTriangle } from "react-icons/bs";
import { AiOutlineSave } from "react-icons/ai";
import { IoIosArrowDropup } from "react-icons/io";
import { IoWarningOutline } from "react-icons/io5";
import { FiSlash } from "react-icons/fi";

function ModalConfirmation({
  message,
  handleSubmitAction,
  id,
  modalType,
  onClickYes,
  isOpen,
  onOpen,
  onClose,
  isLoading,
  setIsloading,
  viewType,
  index,
  name,
  idButtonNo,
  idButtonYes,
}) {
  const initialRef = React.useRef();
  const finalRef = React.useRef();

  // TODO : Dont Use Callback
  const onChangeIsloading = useCallback(() => {
    if (setIsloading) {
      setIsloading(true);
    }
  }, [setIsloading ? setIsloading : ""]);

  return (
    <>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={!isLoading}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody py={10} className="text-center">
            {modalType === "Activate" ||
            modalType === "Leave" ||
            modalType === "stop_procedure" ||
            modalType === "duplicate_process" ? (
              <IoIosArrowDropup
                size={
                  modalType === "duplicate_process" ||
                  modalType === "Leave" ||
                  modalType === "stop_procedure"
                    ? 60
                    : 80
                }
                style={{ color: "#21C306" }}
                className="mx-auto mb-4"
              />
            ) : modalType === "Archive" ? (
              <BiArchiveIn
                size={80}
                style={{ color: "#FFA800" }}
                className="mx-auto mb-4"
              />
            ) : modalType === "Edit" ? (
              <AiOutlineSave
                size={80}
                style={{ color: "#21C306" }}
                className="mx-auto mb-4"
              />
            ) : modalType === "Delete" ||
              modalType === "DiscardTemporaryChange" ? (
              <BsExclamationTriangle
                size={80}
                style={{ color: "#dd0000" }}
                className="mx-auto mb-4"
              />
            ) : modalType === "Cancel" || modalType === "Warning" ? (
              <IoWarningOutline
                size={80}
                style={{ color: "#dd0000" }}
                className="mx-auto mb-4"
              />
            ) : modalType === "Discard" ||
              modalType === "DiscardRow" ||
              modalType === "DiscardStep" ||
              modalType === "DeleteMethod" ||
              modalType === "DiscardTestType" ||
              modalType === "delete_parameter" ? (
              <FiSlash
                size={70}
                style={{ color: "#dd0000" }}
                className="mx-auto mb-4"
              />
            ) : modalType === "save_procedure" ||
              modalType === "Save" ||
              modalType === "SaveResult" ||
              modalType === "SaveTestTypeResult" ||
              modalType === "SubmitTemporaryChange" ? (
              <IoIosArrowDropup
                size={80}
                style={{ color: "#21C306" }}
                className="mx-auto mb-4"
              />
            ) : (
              ""
            )}
            {modalType !== "duplicate_process" &&
              modalType !== "Discard" &&
              modalType !== "DiscardStep" &&
              modalType !== "DiscardTestType" &&
              modalType !== "DeleteMethod" &&
              modalType !== "DiscardRow" &&
              modalType !== "Edit" &&
              modalType !== "Leave" &&
              modalType !== "save_procedure" &&
              modalType !== "Save" &&
              modalType !== "SaveResult" &&
              modalType !== "SaveTestTypeResult" &&
              modalType !== "stop_procedure" &&
              modalType !== "delete_parameter" &&
              modalType !== "duplicate_process" &&
              modalType !== "SubmitTemporaryChange" &&
              modalType !== "DiscardTemporaryChange" && (
                <Text my={1}>{modalType}</Text>
              )}
            {modalType === "Edit" && <Text my={1}>Save</Text>}
            {modalType !== "duplicate_process" &&
              modalType !== "Discard" &&
              modalType !== "DiscardStep" &&
              modalType !== "DiscardTestType" &&
              modalType !== "DeleteMethod" &&
              modalType !== "DiscardRow" &&
              modalType !== "Leave" &&
              modalType !== "save_procedure" &&
              modalType !== "Save" &&
              modalType !== "SaveResult" &&
              modalType !== "SaveTestTypeResult" &&
              modalType !== "delete_parameter" &&
              modalType !== "stop_procedure" &&
              modalType !== "SubmitTemporaryChange" &&
              modalType !== "DiscardTemporaryChange" && (
                <Text
                  mb={modalType === "Cancel" && viewType === "CREATE" ? 1 : 6}
                >
                  <b>"{message}"</b> ?
                  <br />
                </Text>
              )}
            {(modalType === "Discard" ||
              modalType === " delete_parameter" ||
              modalType === "DiscardTemporaryChange") && (
              <Text mb={2} fontSize={20} fontWeight="600">
                {message ? message : "Discard this data?"}
                <br />
              </Text>
            )}

            {modalType === "delete_parameter" && (
              <Text mb={2} fontSize={20} fontWeight="600">
                {message ? message : "Delete this parameter?"}
                <br />
              </Text>
            )}

            {modalType === "DiscardRow" && (
              <Text mb={2} fontSize={20} fontWeight="600">
                {message ? message : "Delete this step?"}
                <br />
              </Text>
            )}
            {modalType === "DiscardStep" && (
              <Text mb={2} fontSize={20} fontWeight="600">
                {message ? message : "Delete this process?"}
                <br />
              </Text>
            )}
            {modalType === "DiscardTestType" && (
              <Text mb={2} fontSize={20} fontWeight="600">
                {message ? message : "Delete this data?"}
                <br />
              </Text>
            )}
            {modalType === "DeleteMethod" && (
              <Text mb={2} fontSize={20} fontWeight="600">
                {message ? message : "Delete this Method?"}
                <br />
              </Text>
            )}
            {modalType === "save_procedure" && (
              <Text mb={5} fontSize={20} fontWeight="600">
                {message ? message : `Save "Step ${index}" `}
                <br />
              </Text>
            )}

            {(modalType === "stop_procedure" ||
              modalType === "SubmitTemporaryChange") && (
              <Text mb={5} fontSize={20} fontWeight="600">
                {message ? message : "Submit this data?"}
                <br />
              </Text>
            )}

            {modalType === "Cancel" && viewType === "CREATE" && (
              <Text mb={6} color="#9b9b9b" fontSize={"sm"}>
                This data cannot be used permanently
              </Text>
            )}
            {modalType === "Discard" && (
              <Text mb={6} color="#9b9b9b" fontSize={"sm"}>
                You will not be able to recover it
              </Text>
            )}
            {modalType === "delete_parameter" && (
              <Text mb={6} color="#9b9b9b" fontSize={"sm"}>
                You will not be able to recover it
              </Text>
            )}

            {modalType === "DiscardRow" && (
              <Text
                mb={6}
                color="#9b9b9b"
                style={{ padding: "0px 20px" }}
                fontSize={"sm"}
              >
                It will also be deleted step and procedures related to it
              </Text>
            )}
            {modalType === "DiscardStep" && (
              <Text
                mb={6}
                color="#9b9b9b"
                style={{ padding: "0px 20px" }}
                fontSize={"sm"}
              >
                It will also be deleted process and procedures related to it
              </Text>
            )}
            {modalType === "DiscardTestType" && (
              <Text
                mb={6}
                color="#9b9b9b"
                style={{ padding: "0px 20px" }}
                fontSize={"sm"}
              >
                You will not be able to recover it
              </Text>
            )}

            {modalType === "DeleteMethod" && (
              <Text
                mb={6}
                color="#9b9b9b"
                style={{ padding: "0px 20px" }}
                fontSize={"sm"}
              >
                It will also be deleted the procedures related to it
              </Text>
            )}

            {modalType === "Save" && (
              <Text mb={5} fontSize={16} fontWeight="600">
                You are about to leave this page, continue to procedure will
                save your latest data <br />
              </Text>
            )}

            {modalType === "Leave" && (
              <Text mb={5} fontSize={16} fontWeight="600">
                You are about to leave this page, continue to detail procedure
                will save your latest data <br />
              </Text>
            )}

            {modalType === "SaveTestTypeResult" && (
              <Text mb={5} fontSize={16} fontWeight="600">
                You are about to leave this page, <br />
                continue to result will save your latest data <br />
              </Text>
            )}

            {modalType === "SaveResult" && (
              <Text mb={5} fontSize={16} fontWeight="600">
                Save "Result"?
              </Text>
            )}

            {modalType === "duplicate_process" && (
              <Text mb={5} fontSize={16} fontWeight="600">
                This action will reset the content of input type: formula, room,
                and equipment in detail procedure
              </Text>
            )}

            <Button
              id={idButtonNo}
              size="md"
              mr={3}
              width={modalType === "Leave" ? 150 : 100}
              ref={initialRef}
              onClick={!isLoading ? onClose : null}
            >
              {modalType === "Leave" ? "No, Stay" : "No"}
            </Button>
            <Button
              id={idButtonYes}
              isLoading={isLoading}
              spinnerPlacement="start"
              size="md"
              className={
                modalType === "Archive" || modalType === "Warning"
                  ? "btn-warning-modal"
                  : modalType === "Activate" ||
                    modalType === "Leave" ||
                    modalType === "Save" ||
                    modalType === "SaveTestTypeResult" ||
                    modalType === "save_procedure" ||
                    modalType === "stop_procedure" ||
                    modalType === "SaveResult" ||
                    modalType === "duplicate_process"
                  ? "btn-success-modal"
                  : modalType === "Cancel" ||
                    modalType === "Discard" ||
                    modalType === "Discard" ||
                    modalType === "DiscardRow" ||
                    modalType === "DiscardStep" ||
                    modalType === "DeleteMethod" ||
                    modalType === "delete_parameter" ||
                    modalType === "DiscardTestType"
                  ? "btn-error-modal"
                  : modalType === "Edit"
                  ? "btn-success-modal"
                  : ""
              }
              width={
                modalType === "Leave" ||
                modalType === "Save" ||
                modalType === "SaveResult" ||
                modalType === "SaveTestTypeResult"
                  ? 150
                  : 100
              }
              onClick={() => {
                onChangeIsloading();
                onClickYes();
              }}
            >
              {modalType === "Leave" ||
              modalType === "Save" ||
              modalType === "SaveResult" ||
              modalType === "SaveTestTypeResult"
                ? "Yes, Continue"
                : "Yes"}
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ModalConfirmation;
