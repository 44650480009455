import styled from "styled-components";
import { useTable, usePagination } from "react-table";
import { Box } from "@chakra-ui/react";
import { isEmpty } from "lodash";

// Fungsi untuk Menampilkan Table
const TableStatic = ({
  columns,
  data,
  handeClickRow,
  isPaging,
  maxData,
  tableMinHeight,
  tableMaxHeight,
  withBorder = true,
  searchValue = "",
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data: data ? data : [{}],
      initialState: {
        pageIndex: 0,
        pageSize: maxData ? maxData : 10,
      },
    },
    usePagination
  );

  // Render the UI for your table
  return (
    <>
      <TableContainer
        tableMinHeight={tableMinHeight}
        tableMaxHeight={tableMaxHeight}
      >
        <StyledTable {...getTableProps()} withBorder={withBorder}>
          <thead style={{ borderTop: "1px solid #c4c4c4" }}>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps({
                      style: {
                        minWidth: column.minWidth,
                        width: column.width,
                        zIndex: 2,
                      },
                    })}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);

              return (
                <TableRow
                  {...(handeClickRow && {
                    onClick: () => handeClickRow(row),
                    clickable: true,
                  })}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </TableRow>
              );
            })}
            {page?.length < 1 && (
              <tr>
                <td
                  colSpan={
                    headerGroups ? headerGroups[0]?.headers?.length + 1 : 3
                  }
                  style={{ textAlign: "center" }}
                >
                  {data.length === 0 && isEmpty(searchValue) ? (
                    <p>There are no records to display</p>
                  ) : data.length === 0 && !isEmpty(searchValue) ? (
                    <p>Data Not Found</p>
                  ) : (
                    <p>Data Not Found</p>
                  )}
                </td>
              </tr>
            )}
          </tbody>
        </StyledTable>
      </TableContainer>
      {isPaging && (
        <Box textAlign="right" marginTop="1em">
          <div className="table-pagination">
            <button
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
              style={{ borderRight: "1px solid #cccc" }}
              className={!canPreviousPage ? "page-disabled" : ""}
            >
              {"< "}
            </button>
            <span className="paging-number">{pageIndex + 1}</span>
            <button
              onClick={() => nextPage()}
              disabled={!canNextPage}
              style={{ borderLeft: "1px solid #cccc" }}
              className={!canNextPage ? "page-disabled" : ""}
            >
              {" >"}
            </button>
          </div>
        </Box>
      )}
    </>
  );
};

const TableContainer = styled.div`
  width: 100%;
  min-height: ${({ tableMinHeight }) => tableMinHeight};
  max-height: ${({ tableMaxHeight }) =>
    tableMaxHeight ? tableMaxHeight : "calc(100vh - 400px)"};
  overflow-y: auto;
`;

const StyledTable = styled.table`
  width: 100%;
  position: relative;
  height: 100%;
  border-collapse: separate;
  border-spacing: 0px;

  th {
    position: sticky;
    top: 0;
    left: 0;
    background: #fff;
    text-align: left;
    padding: 6px 0px;
    user-select: none;
    border-top: ${({ withBorder }) =>
      withBorder ? "1px solid #c4c4c4" : "none"};
    border-bottom: 1px solid #c4c4c4;
    &:first-child {
      padding-left: 1em;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      border-left: ${({ withBorder }) =>
        withBorder ? "1px solid rgba(196, 196, 196, 0.43)" : "none"};
    }
    &:last-child {
      padding-right: 1em;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      border-right: ${({ withBorder }) =>
        withBorder ? "1px solid rgba(196, 196, 196, 0.43)" : "none"};
    }
  }

  td {
    border-bottom: 1px solid rgba(196, 196, 196, 0.43);
    padding: 15px 9px;
    &:first-child {
      border-left: ${({ withBorder }) =>
        withBorder ? "1px solid rgba(196, 196, 196, 0.43)" : "none"};
      padding-left: 1em;
    }
    &:last-child {
      border-right: ${({ withBorder }) =>
        withBorder ? "1px solid rgba(196, 196, 196, 0.43)" : "none"};
      padding-right: 1em;
    }
  }
`;

const TableRow = styled.tr`
  ${({ clickable }) =>
    clickable &&
    `&:hover {
      cursor: pointer;
      background-color: #1fb4d217;
    }`}
`;

export default TableStatic;
