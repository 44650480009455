
import { Box } from '@chakra-ui/react'
import Skeleton from 'react-loading-skeleton'

const SkeletonCardDetail = () => {
  return <Box borderWidth="0px" borderRadius="lg" overflow="hidden" className='bg-white shadow w-100 p-4 content-wrapper-spinner-on-card-detail'>
  <Skeleton height={24} />
  <Skeleton height={20} width="20%" className="mb-4" />
  <Skeleton height='10rem' />
</Box>
}

export default SkeletonCardDetail