import React from "react";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import { BiHomeAlt, BiChevronRight } from "react-icons/bi";

export default function BreadcrumbRequestRM({ detailPage = false }) {
  return (
    <div className="breadcrumb-container">
      <Breadcrumb spacing="8px" separator={<BiChevronRight color="gray.500" />}>
        <BreadcrumbItem>
          &nbsp;{" "}
          <BreadcrumbLink as={Link} to="/">
            <BiHomeAlt color="gray.500" />
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/qcs/qc/request/raw-material">
            Quality Control
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/qcs/qc/request/raw-material">
            Request
          </BreadcrumbLink>
        </BreadcrumbItem>

        {!detailPage && (
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink to="/qcs/qc/request/raw-material">
              Raw Material
            </BreadcrumbLink>
          </BreadcrumbItem>
        )}

        {detailPage && (
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to="/qcs/qc/request/raw-material">
              Raw Material
            </BreadcrumbLink>
          </BreadcrumbItem>
        )}

        {detailPage && (
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink to="/qcs/qc/request/raw-material">
              Detail Request
            </BreadcrumbLink>
          </BreadcrumbItem>
        )}
      </Breadcrumb>
    </div>
  );
}
