import { createSlice } from "@reduxjs/toolkit";

const offlineModeSlice = createSlice({
  name: "offlineMode",
  initialState: false, // Atur nilai awal sebagai online
  reducers: {
    setOffline: (state) => {
      return true;
    },
    setOnline: (state) => {
      return false;
    },
  },
});

export const { setOffline, setOnline } = offlineModeSlice.actions;

export default offlineModeSlice.reducer;
