import { Field, ErrorMessage } from "formik";
import {
  Input,
  InputGroup,
  InputRightAddon,
  InputLeftAddon,
  FormLabel,
  InputRightElement,
} from "@chakra-ui/react";
import withTrans from "../../../config/i18n/withTrans";
import style from "./styles.module.css";

const InputField = ({
  t,
  title,
  required,
  type,
  placeholder,
  name,
  id,
  leftAddon,
  rightAddon,
  leftIcon,
  toggleIcon,
  toggle,
  disabled,
  width,
  ref,
  classNameField,
  value,
  index,
  onChange,
  BackgroundColor,
  isReadOnly,
  className = "",
}) => {
  return (
    <div style={{ width: width ?? "100%" }}>
      <Field name={name}>
        {({ field, form }) => (
          <div style={{ marginTop: title ? 20 : 0 }} className={classNameField}>
            {title && (index === 0 || index === undefined) && (
              <FormLabel htmlFor={id} className="form-label">
                {title}{" "}
                {required ? <span style={{ color: "red" }}> *</span> : ""}
              </FormLabel>
            )}
            <InputGroup>
              {leftAddon && (
                <InputLeftAddon
                  children={leftAddon}
                  onClick={toggle ? toggle : ""}
                />
              )}
              {leftIcon && <div onClick={toggle ? toggle : ""}>{leftIcon}</div>}
              <Input
                {...field}
                id={id}
                backgroundColor={BackgroundColor}
                readOnly={isReadOnly}
                disabled={disabled}
                className={
                  form.errors[name] && form.touched[name]
                    ? `${className} ${style["is-invalid"]}`
                    : `${className}`
                }
                placeholder={
                  placeholder ?? t("commons.placeholder") + " " + title
                }
                type={type ?? "text"}
                ref={ref}
                value={value}
                onChange={onChange}
              />
              {toggleIcon && (
                <InputRightElement top="0.5rem" right="0.25rem" width="3rem">
                  <div onClick={toggle ? toggle : ""}>{toggleIcon}</div>
                </InputRightElement>
              )}
              {rightAddon && (
                <InputRightAddon
                  children={rightAddon}
                  onClick={toggle ? toggle : ""}
                />
              )}
            </InputGroup>
            <ErrorMessage
              name={name}
              component="div"
              className={`${style["invalid-feedback"]}`}
            />
          </div>
        )}
      </Field>
    </div>
  );
};

export default withTrans(InputField);
