import "./style.css";
import { Spinner } from "@chakra-ui/react";

// To use this component, there are 3 template color
// Variant: Primary (default), Outline
// Color: Blue (default), Red, Green, Orange, dark-blue

const Button = ({
  children,
  onClick,
  className,
  type,
  color,
  variant,
  isLoading,
  ...otherProps
}) => {
  const renderClass = () => {
    let colorTemp = color ? color : "blue";
    let variantTemp = variant ? variant : "primary";
    const classNames = [variantTemp, colorTemp];

    let classNameJoined = classNames.join("-");

    return variantTemp + " " + classNameJoined;
  };

  return (
    <button
      onClick={onClick}
      {...otherProps}
      className={"template " + renderClass() + " " + className}
    >
      {isLoading ? (
        <Spinner style={{ verticalAlign: "middle" }} />
      ) : (
        <>{children}</>
      )}
    </button>
  );
};

export default Button;
