import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Textarea,
  PinInput,
  PinInputField,
  FormControl,
  FormLabel,
  FormErrorMessage,
  HStack,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";

const ModalApproval = ({
  docCodeModal,
  modulIdModal,
  modulModal,
  handleSubmitAdd,
  action,
  isOpen,
  onClose,
  loadingButton,
  titleModal,
  isNoteMandatory,
}) => {
  const initialRefAdd = React.useRef();
  const finalRefAdd = React.useRef();
  const dataOpenCode = docCodeModal;
  const dataModulId = modulIdModal;
  const dataModul = modulModal;

  // Fungsi untuk Validasi Kolom Digital Signature
  function validateDigitalSignature(value) {
    let error;
    if (!value) {
      error = "Required";
    }
    if (value.length < 6 || value.length > 6) {
      error = "Required";
    }
    return error;
  }

  // Fungsi untuk Validasi Kolom Notes
  function validateNotes(value) {
    let error;
    if (value) {
      let value2 = value.replace(/\s{2,}/g, " ").trim();
      if (value2 === "") {
        error = "Required";
      }
    }
    if (!value) {
      error = "Required";
    }
    return error;
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        initialFocusRef={initialRefAdd}
        finalFocusRef={finalRefAdd}
        id="review-confirm"
      >
        <ModalOverlay />
        <ModalContent>
          {titleModal ? (
            <ModalHeader>{titleModal}</ModalHeader>
          ) : (
            <ModalHeader>
              {action === "approve" ? "Approval" : "Reject"} Confirmation
            </ModalHeader>
          )}

          <ModalCloseButton />
          <ModalBody>
            <Formik
              initialValues={{
                documentCode: dataOpenCode,
                digitalSignature: "",
                notes: "",
                modulId: dataModulId,
                modul: dataModul,
              }}
              validateOnChange={true}
              validateOnBlur={false}
              onSubmit={handleSubmitAdd}
            >
              {(props) => (
                <Form className="add-org-modal">
                  <Field
                    name="digitalSignature"
                    validate={validateDigitalSignature}
                  >
                    {({ field, form }) => (
                      <FormControl
                        className="mb-4"
                        isInvalid={
                          form.errors.digitalSignature &&
                          form.touched.digitalSignature
                        }
                      >
                        <FormLabel htmlFor="digitalSignature">
                          DIGITAL SIGNATURE{" "}
                          <span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <HStack>
                          <PinInput
                            id="signature-field"
                            {...field}
                            type="number"
                            placeholder=""
                            onChange={(e) => {
                              if (field.value.length === 6 && e.length === 6) {
                                form.setFieldValue(field.name, field.value);
                              } else {
                                form.setFieldValue(field.name, e);
                              }
                            }}
                            isInvalid={
                              form.errors.digitalSignature &&
                              form.touched.digitalSignature
                            }
                            mask
                          >
                            <PinInputField />
                            <PinInputField />
                            <PinInputField />
                            <PinInputField />
                            <PinInputField />
                            <PinInputField />
                          </PinInput>
                        </HStack>
                        <FormErrorMessage>
                          {form.errors.digitalSignature &&
                          form.touched.digitalSignature
                            ? form.errors.digitalSignature
                            : ""}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <Field
                    name="notes"
                    validate={
                      isNoteMandatory || action !== "approve"
                        ? validateNotes
                        : null
                    }
                  >
                    {({ field, form }) => (
                      <FormControl
                        className="mb-4"
                        isInvalid={form.errors.notes && form.touched.notes}
                      >
                        <FormLabel htmlFor="notes">
                          NOTES
                          {(action === "reject" || isNoteMandatory) && (
                            <span style={{ color: "red" }}> *</span>
                          )}
                        </FormLabel>
                        <Textarea
                          {...field}
                          maxLength={200}
                          placeholder="Write a note here"
                          className="form-control"
                          id="notes"
                          resize="none"
                          size="lg"
                        />
                        <FormErrorMessage>
                          {form.errors.notes && form.touched.notes
                            ? form.errors.notes
                            : ""}
                        </FormErrorMessage>
                      </FormControl>
                    )}
                  </Field>

                  <ModalFooter mt={3} pb={0} style={{ paddingRight: "0" }}>
                    <Button
                      id="no-approval-button"
                      onClick={onClose}
                      mr={1}
                      className="btn-cm-outline-primary font-14 text-weight-normal"
                      style={{ minWidth: "100px" }}
                    >
                      No
                    </Button>
                    <Button
                      id="yes-approval-button"
                      isLoading={loadingButton}
                      mr={3}
                      type="submit"
                      className="submitnext action-button modal-yes-btn"
                      style={{ margin: "0 0 0 15px" }}
                    >
                      Yes
                    </Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ModalApproval;
