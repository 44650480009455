import React, { useState, useRef, useEffect } from "react";
import { map, isNil, isEmpty } from "lodash";
import { MdDateRange, MdClose, MdOutlineAccessTime } from "react-icons/md";
import {
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  SimpleGrid,
  Text,
  useOutsideClick,
  VStack,
  InputGroup,
  InputRightElement,
  FormLabel,
  IconButton,
  Stack,
} from "@chakra-ui/react";
import { useDayzed } from "dayzed";
import { format, set } from "date-fns";
import moment from "moment-timezone";
import { BsFillCaretLeftFill, BsFillCaretRightFill } from "react-icons/bs";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { BiTimeFive } from "react-icons/bi";

const MONTH_NAMES = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
const DAY_NAMES = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const DATE_FORMAT = "dd MMM yyyy";
const DATE_FORMAT_TIME = "dd MMM yyyy HH:mm";
const TIME_ONLY_FORMAT = "HH:mm";
const ONLY_DATE_FORMAT = "yyyy-MM-dd";

const SingleDatepickerBackMonthButtons = (props) => {
  const { calendars, getBackProps } = props;
  return (
    <>
      <Button {...getBackProps({ calendars })} variant="ghost" size="sm">
        <BsFillCaretLeftFill />
      </Button>
    </>
  );
};

const SingleDatepickerForwardMonthButtons = (props) => {
  const { calendars, getForwardProps } = props;

  return (
    <>
      <Button {...getForwardProps({ calendars })} variant="ghost" size="sm">
        <BsFillCaretRightFill />
      </Button>
    </>
  );
};

const SingleDatepickerBackYearButtons = (props) => {
  const { calendars, getBackProps } = props;
  return (
    <>
      <Button
        {...getBackProps({
          calendars,
          offset: 12,
        })}
        variant="ghost"
        size="sm"
      >
        <BsFillCaretLeftFill />
      </Button>
    </>
  );
};

const SingleDatepickerForwardYearButtons = (props) => {
  const { calendars, getForwardProps } = props;

  return (
    <>
      <Button
        {...getForwardProps({
          calendars,
          offset: 12,
        })}
        variant="ghost"
        size="sm"
      >
        <BsFillCaretRightFill />
      </Button>
    </>
  );
};

const SingleDatepickerCalendar = (props) => {
  const {
    calendars,
    getDateProps,
    getBackProps,
    getForwardProps,
    currentDate,
  } = props;

  if (isEmpty(calendars)) {
    return null;
  }

  return (
    <Box>
      <HStack spacing={6} alignItems="baseline">
        {map(calendars, (calendar) => {
          return (
            <VStack key={`${calendar.month}${calendar.year}`} className="w-100">
              <HStack>
                <SingleDatepickerBackMonthButtons
                  calendars={calendars}
                  getBackProps={getBackProps}
                />
                <Heading size="xs" textAlign="center">
                  {MONTH_NAMES[calendar.month]}
                </Heading>
                <SingleDatepickerForwardMonthButtons
                  calendars={calendars}
                  getForwardProps={getForwardProps}
                />

                <SingleDatepickerBackYearButtons
                  calendars={calendars}
                  getBackProps={getBackProps}
                />
                <Heading size="xs" textAlign="center">
                  {calendar.year}
                </Heading>
                <SingleDatepickerForwardYearButtons
                  calendars={calendars}
                  getForwardProps={getForwardProps}
                />
              </HStack>
              <Divider />
              <SimpleGrid columns={7} spacing={1} textAlign="center">
                {map(DAY_NAMES, (day) => (
                  <Box key={`${calendar.month}${calendar.year}${day}`}>
                    <Text fontSize="xs" fontWeight="normal">
                      {day}
                    </Text>
                  </Box>
                ))}
                {map(calendar.weeks, (week, weekIndex) => {
                  return map(week, (dateObj, index) => {
                    const { date, today, prevMonth, nextMonth, selectable } =
                      dateObj;

                    let isSelected = false;

                    if (
                      date.getDate() === currentDate.getDate() &&
                      date.getMonth() === currentDate.getMonth() &&
                      date.getFullYear() === currentDate.getFullYear()
                    ) {
                      isSelected = true;
                    }

                    const key = `${calendar.month}${calendar.year}${weekIndex}${index}`;
                    const isDisabled = prevMonth || nextMonth;

                    const style = () => {
                      const obj = {
                        variant: "outline",
                        borderColor: "transparent",
                        color: "inherit",
                        borderRadius: "50%",
                        height: "32px",
                        fontWeight: 500,
                        bg: "inherit",
                      };

                      if (!selectable) {
                        obj.disabled = true;
                      }

                      if (today) {
                        obj.borderColor = "#1fb4d2";
                      }

                      if (isSelected) {
                        obj.bg = "#1fb4d2";
                        obj.color = "#fff";
                      }

                      return obj;
                    };

                    return (
                      <Button
                        {...getDateProps({
                          dateObj,
                          disabled: isDisabled,
                        })}
                        className="date-chakra"
                        key={key}
                        size="xs"
                        {...style()}
                      >
                        {date.getDate()}
                      </Button>
                    );
                  });
                })}
              </SimpleGrid>
            </VStack>
          );
        })}
      </HStack>
    </Box>
  );
};

export const SingleDatepicker = ({
  value,
  disabled = false,
  onChange,
  title,
  required,
  id,
  minDate,
  maxDate,
  size,
  BackgroundColor,
  width,
  inlineInput = false,
  placeHolder,
  zIndexs,
  index,
  timePicker = false,
  placement = "bottom-start",
  isError,
  borderColor,
  name,
  errorMessageAllowed = true,
  parentMarginTop,
  timePickerOnly,
  initialTime,
  onFocus,
  defaultValue,
  isClearable = false,
  date,
  idButtonSetDate,
  ...rest
}) => {
  const [currentDate, setCurrentDate] = useState(
    value
      ? new Date(moment(value).tz("Asia/Jakarta").format())
      : new Date(value)
  );

  const ref = useRef(null);
  const initialFocusRef = useRef(null);
  const initialRender = useRef(true);

  const [proposedDate, setProposedDate] = useState(
    value && !isNaN(value)
      ? format(
          value,
          timePicker
            ? timePickerOnly
              ? TIME_ONLY_FORMAT
              : DATE_FORMAT_TIME
            : DATE_FORMAT
        )
      : ""
  );

  const initialValue =
    value && !isNaN(value)
      ? new Date(value)
      : minDate
      ? new Date(minDate)
      : new Date();

  const [popoverOpen, setPopoverOpen] = useState(false);
  const [timePopover, setTimePopover] = useState(false);
  const [hour, setHour] = useState(new Date().getHours());
  const [minute, setMinute] = useState(new Date().getMinutes());
  const [maxHour, setMaxHour] = useState(0);
  const [maxMinute, setMaxMinute] = useState(0);
  const [minHour, setMinHour] = useState(0);
  const [minMinute, setMinMinute] = useState(0);

  const onChangePrime = (date) => {
    const today = new Date();
    const current = new Date(date);
    const newDate =
      timePicker || timePickerOnly
        ? moment(date).tz("Asia/Jakarta").format()
        : format(
            set(current, {
              hours: today.getHours(),
              minutes: today.getMinutes(),
              seconds: today.getSeconds(),
            }),
            ONLY_DATE_FORMAT
          );

    if (newDate && !initialRender.current) {
      onChange(newDate);
      setProposedDate(
        format(
          new Date(newDate),
          timePicker
            ? timePickerOnly
              ? TIME_ONLY_FORMAT
              : DATE_FORMAT_TIME
            : DATE_FORMAT
        )
      );
    }
  };

  useOutsideClick({
    ref: ref,
    handler: () => {
      setCurrentDate(initialValue);
      setPopoverOpen(false);
    },
  });

  useEffect(() => {
    setProposedDate(
      value && !isNaN(value)
        ? format(
            value,
            timePicker
              ? timePickerOnly
                ? TIME_ONLY_FORMAT
                : DATE_FORMAT_TIME
              : DATE_FORMAT
          )
        : ""
    );
  }, [value]);

  useEffect(() => {
    if (popoverOpen === false) {
      setTimeout(() => {
        setTimePopover(false);
      }, 500);
    }
  }, [popoverOpen]);

  useEffect(() => {
    const minimumDate = new Date(minDate);
    const maximumDate = new Date(maxDate);
    const current = new Date(currentDate);

    if (current.getDate() === minimumDate.getDate()) {
      setMinHour(minimumDate.getHours());
      setMinMinute(minimumDate.getMinutes());
    } else if (current.getDate() === maximumDate.getDate()) {
      setMaxHour(maximumDate.getHours());
      setMaxMinute(maximumDate.getMinutes());
    }
  }, [minDate, maxDate, currentDate]);

  useEffect(() => {
    if (initialTime) {
      const initTime = new Date(initialTime);

      setHour(initTime.getHours());
      setMinute(initTime.getMinutes());
    }
  }, [initialTime]);

  const onDateSelected = (options) => {
    const { selectable, date } = options;

    if (!selectable) {
      return;
    }

    if (!isNil(date)) {
      setCurrentDate(date);
      return date;
    }
  };

  const dayzedData = useDayzed({
    showOutsideDays: true,
    onDateSelected,
    selected: value,
    minDate:
      new Date(minDate).getHours() !== 0
        ? set(new Date(minDate), { date: new Date(minDate).getDate() - 1 })
        : new Date(minDate),
    maxDate: new Date(maxDate),
    date: date || new Date(),
  });

  return (
    <Box
      width={width}
      style={{ marginTop: title ? 20 : parentMarginTop }}
      {...rest}
    >
      {title && (index === 0 || index === undefined) && (
        <FormLabel htmlFor={id} className="form-label">
          {title} {required ? <span style={{ color: "red" }}> *</span> : ""}
        </FormLabel>
      )}
      <Popover
        placement={placement}
        variant="responsive"
        isOpen={popoverOpen}
        initialFocusRef={initialFocusRef}
        isLazy
        onClose={() => {
          setPopoverOpen(false);
        }}
      >
        <PopoverTrigger>
          <Box>
            <InputGroup style={{ borderColor: "#CBD5E0" }}>
              <Input
                bg={disabled ? "#F8F8F8" : BackgroundColor ?? "white"}
                readOnly={true}
                placeholder={placeHolder ? placeHolder : "Please select date"}
                className={disabled ? "disabled-input" : ""}
                value={proposedDate}
                size={size}
                ref={initialFocusRef}
                onFocus={onFocus}
                onClick={() => !disabled && setPopoverOpen(!popoverOpen)}
                borderColor={borderColor}
                clear
                px={2}
                id={id}
              />
              <InputRightElement
                width="3.5rem"
                // children={(<><MdDateRange style={{ top: "-4px" }} /></>)}
                // onClick={() => !disabled && setPopoverOpen(!popoverOpen)}
                style={zIndexs ? { zIndex: zIndexs } : { zIndex: "0" }}
              >
                {isClearable && proposedDate && (
                  <Button
                    variant="unstyled"
                    onClick={() => {
                      setProposedDate("");
                      setProposedDate("");
                      onChange("");
                    }}
                    top="-2px"
                    right="-20px"
                  >
                    <MdClose />
                  </Button>
                )}
                {timePicker ? (
                  <MdOutlineAccessTime
                    style={{
                      top: "-4px",
                      left: "10px",
                      display: isClearable && proposedDate ? "none" : "block",
                    }}
                  />
                ) : (
                  <MdDateRange
                    style={{
                      top: "-4px",
                      left: "10px",
                      display: isClearable && proposedDate ? "none" : "block",
                    }}
                  />
                )}
              </InputRightElement>
            </InputGroup>
            {isError && errorMessageAllowed && (
              <Box className="invalid-feedback">Required</Box>
            )}
          </Box>
        </PopoverTrigger>
        <PopoverContent
          ref={ref}
          borderColor="#CBD5E0"
          width={
            timePicker || timePickerOnly
              ? timePickerOnly
                ? "230px"
                : "460px"
              : undefined
          }
        >
          <PopoverBody>
            {timePicker || timePickerOnly ? (
              <Box marginTop="10px">
                <HStack>
                  {!timePickerOnly && (
                    <SingleDatepickerCalendar
                      {...dayzedData}
                      currentDate={currentDate ? currentDate : new Date()}
                      onClose={() => setPopoverOpen(false)}
                    />
                  )}
                  {!timePickerOnly && (
                    <div
                      style={{
                        width: "1px",
                        height: "230px",
                        backgroundColor: "#CBD5E0",
                        margin: "0 15px",
                        opacity: "0.6",
                      }}
                    ></div>
                  )}
                  <Stack spacing={0} width={!timePickerOnly ? "20%" : "40%"}>
                    <IconButton
                      id="button-up-hour"
                      variant="outline"
                      icon={<IoIosArrowUp />}
                      borderRadius="0.375rem 0.375rem 0px 0px"
                      disabled={
                        maxDate &&
                        currentDate &&
                        currentDate.getDate() >= maxDate.getDate() &&
                        hour === maxHour
                      }
                      onClick={() => {
                        if (hour >= 23) {
                          setHour(0);
                        } else {
                          setHour(hour + 1);
                        }
                      }}
                    />
                    <Input
                      type="number"
                      value={String(hour).padStart(2, "0")}
                      textAlign="center"
                      borderBottom="0px"
                      borderTop="0px"
                      borderRadius={0}
                      height="40px"
                      onBlur={(e) => {
                        const val = e.target.value;

                        if (
                          minDate &&
                          currentDate &&
                          currentDate.getDate() <= minDate.getDate()
                        ) {
                          let currentDateTime = currentDate.setHours(val);
                          currentDateTime = currentDate.setMinutes(minute);

                          if (currentDateTime < minDate) {
                            setHour(minDate.getHours());
                          }
                        } else if (
                          maxDate &&
                          currentDate &&
                          currentDate.getDate() >= maxDate.getDate()
                        ) {
                          let currentDateTime = currentDate.setHours(val);
                          currentDateTime = currentDate.setMinutes(minute);

                          if (currentDateTime > maxDate) {
                            setHour(maxDate.getHours());
                          }
                        } else if (val >= 23) {
                          setHour(23);
                        } else if (val <= 0) {
                          setHour(0);
                        } else if (!val) {
                          setHour(0);
                        }
                      }}
                      onChange={(e) => {
                        if (parseInt(e.target.value) < 24) {
                          setHour(parseInt(e.target.value));
                        }
                      }}
                      pattern="[0-9]*"
                      onWheelCapture={(e) => {
                        e.target.blur();
                      }}
                    />
                    <IconButton
                      id="button-down-hour"
                      variant="outline"
                      disabled={
                        minDate &&
                        currentDate &&
                        currentDate.getDate() <= minDate.getDate() &&
                        hour <= minHour
                      }
                      icon={<IoIosArrowDown />}
                      borderRadius="0px 0px 0.375rem 0.375rem"
                      onClick={() => {
                        if (
                          minDate &&
                          currentDate &&
                          currentDate.getDate() <= minDate.getDate() &&
                          hour - 1 <= minHour &&
                          minute <= minMinute
                        ) {
                          setMinute(minMinute);
                        }

                        if (hour <= 0) {
                          setHour(23);
                        } else {
                          setHour(hour - 1);
                        }
                      }}
                    />
                  </Stack>
                  <Box
                    textAlign="center"
                    width={!timePickerOnly ? "4%" : "20%"}
                    fontSize="xl"
                    fontWeight="extrabold"
                  >
                    :
                  </Box>
                  <Stack spacing={0} width={!timePickerOnly ? "20%" : "40%"}>
                    <IconButton
                      id="button-up-mnt"
                      variant="outline"
                      icon={<IoIosArrowUp />}
                      borderRadius="0.375rem 0.375rem 0px 0px"
                      disabled={
                        maxDate &&
                        currentDate &&
                        currentDate.getDate() >= maxDate.getDate() &&
                        minute === maxMinute
                      }
                      onClick={() => {
                        if (minute >= 59) {
                          setMinute(0);
                        } else {
                          setMinute(minute + 1);
                        }
                      }}
                    />
                    <Input
                      type="number"
                      value={String(minute).padStart(2, "0")}
                      textAlign="center"
                      borderBottom="0px"
                      borderTop="0px"
                      borderRadius={0}
                      height="40px"
                      onBlur={(e) => {
                        const val = e.target.value;

                        if (
                          minDate &&
                          currentDate &&
                          currentDate.getDate() <= minDate.getDate()
                        ) {
                          let currentDateTime = currentDate.setMinutes(val);

                          currentDateTime = currentDate.setHours(hour);

                          if (currentDateTime < minDate) {
                            setMinute(minDate.getMinutes());
                          }
                        } else if (
                          maxDate &&
                          currentDate &&
                          currentDate.getDate() <= maxDate.getDate()
                        ) {
                          let currentDateTime = currentDate.setMinutes(val);

                          currentDateTime = currentDate.setHours(hour);

                          if (currentDateTime > maxDate) {
                            setMinute(maxDate.getMinutes());
                          }
                        } else if (val >= 59) {
                          setMinute(59);
                        } else if (val <= 0) {
                          setMinute(0);
                        } else if (!val) {
                          setMinute(0);
                        }
                      }}
                      onChange={(e) => {
                        if (parseInt(e.target.value) < 60) {
                          setMinute(parseInt(e.target.value));
                        }
                      }}
                      pattern="[0-9]*"
                      onWheelCapture={(e) => {
                        e.target.blur();
                      }}
                    />
                    <IconButton
                      id="button-down-mnt"
                      variant="outline"
                      disabled={
                        minDate &&
                        currentDate &&
                        currentDate.getDate() <= minDate.getDate() &&
                        hour === minHour &&
                        minute === minMinute
                      }
                      icon={<IoIosArrowDown />}
                      borderRadius="0px 0px 0.375rem 0.375rem"
                      onClick={() => {
                        if (minute <= 0) {
                          setMinute(59);
                        } else {
                          setMinute(minute - 1);
                        }
                      }}
                    />
                  </Stack>
                </HStack>
              </Box>
            ) : (
              <SingleDatepickerCalendar
                {...dayzedData}
                currentDate={currentDate ? currentDate : new Date()}
                onClose={() => setPopoverOpen(false)}
              />
            )}
            <Stack
              direction="row"
              padding={timePopover ? "0px 0px" : "0px 25px"}
              align="center"
              justifyContent="center"
              mt={5}
              mb={timePopover ? "10px" : 5}
            >
              <Button
                id="close-date-filter"
                borderColor="#1fb4d2"
                color="#1fb4d2"
                variant="outline"
                size="sm"
                w="100px"
                onClick={() => {
                  onChangePrime(initialValue);
                  setCurrentDate(initialValue);
                  setPopoverOpen(false);
                }}
              >
                Close
              </Button>
              {timePicker || timePickerOnly ? (
                <Button
                  id="button-set-time"
                  backgroundColor="#1fb4d2"
                  color="white"
                  variant="solid"
                  size="sm"
                  w="100px"
                  _hover="#1fb4d2"
                  onClick={() => {
                    const newFormat = new Date(
                      currentDate.getFullYear(),
                      currentDate.getMonth(),
                      currentDate.getDate(),
                      hour,
                      minute,
                      0
                    );
                    initialRender.current = false;

                    onChangePrime(newFormat);
                    setPopoverOpen(false);
                  }}
                >
                  Set Time
                </Button>
              ) : (
                <Button
                  id={idButtonSetDate}
                  backgroundColor="#1fb4d2"
                  color="white"
                  variant="solid"
                  size="sm"
                  w="100px"
                  _hover="#1fb4d2"
                  onClick={() => {
                    initialRender.current = false;
                    onChangePrime(currentDate);
                    setPopoverOpen(false);
                  }}
                >
                  Set Date
                </Button>
              )}
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export const TimePicker = ({
  value,
  disabled = false,
  onChange,
  title,
  required,
  id,
  minDate,
  maxDate,
  size,
  BackgroundColor,
  width,
  inlineInput = false,
  placeHolder,
  index,
  timePicker = false,
  placement = "bottom-start",
  isError,
  borderColor,
  name,
  errorMessageAllowed = true,
  parentMarginTop,
  timePickerOnly,
  initialTime,
  onFocus,
  defaultValue,
  ...rest
}) => {
  const [currentDate, setCurrentDate] = useState(
    value
      ? new Date(moment(value).tz("Asia/Jakarta").format())
      : new Date(value)
  );

  const ref = useRef(null);
  const initialFocusRef = useRef(null);
  const initialRender = useRef(true);

  const [proposedDate, setProposedDate] = useState(
    value && !isNaN(value) ? format(value, TIME_ONLY_FORMAT) : ""
  );

  const initialValue =
    value && !isNaN(value)
      ? new Date(value)
      : minDate
      ? new Date(minDate)
      : new Date();

  const [popoverOpen, setPopoverOpen] = useState(false);
  const [timePopover, setTimePopover] = useState(false);
  const [hour, setHour] = useState(new Date().getHours());
  const [minute, setMinute] = useState(new Date().getMinutes());
  const [maxHour, setMaxHour] = useState(0);
  const [maxMinute, setMaxMinute] = useState(0);
  const [minHour, setMinHour] = useState(0);
  const [minMinute, setMinMinute] = useState(0);

  const onChangePrime = (date) => {
    const newDate = moment(date).tz("Asia/Jakarta").format();

    if (newDate && !initialRender.current) {
      onChange(newDate);
      setProposedDate(format(new Date(newDate), TIME_ONLY_FORMAT));
    }
  };

  useOutsideClick({
    ref: ref,
    handler: () => {
      setCurrentDate(initialValue);
      setPopoverOpen(false);
    },
  });

  useEffect(() => {
    setProposedDate(
      value && !isNaN(value) ? format(value, TIME_ONLY_FORMAT) : ""
    );
  }, [value]);

  useEffect(() => {
    if (popoverOpen === false) {
      setTimeout(() => {
        setTimePopover(false);
      }, 500);
    }
  }, [popoverOpen]);

  useEffect(() => {
    const minimumDate = new Date(minDate);
    const maximumDate = new Date(maxDate);
    const current = new Date(currentDate);

    if (current.getDate() === minimumDate.getDate()) {
      setMinHour(minimumDate.getHours());
      setMinMinute(minimumDate.getMinutes());
    } else if (current.getDate() === maximumDate.getDate()) {
      setMaxHour(maximumDate.getHours());
      setMaxMinute(maximumDate.getMinutes());
    }
  }, [minDate, maxDate, currentDate]);

  useEffect(() => {
    if (initialTime) {
      const initTime = new Date(initialTime);

      setHour(initTime.getHours());
      setMinute(initTime.getMinutes());
    }
  }, [initialTime]);

  return (
    <Box
      width={width}
      style={{ marginTop: title ? 20 : parentMarginTop }}
      {...rest}
    >
      {title && (index === 0 || index === undefined) && (
        <FormLabel htmlFor={id} className="form-label">
          {title} {required ? <span style={{ color: "red" }}> *</span> : ""}
        </FormLabel>
      )}
      <Popover
        placement={placement}
        variant="responsive"
        isOpen={popoverOpen}
        initialFocusRef={initialFocusRef}
        isLazy
        onClose={() => {
          setPopoverOpen(false);
        }}
      >
        <PopoverTrigger>
          <Box>
            <InputGroup style={{ borderColor: "#CBD5E0" }}>
              <Input
                bg={disabled ? "#ECECEC" : BackgroundColor ?? "white"}
                readOnly={true}
                placeholder={placeHolder ? placeHolder : "Please select date"}
                className="disabled-input"
                value={proposedDate}
                size={size}
                ref={initialFocusRef}
                onFocus={onFocus}
                onClick={() => !disabled && setPopoverOpen(!popoverOpen)}
                borderColor={borderColor}
              />
              <InputRightElement
                children={<BiTimeFive />}
                onClick={() => !disabled && setPopoverOpen(!popoverOpen)}
              />
            </InputGroup>
            {isError && errorMessageAllowed && (
              <Box className="invalid-feedback">Required</Box>
            )}
          </Box>
        </PopoverTrigger>
        <PopoverContent ref={ref} borderColor="#CBD5E0" width={"310px"}>
          <PopoverBody>
            <Box marginTop="10px">
              <HStack style={{ justifyContent: "center" }}>
                <Stack spacing={0} width="25%">
                  <IconButton
                    variant="outline"
                    icon={<IoIosArrowUp />}
                    borderRadius="0.375rem 0.375rem 0px 0px"
                    disabled={
                      maxDate &&
                      currentDate &&
                      currentDate.getDate() >= maxDate.getDate() &&
                      hour === maxHour
                    }
                    onClick={() => {
                      if (hour >= 23) {
                        setHour(0);
                      } else {
                        setHour(hour + 1);
                      }
                    }}
                  />
                  <Input
                    type="number"
                    value={String(hour).padStart(2, "0")}
                    textAlign="center"
                    borderBottom="0px"
                    borderTop="0px"
                    borderRadius={0}
                    height="40px"
                    onBlur={(e) => {
                      const val = e.target.value;

                      if (
                        minDate &&
                        currentDate &&
                        currentDate.getDate() <= minDate.getDate()
                      ) {
                        let currentDateTime = currentDate.setHours(val);
                        currentDateTime = currentDate.setMinutes(minute);

                        if (currentDateTime < minDate) {
                          setHour(minDate.getHours());
                        }
                      } else if (
                        maxDate &&
                        currentDate &&
                        currentDate.getDate() >= maxDate.getDate()
                      ) {
                        let currentDateTime = currentDate.setHours(val);
                        currentDateTime = currentDate.setMinutes(minute);

                        if (currentDateTime > maxDate) {
                          setHour(maxDate.getHours());
                        }
                      } else if (val >= 23) {
                        setHour(23);
                      } else if (val <= 0) {
                        setHour(0);
                      } else if (!val) {
                        setHour(0);
                      }
                    }}
                    onChange={(e) => setHour(parseInt(e.target.value))}
                  />
                  <IconButton
                    variant="outline"
                    disabled={
                      minDate &&
                      currentDate &&
                      currentDate.getDate() <= minDate.getDate() &&
                      hour <= minHour
                    }
                    icon={<IoIosArrowDown />}
                    borderRadius="0px 0px 0.375rem 0.375rem"
                    onClick={() => {
                      if (
                        minDate &&
                        currentDate &&
                        currentDate.getDate() <= minDate.getDate() &&
                        hour - 1 <= minHour &&
                        minute <= minMinute
                      ) {
                        setMinute(minMinute);
                      }

                      if (hour <= 0) {
                        setHour(23);
                      } else {
                        setHour(hour - 1);
                      }
                    }}
                  />
                </Stack>
                <Box
                  textAlign="center"
                  width="4%"
                  fontSize="xl"
                  fontWeight="extrabold"
                >
                  :
                </Box>
                <Stack spacing={0} width="25%">
                  <IconButton
                    variant="outline"
                    icon={<IoIosArrowUp />}
                    borderRadius="0.375rem 0.375rem 0px 0px"
                    disabled={
                      maxDate &&
                      currentDate &&
                      currentDate.getDate() >= maxDate.getDate() &&
                      minute === maxMinute
                    }
                    onClick={() => {
                      if (minute >= 59) {
                        setMinute(0);
                      } else {
                        setMinute(minute + 1);
                      }
                    }}
                  />
                  <Input
                    type="number"
                    value={String(minute).padStart(2, "0")}
                    textAlign="center"
                    borderBottom="0px"
                    borderTop="0px"
                    borderRadius={0}
                    height="40px"
                    onBlur={(e) => {
                      const val = e.target.value;

                      if (
                        minDate &&
                        currentDate &&
                        currentDate.getDate() <= minDate.getDate()
                      ) {
                        let currentDateTime = currentDate.setMinutes(val);

                        currentDateTime = currentDate.setHours(hour);

                        if (currentDateTime < minDate) {
                          setMinute(minDate.getMinutes());
                        }
                      } else if (
                        maxDate &&
                        currentDate &&
                        currentDate.getDate() <= maxDate.getDate()
                      ) {
                        let currentDateTime = currentDate.setMinutes(val);

                        currentDateTime = currentDate.setHours(hour);

                        if (currentDateTime > maxDate) {
                          setMinute(maxDate.getMinutes());
                        }
                      } else if (val >= 59) {
                        setMinute(59);
                      } else if (val <= 0) {
                        setMinute(0);
                      } else if (!val) {
                        setMinute(0);
                      }
                    }}
                    onChange={(e) => setMinute(parseInt(e.target.value))}
                  />
                  <IconButton
                    variant="outline"
                    disabled={
                      minDate &&
                      currentDate &&
                      currentDate.getDate() <= minDate.getDate() &&
                      hour === minHour &&
                      minute === minMinute
                    }
                    icon={<IoIosArrowDown />}
                    borderRadius="0px 0px 0.375rem 0.375rem"
                    onClick={() => {
                      if (minute <= 0) {
                        setMinute(59);
                      } else {
                        setMinute(minute - 1);
                      }
                    }}
                  />
                </Stack>
              </HStack>
            </Box>
            <Stack
              direction="row"
              padding={timePopover ? "0px 0px" : "0px 25px"}
              align="center"
              justifyContent="center"
              mt={5}
              mb={timePopover ? "10px" : 5}
            >
              <Button
                borderColor="#1fb4d2"
                color="#1fb4d2"
                variant="outline"
                size="sm"
                w="100px"
                onClick={() => {
                  onChangePrime(initialValue);
                  setCurrentDate(initialValue);
                  setPopoverOpen(false);
                }}
              >
                Close
              </Button>

              <Button
                backgroundColor="#1fb4d2"
                color="white"
                variant="solid"
                size="sm"
                w="100px"
                _hover="#1fb4d2"
                onClick={() => {
                  const newFormat = new Date(
                    currentDate.getFullYear(),
                    currentDate.getMonth(),
                    currentDate.getDate(),
                    hour,
                    minute,
                    0
                  );
                  initialRender.current = false;

                  onChangePrime(newFormat);
                  setPopoverOpen(false);
                }}
              >
                Set Time
              </Button>
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};
