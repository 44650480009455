import { Text, Box, Flex } from '@chakra-ui/react'
import React from 'react'
import PublicLogo from '../../assets/img/logo1.png'
import style from './style.module.css'

const Landing = ({t}) => {
  return (
    <>
      <div className='dashboard vh-100' style={{ backgroundColor: '#fff' }}>
        <Flex className='mb-5' style={{ maxWidth: '100vw' }}>
          <Box className={style['landing']}>
            <img src={PublicLogo} alt="Q100+ Application" style={{ maxHeight: '16vh' }} />
          </Box>
          <Box className={`my-auto ml-4 ${style['landing']}`}>
            <Text className='font-quicksand' style={{ color: '#1194A8', fontWeight: '600', fontSize: '4rem' }}>platform</Text>
          </Box>
        </Flex>
      </div>
    </>
  )
}

export default Landing
