import { Text, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { ChangeFormatDate } from "utils/Helpers";

export const useCounterTime = () => {
  const [dateStart, setDateStart] = useState(null);
  const [startCounting, setStartCounting] = useState(false);
  const [time, setTime] = useState({ hours: 0, minutes: 0, seconds: 0 });

  const handleStartCounting = (date = null) => {
    const now = new Date();
    if (date) {
      setDateStart(date);
      setTime({
        hours: date.getHours(),
        minutes: date.getMinutes(),
        seconds: date.getSeconds(),
      });
    } else {
      setDateStart(now);
      setTime({ hours: 0, minutes: 0, seconds: 0 });
    }
    setStartCounting(true);
  };

  const handleStopCounting = () => {
    setStartCounting(false);
  };

  useEffect(() => {
    let interval;

    if (startCounting) {
      interval = setInterval(() => {
        setTime((prevTime) => {
          const newTime = { ...prevTime };
          newTime.seconds += 1;
          if (newTime.seconds === 60) {
            newTime.seconds = 0;
            newTime.minutes += 1;
            if (newTime.minutes === 60) {
              newTime.minutes = 0;
              newTime.hours += 1;
            }
          }
          return newTime;
        });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [startCounting]);

  const formattedTime = `${time.hours
    .toString()
    .padStart(2, "0")}:${time.minutes
    .toString()
    .padStart(2, "0")}:${time.seconds.toString().padStart(2, "0")}`;

  const startDate = ChangeFormatDate(dateStart, true);

  const CounterTesting = ({ hasStart }) => {
    return (
      <>
        <VStack
          justifyContent="start"
          alignItems="start"
          display={hasStart ? "block" : "none"}
        >
          <Text color="#A7A7A7" fontSize="14px" fontWeight="bold">
            Start
          </Text>
          <Text color="#404040" fontSize="14px" fontWeight="bold">
            {startDate}
          </Text>
        </VStack>

        <VStack
          width={100}
          justifyContent="start"
          alignItems="start"
          display={hasStart ? "block" : "none"}
        >
          <Text color="#A7A7A7" fontSize="14px" fontWeight="bold">
            Duration
          </Text>
          <Text color="#404040" fontSize="14px" fontWeight="bold">
            {hasStart ? formattedTime : ""}
          </Text>
        </VStack>
      </>
    );
  };

  return {
    startDate,
    formattedTime,
    handleStartCounting,
    handleStopCounting,
    CounterTesting,
  };
};
