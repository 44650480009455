import React from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalContent,
  Text,
} from "@chakra-ui/react";
import { BiArchiveIn } from "react-icons/bi";
import { BsExclamationTriangle } from "react-icons/bs";
import { AiOutlineSave } from "react-icons/ai";
import { IoIosArrowDropup } from "react-icons/io";
import { IoWarningOutline } from "react-icons/io5";
import { FiSlash } from "react-icons/fi";
import './ConfirmationModal.css'

function ConfirmationModal({
  isOpen,
  onClose,
  isLoading,
  closeOnOverlayClick,
  icon,
  title,
  message,
  subMessage,
  onSubmit,
  onCancel,
  type,
  yesbtntext,
}) {
  const initialRef = React.useRef();
  const finalRef = React.useRef();

  const renderIcon = () => {
    switch (type) {
      case "activate":
        return (
          <IoIosArrowDropup
            size={80}
            style={{ color: "#21C306" }}
            className="mx-auto mb-2"
          />
        );
      case "archive":
        return (
          <BiArchiveIn
            size={80}
            style={{ color: "#FFA800" }}
            className="mx-auto mb-2"
          />
        );
      case "edit":
        return (
          <AiOutlineSave
            size={80}
            style={{ color: "#21C306" }}
            className="mx-auto mb-2"
          />
        );
      case "delete":
        return (
          <BsExclamationTriangle
            size={80}
            style={{ color: "#dd0000" }}
            className="mx-auto mb-2"
          />
        );
      case "cancel":
        return (
          <IoWarningOutline
            size={80}
            style={{ color: "#dd0000" }}
            className="mx-auto mb-2"
          />
        );
      case "discard":
        return (
          <FiSlash
            size={80}
            style={{ color: "#dd0000" }}
            className="mx-auto mb-2"
          />
        );
      case "warning":
        return (
            <BsExclamationTriangle
                size={60}
                style={{ color: "#FFA800" }}
                className="mx-auto mb-3"
            />
        );
      default:
        if (icon) {
          return icon;
        }
        return <></>;
    }
  };

  const renderTitle = () => {
    let titleTemp = title;
    if (type && !titleTemp) {
      switch (type) {
        case "discard":
          titleTemp = "Discard this data?";
          break;
        default:
          break;
      }
    }
    return (
      <Text mb={1} fontSize={18} fontWeight="600">
        {titleTemp}
      </Text>
    );
  };

  const renderMessage = () => {
    let messageTemp = message;
    if (type && !message) {
      switch (type) {
        default:
          break;
      }
    }

    return (
      <Text mb={messageTemp ? 1 : 0} fontSize={18}>
        {messageTemp}
      </Text>
    );
  };

  const renderSubMessage = () => {
    let subMessageTemp = subMessage;
    if (type && !subMessageTemp) {
      switch (type) {
        default:
          break;
      }
    }
    return (
      <Text mb={3} color="#9b9b9b" fontSize={"sm"}>
        {subMessageTemp}
      </Text>
    );
  };

  const renderConfirmationBtnClassName = () =>{
    if(type == 'activate'){
      return 'green-confirmation-btn'
    }

    if(type == 'warning'){
      return 'orange-confirmation-btn'
    }
  }

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={closeOnOverlayClick}
      autoFocus={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody py={5} className="text-center">
          <container>{renderIcon()}</container>

          <container>
            <container>{renderTitle()}</container>

            <container>{renderMessage()}</container>

            <container>{renderSubMessage()}</container>
          </container>

          <div className={"mt-4"}>
            <Button
              size="md"
              mr={3}
              width={100}
              ref={initialRef}
              disabled={isLoading}
              variant='outline'
              onClick={() => {
                if (onCancel) {
                  return onCancel();
                }
                onClose();
              }}
            >
              No
            </Button>
            <Button
              spinnerPlacement="start"
              size="md"
              width={100}
              className={renderConfirmationBtnClassName()}
              disabled={isLoading}
              isLoading={isLoading}
              onClick={() => {
                if (onSubmit) {
                  return onSubmit();
                }
                onClose();
              }}
            >
              {yesbtntext ? yesbtntext : 'Yes'}
            </Button>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default ConfirmationModal;
