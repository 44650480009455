import { Field } from "formik";
import {
  InputGroup,
  InputRightAddon,
  InputLeftAddon,
  FormLabel,
} from "@chakra-ui/react";
import withTrans from "../../config/i18n/withTrans";
import { InputControl } from "formik-chakra-ui";

const InputText = ({
  t,
  title,
  required,
  type,
  placeholder,
  name,
  id,
  leftAddon,
  rightAddon,
  leftIcon,
  rightIcon,
  toggle,
  disabled,
  width,
  value,
  onChange,
  index,
  BackgroundColor,
  isReadOnly,
  classNameRightAddon,
  styleRightAddon,
  ...rest
}) => {
  return (
    <div style={{ width: width ?? "100%" }}>
      <Field name={name} disabled={disabled}>
        {({ field, form }) => (
          <div style={{ marginTop: title ? 20 : 0 }}>
            {title && (index === 0 || index === undefined) && (
              <FormLabel htmlFor={id} className="form-label">
                {title}{" "}
                {required ? <span style={{ color: "red" }}> *</span> : ""}
              </FormLabel>
            )}
            <InputGroup>
              {leftAddon && (
                <InputLeftAddon
                  children={leftAddon}
                  onClick={toggle ? toggle : ""}
                />
              )}
              {leftIcon && <div onClick={toggle ? toggle : ""}>{leftIcon}</div>}
              <InputControl
                {...field}
                {...rest}
                id={id}
                backgroundColor={BackgroundColor}
                value={value}
                type={type ?? "text"}
                onChange={onChange}
                inputProps={{
                  placeholder:
                    placeholder ?? t("commons.placeholder") + " " + title,
                  disabled: disabled,
                  isReadOnly: isReadOnly,
                }}
              />
              {rightIcon && (
                <div
                  className="border-color-gray"
                  onClick={toggle ? toggle : ""}
                >
                  {rightIcon}
                </div>
              )}
              {rightAddon && (
                <InputRightAddon
                  children={rightAddon}
                  className={classNameRightAddon}
                  style={styleRightAddon}
                  onClick={toggle ? toggle : ""}
                />
              )}
            </InputGroup>
          </div>
        )}
      </Field>
    </div>
  );
};

export default withTrans(InputText);
