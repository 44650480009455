const LoaderTable = (classLoader) => {

  return (
    <div className={`d-inline-flex ` + classLoader}>
      <div className="loader" />
      <p className="mt-1 ml-2 font-14">Fetching Data</p>
    </div>
  )
}

export default LoaderTable