import React, {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  useDisclosure,
  PinInput,
  PinInputField,
  Text,
  MenuItem,
  FormControl,
  FormErrorMessage,
  HStack,
  InputRightElement,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import { MdOutlineChangeCircle } from "react-icons/md";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import Input from "../../form/Input";
import {
  getNikUser,
  getNIK4asUsername,
  getUser,
  getUserNameLogin,
} from "../../../utils/User";
import { useSelector } from "react-redux";
import { VerifyUser } from "../../../services/actions/Auth";
import { postChangeDigitalSign } from "../../../services/actions/Auth/index";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { history } from "../../../utils/useHistory";

const ModalDigitalSignature = forwardRef((props, ref) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [confirmedPassword, setConfirmedPassword] = useState("");

  const initialRef = useRef();
  const finalRef = useRef();

  const {
    isOpen: isOpenChangeSign,
    onOpen: onOpenChangeSign,
    onClose: onCloseChangeSign,
  } = useDisclosure();
  const initialRefSign = useRef();
  const finalRefSign = useRef();

  const [show, setShow] = useState(false);
  const TogglePassword = () => setShow(!show);

  const [showCloseButton, setShowCloseButton] = useState(true);

  const userNik = useSelector(getNikUser);
  const userName = useSelector(getNIK4asUsername);
  const userData = useSelector(getUser);
  const userNameLogin = useSelector(getUserNameLogin);

  // Fungsi untuk Validasi Kolom Digital Signature
  function validateDigitalSignature(value) {
    let error;
    if (value.length < 6 || value.length > 6) {
      error = "It must Contain 6 Digit Number";
    }
    if (!value) {
      error = "Required";
    }
    return error;
  }

  // Fungsi untuk Validasi Kolom Digital Signature
  function validateDigitalSignatureConfirm(value) {
    let error;
    if (value.length < 6 || value.length > 6) {
      error = "It must Contain 6 Digit Number";
    }
    if (!value) {
      error = "Required";
    }
    return error;
  }

  // Fungsi untuk Validasi Kolom Building Name
  function validateName(value) {
    let error;
    let value2 = value.replace(/\s{2,}/g, " ").trim();
    if (!value) {
      error = "Required";
    }
    if (value2 === "") {
      error = "Required";
    }
    return error;
  }

  // Fungsi untuk handle submit Verify Credential
  async function handleSubmitVerify(values, actions) {
    let completeValues = {};
    completeValues = {
      userName: values.username,
      password: values.password,
    };
    let response = await VerifyUser(completeValues);

    if (response !== undefined && response.data.statusCode === 200) {
      setConfirmedPassword(values.password);
      values.password = "";
      onClose();
      onOpenChangeSign();
    } else {
      toast.error(response.data.message);
    }
  }

  // Fungsi untuk handle submit Change Digital Signature
  async function handleSubmitNewDigitalSignature(values, actions) {
    let completeValuesDS = {};
    completeValuesDS = {
      pin: values.digitalSignature,
      confirmationPIN: values.digitalSignatureConfirm,
      nik: values.nik,
      password: values.password,
      niK4Digit: userName,
    };

    let response = await postChangeDigitalSign(completeValuesDS);

    if (response !== undefined && response.data.statusCode === 200) {
      values.digitalSignature = "";
      values.digitalSignatureConfirm = "";
      values.password = "";
      onClose();
      props.closeModalReminder();
      if (props.openModal) {
        // showing close button in popup modal & automatic close modal if user success create signature
        setShowCloseButton(true);
      }
      sessionStorage.setItem("checkSignature", true);
      toast.success(response.data.message);
      setTimeout(() => {
        history.push("/");
      }, 1500);
    } else {
      toast.error(response.data.message);
    }
  }

  useEffect(() => {
    // condition if new user login popup create signature showing
    if (props.openModal) {
      onOpen();
      setShowCloseButton(false); // hidden close button if new user login
    }
  }, [props.openModal]);

  useImperativeHandle(ref, () => ({
    openModalChangeDigitalSignature() {
      onOpen();
    },
  }));

  return (
    <>
      <MenuItem
        onClick={() => {
          props.setIsFirst(false);
          onOpen();
          setShowCloseButton(true);
        }}
        icon={
          <MdOutlineChangeCircle
            style={{ width: "1.5rem", height: "1.5rem" }}
            className="mr-0"
          />
        }
      >
        Change Digital Signature
      </MenuItem>

      <Modal
        initialFocusRef={initialRefSign}
        finalFocusRef={finalRefSign}
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={showCloseButton}
        closeOnEsc={false}
      >
        <ModalOverlay />
        <ModalContent>
          {showCloseButton && <ModalCloseButton />}
          <ModalBody py={10} className="text-center">
            <Text style={{ fontSize: "1.1em", fontWeight: "600" }} mb={6}>
              {props.isFirst ? "Set Your" : "Change"} Digital Signature
              <br />
            </Text>
            <Formik
              initialValues={{
                digitalSignature: "",
                digitalSignatureConfirm: "",
                nik: userNik,
                password: "",
              }}
              validateOnChange={false}
              validateOnBlur={true}
              onSubmit={handleSubmitNewDigitalSignature}
            >
              {(props) => (
                <Form>
                  {showCloseButton && <ModalCloseButton />}
                  <ModalBody>
                    <Field
                      name="digitalSignature"
                      validate={validateDigitalSignature}
                    >
                      {({ field, form }) => (
                        <FormControl
                          className="mb-4"
                          isInvalid={
                            form.errors.digitalSignature &&
                            form.touched.digitalSignature
                          }
                        >
                          <Text
                            style={{ color: "#8E8E8E", fontSize: "0.75em" }}
                            mb={6}
                            mx={15}
                          >
                            Enter your new 6-digit PIN
                            <br />
                          </Text>
                          <HStack justifyContent="center">
                            <PinInput
                              {...field}
                              type="number"
                              placeholder=""
                              onChange={(e) => {
                                if (
                                  field.value.length === 6 &&
                                  e.length === 6
                                ) {
                                  form.setFieldValue(field.name, field.value);
                                } else {
                                  form.setFieldValue(field.name, e);
                                }
                              }}
                              isInvalid={
                                form.errors.digitalSignature &&
                                form.touched.digitalSignature
                              }
                              mask
                            >
                              <PinInputField />
                              <PinInputField />
                              <PinInputField />
                              <PinInputField />
                              <PinInputField />
                              <PinInputField />
                            </PinInput>
                          </HStack>
                          <FormErrorMessage style={{ marginLeft: "10%" }}>
                            {form.errors.digitalSignature &&
                            form.touched.digitalSignature
                              ? form.errors.digitalSignature
                              : ""}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                    <Field
                      name="digitalSignatureConfirm"
                      validate={validateDigitalSignatureConfirm}
                    >
                      {({ field, form }) => (
                        <FormControl
                          className="mb-4"
                          isInvalid={
                            form.errors.digitalSignatureConfirm &&
                            form.touched.digitalSignatureConfirm
                          }
                        >
                          <Text
                            style={{ color: "#8E8E8E", fontSize: "0.75em" }}
                            mb={6}
                            mx={15}
                          >
                            Confirm your new 6-digit PIN
                            <br />
                          </Text>
                          <HStack justifyContent="center">
                            <PinInput
                              {...field}
                              type="number"
                              placeholder=""
                              onChange={(e) => {
                                if (
                                  field.value.length === 6 &&
                                  e.length === 6
                                ) {
                                  form.setFieldValue(field.name, field.value);
                                } else {
                                  form.setFieldValue(field.name, e);
                                }
                              }}
                              isInvalid={
                                form.errors.digitalSignatureConfirm &&
                                form.touched.digitalSignatureConfirm
                              }
                              mask
                            >
                              <PinInputField />
                              <PinInputField />
                              <PinInputField />
                              <PinInputField />
                              <PinInputField />
                              <PinInputField />
                            </PinInput>
                          </HStack>
                          <FormErrorMessage style={{ marginLeft: "10%" }}>
                            {form.errors.digitalSignatureConfirm &&
                            form.touched.digitalSignatureConfirm
                              ? form.errors.digitalSignatureConfirm
                              : ""}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>

                    <Text
                      className="text-center"
                      style={{ color: "#8E8E8E", fontSize: "0.75em" }}
                      mb={6}
                      mx={15}
                    >
                      Please enter your password below to change digital
                      signature
                      <br />
                    </Text>
                    <Field name="password" validate={validateName}>
                      {({ field, form }) => (
                        <FormControl
                          className="mb-2"
                          isInvalid={
                            form.errors.password && form.touched.password
                          }
                        >
                          <Input
                            {...field}
                            name="password"
                            id="password"
                            placeholder="Enter password"
                            type={show ? "text" : "password"}
                            className="w-100"
                            required
                          />
                          <InputRightElement
                            top="0.75rem"
                            right="0.25rem"
                            width="3rem"
                          >
                            {show ? (
                              <BsEye
                                onClick={() => TogglePassword()}
                                color="inherit"
                                style={{ zIndex: "10000" }}
                              />
                            ) : (
                              <BsEyeSlash
                                onClick={() => TogglePassword()}
                                color="inherit"
                                style={{ zIndex: "10000" }}
                              />
                            )}
                          </InputRightElement>
                        </FormControl>
                      )}
                    </Field>
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      isLoading={props.isSubmitting}
                      type="submit"
                      loadingText="Save"
                      spinnerPlacement="start"
                      size="md"
                      backgroundColor="#1FB4D2"
                      color="#fff"
                      className="w-100"
                    >
                      Save
                    </Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
});

export default ModalDigitalSignature;
