import React, { useEffect } from 'react'
import {
  Grid,
  GridItem,
  Button,
  Box,
  Image,
  Text, useDisclosure
} from '@chakra-ui/react'
import Input from '../../components/form/Input/InputLogin'
import { Formik } from 'formik'
import { ToastContainer } from 'react-toastify'
import * as Yup from "yup"
import { useState, useRef } from "react"
import { BsEye, BsEyeSlash } from 'react-icons/bs'
import jwt_decode from 'jwt-decode'
import withTrans from '../../config/i18n/withTrans'
import imageLogin from '../../assets/img/login.png'
import imageLogo from '../../assets/img/logo1.png'
import style from './style.module.css'
import { LoginAction } from '../../store/actions/Auth/actions'
import { useDispatch, useSelector } from 'react-redux'
import { useCookies } from 'react-cookie'
import { POST_AUTH_LOGIN_ERROR } from '../../store/actions/Auth/index'
import { APPLICATION_NAME } from '../../utils/ConstantGlobal'
import ConfirmationModal from "../../components/Modal/ConfirmationModal";

const Login = (propsGlobal) => {
  const [cookies, setCookie, removeCookie] = useCookies()
  const dispatch = useDispatch()
  const initialRoute = useSelector((state) => state.auth.initialRoute)
  const isLoading = useSelector((state) => state.auth.isLoading)
  const isLogin = useSelector(state => state.auth.isLogin)
  const userData = useSelector(state => state.auth.userData)
  const userInfo = useSelector(state => state.auth.userInfo)
  const { t } = propsGlobal
  const formikRef = useRef()
  const [userName, setUserName] = useState('')
  const [password, setPassword] = useState('')
  const [show, setShow] = useState(false)
  const TogglePassword = () => setShow(!show)

  const {
    onOpen: onOpenConfirmationModal,
    onClose: onCloseConfirmationModal,
    isOpen: isOpenConfirmationModal,
  } = useDisclosure();

  const initial = {
    userName: userName,
    password: password,
    applicationCode:process.env.REACT_APP_CODE
  }

  const validation =
    Yup.object().shape({
      userName: Yup.string().required(t('commons.requiredField')).nullable(),
      password: Yup.string().required(t('commons.requiredField')).nullable(),
    })


  useEffect(() => {
    if(!isLogin) {
      localStorage.removeItem('refreshTime')
      localStorage.removeItem('autoLogoutTime')
    }
  }, [])
    
  useEffect(() => {
    if(!isLoading) {
      if(isLogin) {
        setCookie('access_token', userData.accessToken, { path: '/' })
        setCookie('refresh_token', userData.refreshToken, { path: '/' })

      } else {
        if (cookies.access_token && cookies.refresh_token) {
          removeCookie('access_token')
          removeCookie('refresh_token')
          dispatch(POST_AUTH_LOGIN_ERROR())
        }
      }
    }
    
  }, [isLoading, isLogin, userData])

  return (
    <div>
      <div className={`main-content-wrapper vh-100 mt-0 px-0 py-0 ${style['login']}`}>
        <div style={{ margin: "0 2rem", position: "absolute", top: "50%", transform: "translateY(-50%)" }} className={`${style['form-container']}`} >
          <Formik
            innerRef={formikRef}
            enableReinitialize
            initialValues={initial}
            initialTouched={{
                userName: false,
                password: false
            }}
            validationSchema={validation}
            onSubmit={async(values, actions) => {
              let isUsed;
               isUsed = await dispatch(LoginAction(propsGlobal, values, initialRoute))
              if(isUsed){
                onOpenConfirmationModal()
              }
            }}
          >
            {(props) => (
              <form onSubmit={props.handleSubmit}>
                <Grid
                  h="100%"
                  w="100%"
                  // templateRows="auto 1fr"
                  templateColumns="repeat(12, 1fr)"
                  gap={12}
                >
                  <GridItem colSpan={{ base: 12, md: 6,sm:12 }} className={`text-center my-auto ${style['input-container']}`}>
                    <Image src={imageLogo} alt={APPLICATION_NAME} className="mx-auto mb-2" style={{ width: '110px' }} />
                    <Text className='mb-2' style={{ fontSize: '2.75rem', fontWeight: 500 }}>Hello Again!</Text>
                    <Text className='mb-4' style={{ color: '#656565' }}>Hope you’re having a wonderful day</Text>
                    <Box className='mx-auto px-4 text-left' style={{ width: '80%', minWidth: '400px' }}>
                      <Input
                        className={`${style['chakra-input-login']}`}
                        name='userName'
                        id='userName'
                        placeholder={t('commons.placeholder') + `username`}
                        onChange={(e) => {
                          setUserName(e.target.value)
                        }}
                        required
                      />
                      <Input
                        className={`${style['chakra-input-login']}`}
                        name='password'
                        id='password'
                        placeholder={t('commons.placeholder') + `password`}
                        type={show ? "text" : "password"}
                        classNameField="mt-3"
                        onChange={(e) => {
                          setPassword(e.target.value)
                        }}
                        required
                        toggleIcon={ show ? <BsEye color='inherit' /> : <BsEyeSlash color='inherit' />}
                        toggle={TogglePassword}
                      />
                      <Button
                        isLoading={isOpenConfirmationModal ? false : isLoading}
                        loadingText="Login"
                        spinnerPlacement="start"
                        type="submit"
                        disabled={isOpenConfirmationModal ? false : isLoading}
                        className={`w-100 mt-3 mb-4 ${style['btn-cm-primary']} btn-cm-primary`}
                      >
                        Login
                      </Button>
                    </Box>
                  </GridItem>
                  <GridItem colSpan={{ base: 12, md: 6 }} className={`py-3 ${style['image-container']}`} style={{ maxHeight: '100vh' }}>
                    <Image src={imageLogin} alt='Dan Abramov' className="h-100" />
                  </GridItem>
                </Grid>

                {isOpenConfirmationModal && <ConfirmationModal
                    isOpen={isOpenConfirmationModal}
                    onClose={onCloseConfirmationModal}
                    type={'warning'}
                    title={'This account is being used on another device, will it continue to sign in?'}
                    isLoading={isLoading}
                    onSubmit={async () => {
                      let newValues = props.values
                      newValues.isForce = true
                      await dispatch(LoginAction(propsGlobal, newValues, initialRoute))
                      onCloseConfirmationModal()
                    }}
                />}
              </form>
            )}
          </Formik>
        </div>
      </div>
      <ToastContainer
        enableMultiContainer
        containerId='login'
        theme="colored"
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />


    </div>
  )
}

export default withTrans(Login)
