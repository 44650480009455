import { isEmpty } from "lodash";
import { usePagination, useTable } from "react-table";
import styled from "styled-components";
import LoaderTable from "../../components/Sections/loader/LoaderTable";
import "./TableDynamic.css";

const TableRow = styled.tr`
  ${({ clickable }) =>
    clickable &&
    `&:hover {
      cursor: pointer;
      background-color: #1fb4d217;
    }`}
`;

const StyledTable = styled.table`
  width: 100%;
  position: relative;
  height: 100%;
  border-collapse: separate;
  border-spacing: 0px;

  th {
    background: #fff;
    text-align: left;
    padding: 15px 0px 15px 1em;
    user-select: none;
    border-bottom: 1px solid #c4c4c4;

    &:first-child {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
    &:last-child {
      padding-right: 1em;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }

  td {
    border-bottom: 1px solid rgba(196, 196, 196, 0.43);
    padding: 15px 9px;
    &:first-child {
      padding-left: 1em;
    }
    &:last-child {
      padding-right: 1em;
    }
  }
`;

// Fungsi untuk Menampilkan Table
const TableDynamic = ({
  id,
  columns,
  data,
  isPaging,
  isLoading,
  tableHeight,
  totalPage,
  currentPage,
  handeClickRow,
  handleChangePage,
  tableMinHeight,
  tableMaxHeight,
  limit,
  searchValue = "",
}) => {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    useTable(
      {
        columns,
        data,
        initialState: {
          pageIndex: currentPage ? currentPage : 1,
        },
        manualPagination: true,
        pageCount: totalPage,
      },
      usePagination
    );

  const renderDataList = () => {
    return page.map((row) => {
      prepareRow(row);

      return (
        <TableRow
          {...(handeClickRow && {
            onClick: () => handeClickRow(row),
            clickable: true,
          })}
          {...row.getRowProps()}
        >
          {row.cells.map((cell) => (
            <td
              {...cell.getCellProps()}
              style={{ paddingLeft: "0", paddingRight: "1em" }}
            >
              {cell?.render("Cell")}
            </td>
          ))}
        </TableRow>
      );
    });
  };

  // Render the UI for your table
  return (
    <div style={{ height: tableHeight ? tableHeight : "85%" }}>
      <div
        className="table-dynamic-container"
        style={{
          maxHeight: tableMaxHeight ? tableMaxHeight : "calc(100vh - 325px)",
          minHeight: tableMinHeight,
        }}
      >
        <StyledTable id={id} {...getTableProps()}>
          <thead style={{ borderTop: "1px solid #c4c4c4" }}>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps({
                      style: { minWidth: column.minWidth, width: column.width },
                    })}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {!isLoading && renderDataList()}
            {page?.length < 1 && !isLoading && (
              <tr>
                <td
                  colSpan={
                    headerGroups ? headerGroups[0]?.headers?.length + 1 : 3
                  }
                  style={{ textAlign: "center" }}
                >
                  {data.length === 0 && isEmpty(searchValue) ? (
                    <p>There are no records to display</p>
                  ) : data.length === 0 && !isEmpty(searchValue) ? (
                    <p>Data not found</p>
                  ) : (
                    <p>Data not found</p>
                  )}
                </td>
              </tr>
            )}

            {isLoading && (
              <tr>
                <td
                  colSpan={
                    headerGroups ? headerGroups[0]?.headers?.length + 1 : 3
                  }
                  style={{ textAlign: "center" }}
                >
                  <LoaderTable />
                </td>
              </tr>
            )}
          </tbody>
        </StyledTable>
      </div>
      {isPaging && (
        <div style={{ textAlign: "right", marginTop: "1em" }}>
          <div className="table-pagination">
            <button
              id="button-previous-page"
              onClick={() => handleChangePage(currentPage - 1)}
              disabled={currentPage === 1}
              style={{ borderRight: "1px solid #cccc" }}
              className={currentPage === 1 ? "page-disabled" : ""}
            >
              {"< "}
            </button>
            <span className="paging-number">{currentPage}</span>
            <button
              id="button-next-page"
              onClick={() => handleChangePage(currentPage + 1)}
              disabled={currentPage >= totalPage}
              style={{ borderLeft: "1px solid #cccc" }}
              className={currentPage >= totalPage ? "page-disabled" : ""}
            >
              {" >"}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TableDynamic;
