import { combineReducers } from "redux";

import menuReducer from "./Menu/reducers";
import actionsReducer from "./Actions/reducers";
import roleReducer from "./Roles/reducers"

const rootReducer = combineReducers({
  menu: menuReducer,
  actions: actionsReducer,
  roles: roleReducer
});

export default rootReducer;
