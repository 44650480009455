import qasamAPIInstance from "services/actions/QAS/APIQASAM";
import { store } from "store";
import {
  SET_ALL_ENUMS,
  SET_AUDIT_CATEGORY,
  SET_AUDIT_OBJECT_STATUS,
  SET_FINDING_AUDIT_OBJECT_STATUS,
  SET_AUDIT_TYPE,
  SET_CREATE_SCHEDULE_REQ,
  SET_INSPECTION_GUIDELINE_OPTIONS,
  SET_INSPECTION_SCOPE_OPTIONS,
  SET_PERMISSION,
} from ".";

export const getAuditScheduleCategoryEnum = async () => {
  try {
    const response = await qasamAPIInstance.get(
      "/qas-audit-management/v1/Enums/GetAuditScheduleCategoryEnum"
    );
    store.dispatch(SET_AUDIT_CATEGORY(response.data.data));
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getAuditScheduleTypeEnum = async () => {
  try {
    const response = await qasamAPIInstance.get(
      "/qas-audit-management/v1/Enums/GetAuditScheduleTypeEnum"
    );
    store.dispatch(SET_AUDIT_TYPE(response.data.data));
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getAuditScheduleObjectStatusEnum = async () => {
  try {
    const response = await qasamAPIInstance.get(
      "/qas-audit-management/v1/Enums/GetAuditScheduleObjectStatusEnum"
    );
    store.dispatch(SET_AUDIT_OBJECT_STATUS(response.data.data));
    return response.data;
  } catch (error) {
    return error;
  }
};

export const setCreateScheduleReqFieldValue = (reqData) => {
  store.dispatch(SET_CREATE_SCHEDULE_REQ(reqData));
  return reqData;
};

export const getAuditorRoleEnum = async () => {
  try {
    const response = await qasamAPIInstance.get(
      "/qas-audit-management/v1/Enums/GetAuditorRoleEnum"
    );
    return response.data.data;
  } catch (error) {
    return error;
  }
};

export const getAuditorQualificationEnum = async () => {
  try {
    const response = await qasamAPIInstance.get(
      "/qas-audit-management/v1/Enums/GetAuditorQualificationEnum"
    );
    return response.data.data;
  } catch (error) {
    return error;
  }
};

export const getAuditScheduleNotificationeminderEnum = async () => {
  try {
    const response = await qasamAPIInstance.get(
      "/qas-audit-management/v1/Enums/GetAuditScheduleNotificationReminderEnum"
    );
    return response.data.data;
  } catch (error) {
    return error;
  }
};

export const getAuditScheduleMethodEnum = async () => {
  try {
    const response = await qasamAPIInstance.get(
      "/qas-audit-management/v1/Enums/GetAuditScheduleMethodEnum"
    );
    return response.data.data;
  } catch (error) {
    return error;
  }
};

export const getOrganizationOptions = async () => {
  try {
    const response = await qasamAPIInstance.get(
      "/qas-audit-management/v1/AuditSchedule/GetOrganizationOptions"
    );
    return response.data.data;
  } catch (error) {
    return error;
  }
};

export const getAuditorExternalOptions = async () => {
  try {
    const response = await qasamAPIInstance.get(
      "/qas-audit-management/v1/AuditSchedule/GetAuditorExternalOptions"
    );
    return response.data.data;
  } catch (error) {
    return error;
  }
};

export const getItemOptions = async () => {
  try {
    const response = await qasamAPIInstance.get(
      "/qas-audit-management/v1/AuditSchedule/GetItemOptions"
    );
    return response.data.data;
  } catch (error) {
    return error;
  }
};

export const getFindingAuditAuditorPresenceEnum = async () => {
  try {
    const response = await qasamAPIInstance.get(
      "/qas-audit-management/v1/Enums/GetFindingAuditAuditorPresenceEnum"
    );
    return response.data.data;
  } catch (error) {
    return error;
  }
};

export const getFindingAuditFindingClassificationEnum = async () => {
  try {
    const response = await qasamAPIInstance.get(
      "/qas-audit-management/v1/Enums/GetFindingAuditFindingClassificationEnum"
    );
    return response.data.data;
  } catch (error) {
    return error;
  }
};

export const getFindingAuditFindingMandatoryChecklistEnum = async () => {
  try {
    const response = await qasamAPIInstance.get(
      "/qas-audit-management/v1/Enums/GetFindingAuditFindingMandatoryChecklistEnum"
    );
    return response.data.data;
  } catch (error) {
    return error;
  }
};

export const getFindingAuditItemInspectionResultEnum = async () => {
  try {
    const response = await qasamAPIInstance.get(
      "/qas-audit-management/v1/Enums/GetFindingAuditItemInspectionResultEnum"
    );
    return response.data.data;
  } catch (error) {
    return error;
  }
};

export const getAllEnums = async () => {
  const [
    auditorRoleEnum,
    auditorQualificationEnum,
    auditScheduleNotificationReminderEnum,
    auditScheduleMethodEnum,
    organizationOptions,
    auditorExternalOptions,
    itemOptions,
    findingAuditAuditorPresenceEnum,
    findingAuditFindingClassificationEnum,
    findingAuditItemInspectionResultEnum,
    findingAuditMandatoryChecklistEnum,
  ] = await Promise.all([
    getAuditorRoleEnum(),
    getAuditorQualificationEnum(),
    getAuditScheduleNotificationeminderEnum(),
    getAuditScheduleMethodEnum(),
    getOrganizationOptions(),
    getAuditorExternalOptions(),
    getItemOptions(),
    getFindingAuditAuditorPresenceEnum(),
    getFindingAuditFindingClassificationEnum(),
    getFindingAuditItemInspectionResultEnum(),
    getFindingAuditFindingMandatoryChecklistEnum(),
  ]);

  store.dispatch(
    SET_ALL_ENUMS({
      auditorRoleEnum,
      auditorQualificationEnum,
      auditScheduleNotificationReminderEnum,
      auditScheduleMethodEnum,
      organizationOptions,
      auditorExternalOptions,
      itemOptions,
      findingAuditAuditorPresenceEnum,
      findingAuditFindingClassificationEnum,
      findingAuditItemInspectionResultEnum,
      findingAuditMandatoryChecklistEnum,
    })
  );
};

export const getAuditMethodEnum = async () => {
  try {
    const response = await qasamAPIInstance.get(
      "/qas-audit-management/v1/Enums/GetAuditScheduleMethodEnum"
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const postCreateAuditScheduleReq = async (payload) => {
  try {
    const response = await qasamAPIInstance.post(
      "/qas-audit-management/v1/AuditSchedule/Create",
      payload
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const postUpdateAuditScheduleReq = async (payload) => {
  try {
    const response = await qasamAPIInstance.post(
      "/qas-audit-management/v1/AuditSchedule/Edit",
      payload
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const postCorrectionAuditScheduleReq = async (payload) => {
  try {
    const response = await qasamAPIInstance.post(
      "/qas-audit-management/v1/AuditSchedule/Correct",
      payload
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const getKitAuditOptions = async (params) => {
  try {
    const response = await qasamAPIInstance.get(
      "/qas-audit-management/v1/AuditSchedule/GetKitAuditOptions",
      { params }
    );
    store.dispatch(SET_INSPECTION_SCOPE_OPTIONS(response.data.data));
  } catch (error) {
    return error;
  }
};
export const getKitAuditGuidelineOptions = async (params) => {
  try {
    const response = await qasamAPIInstance.get(
      "/qas-audit-management/v1/AuditSchedule/GetKitAuditGuidelineOptions",
      { params }
    );
    store.dispatch(SET_INSPECTION_GUIDELINE_OPTIONS(response.data.data));
  } catch (error) {
    return error;
  }
};

export const createExternalKitAudit = async (payload) => {
  try {
    const response = await qasamAPIInstance.post(
      "/qas-audit-management/v1/AuditSchedule/CreateExternalKitAudit",
      payload
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const createExternalKitAuditGuideline = async (payload) => {
  try {
    const response = await qasamAPIInstance.post(
      "/qas-audit-management/v1/AuditSchedule/CreateExternalKitAuditGuideline",
      payload
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const getFindingAuditObjectStatusEnum = async () => {
  try {
    const response = await qasamAPIInstance.get(
      "/qas-audit-management/v1/Enums/GetFindingAuditObjectStatusEnum"
    );
    store.dispatch(SET_FINDING_AUDIT_OBJECT_STATUS(response.data.data));
    return response.data;
  } catch (error) {
    return error;
  }
};

export const storePermission = (permission) => {
  store.dispatch(SET_PERMISSION(permission));
};
