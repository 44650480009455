import { createSlice } from "@reduxjs/toolkit";
import jwt_decode from "jwt-decode";

export const AuthReducer = createSlice({
  name: "auth",
  initialState: {
    isLoading: false,
    isLogin: false,
    initialRoute: "",
    userData: {
      accessToken: "",
      endpoint: null,
      menu: null,
      refreshToken: "",
    },
    userInfo: {
      PositionId: "",
      Name: "",
      Email: "",
      OrganizationId: "",
      NIK: "",
      Roles: [],
      nbf: null,
      exp: null,
      iat: null,
    },
    userName: "",
  },
  reducers: {
    POST_AUTH_REQUEST: (state, data) => {
      state.isLoading = data;
    },
    POST_AUTH_LOGIN_SUCCESS: (state, data) => {
      state.isLoading = false;
      state.isLogin = true;
      state.userData = data.payload;
      state.userInfo = jwt_decode(data.payload.accessToken);
      state.userName = data.payload.userName;
    },
    POST_AUTH_LOGIN_ERROR: (state) => {
      state.isLogin = false;
      state.isLoading = false;
    },
    POST_AUTH_LOGOUT_SUCCESS: (state) => {
      state.isLoading = false;
      state.isLogin = false;
      state.userData = {
        accessToken: "",
        endpoint: null,
        menu: null,
        refreshToken: "",
      };
      state.userInfo = {
        PositionId: "",
        Name: "",
        Email: "",
        OrganizationId: "",
        NIK: "",
        Roles: [],
        nbf: 0,
        exp: 0,
        iat: 0,
      };
      state.userName = "";
    },
    POST_AUTH_LOGOUT_ERROR: (state) => {
      state.isLogin = true;
      state.isLoading = false;
    },
    SET_INITIAL_URL: (state, data) => {
      state.initialRoute = data.payload;
    },
    SET_EXP_TOKEN: (state, data) => {
      state.userInfo = {
        ...state.userInfo,
        nbf: data.payload.nbf,
        exp: data.payload.exp,
        iat: data.payload.iat,
      };
    },
    REFRESH_TOKEN: (state, data) => {
      state.userData = {
        ...state.userData,
        accessToken: data.payload.accessToken,
        refreshToken: data.payload.refreshToken,
      };
    },
  },
});

export const {
  POST_AUTH_REQUEST,
  POST_AUTH_LOGIN_SUCCESS,
  POST_AUTH_LOGIN_ERROR,
  POST_AUTH_LOGOUT_SUCCESS,
  POST_AUTH_LOGOUT_ERROR,
  GET_MENU_SUCCESS,
  REFRESH_TOKEN,
  SET_INITIAL_URL,
  SET_EXP_TOKEN,
} = AuthReducer.actions;
