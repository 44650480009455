import React from "react";
import { Field, ErrorMessage } from "formik";
import {
  InputGroup,
  InputRightAddon,
  InputLeftAddon,
  FormLabel,
  Button,
  PopoverContent,
  Popover,
  HStack,
  PopoverAnchor,
  InputLeftElement,
  InputRightElement,
  Input,
  PopoverTrigger,
  PopoverBody,
  useBoolean,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import withTrans from "../../../config/i18n/withTrans";
import Select, { components } from "react-select";
import style from "../threeSelect.css";
import { toast } from "react-toastify";

const Option = (props) => {
  return (
    <>
      <components.Option {...props}>
        {props.data.value === "button-create" ? (
          <div
            style={{
              cursor: "pointer",
            }}
          >
            <div
              style={{
                color: "#1FB4D2",
                fontStyle: "italic",
              }}
            >
              {props.children}
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div>{props.children}</div>
            {!props.data.newData && <div style={{ color: "#1FB4D2" }}>NEW</div>}
          </div>
        )}
      </components.Option>
    </>
  );
};

const SelectField3 = ({
  t,
  title,
  required,
  type,
  name,
  id,
  leftAddon,
  rightAddon,
  leftIcon,
  rightIcon,
  toggle,
  disabled,
  width,
  data = [],
  index,
  hidden,
  bg,
  SelectedColor,
  ListDisabledOption,
  children,
  variant,
  className,
  parentMarginTop,
  leftAddOnBorderRadius,
  selectBorderRadius,
  isError,
  value,
  position,
  errorMessageAllowed = true,
  isFingerDab,
  createble = false,
  options = [],
  isThreeData,
  formikRef,
  ...props
}) => {
  const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      width: isFingerDab ? "200%" : "100%",
    }),
    option: (base, state) => ({
      ...base,
      color: state.isDisabled ? "#98a7b7" : "#000",
      zIndex: 1000,
      backgroundColor: state.isDisabled
        ? undefined
        : state.isSelected
        ? "#1FB4D2"
        : state.isFocused
        ? "rgba(70, 162, 225, 0.16)"
        : "#fff",
      ":active": {
        ...base[":active"],
        backgroundColor: !state.isDisabled
          ? state.isDisabled
            ? "#1FB4D2"
            : "rgba(70, 162, 225, 0.16)"
          : undefined,
      },
    }),
    control: (base, state) => ({
      ...base,
      background: disabled ? "#F8F8F8" : "#fff",
      borderColor: "#CBD5E0",
      borderRadius: selectBorderRadius
        ? selectBorderRadius
        : "0.375rem 0.375rem 0.375rem 0.375rem",
      width: width ?? "100%",
      position: position && "absolute",
      paddingBottom: isFingerDab ? "3px" : "",
      paddingLeft: isFingerDab ? "5px" : "",
    }),
    placeholder: (base, state) => ({
      ...base,
      color: state.isSelected ? "#000" : "#98a7b7",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    }),
    singleValue: (base, state) => ({
      ...base,
      color: "#000",
    }),
  };
  const [isEditing, setIsEditing] = useBoolean();
  const [label, setLabel] = React.useState(null);

  const setDataLabel = async (item) => {
    await formikRef.current.setFieldValue(name, item);
    setLabel(item.labelChild);
  };
  const renderListOfTree = (data) => {
    if (data?.length > 0) {
      return (
        <ul>
          {data.map((item, index) => {
            return (
              <div key={index} style={{ marginBottom: "1em" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {/* <span
                    onClick={() => {}}
                    style={{
                      cursor: "pointer",
                      fontWeight: "600",
                      fontSize: "0.75em",
                    }}
                    className={`${renderColor(
                      item,
                      item.value
                    )}`}
                  >
                    {item.label}
                  </span> */}
                  <Button
                    colorScheme="blue"
                    variant="ghost"
                    onClick={() => setDataLabel(item)}
                  >
                    {item.name}
                  </Button>
                </div>
                {item.child && (
                  <div style={{ marginLeft: "1em" }}>
                    {renderListOfTree(item.child, true)}
                  </div>
                )}
              </div>
            );
          })}
        </ul>
      );
    }
    return <></>;
  };
  const optionsCreateable = [
    {
      value: "button-create",
      label: "Add New Sediaan",
    },
    ...options,
  ];

  return (
    <div
      style={{
        marginTop: title && !parentMarginTop ? 20 : parentMarginTop,
        width: width ?? "100%",
      }}
      hidden={hidden}
    >
      <Field name={name}>
        {({ field, form }) => (
          <>
            {title && (
              <FormLabel htmlFor={id} className="form-label">
                {title}{" "}
                {required ? <span style={{ color: "red" }}> *</span> : ""}
              </FormLabel>
            )}
            <InputGroup isDisabled={disabled}>
              {leftAddon && (
                <InputLeftAddon
                  backgroundColor={bg}
                  children={leftAddon}
                  onClick={toggle ? toggle : ""}
                  borderRadius={leftAddOnBorderRadius}
                  style={{ paddingRight: isFingerDab ? "19px" : "" }}
                />
              )}

              {leftIcon && <div onClick={toggle ? toggle : ""}>{leftIcon}</div>}

              {isThreeData ? (
                <Popover
                  {...props}
                  isOpen={disabled ? null : isEditing}
                  onOpen={setIsEditing.on}
                  onClose={setIsEditing.off}
                  closeOnBlur={false}
                  isDisabled={disabled}
                  isLazy
                  lazyBehavior="keepMounted"
                >
                  <HStack>
                    <PopoverTrigger isDisabled={disabled}>
                      <InputGroup>
                        <Input
                          {...props}
                          isDisabled={disabled}
                          styles={customStyles}
                          defaultValue={label}
                          display="block"
                          w="auto"
                          variant={disabled ? "filled" : "outline"}
                          value={label ? label : value?.labelChild}
                          id={id}
                          name={name}
                          className={
                            form.errors[name] && form.touched[name]
                              ? `w100 ${className} is-invalid`
                              : `w100 ${className}`
                          }
                        />
                        <InputRightElement isDisabled={disabled}>
                          <ChevronDownIcon color="green.500" />
                        </InputRightElement>
                      </InputGroup>
                    </PopoverTrigger>
                  </HStack>

                  <PopoverContent>
                    <PopoverBody>{renderListOfTree(options)}</PopoverBody>
                  </PopoverContent>
                </Popover>
              ) : (
                <Select
                  {...props}
                  filterOption={(option, searchText) => {
                    if (
                      option?.data.label &&
                      option.data?.label
                        .toLowerCase()
                        .includes(searchText.toLowerCase())
                    ) {
                      return true;
                    } else {
                      return false;
                    }
                  }}
                  id={id}
                  name={name}
                  className={
                    form.errors[name] && form.touched[name]
                      ? `w100 ${className} is-invalid`
                      : `w100 ${className}`
                  }
                  styles={customStyles}
                  isDisabled={disabled}
                  value={value}
                  menuPortalTarget={document.body}
                  options={createble ? optionsCreateable : options}
                  components={createble ? { Option } : {}}
                />
              )}

              {rightIcon && (
                <div onClick={toggle ? toggle : ""}>{rightIcon}</div>
              )}
              {rightAddon && (
                <InputRightAddon
                  children={rightAddon}
                  onClick={toggle ? toggle : ""}
                />
              )}
            </InputGroup>
            {errorMessageAllowed && (
              <ErrorMessage
                name={name}
                component="div"
                className="invalid-feedback"
              />
            )}
          </>
        )}
      </Field>
    </div>
  );
};

export default withTrans(SelectField3);
