import React, { Component } from "react";
import Select from "react-select";
import { FormErrorMessage } from "@chakra-ui/react";

const colourStyles = {
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  control: (styles, { isDisabled }) => ({
    ...styles,
    borderColor: "none",
    borderRadius: "0.375rem",
    backgroundColor: isDisabled ? "#F8F8F8" : "inherit",
    color: "#000",
  }),
  menu: (provided) => ({ ...provided, zIndex: 9999 }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? "#1FB4D2"
        : isFocused
        ? "rgba(70, 162, 225, 0.16)"
        : "#fff",

      ":active": {
        ...styles[":active"],
        backgroundColor: !isDisabled
          ? isSelected
            ? "#1FB4D2"
            : "rgba(70, 162, 225, 0.16)"
          : undefined,
      },
    };
  },
  ValueContainer: (styles) => ({
    ...styles,
    padding: "3px 8px",
  }),
  singleValue: (styles, { isDisabled }) => ({
    ...styles,
    color: isDisabled ? "#1A202C !important" : "inherit",
  }),
  placeholder: (styles) => ({
    ...styles,
    color: "#A0AEC0",
  }),
};

class SelectSingleSearchField extends Component {
  handleChange = (value, action) => {
    const { onChange, name } = this.props;
    onChange(name, value, action);
  };

  handleBlur = () => {
    const { onBlur, name } = this.props;
    if (onBlur) {
      onBlur(name, true);
    }
  };

  render() {
    const {
      id,
      name,
      label,
      ref,
      placeholder,
      options,
      value,
      isMulti,
      isDisabled,
      onSelect,
      touched,
      error,
      isClearable,
      backspaceRemovesValue,
      className,
      isLoading,
      readonly,
      instanceId,
      ...rest
    } = this.props;

    return (
      <div className="input-field-wrapper w-100">
        {label && (
          <h3 className="input-label" htmlFor={name} error={error}>
            {label}
          </h3>
        )}

        <Select
          {...rest}
          instanceId={instanceId}
          inputId={id}
          placeholder={placeholder}
          ref={ref}
          options={options}
          value={value}
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          onSelect={onSelect}
          touched={touched}
          error={error}
          isMulti={isMulti}
          isDisabled={isDisabled}
          isClearable={isClearable}
          backspaceRemovesValue={backspaceRemovesValue}
          styles={colourStyles}
          className={className}
          isLoading={isLoading}
          readony={readonly}
        />

        {error && touched ? <FormErrorMessage>{error}</FormErrorMessage> : null}
      </div>
    );
  }
}

export { SelectSingleSearchField };
