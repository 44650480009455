import React from "react";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import { BiHomeAlt, BiChevronRight } from "react-icons/bi";
import { useCheckRole } from "./hooks";

export default function BreadcrumbTestingRM({
  listPage = true,
  text = "Create Test",
}) {
  const { isKadepBagRM } = useCheckRole();
  const prefixPathRole = `/qcs/qc/${
    isKadepBagRM ? "review" : "testing"
  }/raw-material`;

  return (
    <div className="breadcrumb-container">
      <Breadcrumb spacing="8px" separator={<BiChevronRight color="gray.500" />}>
        <BreadcrumbItem>
          &nbsp;{" "}
          <BreadcrumbLink as={Link} to="/">
            <BiHomeAlt color="gray.500" />
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to={prefixPathRole}>
            Quality Control
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to={prefixPathRole}>
            {isKadepBagRM ? "Review" : "Testing"}
          </BreadcrumbLink>
        </BreadcrumbItem>

        {listPage && (
          <BreadcrumbItem isCurrentPage={listPage}>
            <BreadcrumbLink to={prefixPathRole}>Raw Material</BreadcrumbLink>
          </BreadcrumbItem>
        )}

        {!listPage && (
          <BreadcrumbItem>
            <BreadcrumbLink as={Link} to={prefixPathRole}>
              Raw Material
            </BreadcrumbLink>
          </BreadcrumbItem>
        )}

        {!listPage && (
          <BreadcrumbItem isCurrentPage>
            <BreadcrumbLink to={prefixPathRole}>{text}</BreadcrumbLink>
          </BreadcrumbItem>
        )}
      </Breadcrumb>
    </div>
  );
}
