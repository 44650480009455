import { SET_ROLES_DATA } from "./actionTypes";

const INITIAL_STATE = {
  data: [],
};

function ActionsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_ROLES_DATA:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
}

export default ActionsReducer;
