import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  testingDetailData: {},
  testingGeneralData: {},
  templateGeneralData: {},
};

const testingTemplateSlice = createSlice({
  name: "testingTemplate",
  initialState,
  reducers: {
    setTestingDetailData(state, action) {
      state.testingDetailData = action.payload;
    },
    setTestingGeneralData(state, action) {
      state.testingGeneralData = action.payload;
    },
    setTemplateGeneralData(state, action) {
      state.templateGeneralData = action.payload;
    },
  },
});

export const {
  setTestingDetailData,
  setTestingGeneralData,
  setTemplateGeneralData,
} = testingTemplateSlice.actions;

export default testingTemplateSlice.reducer;
