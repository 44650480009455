import React from 'react'
import { NavLink } from "react-router-dom"
import {
  Grid,
  GridItem,
  Image,
  Text,
  Button
} from '@chakra-ui/react'
import style from './style.module.css'

const NotFound = (props) => {
  return (
    <div className='vh-100'>
      <Grid
        className='h-100 mx-lg-5 mx-0'
        templateColumns="repeat(4, 1fr)"
        gap={{ lg: 100, md: '20px', base: 10}}
      >
        <GridItem colSpan={{ base: 4, lg: 2, md: 4 }} className="m-auto py-5" style={{ width: '75%' }}>
          <Text style={{ fontSize: '4rem', lineHeight: '75px', color: '#404040', fontWeight: 'bold' }}>So, What</Text>
          <Text style={{ fontSize: '4rem', lineHeight: '75px', color: '#404040', fontWeight: 'bold' }} className='mb-5'>We Do Now?</Text>
          <Text style={{ fontSize: '1.375rem', color: '#ABABAB' }}>The page you're looking for isn't found :(</Text>
          <Text style={{ fontSize: '1.375rem', color: '#ABABAB' }} className='mb-5'>We suggest you back home</Text>
          {
            props.urlRedirect ? (
              <NavLink to={props.urlRedirect}>
                <Button className={`${style['btn-errorpage']}`}>Go Back</Button>
              </NavLink>
            ) : null
          }
        </GridItem>
        <GridItem colSpan={{ base: 4, lg: 2, md: 4 }} className="py-3" style={{ maxHeight: '100vh' }}>
          <div style={{ position: 'relative', top: '50%', transform: 'translateY(-50%)' }}>
            <div className='mx-sm-auto mx-lg-0' style={{ width: '85%' }}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 711 756" fill="none">
                <path d="M441.68 475.162C439.876 475.707 438.122 476.322 436.417 477.026C435.501 477.397 434.518 477.571 433.531 477.538C432.543 477.506 431.574 477.266 430.685 476.836C429.795 476.405 429.006 475.793 428.368 475.039C427.731 474.284 427.258 473.405 426.981 472.456L398.811 377.807L77.9702 473.289L154.461 730.326C157.139 739.311 163.275 746.864 171.52 751.325C179.766 755.786 189.445 756.789 198.43 754.115L451.523 678.784C460.507 676.106 468.06 669.97 472.521 661.725C476.982 653.479 477.986 643.8 475.312 634.815L457.232 574.044C456.946 573.098 456.862 572.103 456.984 571.123C457.106 570.143 457.432 569.199 457.941 568.352C458.449 567.505 459.13 566.774 459.938 566.206C460.747 565.638 461.665 565.246 462.634 565.054C464.463 564.713 466.273 564.276 468.056 563.746C494.62 555.816 510.241 529.599 502.966 505.126C495.691 480.654 468.244 467.262 441.68 475.162Z" fill="#1FB4D2"/>
                <path d="M312.239 86.9102L25.2577 172.292C16.2741 174.967 8.72106 181.102 4.2599 189.345C-0.201265 197.589 -1.20518 207.268 1.46898 216.252L77.9696 473.289L188.072 440.58C188.941 440.317 189.856 440.24 190.757 440.351C191.658 440.463 192.526 440.762 193.305 441.228C194.085 441.695 194.758 442.319 195.282 443.061C195.806 443.802 196.169 444.645 196.348 445.536C196.672 447.187 197.082 448.839 197.577 450.491C205.507 477.046 231.724 492.677 256.187 485.392C280.65 478.106 294.09 450.7 286.181 424.116C285.685 422.47 285.13 420.855 284.496 419.289C284.164 418.446 284.012 417.544 284.049 416.639C284.086 415.735 284.31 414.848 284.708 414.035C285.106 413.222 285.669 412.501 286.361 411.917C287.053 411.333 287.859 410.9 288.728 410.645L398.83 377.876L312.239 86.9102Z" fill="#FFA132"/>
                <path d="M312.239 86.9103L336.89 169.735C337.29 171.054 337.402 172.443 337.219 173.809C337.037 175.176 336.564 176.487 335.832 177.655C335.101 178.823 334.128 179.821 332.978 180.582C331.829 181.343 330.53 181.848 329.169 182.065C326.598 182.448 324.058 183.011 321.567 183.75C295.012 191.68 279.381 217.897 286.666 242.37C293.952 266.842 321.358 280.263 347.932 272.363C350.425 271.625 352.86 270.704 355.218 269.608C356.475 269.054 357.835 268.775 359.209 268.788C360.582 268.802 361.937 269.107 363.183 269.685C364.43 270.263 365.539 271.099 366.436 272.138C367.334 273.178 368 274.397 368.391 275.714L398.771 377.807L503.57 346.604C506.574 345.688 509.093 343.621 510.579 340.854C512.064 338.087 512.395 334.845 511.5 331.835L511.411 331.538C503.481 304.974 516.941 277.537 541.404 270.262C565.867 262.987 592.114 278.608 600.014 305.172L600.103 305.459C601.003 308.478 603.063 311.016 605.831 312.517C608.6 314.019 611.851 314.361 614.872 313.468L685.742 292.376C694.726 289.7 702.279 283.566 706.74 275.322C711.201 267.078 712.205 257.4 709.531 248.416L643.121 25.2578C640.445 16.2742 634.311 8.72111 626.067 4.25995C617.824 -0.201216 608.145 -1.20513 599.161 1.46903L312.239 86.9103Z" fill="#46A2E1"/>
                <path d="M116.071 300.96C125.443 300.96 133.041 293.362 133.041 283.99C133.041 274.618 125.443 267.021 116.071 267.021C106.699 267.021 99.1021 274.618 99.1021 283.99C99.1021 293.362 106.699 300.96 116.071 300.96Z" fill="#1C3177"/>
                <path d="M241.943 263.512C251.315 263.512 258.913 255.915 258.913 246.543C258.913 237.171 251.315 229.573 241.943 229.573C232.572 229.573 224.974 237.171 224.974 246.543C224.974 255.915 232.572 263.512 241.943 263.512Z" fill="#1C3177"/>
                <path d="M169.725 304.974C169.725 304.974 170.2 291.761 185.782 287.132C201.364 282.503 208.51 293.446 208.51 293.446" stroke="#1C3177" stroke-width="9" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M221.645 596.217C231.017 596.217 238.614 588.62 238.614 579.248C238.614 569.876 231.017 562.279 221.645 562.279C212.273 562.279 204.675 569.876 204.675 579.248C204.675 588.62 212.273 596.217 221.645 596.217Z" fill="#1C3177"/>
                <path d="M347.517 558.76C356.889 558.76 364.486 551.163 364.486 541.791C364.486 532.419 356.889 524.821 347.517 524.821C338.145 524.821 330.547 532.419 330.547 541.791C330.547 551.163 338.145 558.76 347.517 558.76Z" fill="#1C3177"/>
                <path d="M458.164 182.194C460.83 191.174 454.199 195.506 445.209 198.182C436.219 200.858 428.299 200.858 425.623 191.878C424.934 189.727 424.682 187.46 424.881 185.21C425.08 182.96 425.726 180.772 426.781 178.775C427.836 176.777 429.279 175.011 431.026 173.578C432.772 172.145 434.787 171.075 436.952 170.431C439.117 169.787 441.388 169.581 443.634 169.826C445.879 170.07 448.054 170.761 450.029 171.856C452.004 172.952 453.741 174.43 455.138 176.205C456.535 177.98 457.564 180.016 458.164 182.194Z" fill="#1C3177"/>
                <path d="M584.026 144.737C586.703 153.727 580.062 158.048 571.072 160.725C562.081 163.401 554.162 163.401 551.485 154.421C550.797 152.269 550.545 150.002 550.744 147.752C550.943 145.502 551.589 143.314 552.644 141.317C553.699 139.32 555.142 137.553 556.888 136.12C558.635 134.688 560.649 133.618 562.814 132.974C564.979 132.329 567.251 132.124 569.497 132.368C571.742 132.613 573.916 133.303 575.892 134.399C577.867 135.494 579.604 136.973 581.001 138.748C582.398 140.523 583.426 142.559 584.026 144.737Z" fill="#1C3177"/>
                <path d="M542.713 195.774C542.089 204.328 531.701 203.416 516.645 207.896C501.588 212.376 494.392 219.79 488.187 212C480.258 202.088 493.867 186.427 508.923 181.946C523.98 177.466 543.635 183.166 542.713 195.774Z" fill="#F774B9"/>
                <path d="M321.899 576.366L261.049 594.471C255.267 596.192 251.974 602.274 253.695 608.056C255.415 613.838 261.497 617.13 267.279 615.41L328.129 597.305C333.911 595.584 337.204 589.502 335.483 583.72C333.763 577.938 327.681 574.645 321.899 576.366Z" fill="white"/>
                <path d="M595.444 531.72L612.364 562.348" stroke="#1C3177" stroke-width="12" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M631.395 426.673L663.103 411.874" stroke="#1C3177" stroke-width="12" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M629.422 483.34L670.309 496.78" stroke="#1C3177" stroke-width="12" stroke-miterlimit="10" stroke-linecap="round"/>
                </svg>
            </div>
          </div>
        </GridItem>
      </Grid>
    </div>
  )
}
export default NotFound