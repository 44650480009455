import { lazy } from "react";
import { componentLoader } from "utils/Helpers";

/*
  Note: i cant find way to make promise function with params because the imports path cant be dynamic.
  maybe the problem is we still using webpack 4, because when i try dynamic imports path in NEXT with webpack 5, it works. Or maybe because NEXT JS is Server Side not Client Side like CRA.
*/

// PES Components
export const FormTc = lazy(() =>
  componentLoader(() => import("../../pages/QAS/release/TemporaryChangeDetail"))
);

export const ProductionPES = lazy(() =>
  componentLoader(() => import("../../pages/PES/Production"))
);
export const ProductionExecutionListPES = lazy(() =>
  componentLoader(() => import("../../pages/PES/Production/Execution"))
);
export const ProductionExecutionPES = lazy(() =>
  componentLoader(() =>
    import("../../pages/PES/Production/form/ProductionExecutionForm")
  )
);
export const ProductionReviewPES = lazy(() =>
  componentLoader(() => import("../../pages/PES/Production/Review"))
);
export const ProductionReviewDetailPES = lazy(() =>
  componentLoader(() => import("../../pages/PES/Production/ReviewDetail"))
);
export const DeviationPES = lazy(() =>
  componentLoader(() => import("../../pages/PES/Production/Deviation"))
);
export const DeviationProcessPES = lazy(() =>
  componentLoader(() => import("../../pages/PES/Production/DeviationProcess"))
);
export const DeviationDetailPES = lazy(() =>
  componentLoader(() => import("../../pages/PES/Production/DeviationDetail"))
);
export const TemporaryChangePES = lazy(() =>
  componentLoader(() => import("../../pages/QAS/release/TemporaryChange"))
);
