import React, { useRef } from 'react'
import {
  Button,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalContent,
  useDisclosure,
  Text,
  MenuItem
} from "@chakra-ui/react"
import { FaPowerOff } from 'react-icons/fa'
import { BsExclamationTriangle } from 'react-icons/bs'


function ModalConfirmation({
  onClick,
  isLoading
}) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const initialRef = useRef()
  const finalRef = useRef()

  return (
    <>

      <MenuItem onClick={onOpen} icon={<FaPowerOff style={{ width: '1.25rem', height: '1.25rem' }} className="mr-1" />}>
        Logout
      </MenuItem>

      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={true}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalBody py={10} className='text-center'>
            <BsExclamationTriangle size={80} style={{ color: '#dd0000' }} className='mx-auto mb-4' />
            <Text mb={6}>Are you sure you want to log out?<br /></Text>
            <Button 
              size="md" 
              mr={3} 
              width={100} 
              ref={initialRef} 
              onClick={onClose}
              className="btn-modal-close"
            >
              Cancel
            </Button>
            <Button
              isLoading={isLoading}
              loadingText="Logout"
              spinnerPlacement="start"
              size="md"
              colorScheme="red"
              width={100}
              onClick={() => {
                onClick()
              }}
            >
              Logout
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default ModalConfirmation