import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  IconButton,
  Input,
  Link,
  Stack,
  Text,
  Textarea,
} from "@chakra-ui/react";
import Select from "react-select/";
import { ContentListSkeleton1Col } from "components";
import { SelectSingleSearchField } from "components/form/SelectSingleSearchField";
import { useEffect, useState } from "react";
import { BsPlus } from "react-icons/bs";
import { MdAttachFile, MdDeleteOutline, MdHistory } from "react-icons/md";
import { UploadFindingModal } from "../../components/UploadFindingModal";
import { useSelector } from "react-redux";
import { useScopeItemHook } from "../hook/inspectionScopeHooks";
import { useMemo } from "react";

const initialFindingState = {
  products: [],
  finding: "",
  attachments: [],
  classification: 1,
  prevFindingNumber: "",
  regulations: "",
  mandatoryChecklist: null,
  isDeleted: false,
  originalFindingNumber: "",
};

export const AccordionForm = ({
  t,
  data,
  auditId,
  scopeId,
  auditees,
  initialFindings,
  auditeeId,
}) => {
  const filteredFinding = initialFindings?.filter(
    (finding) =>
      finding.findingAuditInspectionScopeId === scopeId &&
      finding.findingAuditAuditeeId === auditeeId
  );
  const mappedInitialFindings = filteredFinding?.map((f) => ({
    products:
      f.products?.map((product) => ({
        findingAuditAuditeeProductId: product.id,
        isDeleted: false,
      })) || [],
    finding: f.finding || "",
    attachments: f.attachments || [],
    classification: f.classification || 1,
    prevFindingNumber: f.prevFindingNumber || "",
    regulations: f.regulations || "",
    mandatoryChecklist: f.mandatoryChecklist || null,
    originalFindingNumber: f.originalFindingNumber || "",
    findingNumber: f.findingNumber || "",
    isDeleted: false,
  }));

  const [deletedFindings, setDeletedFindings] = useState([]);

  const initialFindingData = useMemo(() => {
    return mappedInitialFindings.length > 0
      ? mappedInitialFindings
      : [initialFindingState];
  }, [mappedInitialFindings]);

  const [loading, setLoading] = useState(false);
  const [showUploadMoodal, setShowUploadModal] = useState(false);
  const [initialAttachments, setInitialAttachments] = useState([]);
  const [findings, setFindings] = useState(initialFindingData);
  const [idx, setIdx] = useState(0);
  const {
    findingAuditFindingClassificationEnum,
    findingAuditMandatoryChecklistEnum: mandatoryChecklistEnum,
  } = useSelector((state) => state.auditManagement);
  const [formattedFindings, setFormatedFindings] =
    useState(initialFindingState);

  const findingFormik = useScopeItemHook({
    items: [],
    scopeId,
    auditeeId: auditeeId,
  });

  const styles = {
    multiValue: (base, state) => {
      return {
        ...base,
        backgroundColor: "#1FB4D224",
        color: "#1FB4D2 !important",
      };
    },
    multiValueLabel: (base, state) => {
      return {
        ...base,
        color: "#1FB4D2",
      };
    },
    multiValueRemove: (base, state) => {
      return {
        ...base,
        display: "none",
      };
    },
    control: (base, state) => {
      return {
        ...base,
        backgroundColor: "#F8F8F8",
      };
    },
    indicatorsContainer: (base, state) => {
      return {
        ...base,
        display: "none",
      };
    },
  };

  if (loading) {
    return <ContentListSkeleton1Col />;
  }

  const classificationOptions = findingAuditFindingClassificationEnum?.map(
    (item) => ({
      value: item.id,
      label: item.name,
    })
  );

  const handleDeleteFindings = () => {
    const temp = [...findings];
    if (temp[idx].id) {
      temp[idx].isDeleted = true;
      setDeletedFindings([...deletedFindings, temp[idx].id]);
    } else {
      temp.splice(idx, 1);
    }

    setFindings(temp);

    // Find the next item that is not deleted
    let newIdx = idx;
    while (newIdx < temp.length && temp[newIdx].isDeleted) {
      newIdx++;
    }

    // If there is no such item, find the previous one
    if (newIdx >= temp.length) {
      newIdx = idx - 1;
      while (newIdx >= 0 && temp[newIdx].isDeleted) {
        newIdx--;
      }
    }

    // If there is no such item, set the index to -1
    if (newIdx < 0) {
      newIdx = -1;
    }

    setIdx(newIdx);
  };

  const handleNextFinding = () => {
    setIdx(idx + 1);
  };

  const handlePrevFinding = () => {
    setIdx(idx - 1);
  };

  const handleNewFinding = () => {
    const temp = [...findings, initialFindingState];
    setFindings(temp);

    // Set the index to the new finding
    const newIdx = temp.length - 1;

    console.log("idx", temp, newIdx);
    setIdx(newIdx);
  };

  const handleOnChangeFindingName = (e) => {
    const temp = [...findings];
    temp[idx].finding = e.target.value;
    setFindings(temp);
  };

  const handleOnChangeClassification = (value) => {
    const temp = [...findings];
    temp[idx].classification = value;
    setFindings(temp);
  };

  const handleMandatoryChecklistChange = (id) => {
    const newFindings = [...findings];
    newFindings[idx] = {
      ...newFindings[idx],
      mandatoryChecklist: Number(id.target.value),
    };

    setFindings(newFindings);
  };

  const checkDeletedProduct = (
    existingFindingProducts,
    updatedFindingProducts
  ) => {
    const deletedProducts = existingFindingProducts.filter(
      (existingProduct) =>
        !updatedFindingProducts.some(
          (updatedProduct) =>
            updatedProduct.findingAuditAuditeeProductId ===
            existingProduct.findingAuditAuditeeProductId
        )
    );

    return deletedProducts.map((product) => ({
      ...product,
      isDeleted: true,
    }));
  };

  const checkNewProduct = (existingFindingProducts, updatedFindingProducts) => {
    const newProducts = updatedFindingProducts.filter(
      (updatedProduct) =>
        !existingFindingProducts.some(
          (existingProduct) =>
            updatedProduct.findingAuditAuditeeProductId ===
            existingProduct.findingAuditAuditeeProductId
        )
    );

    return newProducts.map((product) => ({
      ...product,
      isDeleted: false,
    }));
  };

  const handleAuditeeProductChange = (products) => {
    const temp = [...findings];
    const mappedSelectedProducts = products.map((product) => ({
      findingAuditAuditeeProductId: product.value,
      isDeleted: false,
    }));

    temp[idx].products = [
      ...checkNewProduct(temp[idx].products, mappedSelectedProducts),
      ...checkDeletedProduct(temp[idx].products, mappedSelectedProducts),
    ];
    setFindings(temp);
  };

  const handleOnchangeOriginalFindingNumber = (e) => {
    const temp = [...findings];
    temp[idx].originalFindingNumber = e.target.value;
    setFindings(temp);
  };

  const handleOnchangeRegulations = (value) => {
    const temp = [...findings];
    temp[idx].regulations = value;
    setFindings(temp);
  };

  const hasPrevFindings = () => {
    return idx > 0 && findings.slice(0, idx).some((val) => !val.isDeleted);
  };

  const hasNextFindings = () => {
    return (
      idx < findings.length - 1 &&
      findings.slice(idx + 1).some((val) => !val.isDeleted)
    );
  };

  return (
    <form
      id={"submit-finding" + auditeeId}
      key={auditeeId}
      onSubmit={(event) => {
        event.preventDefault();
        findingFormik.setFieldValue("findings", findings);
        findingFormik.handleSubmit();
      }}
    >
      <Box border={"1px"} borderRadius={"md"} borderColor={"#C4C4C4"}>
        <Box padding={7}>
          <Box>
            <Flex
              alignItems={"center"}
              alignSelf={"center"}
              justifyContent={"space-between"}
            >
              <Text color={"#8E8E8E"} fontWeight={600}>
                {
                  findings.slice(0, idx + 1).filter((val) => !val.isDeleted)
                    .length
                }{" "}
                of {findings.filter((val) => !val.isDeleted).length}
              </Text>
              <Box>
                <Flex>
                  <IconButton
                    backgroundColor={"rgb(31, 180, 210, 0.14)"}
                    icon={<BsPlus color="rgb(31, 180, 210)" size={"22px"} />}
                    marginRight={2}
                    onClick={() => handleNewFinding()}
                  />
                  {findings.filter((v) => !v.isDeleted).length > 1 && (
                    <IconButton
                      backgroundColor={"rgba(255, 0, 0, 0.15)"}
                      icon={<MdDeleteOutline color="#F00" size={"22px"} />}
                      onClick={() => handleDeleteFindings()}
                    />
                  )}
                </Flex>
              </Box>
            </Flex>
          </Box>
          <Box marginBottom={5}>
            {/* <FormControl> */}
            <FormLabel>PRODUCT</FormLabel>
            {auditees?.map((item) => {
              const matchingProducts = item.products?.filter((product) =>
                findings[idx].products.some(
                  (findingProduct) =>
                    findingProduct.findingAuditAuditeeProductId === product.id
                )
              );
              const value = matchingProducts?.map((el) => ({
                value: el.id,
                label: el.item.itemNameIdn,
              }));

              return (
                <Select
                  key={item.id}
                  value={value}
                  isMulti={true}
                  options={item.products.map((el) => ({
                    value: el.id,
                    label: el.item.itemNameIdn,
                  }))}
                  onChange={(val) => {
                    handleAuditeeProductChange(val);
                  }}
                  styles={styles}
                />
              );
            })}
            {/* </FormControl> */}
          </Box>
          <Box marginBottom={5}>
            <FormControl isRequired={true}>
              <FormLabel fontSize={10}>FINDING</FormLabel>
              <Flex alignItems={"center"} alignSelf={"center"}>
                <Input
                  value={idx !== -1 ? findings[idx]?.finding : ""}
                  id={"upload-input" + idx}
                  onChange={(e) => handleOnChangeFindingName(e)}
                />
                <IconButton
                  backgroundColor={"rgb(31, 180, 210, 0.14)"}
                  icon={
                    <MdAttachFile color="rgb(31, 180, 210)" size={"22px"} />
                  }
                  onClick={() => setShowUploadModal(true)}
                  marginLeft={5}
                />
              </Flex>
            </FormControl>
          </Box>
          <Box>
            <Grid
              gap={"10px"}
              templateColumns={"repeat(5, 1fr)"}
              alignContent={"center"}
            >
              <GridItem colSpan={"0.50"}>
                <Box maxWidth={196}>
                  <FormControl isRequired={true}>
                    <FormLabel fontSize={10}>CLASSIFICATION</FormLabel>
                    <SelectSingleSearchField
                      id={"classification" + auditeeId}
                      options={classificationOptions}
                      value={classificationOptions.find(
                        (option) =>
                          option.value === findings[idx]?.classification
                      )}
                      onChange={(_, selected) => {
                        handleOnChangeClassification(selected.value);
                      }}
                    />
                  </FormControl>
                </Box>
              </GridItem>
              <GridItem colSpan={2}>
                <Box>
                  <FormControl>
                    {/* <FormControl isRequired={true}> */}
                    <FormLabel fontSize={10} requiredIndicator={true}>
                      ORIGINAL FINDING NUMBER
                    </FormLabel>
                    <Input
                      value={
                        idx !== -1 ? findings[idx].originalFindingNumber : ""
                      }
                      onChange={(e) => handleOnchangeOriginalFindingNumber(e)}
                    />
                  </FormControl>
                </Box>
              </GridItem>
              <GridItem colSpan={2}>
                <FormControl isReadOnly>
                  <FormLabel fontSize={10} requiredIndicator={true}>
                    FINDING NUMBER
                  </FormLabel>
                  <Input backgroundColor={"rgba(248, 248, 248, 1)"} />
                </FormControl>
              </GridItem>
            </Grid>
          </Box>
          <Box mt={5}>
            <FormControl isRequired>
              <FormLabel fontSize={10}>REGULATIONS</FormLabel>
              <Textarea
                placeholder={"Type the regulation here..."}
                onChange={(e) => {
                  handleOnchangeRegulations(e.target.value);
                }}
                rows={5}
                borderRadius={"md"}
                value={idx !== -1 ? findings[idx]?.regulations : ""}
              />
            </FormControl>
          </Box>
          <Box marginTop={5}>
            <FormLabel fontWeight={600} marginBottom={2}>
              MANDATORY CHECKLIST
            </FormLabel>

            <CheckboxGroup
              value={idx !== -1 ? [findings[idx].mandatoryChecklist] : []}
            >
              <Stack spacing={[1, 5]} direction={["column", "row"]}>
                {mandatoryChecklistEnum.map((item, index) => {
                  return (
                    <Checkbox
                      key={index}
                      value={item.id}
                      isChecked={
                        findings[idx]?.mandatoryChecklist === Number(item.id)
                      }
                      onChange={handleMandatoryChecklistChange}
                    >
                      {item.name}
                    </Checkbox>
                  );
                })}
              </Stack>
            </CheckboxGroup>
          </Box>
          <Box>
            {showUploadMoodal && (
              <UploadFindingModal
                setShowUploadModal={setShowUploadModal}
                initialAttachments={initialAttachments}
              />
            )}
          </Box>
        </Box>

        <Divider marginTop={2} marginLeft={0} marginRight={0} />
        <Flex p={5} alignItems={"center"} justifyContent={"space-between"}>
          <Box flex={1}>
            {hasPrevFindings() && (
              <Link
                title="Prev"
                color={"#1FB4D2"}
                borderRadius={"lg"}
                minWidth={79}
                fontSize={12}
                onClick={() => handlePrevFinding()}
                fontWeight={600}
              >
                {"\u2190"} Previous
              </Link>
            )}
          </Box>

          <Box flex={1} textAlign="center">
            <Button
              title="Save"
              background={"#21C306"}
              color={"#fff"}
              borderRadius={"lg"}
              minWidth={79}
              fontSize={12}
              type="submit"
              fontWeight={600}
            >
              Save
            </Button>
          </Box>

          <Box flex={1} textAlign="right">
            {hasNextFindings() && (
              <Link
                title="Next"
                color={"#1FB4D2"}
                borderRadius={"lg"}
                minWidth={79}
                fontSize={12}
                onClick={() => handleNextFinding()}
                fontWeight={600}
              >
                Next {"\u2192"}
              </Link>
            )}
          </Box>
        </Flex>
      </Box>
    </form>
  );
};

export const ScopeForm = ({
  t,
  auditees,
  scopeId,
  auditId: findingId,
  findings,
}) => {
  return (
    <Box width={"100%"}>
      <Box>
        <Flex alignItems={"center"} alignSelf={"center"} padding={5}>
          <Text fontSize={14} fontWeight={"bold"}>
            REFER AUDIT:{" "}
          </Text>
          <Button
            title="Refer Audit"
            background={"#1FB4D2"}
            color={"#fff"}
            marginLeft={5}
            fontSize={12}
          >
            Refer Audit
          </Button>
        </Flex>
      </Box>
      <Box>
        {auditees?.map((auditee, index) => (
          <Accordion key={auditee.id} allowToggle>
            <AccordionItem
              paddingLeft={0}
              paddingRight={0}
              borderTop={"0px solid #EFEFEF"}
            >
              <Flex
                alignItems={"center"}
                justifyContent={"center"}
                borderBottom={"1px solid #EFEFEF"}
              >
                <AccordionButton paddingLeft={0} minHeight={"70px"}>
                  <Box paddingLeft={5} fontSize={12}>
                    <AccordionIcon />
                    Auditee:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {auditee.organization.organizationName}
                    </span>
                  </Box>
                </AccordionButton>
                <IconButton
                  justifyItems={"flex-end"}
                  position={"relative"}
                  backgroundColor={"rgb(31, 180, 210, 0.14)"}
                  icon={<MdHistory color="rgb(31, 180, 210)" size={"22px"} />}
                  marginRight={5}
                />
              </Flex>
              <AccordionPanel borderTop={1} borderColor={"black"}>
                <AccordionForm
                  auditees={auditees}
                  scopeId={scopeId}
                  findingId={findingId}
                  auditeeId={auditee.id}
                  initialFindings={findings}
                />
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        ))}
      </Box>
      <Box borderTop={1} p={5}>
        <Flex justifyContent={"center"}>
          <Button
            title="Discard"
            borderColor={"#1FB4D2"}
            color={"#1FB4D2"}
            fontSize={12}
            marginRight={5}
            borderWidth={2}
            variant="outline"
          >
            Discard
          </Button>
          <Button
            title="Generate"
            background={"#1FB4D2"}
            color={"#fff"}
            fontSize={12}
          >
            Generate
          </Button>
        </Flex>
      </Box>
    </Box>
  );
};
