import notificationAPIInstance from "./ApiNotification";

export async function getAllListNotification(params) {
  try {
    const response = await notificationAPIInstance.get(
      `/v1/NotificationBell/ListAll`,
      { params: params }
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function updateNotificationStatusById(payload) {
  try {
    const response = await notificationAPIInstance.post(
      `/v1/NotificationBell/MarkAsRead`,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function updateAllNotificationStatus(payload) {
  try {
    const response = await notificationAPIInstance.post(
      `/v1/NotificationBell/MarkAllAsRead`,
      payload
    );
    return response;
  } catch (error) {
    return error.response;
  }
}
