import { createSlice } from "@reduxjs/toolkit";

const getDetailTestingFuncSlice = createSlice({
  name: "getDetailTestingFunc",
  initialState: null,
  reducers: {
    getDetailTestingGlobalFunc: (state, action) => {
      return action.payload;
    },
    getDetailTemplateGlobalFunc: (state, action) => {
      return action.payload;
    },
    getDetailRbeProcedureGlobalFunc: (state, action) => {
      return action.payload;
    },
    checkingAllTesttingAckGlobalFunc: (state, action) => {
      return action.payload;
    },
  },
});

export const {
  getDetailTestingGlobalFunc,
  getDetailTemplateGlobalFunc,
  getDetailRbeProcedureGlobalFunc,
  checkingAllTesttingAckGlobalFunc,
} = getDetailTestingFuncSlice.actions;

export default getDetailTestingFuncSlice.reducer;
