import { useState } from 'react';
import styled from 'styled-components'
import { useTable, usePagination } from 'react-table'


const TableContainer = styled.div`
    width: 100%;
    max-height: calc(100vh - 400px);
    overflow-y: auto;
`;

const StyledTable = styled.table`
    width: 100%;
    position: relative;
    height: 100%;
    border-collapse: separate;
    border-spacing: 0px;

    th {
      position: sticky;
      top: 0;
      left: 0;
      background: #fff;
      text-align: left;
      padding: 6px 0px;
      user-select: none;
      border-top: 1px solid #C4C4C4;
      border-bottom: 1px solid #C4C4C4;
      &:first-child {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
      }
      &:last-child {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }
    }

    td {
      border-bottom: 1px solid rgba(196, 196, 196, 0.43);
    }
`;

// Fungsi untuk Menampilkan Table
function TableWorkflowHistory({ columns, data, isPaging = true  }) {

    const [currentPage, setCurrentPage] = useState(0)
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,
      canPreviousPage,
      canNextPage,
      state: { pageIndex },
    } = useTable(
      {
      columns,
      data: data ? data : [{}],
        initialState: {
          pageIndex: isPaging ? currentPage : 0 ,
          pageSize: isPaging ? 5 : 999
        },
      },
      usePagination
    )
    // Render the UI for your table
    return (
      <>
        <TableContainer>
          <StyledTable {...getTableProps()}>
            <thead style={{ borderTop: '1px solid #c4c4c4' }}>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps({style: { minWidth: column.minWidth, width: column.width }})}>{column.render('Header')}</th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row)
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    })}
                  </tr>
                )
              })}
              {page?.length < 1  && (
                <tr>
                  <td
                    colSpan={
                      headerGroups ? headerGroups[0]?.headers?.length + 1 : 3
                    }
                    style={{ textAlign: "center" }}
                  >
                    {data.length === 0 ? <p>There are no records to display</p> : <p>Data Not Found</p> }
                  </td>
                </tr>
              )}
            </tbody>
          </StyledTable>
        </TableContainer>
        {
          isPaging ? (
            <div style={{textAlign: "right"}}>
              <div className="table-pagination">
              <button
                onClick={() => setCurrentPage(currentPage - 1)}
                disabled={!canPreviousPage}
                style={{borderRight: '1px solid #cccc'}}
                className={!canPreviousPage ? 'page-disabled' : ""}>
                {'< '}
              </button>
              <span className="paging-number">{pageIndex + 1}</span>
              <button
                onClick={() => setCurrentPage(currentPage + 1)}
                disabled={!canNextPage}
                style={{borderLeft: '1px solid #cccc'}}
                className={!canNextPage ? 'page-disabled' : ""}>
                {' >'}
              </button>
              </div>
            </div>
          ) : null
        }
      </>
    )
}

export default TableWorkflowHistory;
