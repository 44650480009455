import React from 'react'

const Dashboard = ({t}) => {
  return (
    <div className='dashboard'>
      <p>{t('dashboardContent')}</p>
    </div>
  )
}

export default Dashboard
