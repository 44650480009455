import authAPIInstance from './ApiAuth'

export async function PostLogin(Data) {
  try {
    const response = await authAPIInstance.post(`/v1/Auth/Login`, Data)
    return response
  } catch (error) {
    return error.response
  }
}

export async function RefreshToken(payload) {
  try {
    const response = await authAPIInstance.post(`/v1/Auth/RefreshToken`, payload)
    return response
  } catch (error) {
    return error.response
  }
}

export async function PostLogout(payload) {
  try {
    const response = await authAPIInstance.post(`/v1/Auth/Logout`, payload)
    return response
  } catch (error) {
    return error.response
  }
}

export async function GetRoutes(payload) {
  try {
    const response = await authAPIInstance.get(`/v1/Menu/ListByApplicationCodeRoleCode?applicationCode=${payload.applicationCode}&roleCode=${payload.roleCode}`)

    return response
  } catch (error) {
    return error.response
  }
}

export async function GetMenus(payload) {
  try {
    const response = await authAPIInstance.get(`/v1/Menu/ListByApplicationCodeRoleCodeWithTree?applicationCode=${payload.applicationCode}&roleCode=${payload.roleCode}`)

    return response
  } catch (error) {
    return error.response
  }
}

export async function VerifyUser(payload) {
  try {
    const response = await authAPIInstance.post(`/v1/Auth/VerifyUsernamePassword`, payload)
    return response
  } catch (error) {
    return error.response
  }
}

export const listUserByRole = async (payload) => {
  try {
    const response = await authAPIInstance.get('/v1/User/ListByRole',  {
      params: payload
    })

    return response
  } catch (error) {
    console.log(error)

    return error.response
  }
}

export const GetListUsersByOrgId = async (payload) => {
  try {
    const response = await authAPIInstance.get(`/v1/User/ListByBioHROrganization?bioHROrganizationId=${payload.orgId}&positionType=${payload.positionType}`)
    return response
  } catch (error) {
    return error
  }
}

export const GetListKasieAhliMuda = async (payload) => {
  try {
    const response = await authAPIInstance.get(`/v1/Position/ListPositionLevelKasieAndAhliMuda?bioHrOrganizationId=${payload}`)
    return response
  } catch (error) {
    return error
  }
}

export async function postChangeDigitalSign(payload) {
  try {
    const response = await authAPIInstance.post(`/v1/DigitalSignature/Update`, payload)
    return response
  } catch (error) {
    return error.response
  }
}

export async function CheckValidityTimeDigitalSignature(nik) {
  try {
    const response = await authAPIInstance.get(`/v1/DigitalSignature/CheckValidityTime?NIK=${nik}`)
    return response
  } catch (error) {
    return error.response
  }
}
