import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
} from "@chakra-ui/react";
import { useRef } from "react";
import styled from "styled-components";
import { useTable, usePagination } from "react-table";
import moment from "moment";

const TableContainer = styled.div`
  flex: 1;
  overflow-y: auto;
`;

const StyledTable = styled.table`
  width: 100%;
  position: relative;
  height: 100%;
  border-collapse: separate;
  border-spacing: 0px;

  th {
    position: sticky;
    top: 0;
    left: 0;
    background: #fff;
    text-align: left;
    padding: 6px 0px;
    user-select: none;
    border-top: 1px solid #c4c4c4;
    border-bottom: 1px solid #c4c4c4;
    &:first-child {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
    &:last-child {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }

  td {
    border-bottom: 1px solid rgba(196, 196, 196, 0.43);
  }
`;

// TODO: Refactor
const TableWorkflow = ({ columns, data, isPaging = false }) => {
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    useTable(
      {
        columns,
        data: data ? data : [{}],
        initialState: {
          pageIndex: 0,
          pageSize: 999,
        },
      },
      usePagination
    );

  // Render the UI for your table
  return (
    <TableContainer>
      <StyledTable {...getTableProps()}>
        <thead style={{ borderTop: "1px solid #c4c4c4" }}>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps({
                    style: { minWidth: column.minWidth, width: column.width },
                  })}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>
                      {cell.value && cell.value.trim()
                        ? cell.render("Cell")
                        : "-"}
                    </td>
                  );
                })}
              </tr>
            );
          })}
          {page?.length < 1 && (
            <tr>
              <td
                colSpan={
                  headerGroups ? headerGroups[0]?.headers?.length + 1 : 3
                }
                style={{ textAlign: "center" }}
              >
                {data.length === 0 ? (
                  <p>There are no records to display</p>
                ) : (
                  <p>Data Not Found</p>
                )}
              </td>
            </tr>
          )}
        </tbody>
      </StyledTable>
    </TableContainer>
  );
};

const columnsHistory = [
  {
    Header: "DATE TIME",
    accessor: "dateTime",
    width: "20%",
    Cell: (row) => {
      return (
        <>{row.value ? moment(row.value).format("DD MMM YYYY HH:mm") : ""}</>
      );
    },
  },
  {
    Header: "NIK",
    accessor: "personalNik",
    width: "10%",
  },
  {
    Header: "NAME",
    accessor: "personalName",
    width: "20%",
  },
  {
    Header: "POSITION",
    accessor: "position",
    width: "20%",
  },
  {
    Header: "ACTION",
    accessor: "action",
    width: "10%",
  },
  {
    Header: "NOTE",
    accessor: "note",
    width: "20%",
    Cell: (row) => {
      return (
        <Text
          style={{
            minWidth: "100px",
            maxWidth: "200px",
            wordWrap: "break-word",
          }}
        >
          {row.value}
        </Text>
      );
    },
  },
];

const ModalWorkflowHistory = ({ isOpen, onClose, data }) => {
  const initialRef = useRef();
  const finalRef = useRef();

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      id="workflow"
    >
      <ModalOverlay />
      <ModalContent maxW="70%">
        <ModalHeader>Workflow History</ModalHeader>
        <ModalCloseButton />
        <ModalBody
          className="o-table-review pt-0 pb-4"
          display="flex"
          maxHeight="75vh"
        >
          <TableWorkflow columns={columnsHistory} data={data} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalWorkflowHistory;
