import { Box, Button, FormLabel, ModalFooter, Input } from "@chakra-ui/react";
import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  toolGroupId: Yup.string().required("required"),
  toolGroupName: Yup.string().required("required"),
});

const EquipmentGroupForm = ({ onClose, formData, handleSubmit }) => {
  return (
    <Formik
      initialValues={{
        toolGroupId: formData?.toolGroupId ? formData?.toolGroupId : "",
        toolGroupName: formData?.toolGroupName ? formData?.toolGroupName : "",
      }}
      onSubmit={async (values) => {
        const res = await handleSubmit({
          ...values,
          id: formData?.id,
        });
        if (res?.statusCode === 200) return onClose();
      }}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {({ values, errors, touched, isSubmitting, handleChange }) => (
        <Form className="add-org-modal">
          <Box marginBottom="2em">
            <FormLabel className="form-label" w="100%">
              Equipment Group ID{" "}
              <Box as="span" color="red">
                *
              </Box>
            </FormLabel>
            <Input
              className={
                touched.toolGroupId && errors.toolGroupId ? "is-invalid" : ""
              }
              name="toolGroupId"
              placeholder="Input Equipment Group ID"
              value={values.toolGroupId}
              onChange={handleChange}
              boxShadow="none !important"
            />
            <ErrorMessage
              name="toolGroupId"
              component="div"
              className="invalid-feedback"
              style={{ position: "absolute" }}
            />
          </Box>
          <Box marginBottom="2em">
            <FormLabel className="form-label" w="100%">
              Equipment Group Name{" "}
              <Box as="span" color="red">
                *
              </Box>
            </FormLabel>
            <Input
              className={
                touched.toolGroupName && errors.toolGroupName
                  ? "is-invalid"
                  : ""
              }
              name="toolGroupName"
              placeholder="Input Equipment Group Name"
              value={values.toolGroupName}
              onChange={handleChange}
              boxShadow="none !important"
            />
            <ErrorMessage
              name="toolGroupName"
              component="div"
              className="invalid-feedback"
              style={{ position: "absolute" }}
            />
          </Box>

          <ModalFooter mt={3} pb={0} pr={0}>
            <Button className="c-modal-cancel" onClick={onClose}>
              Close
            </Button>
            <Button
              isLoading={isSubmitting}
              loadingText="Saving"
              spinnerPlacement="start"
              mr={3}
              type="submit"
              className="submitnext action-button c-modal-add"
              margin="0 0 0 15px"
            >
              Save
            </Button>
          </ModalFooter>
        </Form>
      )}
    </Formik>
  );
};

export default EquipmentGroupForm;
