import logo from '../../../assets/img/logo.png';

import '../../../assets/css/Layout.css'

const Logo = () => {
    return (
        <div className="profile-menu" style={{ cursor: "pointer" }}>
            <img src={logo} className="logo-image" alt="account" />
            <div className="profile-info hover-show">
                <p className="profile-name text-bold">platform</p>
            </div>
        </div>
    )
}

export default Logo;