import axios from "axios";
import { getCookieAccessToken } from "../../../utils/Helpers";

const notificationAPIInstance = axios.create({
  baseURL: process.env.REACT_APP_API_HOST_NOTIFICATION,
});

notificationAPIInstance.interceptors.request.use(
  function (config) {
    config.headers = {
      Authorization: `Bearer ${getCookieAccessToken()}`,
    };
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default notificationAPIInstance;
