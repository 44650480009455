import { SET_MENU_DATA } from "./actionTypes";

const INITIAL_STATE = {
  data: {
    routes: [],
    menus: [],
  },
};

function MenuReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_MENU_DATA:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
}

export default MenuReducer;
