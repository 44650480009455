import {
  Button,
  Checkbox,
  Grid,
  Heading,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import { SingleDatepicker } from "components";
import moment from "moment";
import { useState } from "react";
import { MdArrowDropDown } from "react-icons/md";
import { useSelector } from "react-redux";

const PopoverFilter = ({ handleFilter, filterData, setFilterData }) => {
  const category = useSelector((state) => state.auditManagement.auditCategory);
  const status = useSelector(
    (state) => state.auditManagement.findingObjectStatus
  );

  const { onOpen, onClose, isOpen } = useDisclosure();
  const [isFilter, setIsFilter] = useState(Boolean(filterData));
  const [startDate, setStartDate] = useState(filterData?.scheduleStartDate);
  const [endDate, setEndDate] = useState(filterData?.scheduleEndDate);
  const [categoriesFilter, setCategoriesFilter] = useState(
    filterData?.categories.split("") ?? []
  );
  const [statusFilter, setStatusFilter] = useState(
    filterData?.objectStatuses.split("") ?? []
  );

  const onClickSetFilter = () => {
    const payload = {
      scheduleStartDate: startDate,
      scheduleEndDate: endDate,
      categories: categoriesFilter.join(","),
      objectStatuses: statusFilter.join(","),
    };
    setFilterData(payload);
    if (
      startDate ||
      endDate ||
      categoriesFilter.length ||
      statusFilter.length
    ) {
      setIsFilter(true);
    } else {
      setIsFilter(false);
    }
    onClose();
    handleFilter(payload);
  };

  const onClickReset = () => {
    setStartDate(null);
    setEndDate(null);
    setCategoriesFilter([]);
    setStatusFilter([]);

    const payload = {
      scheduleStartDate: null,
      scheduleEndDate: null,
      categories: null,
      objectStatuses: null,
    };

    setIsFilter(false);
    onClose();
    handleFilter(payload);
  };

  const handleChangeCategories = (e) => {
    const set = new Set(categoriesFilter);

    if (e.target.checked) {
      set.add(e.target.value);
    } else {
      set.delete(e.target.value);
    }

    setCategoriesFilter(Array.from(set));
  };
  const handleChangeStatus = (e) => {
    const set = new Set(statusFilter);

    if (e.target.checked) {
      set.add(e.target.value);
    } else {
      set.delete(e.target.value);
    }

    setStatusFilter(Array.from(set));
  };

  return (
    <Popover onOpen={onOpen} onClose={onClose} isOpen={isOpen}>
      <PopoverTrigger>
        <Button
          width={"100%"}
          variant={isFilter ? "solid" : "outline"}
          justifyContent={"space-between"}
          backgroundColor={isFilter ? "#46A2E1" : "#fff"}
          boxShadow={"0px 0px 2px 0px rgba(0, 0, 0, 0.56)"}
        >
          Filter
          <MdArrowDropDown />
        </Button>
      </PopoverTrigger>
      <PopoverContent padding={"12px"} gap={"8px"}>
        <Heading as={"h5"} size="sm">
          Schedule
        </Heading>
        <Stack direction={"row"} gap={"8px"}>
          <Heading as={"h6"} size="xs">
            Start Date
            <SingleDatepicker
              placeHolder={"Start Date"}
              onChange={(value) =>
                setStartDate(
                  moment(value).startOf("day").format("YYYY-MM-DDTHH:mm:ss")
                )
              }
              maxDate={endDate}
            />
          </Heading>
          <Heading as={"h6"} size="xs">
            End Date
            <SingleDatepicker
              placeHolder={"End Date"}
              onChange={(value) =>
                setEndDate(
                  moment(value).endOf("day").format("YYYY-MM-DDTHH:mm:ss")
                )
              }
              minDate={startDate}
            />
          </Heading>
        </Stack>
        <Heading as={"h5"} size="sm">
          Category
        </Heading>
        <Stack direction={"column"}>
          {category?.map((item) => {
            return (
              <Checkbox
                onChange={handleChangeCategories}
                value={item.id}
                isChecked={categoriesFilter.includes(String(item.id))}
              >
                {item.name}
              </Checkbox>
            );
          })}
        </Stack>
        <Heading as={"h5"} size="sm">
          Status
        </Heading>
        <Stack direction={"column"}>
          {status?.map((item) => {
            return (
              <Checkbox
                value={item.id}
                onChange={handleChangeStatus}
                isChecked={statusFilter.includes(String(item.id))}
              >
                {item.name}
              </Checkbox>
            );
          })}
        </Stack>
        <Grid templateColumns={"auto auto"} gap={"8px"}>
          <Button
            variant="outline"
            borderColor={"#1FB4D2"}
            color={"#1FB4D2"}
            onClick={onClickReset}
          >
            Reset
          </Button>
          <Button
            backgroundColor={"#1FB4D2"}
            color={"#fff"}
            _hover={{ backgroundColor: "#46A2E1" }}
            onClick={onClickSetFilter}
          >
            Set
          </Button>
        </Grid>
      </PopoverContent>
    </Popover>
  );
};

export default PopoverFilter;
