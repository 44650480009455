import { isUndefined, omit } from "lodash";

// mapping request utils
const getAuditeeProducts = (data) =>
  data.auditeeProducts.map((val) => ({
    auditeeId: val.auditeeId,
    id: val.id,
  }));
const getExternalAuditors = (data) =>
  data.externalAuditors.map((val) => ({
    name: val.name,
    role: val.role,
  }));
const getInternalAuditors = (data) =>
  data.map((item) => ({
    auditorId: item.auditorId,
    role: item.role,
  }));
const getInspectionGoals = (data) =>
  data.inspectionGoals.map((val) => val.goal);
// const getScopeIds = (data) => data.;

export const mapDetailsToCreateReqObj = (data) => {
  const auditeeProducts = getAuditeeProducts(data.auditeeProducts);
  const externalAuditors = getExternalAuditors(data.externalAuditors);
  const internalAuditors = getInternalAuditors(data.internalAuditors);
  const inspectionGoals = getInspectionGoals(data.inspectionGoals);
  //   const scopeIds = getScopeIds(data.scopes); // need confirm to BE where we can get this values

  const requestData = {
    id: data?.id,
    category: data.category,
    type: data.type,
    method: data.method,
    auditeeId: data.auditeeId,
    auditorId: data.auditorId,
    auditeeProducts,
    scheduleStartDate: data.scheduleStartDate,
    scheduleEndDate: data.scheduleEndDate,
    notificationReminder: data.notificationReminder,
    internalAuditors,
    externalAuditors,
    inspectionGoals,
    // scopeIds,
    inspectionScopes: data.inspectionScopes,
    isDraft: data.isDraft,
    createdBy: data.createdBy,
  };

  return omit(requestData, isUndefined);
};

export const ALERT_TYPE = {
  DELETE: "delete",
  DISCARD: "discard",
  REMOVE: "remove",
  CANCEL: "cancel",
  CANCEL_EDIT: "cancelEdit",
  SAVE: "save",
  SUBMIT: "submit",
};

export const isRoleCode = (roles, roleType) => {
  return roles.some((role) => role === roleType);
};

export const QAS_ROLES = {
  REVIEWER_OPERATOR: "OP-QA-AU", // operator & reviewer audit
  AUDITOR: "AU", // auditor
  LEAD_AUDITOR: "RE-QA-AU", // lead auditor
};

export const getPermission = (roles) => ({
  canCreateSchedule: isRoleCode(roles, QAS_ROLES.REVIEWER_OPERATOR),
  canViewListSchedule:
    isRoleCode(roles, QAS_ROLES.REVIEWER_OPERATOR) ||
    isRoleCode(roles, QAS_ROLES.LEAD_AUDITOR),
  canViewDetail:
    isRoleCode(roles, QAS_ROLES.REVIEWER_OPERATOR) ||
    isRoleCode(roles, QAS_ROLES.LEAD_AUDITOR),
  canCorrectSchedule: isRoleCode(roles, QAS_ROLES.REVIEWER_OPERATOR),
  canCancelSchedule: isRoleCode(roles, QAS_ROLES.REVIEWER_OPERATOR),
  canRejectSchedule: isRoleCode(roles, QAS_ROLES.LEAD_AUDITOR),
  canApproveSchedule: isRoleCode(roles, QAS_ROLES.LEAD_AUDITOR),

  // checking rollname
  isLeadAuditor: isRoleCode(roles, QAS_ROLES.LEAD_AUDITOR),
  isReviewerOperator: isRoleCode(roles, QAS_ROLES.REVIEWER_OPERATOR),
  isAuditor: isRoleCode(roles, QAS_ROLES.AUDITOR),
});

export const getCurrentStatePage = (path) => {
  switch (true) {
    case path.includes("detail"):
      return "detail";
    case path.includes("edit"):
      return "edit";
    case path.includes("confirmation"):
      return "confirmation";
    case path.includes("correction"):
      return "correction";
    default:
      return "create";
  }
};
