import { configureStore, applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";

import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

import { encryptTransform } from "redux-persist-transform-encrypt";
import { composeWithDevTools } from "redux-devtools-extension";
// import { routerMiddleware } from "connected-react-router";

import rootReducer from "./actions";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["qcs", "userCredentials"],
  transforms: [
    encryptTransform({
      secretKey: "my-super-secret-key",
      onError: function (error) {
        console.log(error);
      },
    }),
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
// const routeMiddleware = routerMiddleware();

// export const store = configureStore({
//   reducer: persistedReducer,
// middleware: [thunk],
// });

export const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

export const persistor = persistStore(store);
