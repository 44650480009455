import {
  Button,
  Modal,
  Box,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Textarea,
  FormLabel,
} from "@chakra-ui/react";

const ModalNote = ({
  isOpenNote,
  onCloseNote,
  initialRefNote,
  note = "",
  titleNote = "",
  listNote = [],
  menu,
}) => {
  return (
    <Modal
      isOpen={isOpenNote}
      onClose={onCloseNote}
      initialFocusRef={initialRefNote}
      size="xl"
    >
      <ModalOverlay />
      <ModalContent className="modalNote">
        <ModalHeader textAlign="center">Note {titleNote}</ModalHeader>
        <hr />
        <ModalBody>
          {!menu ? (
            <Textarea
              value={note}
              placeholder="-"
              size="sm"
              readOnly={true}
              color="#000"
              backgroundColor="#ECECEC"
            />
          ) : (
            listNote.map((item) => (
              <Box mb={2}>
                <FormLabel className="form-label">Note {item.title}</FormLabel>
                <Textarea
                  value={item.note}
                  placeholder="-"
                  size="sm"
                  readOnly={true}
                  color="#000"
                  backgroundColor="#ECECEC"
                />
              </Box>
            ))
          )}
        </ModalBody>
        <ModalFooter pt={4} pb={4}>
          <Button
            colorScheme="blue"
            variant="outline"
            onClick={onCloseNote}
            style={{
              margin: "0 auto",
              color: "#1fb4d2",
              border: "2px solid #1fb4d2",
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalNote;
