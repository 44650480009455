import React, {useState} from 'react'
import { useTable, usePagination } from 'react-table'

// Fungsi untuk Menampilkan Table
const NewTable2 = ({ columns, data, isPaging = true  }) => {
  // TODO-UnUse : setState jobless 
  const [currentPage, setCurrentPage] = useState(0)

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: { pageIndex, pageSize },
  } = useTable(
    {
    columns,
    data: data ? data : [{}],
      initialState: {
        pageIndex: isPaging ? currentPage : 0 ,
        pageSize: isPaging ? 10 : 999
      },
    },
    usePagination
  )

    // Render the UI for your table
  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps({style: { minWidth: column.minWidth, width: column.width }})}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
            {page?.length < 1  && (
            <tr>
              <td
                colSpan={
                  headerGroups ? headerGroups[0]?.headers?.length + 1 : 3
                }
                style={{ textAlign: "center" }}
              >
                {data.length === 0 ? <p style={{ margin: '10% auto'}}>There are no records to display</p> : <p>Data Not Found</p> }
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  )
}

export default NewTable2;