import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  process: null,
  isLoading: true,
};

export const productionReviewProcessSlice = createSlice({
  name: "productionReviewProcess",
  initialState,
  reducers: {
    setProcess(state, action) {
      state.process = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
  },
});

export const { setProcess, setIsLoading } =
  productionReviewProcessSlice.actions;
export default productionReviewProcessSlice.reducer;
