import axios from "axios";
import { generateToken, getCookieAccessToken } from "../../../utils/Helpers";

const pesAPIInstance = axios.create({
  baseURL: process.env.REACT_APP_API_HOST_PES,
});

pesAPIInstance.interceptors.request.use(
  (config) => {
    config.headers = {
      Authorization: `Bearer ${getCookieAccessToken()}`,
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

pesAPIInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { config, status } = error.response;

    // invalid token
    if (status === 401) {
      const accessToken = await generateToken();

      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${accessToken}`,
      };

      return axios(config);
    }

    return Promise.reject(error);
  }
);

export default pesAPIInstance;
