import { Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Menu } from "../../utils/ConstantGlobal";
import Dashboard from "../../pages/Dashboard";
import BreadcrumbReportEM from "../../pages/QCS/Report/EM/Breadcrumb";
import DynamicBreadCrumb from "pages/QAS/audit-management/Schedule/DynamicBreadcrumb";

// Breadcrumb QAS
import {
  BreadcrumbOrganization,
  BreadcrumbBuilding,
  BreadcrumbFacility,
  BreadcrumbReviewPending,
  BreadcrumbReviewCorrection,
  BreadcrumbReviewComplete,
  BreadcrumbGradeRoom,
  BreadcrumbRoom,
  BreadcrumbEquipment,
  BreadcrumbTestType,
  BreadcrumbDetailTestType,
  BreadcrumbTestTypeProcedure,
  BreadcrumbPersonnel,
  BreadcrumbAuditor,
  BreadcrumbReleaseMedia,
  BreadcrumbMicroba,
  BreadcrumbMasterItem,
  BreadCrumbProduction,
  BreadCrumbHierarchy,
  BreadcrumbMasterLoop,
  BreadcrumbMasterWater,
  BreadcrumbMasterUsePoint,
  BreadCrumbCreateProcedure,
  BreadcrumbCertification,
  BreadcrumbSas,
  BreadcrumbPostApproval,
  BreadcrumbProductRegistration,
  BreadcrumbFormSchedule,
  BreadcrumbAuthority,
  BreadcrumbFormConfiguration,
  BreadcrumbReportCustom,
  BreadcrumbReleaseProduction,
  BreadcrumbDetailReleaseProduction,
  BreadcrumbKitaudit,
} from "../../pages/QAS";
// Breadcrumb QCS
import BreadcrumbTodoQC from "../../pages/QCS/Todo/QC/Breadcrumb";
import BreadcrumbTodoSchedule from "../../pages/QCS/Todo/Schedule/Breadcrumb";
import BreadcrumbScheduled from "../../pages/QCS/WaterMonitoring/Schedule/Breadcrumb";
import BreadcrumbDepryogenationSterilization from "../../pages/QCS/WaterMonitoring/DepryogenationSterilization/Breadcrumb";
import BreadcrumbMonitoringQC from "../../pages/QCS/Monitoring/QC/Breadcrumb";
import BreadcrumbReleaseQC from "../../pages/QCS/Release/QC/Breadcrumb";
import BreadcrumbDeviationQCS from "pages/QCS/Deviation/Breadcrumb";
import BreadcrumbPSPM from "pages/QCS/Request/RawMaterial/BreadcrumbPSPM";
import BreadcrumbRequestRM from "pages/QCS/Request/RawMaterial/BreadcrumbRequestRM";
import BreadcrumbSamplingRM from "pages/QCS/Sampling/RawMaterial/BreadcrumbSamplingRM";

// Router
import PrivateRoute from "./PrivateRouter";
import PublicRoute from "./PublicRouter";
import {
  Presence,
  BreadCrumbProductionPES,
  BreadCrumbProductionExecutionPES,
} from "../../pages/PES";
import Layout from "../../components/layout";
import { useSelector } from "react-redux";
import Login from "../../pages/Auth";
import NotFound from "../../pages/Auth/NotFound";
import Forbidden from "../../pages/Auth/Forbidden";
import SquareLoading from "../../components/loading/SquareLoading";
import {
  ListQCRequest,
  FormRequest,
  ListQCRequestProduction,
  FormRequestProduction,
  ListSampling,
  FormSampling,
  ListSamplingTransfer,
  FormSamplingTransfer,
  ScannerSamplingTransfer,
  // ListSamplingProduction,
  // FormSamplingProduction,
  ListSample,
  LabelScanner,
  FormTransferedSample,
  ListSampleProduction,
  LabelScannerProduction,
  FormTransferProduction,
  ListTesting,
  FormQCTest,
  StartTest,
  ListTestingWaterMonitoring,
  FormQCTestWaterMonitoring,
  StartTestWaterMonitoring,
  ListTestingProduction,
  // FormQCTestProduction,
  FormTestQCProduction,
  OperatorTestingFormDetail,
  StartTestQCProduction,
  ListReviewWaterMonitoring,
  ListDeviationsWaterMonitoring,
  ViewDeviationsWaterMonitoring,
  ReportEM,
  ReleaseQC,
  MonitoringQC,
  TodoQC,
  DetailQCRelease,
  DetailQCMonitoring,
  DetailQCTodo,
  TodoSchedule,
  TodoScheduleDetail,
  TodoWaterMonitoring,
  TodoWaterMonitoringDetail,
  Scheduled,
  ScheduledForm,
  DepryogenationSterilization,
  DepryogenationSterilizationForm,
  ListSampleQC,
  DetailListSampleQC,
  ListSampleMaster,
  ListSampleTransaction,
  ListSampleOnHand,
  FormSampleManagement,
  ListRequestQCProduct,
  FormRequestQCProduct,
  FormCreateSample,
  ListSamplingProduct,
  FormSamplingProduct,
  ListTemplateTesting,
  FormTemplateTesting,
  ListSamplingPlanRawMaterial,
  FormSamplingPlanRawMaterial,
  ListQCRequestRawMaterial,
  FormQCResquestRawMaterial,
  FormQCResquestSamplingRawMaterial,
  ListDeviation,
  FormQC,
  ListRequestWaterMonitoring,
  FormRequestWaterMonitoring,
  ListSamplingWaterMonitoring,
  FormSamplingWaterMonitoring,
  ListSamplingRawMaterial,
  FormSamplingRawMaterial,
  ListTransferWaterMonitoring,
  FormTransferWaterMonitoring,
  ListMonitoringWaterMonitoring,
  ListSampleManagementWaterMonitoring,
  DetailSampleManagementWaterMonitoring,
  ListSampleManagementRawMaterial,
  ListSampleManagementRawMaterialMaster,
  FormRetainedSample,
  ListTestingRawMaterial,
  FormTestRawMaterial,
  FormStartTestRawMaterial,
  ListTransferRawMaterial,
  FormTransferRawMaterial,
  ListReviewRawMaterial,
  DetailReviewMenu,
  ListDeviationRawMaterial,
  ViewDeviationsRawMaterial,
  ListTodoRawMaterial,
  DetailTodoRawMaterial,
  ListDeviationProduct,
  DetailDeviationProduct,
} from "./QCSPages";

import {
  Organization,
  Building,
  Facility,
  AddFacility,
  EMPhysical,
  EMPhysicalRoom,
  EMPhysicalMonitoring,
  Equipment,
  FormEquipment,
  GradeRoom,
  AddGradeRoom,
  Room,
  FormRoom,
  Item,
  FormItem,
  Auditor,
  Personnel,
  DetailPersonnel,
  Microba,
  FormMicroba,
  TestType,
  FormTestType,
  FormTestTypeProcedure,
  Loop,
  FormLoop,
  Water,
  FormWater,
  UsePoint,
  FormUsePoint,
  Pending,
  Kitaudit,
  DetailReviewGradeRoom,
  DetailReviewFacility,
  DetailReviewRoom,
  DetailReviewEquipment,
  DetailReviewMicroba,
  DetailReviewAuditor,
  DetailReviewKitaudit,
  Correction,
  DetailCorrectionReviewGradeRoom,
  DetailCorrectionReviewFacility,
  DetailCorrectionReviewRoom,
  DetailCorrectionReviewEquipment,
  DetailCorrectionReviewMicroba,
  DetailCorrectionAuditor,
  DetailCorrectionKitaudit,
  Complete,
  DetailCompleteReviewGradeRoom,
  DetailCompleteReviewFacility,
  DetailCompleteReviewRoom,
  DetailCompleteReviewEquipment,
  DetailCompleteReviewMicroba,
  DetailCompleteReviewAuditor,
  DetailCompleteReviewKitaudit,
  ReleaseMedia,
  Production,
  FormHierarchy,
  FormAuditor,
  FormProcedure,
  FormKitaudit,
  EquipmentGroup,
  Activity,
  TestParameter,
  UoM,
  Presentation,
  DosageForm,
  AuditorExternal,
  JobDescription,
  ListForm,
  FormSchedule,
  Registration,
  FormRegistration,
  Certification,
  FormCertification,
  PostApproval,
  FormPostApproval,
  SasRegistration,
  FormSasRegistration,
  Authority,
  FormConfiguration,
  ReportCustom,
  ReleaseProduction,
  DetailReleaseProduction,
  AuditSchedule,
  FormAuditSchedule,
  FormFindingSheet,
  EMPhysicalDeviation,
  EMPhysicalDeviationDetail,
  Manufacturer,
  FindingAudit,
  ProcedureSampling,
} from "./QASPages";

import {
  ProductionExecutionPES,
  ProductionPES,
  ProductionReviewDetailPES,
  ProductionReviewPES,
  ProductionExecutionListPES,
  DeviationPES,
  DeviationProcessPES,
  DeviationDetailPES,
  TemporaryChangePES,
  FormTc,
} from "./PESPages";
import { Notifications } from "./NotificationPages";
import BreadcrumbProductionExecutionListPES from "pages/PES/Production/Execution/Breadcrumb.jsx";
import BreadcrumbProductionReviewPes from "pages/PES/Production/Review/Breadcrumb";
import BreadcrumbProductionReviewDetailPes from "pages/PES/Production/ReviewDetail/Breadcrumb";
import BreadcrumbDeviationPes from "pages/PES/Production/Deviation/Breadcrumb";
import BreadcrumbDeviationDetailPes from "pages/PES/Production/DeviationProcess/Breadcrumb";
import BreadcrumbDeviationParameterDetailPes from "pages/PES/Production/DeviationDetail/Breadcrumb";
import BreadcrumbTodoProductionQas from "pages/QAS/Todo/Production/Breadcrumb";
import BreadcrumbEMP from "pages/QAS/em-physical/monitoring/components/breadcrumb";
import BreadcrumbTestingRM from "pages/QCS/Testing/RawMaterial/BreadcrumbTestingRM";
import StartTestForm from "pages/QCS/Testing/Production/StartTestForm";
import { FindingSheetDetail } from "pages/QAS/audit-management/Finding";
import BreadCrumbProductionTemporaryChangePES from "pages/QAS/release/TemporaryChange/Breadcrumb";

import BreadCrumbFormTc from "pages/QAS/release/TemporaryChangeDetail/BreadCrumbTc";
import BreadcrumbTestingProduct from "pages/QCS/Testing/Production/BreadcrumbTestingProduct";

const Routes = () => {
  const isLogin = useSelector((state) => state.auth.isLogin);

  const all = [Menu.qcRequest];

  return (
    <Router>
      <Suspense
        fallback={
          isLogin ? (
            <Layout title={""} breadcrumb={""}>
              <SquareLoading />
            </Layout>
          ) : (
            <></>
          )
        }
      >
        <Switch>
          <PublicRoute path="/login" exact component={Login} />
          <PrivateRoute
            path="/"
            exact
            component={Dashboard}
            title="Dashboard"
            allRole
          />
          <PrivateRoute
            path="/dashboard"
            exact
            component={Dashboard}
            title="Dashboard"
            menu={[Menu.qcCreate]}
            allRole
          />

          <PrivateRoute
            path="/notifications"
            exact
            title={"Notifications"}
            component={Notifications}
            menu={all}
            allRole
          />

          {/* ====== QCS Routes ====== */}
          <PrivateRoute
            path="/qcs/qc/request"
            exact
            component={ListQCRequest}
            title="Request"
            menu={all}
          />
          <PrivateRoute
            path="/qcs/qc/request/create"
            component={FormRequest}
            title="Create Request"
            menu={all}
          />
          <PrivateRoute
            path="/qcs/qc/request/detail/:id"
            component={FormRequest}
            title="Detail Request"
            menu={all}
          />
          <PrivateRoute
            path="/qcs/qc/request/product"
            exact
            component={ListRequestQCProduct}
            title="QC Request"
            menu={all}
          />
          <PrivateRoute
            path="/qcs/qc/request/product/create"
            exact
            component={FormRequestQCProduct}
            title="Create Request Product"
            menu={all}
          />
          <PrivateRoute
            path="/qcs/review-qcs-product/request"
            exact
            component={ListRequestQCProduct}
            title="Review QC Request"
            menu={all}
          />
          <PrivateRoute
            path="/qcs/review-qcs-product/request/detail/:requestId"
            component={FormRequestQCProduct}
            title="Detail Review Request"
            menu={all}
          />
          <PrivateRoute
            path="/qcs/qc/request/product/detail/:requestId"
            exact
            component={FormRequestQCProduct}
            title="Detail Request Product"
            menu={all}
          />
          <PrivateRoute
            path="/qcs/qc/request./production"
            exact
            component={ListQCRequestProduction}
            title="Request Production"
            menu={all}
          />
          <PrivateRoute
            path="/qcs/qc/request./production/create"
            component={FormRequestProduction}
            title="Create Request Production"
            menu={all}
          />
          <PrivateRoute
            path="/qcs/qc/request./production/detail/:id"
            component={FormRequestProduction}
            title="Detail Request Production"
            menu={all}
          />

          <PrivateRoute
            path="/qcs/qc/sampling"
            exact
            component={ListSampling}
            title="Sampling"
            menu={all}
          />
          <PrivateRoute
            path="/qcs/qc/sampling/detail/:id"
            component={FormSampling}
            title="Detail Sampling"
            menu={all}
          />
          <PrivateRoute
            path="/qcs/qc/sampling/water-monitoring"
            exact
            component={ListSamplingWaterMonitoring}
            title="Sampling"
            menu={all}
          />

          <PrivateRoute
            path={[
              "/qcs/qc/sampling/water-monitoring/create",
              "/qcs/qc/sampling/water-monitoring/:typeOfForm/:sampleId",
            ]}
            exact
            component={FormSamplingWaterMonitoring}
            title="Create Sampling"
            menu={all}
          />

          <PrivateRoute
            path="/qcs/qc/transfer/product"
            exact
            component={ListSamplingTransfer}
            title="List Sample"
            menu={all}
          />
          <PrivateRoute
            path="/qcs/qc/transfer/product/detail/:id"
            component={FormSamplingTransfer}
            title="Detail Sample"
            menu={all}
          />
          <PrivateRoute
            path="/qcs/qc/transfer/product/send"
            exact
            component={ScannerSamplingTransfer}
            title="Scan Sending Sample"
            menu={all}
          />
          <PrivateRoute
            path="/qcs/qc/transfer/product/receive"
            exact
            component={ScannerSamplingTransfer}
            title="Scan Received Sample"
            menu={all}
          />

          {/* <PrivateRoute
            path="/qcs/qc/sampling./production"
            exact
            component={ListSamplingProduction}
            title="Sampling"
            menu={all}
          /> */}
          {/* <PrivateRoute
            path="/qcs/qc/sampling./production/detail/:id"
            component={FormSamplingProduction}
            title="Detail Sampling"
            menu={all}
          /> */}

          <PrivateRoute
            path="/qcs/transfer"
            exact
            component={ListSample}
            title="List Sample"
            menu={all}
          />
          <PrivateRoute
            path="/qcs/transfer/detail/:id"
            exact
            component={FormTransferedSample}
            title="Detail Sample"
            menu={all}
          />
          <PrivateRoute
            path="/qcs/transfer/send"
            exact
            component={LabelScanner}
            title="Scan Sending Sample"
            menu={all}
          />
          <PrivateRoute
            path="/qcs/transfer/receive"
            exact
            component={LabelScanner}
            title="Scan Received Sample"
            menu={all}
          />

          <PrivateRoute
            path="/qcs/transfer/water-monitoring"
            exact
            component={ListTransferWaterMonitoring}
            title="List Transfer"
            menu={all}
          />
          <PrivateRoute
            path="/qcs/transfer/water-monitoring/:typeOfForm/:id"
            exact
            component={FormTransferWaterMonitoring}
            title="Detail Sample"
            menu={all}
          />
          <PrivateRoute
            path="/qcs/qc/monitoring/water-monitoring"
            exact
            component={ListMonitoringWaterMonitoring}
            title="Daily Monitoring"
            menu={all}
          />
          <PrivateRoute
            path="/qcs/qc/sample-management/water-monitoring"
            exact
            component={ListSampleManagementWaterMonitoring}
            title="Sample Management"
            menu={all}
          />
          <PrivateRoute
            path="/qcs/qc/sample-management/water-monitoring/:typeOfForm/:id"
            exact
            component={DetailSampleManagementWaterMonitoring}
            title="Daily Monitoring"
            menu={all}
          />

          <PrivateRoute
            path="/qcs/qc/late-transfer"
            exact
            component={ListSample}
            title="List Late Transfer"
            menu={all}
          />
          <PrivateRoute
            path="/qcs/qc/late-transfer/detail/:id"
            exact
            component={FormTransferedSample}
            title="Detail Late Transfer"
            menu={all}
          />

          <PrivateRoute
            path="/qcs/qc/sampling/product"
            exact
            component={ListSamplingProduct}
            title="QC Sampling"
            menu={all}
          />
          <PrivateRoute
            path="/qcs/qc/sampling/product/detail/:id"
            exact
            component={FormSamplingProduct}
            title="Detail Sampling"
            menu={all}
          />
          <PrivateRoute
            path="/qcs/qc/transfer./production"
            exact
            component={ListSampleProduction}
            title="List Sample"
            menu={all}
          />
          <PrivateRoute
            path="/qcs/qc/transfer./production/detail/:id"
            exact
            component={FormTransferProduction}
            title="Detail Sample"
            menu={all}
          />
          <PrivateRoute
            path="/qcs/review-qcs-product/sample"
            exact
            component={ListSamplingProduct}
            title="Review QC Sampling"
            menu={all}
          />
          <PrivateRoute
            path="/qcs/review-qcs-product/sample/detail/:id"
            component={FormSamplingProduct}
            title="Detail Review Sampling"
            menu={all}
          />

          <PrivateRoute
            path="/qcs/qc/transfer./production/send"
            exact
            component={LabelScannerProduction}
            title="Scan Sending Sample"
            menu={all}
          />
          <PrivateRoute
            path="/qcs/qc/transfer./production/receive"
            exact
            component={LabelScannerProduction}
            title="Scan Received Sample"
            menu={all}
          />

          <PrivateRoute
            path="/qcs/qc/late-transfer./production"
            exact
            component={ListSampleProduction}
            title="List Late Transfer"
            menu={all}
          />
          <PrivateRoute
            path="/qcs/qc/late-transfer./production/detail/:id"
            exact
            component={FormTransferProduction}
            title="Detail Late Transfer"
            menu={all}
          />

          <PrivateRoute
            path="/qcs/qc/testing"
            exact
            component={ListTesting}
            title="QC Test"
            menu={all}
          />
          <PrivateRoute
            path="/qcs/qc/testing/create"
            exact
            component={FormQCTest}
            title="Create Test"
            menu={all}
          />
          <PrivateRoute
            path="/qcs/qc/testing/detail/:id"
            exact
            component={FormQCTest}
            title="Detail Test"
            menu={all}
          />
          <PrivateRoute
            path="/qcs/qc/testing/detail/:type(test|view|correction)/:id"
            exact
            component={StartTest}
            title="Detail Test"
            menu={all}
          />
          <PrivateRoute
            path="/qcs/qc/testing/water-monitoring"
            exact
            component={ListTestingWaterMonitoring}
            title="QC Test"
            menu={all}
          />
          <PrivateRoute
            path="/qcs/qc/testing/water-monitoring/create"
            exact
            component={FormQCTestWaterMonitoring}
            title="Create Test"
            menu={all}
          />
          <PrivateRoute
            path="/qcs/qc/testing/water-monitoring/start/:id"
            exact
            component={StartTestWaterMonitoring}
            title="Detail Start Testing"
            menu={all}
          />
          <PrivateRoute
            path="/qcs/qc/testing/water-monitoring/edit/:id"
            exact
            component={FormQCTestWaterMonitoring}
            title="Detail Test"
            menu={all}
          />
          <PrivateRoute
            path="/qcs/qc/review/water-monitoring"
            exact
            component={ListReviewWaterMonitoring}
            title="List Testing"
            menu={all}
          />

          <PrivateRoute
            path="/qcs/qc/review/water-monitoring/view/:id"
            exact
            component={FormQCTestWaterMonitoring}
            title="Testing Process"
            menu={all}
          />

          <PrivateRoute
            path="/qcs/qc/review/water-monitoring/form/:id"
            exact
            component={StartTestWaterMonitoring}
            title="Testing Process"
            menu={all}
          />

          <PrivateRoute
            path="/qcs/qc/deviation/water-monitoring"
            exact
            component={ListDeviationsWaterMonitoring}
            title="Deviation"
            menu={all}
          />

          <PrivateRoute
            path="/qcs/qc/deviation/water-monitoring/view/:id"
            exact
            component={ViewDeviationsWaterMonitoring}
            title="Deviation"
            menu={all}
          />

          <PrivateRoute
            path="/qcs/qc/testing/production"
            exact
            component={ListTestingProduction}
            title="QC Test"
            menu={all}
          />
          <PrivateRoute
            path="/qcs/qc/testing/production/create"
            exact
            component={FormTestQCProduction}
            title="Create Test"
            menu={all}
          />
          <PrivateRoute
            path="/qcs/qc/testing/production/edit/:id"
            exact
            component={FormTestQCProduction}
            title="Edit Test"
            menu={all}
          />
          <PrivateRoute
            path="/qcs/qc/testing/production/detail/:id"
            exact
            component={FormTestQCProduction}
            title="Detail Test"
            menu={all}
          />
          <PrivateRoute
            path="/qcs/qc/testing/production/detail/:id/form/:formId"
            exact
            component={OperatorTestingFormDetail}
            title="Form Detail"
            menu={all}
          />
          <PrivateRoute
            path="/qcs/qc/testing/production/detail/:type(test|view|correction)/:id"
            exact
            // component={StartTestProduction}
            component={OperatorTestingFormDetail}
            title="Detail Test"
            menu={all}
          />

          <PrivateRoute
            path="/qcs/review-qcs-product/testing"
            exact
            component={ListTestingProduction}
            title="Review QC Testing"
            menu={all}
          />
          <PrivateRoute
            path="/qcs/review-qcs-product/testing/detail/:id"
            exact
            component={FormTestQCProduction}
            title="Detail Testing"
            menu={all}
          />
          <PrivateRoute
            path="/qcs/review-qcs-product/testing/detail/:id/form/:formId"
            exact
            component={OperatorTestingFormDetail}
            title="Form Detail"
            menu={all}
          />
          <PrivateRoute
            path="/qcs/review-qcs-product/testing/detail/:type(test|view|correction)/:id"
            exact
            component={OperatorTestingFormDetail}
            title="Detail Test"
            menu={all}
          />

          <PrivateRoute
            path="/qcs/report/em"
            exact
            component={ReportEM}
            title="Environmental Monitoring"
            breadcrumb={<BreadcrumbReportEM />}
            menu={all}
          />
          <PrivateRoute
            path="/qcs/qc/sampling/raw-material"
            exact
            component={ListSamplingPlanRawMaterial}
            title="List Sample"
            breadcrumb={<BreadcrumbSamplingRM listPage />}
            menu={all}
          />
          <PrivateRoute
            path="/qcs/qc/sampling/raw-material/detail/:id"
            exact
            component={FormSamplingPlanRawMaterial}
            title="Sampling Plan"
            breadcrumb={<BreadcrumbSamplingRM text="Sampling Plan" />}
            menu={all}
          />
          <PrivateRoute
            path="/qcs/qc/sample-management/production"
            exact
            component={ListSampleQC}
            title="List Sample"
            menu={all}
          />
          <PrivateRoute
            path="/qcs/qc/sample-management/production/list-detail/:id/:batch"
            exact
            component={DetailListSampleQC}
            title="List Sample"
            menu={all}
          />
          <PrivateRoute
            path="/qcs/qc/sample-management/production/create"
            exact
            component={FormCreateSample}
            title="Add Sample"
            menu={all}
          />
          <PrivateRoute
            path="/qcs/qc/sample-management/master"
            exact
            component={ListSampleMaster}
            title="Sample - Master"
            menu={all}
          />
          <PrivateRoute
            path="/qcs/qc/sample-management/transaction"
            exact
            component={ListSampleTransaction}
            title="Sample - Transaction"
            menu={all}
          />
          <PrivateRoute
            path="/qcs/qc/sample-management/onhand"
            exact
            component={ListSampleOnHand}
            title="Sample - OnHand"
            menu={all}
          />
          <PrivateRoute
            path="/qcs/qc/sample-management/add"
            exact
            component={FormSampleManagement}
            title="Add Sample"
            menu={all}
          />
          <PrivateRoute
            path="/qcs/qc/template-qc"
            exact
            component={ListTemplateTesting}
            title="Template QC"
          />
          <PrivateRoute
            path="/qcs/qc/template-qc/:id"
            exact
            component={FormTemplateTesting}
            title="Form"
          />
          <PrivateRoute
            path="/qcs/qc/template-qc/review/:id"
            exact
            component={FormTemplateTesting}
            title="Review Form Template"
            menu={all}
          />
          <PrivateRoute
            path="/qcs/pspm"
            exact
            component={ListQCRequestRawMaterial}
            title="Request"
            breadcrumb={<BreadcrumbPSPM />}
            menu={all}
          />
          <PrivateRoute
            path="/qcs/pspm/create"
            exact
            component={FormQCResquestRawMaterial}
            title="Request"
            breadcrumb={
              <BreadcrumbPSPM page="Create Request" to="/qcs/pspm/create" />
            }
            menu={all}
          />
          <PrivateRoute
            path="/qcs/pspm/detail/:id"
            exact
            component={FormQCResquestRawMaterial}
            title="Detail Request"
            breadcrumb={<BreadcrumbPSPM page="Detail Request" />}
            menu={all}
          />
          <PrivateRoute
            path="/qcs/qc/request/raw-material"
            exact
            component={ListQCRequestRawMaterial}
            title="List Sample"
            breadcrumb={<BreadcrumbRequestRM />}
            menu={all}
          />
          <PrivateRoute
            path="/qcs/qc/request/raw-material/create"
            exact
            component={FormQCResquestRawMaterial}
            title="Request"
            breadcrumb={<BreadcrumbRequestRM />}
            menu={all}
          />
          <PrivateRoute
            path="/qcs/qc/request/raw-material/detail/:id"
            exact
            component={FormQCResquestRawMaterial}
            title="Detail Request"
            breadcrumb={<BreadcrumbRequestRM detailPage />}
            menu={all}
          />
          <PrivateRoute
            path="/qcs/qc/request/raw-material/sampling/:id"
            exact
            component={FormQCResquestSamplingRawMaterial}
            title="Sampling Plan"
            menu={all}
          />

          <PrivateRoute
            path="/qcs/deviation"
            exact
            component={ListDeviation}
            title="Deviation"
            breadcrumb={<BreadcrumbDeviationQCS />}
            menu={all}
          />

          <PrivateRoute
            path="/qcs/qc/request/water-monitoring"
            exact
            component={ListRequestWaterMonitoring}
            title="Sampling Request"
            menu={all}
          />

          <PrivateRoute
            path="/qcs/qc/request/water-monitoring/create"
            exact
            component={FormRequestWaterMonitoring}
            title="Request"
            menu={all}
          />

          <PrivateRoute
            path={[
              "/qcs/qc/request/water-monitoring/create",
              "/qcs/qc/request/water-monitoring/:typeOfForm/:requestId",
            ]}
            exact
            component={FormRequestWaterMonitoring}
            title="Request"
            menu={all}
          />

          <PrivateRoute
            path="/qcs/qc/sampling/raw-material"
            exact
            component={ListSamplingRawMaterial}
            title="List Sample"
            menu={all}
          />

          <PrivateRoute
            path="/qcs/qc/sampling/raw-material/create/:id"
            exact
            component={FormSamplingRawMaterial}
            title="Sampling"
            menu={all}
          />

          <PrivateRoute
            path="/qcs/qc/sample-management/raw-material"
            exact
            component={ListSampleManagementRawMaterial}
            title="Sample Management"
            menu={all}
          />

          <PrivateRoute
            path="/qcs/qc/sample-management/raw-material/master/:id"
            exact
            component={ListSampleManagementRawMaterialMaster}
            title="Sample Management"
            menu={all}
          />

          <PrivateRoute
            path="/qcs/qc/sample-management/raw-material/retained-sample/create"
            exact
            component={FormRetainedSample}
            title="Retained Sample"
            menu={all}
          />

          <PrivateRoute
            path="/qcs/qc/testing/raw-material"
            exact
            component={ListTestingRawMaterial}
            title="List Testing"
            menu={all}
            breadcrumb={<BreadcrumbTestingRM />}
          />

          <PrivateRoute
            path="/qcs/qc/testing/raw-material/create"
            exact
            component={FormTestRawMaterial}
            title="Create Test"
            menu={all}
            breadcrumb={
              <BreadcrumbTestingRM listPage={false} text="Create Test" />
            }
          />

          <PrivateRoute
            path="/qcs/qc/testing/raw-material/view/:id"
            exact
            component={FormTestRawMaterial}
            title="Detail Test"
            menu={all}
            breadcrumb={
              <BreadcrumbTestingRM listPage={false} text="Detail Test" />
            }
          />

          <PrivateRoute
            path="/qcs/qc/testing/raw-material/form/:id"
            exact
            component={FormStartTestRawMaterial}
            title="Detail Testing"
            breadcrumb={
              <BreadcrumbTestingRM listPage={false} text="Detail Page" />
            }
            menu={all}
          />

          <PrivateRoute
            path="/qcs/transfer/raw-material"
            exact
            component={ListTransferRawMaterial}
            title="List Transfer"
            menu={all}
          />

          <PrivateRoute
            path="/qcs/transfer/raw-material/send/:id"
            exact
            component={FormTransferRawMaterial}
            title="Send Sample"
            menu={all}
          />

          <PrivateRoute
            path="/qcs/transfer/raw-material/receive/:id"
            exact
            component={FormTransferRawMaterial}
            title="Receive Sample"
            menu={all}
          />

          <PrivateRoute
            path="/qcs/qc/review/raw-material"
            exact
            component={ListReviewRawMaterial}
            title="List Review"
            menu={all}
            breadcrumb={<BreadcrumbTestingRM />}
          />

          <PrivateRoute
            path="/qcs/qc/review/raw-material/view/:id"
            exact
            component={FormTestRawMaterial}
            title="Detail Test"
            menu={all}
            breadcrumb={
              <BreadcrumbTestingRM listPage={false} text="Detail Test" />
            }
          />

          <PrivateRoute
            path="/qcs/qc/review/raw-material/form/:id"
            exact
            component={FormStartTestRawMaterial}
            title="Testing Process"
            breadcrumb={
              <BreadcrumbTestingRM listPage={false} text="Detail Page" />
            }
            menu={all}
          />

          <PrivateRoute
            path="/qcs/qc/review/raw-material/detail/:id"
            exact
            component={DetailReviewMenu}
            title="Detail"
            breadcrumb={<BreadcrumbTestingRM listPage={false} text="Review" />}
            menu={all}
          />

          <PrivateRoute
            path="/qcs/qc/deviation/raw-material"
            exact
            component={ListDeviationRawMaterial}
            title="List Deviation"
            menu={all}
            breadcrumb={<BreadcrumbTestingRM />}
          />

          <PrivateRoute
            path="/qcs/qc/deviation/raw-material/view/:id"
            exact
            component={ViewDeviationsRawMaterial}
            title="Detail Deviation"
            menu={all}
            breadcrumb={<BreadcrumbTestingRM />}
          />

          <PrivateRoute
            path="/qcs/to-do/raw-material"
            exact
            component={ListTodoRawMaterial}
            title="List To Do"
            menu={all}
            breadcrumb={<BreadcrumbTestingRM />}
          />

          <PrivateRoute
            path="/qcs/to-do/raw-material/view/:id"
            exact
            component={DetailTodoRawMaterial}
            title="Detail To Do"
            menu={all}
            breadcrumb={
              <BreadcrumbTestingRM listPage={false} text="Detail Test" />
            }
          />

          <PrivateRoute
            path="/qcs/qc/deviation/product"
            exact
            component={ListDeviationProduct}
            title="List Deviation"
            menu={all}
            breadcrumb={<BreadcrumbTestingProduct />}
          />

          <PrivateRoute
            path="/qcs/qc/deviation/product/view/:id"
            exact
            component={DetailDeviationProduct}
            title="Detail Deviation"
            menu={all}
            breadcrumb={<BreadcrumbTestingProduct />}
          />

          {/* ====== QAS Routes ====== */}
          <PrivateRoute
            path="/qas/to-do/qc"
            exact
            component={TodoQC}
            title="QC"
            breadcrumb={<BreadcrumbTodoQC />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/to-do/schedule"
            exact
            component={TodoSchedule}
            title="Schedule"
            breadcrumb={<BreadcrumbTodoSchedule />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/to-do/schedule/:scheduleId"
            exact
            component={TodoScheduleDetail}
            title="Schedule"
            breadcrumb={<BreadcrumbTodoSchedule />}
            menu={all}
          />
          <PrivateRoute
            path="/qcs/to-do/water-monitoring"
            exact
            component={TodoWaterMonitoring}
            title="Water Monitoring"
            // breadcrumb={<BreadcrumbTodoSchedule />}
            menu={all}
          />

          <PrivateRoute
            path="/qcs/to-do/water-monitoring/:typeOfForm/:id"
            exact
            component={TodoWaterMonitoringDetail}
            title="Water Monitoring"
            // breadcrumb={<BreadcrumbTodoSchedule />}
            menu={all}
          />
          <PrivateRoute
            path="/qcs/water-monitoring/schedule"
            exact
            component={Scheduled}
            title="Schedule"
            breadcrumb={<BreadcrumbScheduled />}
            menu={all}
          />
          <PrivateRoute
            path={[
              "/qcs/water-monitoring/schedule/create",
              "/qcs/water-monitoring/schedule/:typeOfForm/:scheduleId",
            ]}
            exact
            component={ScheduledForm}
            title="Schedule"
            breadcrumb={<BreadcrumbScheduled />}
            menu={all}
          />
          <PrivateRoute
            path="/qcs/water-monitoring/schedule/create"
            exact
            component={ScheduledForm}
            title="Schedule"
            breadcrumb={<BreadcrumbScheduled />}
            menu={all}
          />
          <PrivateRoute
            path="/qcs/water-monitoring/depryogenation-sterilization"
            exact
            component={DepryogenationSterilization}
            title="Depryogenation / Sterilization"
            breadcrumb={<BreadcrumbDepryogenationSterilization />}
            menu={all}
          />
          <PrivateRoute
            path={[
              "/qcs/water-monitoring/depryogenation-sterilization/create",
              "/qcs/water-monitoring/depryogenation-sterilization/:typeOfForm/:depryogenationSterilizationId",
            ]}
            exact
            component={DepryogenationSterilizationForm}
            title="Depryogenation / Sterilization"
            breadcrumb={<BreadcrumbDepryogenationSterilization />}
            menu={all}
          />
          <PrivateRoute
            path="/qcs/to-do/qc"
            exact
            component={TodoQC}
            title="QC"
            breadcrumb={<BreadcrumbTodoQC />}
            menu={all}
          />

          <PrivateRoute
            path="/qas/to-do/qc/:requestId"
            exact
            component={DetailQCTodo}
            title="QC"
            breadcrumb={<BreadcrumbTodoQC />}
            menu={all}
          />

          <PrivateRoute
            path="/qas/release/qc"
            exact
            component={ReleaseQC}
            title="QC"
            breadcrumb={<BreadcrumbReleaseQC />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/release/qc/:requestId"
            exact
            component={DetailQCRelease}
            title="QC"
            breadcrumb={<BreadcrumbReleaseQC />}
            menu={all}
          />

          <PrivateRoute
            path="/qas/monitoring/qc"
            exact
            component={MonitoringQC}
            title="QC"
            breadcrumb={<BreadcrumbMonitoringQC />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/monitoring/qc/:requestId"
            exact
            component={DetailQCMonitoring}
            title="QC"
            breadcrumb={<BreadcrumbMonitoringQC />}
            menu={all}
          />

          <PrivateRoute
            path="/qas/review-master/pending"
            exact
            component={Pending}
            title="Pending"
            breadcrumb={<BreadcrumbReviewPending />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/review-master/pending/grade-room/:documentCode"
            exact
            component={DetailReviewGradeRoom}
            title="Pending"
            breadcrumb={<BreadcrumbReviewPending />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/review-master/pending/room/:documentCode"
            exact
            component={DetailReviewRoom}
            title="Pending"
            breadcrumb={<BreadcrumbReviewPending />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/review-master/pending/facility/:documentCode"
            exact
            component={DetailReviewFacility}
            title="Pending"
            breadcrumb={<BreadcrumbReviewPending />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/review-master/pending/equipment/:documentCode"
            exact
            component={DetailReviewEquipment}
            title="Pending"
            breadcrumb={<BreadcrumbReviewPending />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/review-master/pending/microba/:documentCode"
            exact
            component={DetailReviewMicroba}
            title="Pending"
            breadcrumb={<BreadcrumbReviewPending />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/review-master/pending/production/:documentCode/:typeForm/:productionId"
            exact
            component={FormHierarchy}
            title="Pending"
            breadcrumb={<BreadcrumbReviewPending />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/review-master/pending/auditor/:documentCode"
            exact
            component={DetailReviewAuditor}
            title="Pending"
            breadcrumb={<BreadcrumbReviewPending />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/review-master/pending/production/:documentCode/:typeForm/:productionId/procedure/:productionProcessId"
            exact
            component={FormProcedure}
            title="Pending"
            breadcrumb={<BreadcrumbReviewPending />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/review-master/pending/item/:documentCode/:typeForm/:itemId"
            exact
            component={FormItem}
            title="Pending"
            breadcrumb={<BreadcrumbReviewPending />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/review-master/pending/test-type/:documentCode/:typeOfForm/:testTypeId"
            exact
            component={FormTestType}
            title="Pending"
            breadcrumb={<BreadcrumbReviewPending />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/review-master/pending/test-type/:documentCode/:typeOfForm/:testTypeId/step/:stepId"
            exact
            component={FormTestTypeProcedure}
            title="Pending"
            breadcrumb={<BreadcrumbReviewPending />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/review-master/pending/kitaudit/:documentCode"
            exact
            component={DetailReviewKitaudit}
            title="Pending"
            breadcrumb={<BreadcrumbReviewPending />}
            menu={all}
          />

          <PrivateRoute
            path="/qas/review-master/correction"
            exact
            component={Correction}
            title="Correction"
            breadcrumb={<BreadcrumbReviewCorrection />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/review-master/correction/grade-room/:documentCode"
            exact
            component={DetailCorrectionReviewGradeRoom}
            title="Correction"
            breadcrumb={<BreadcrumbReviewCorrection />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/review-master/correction/room/:documentCode"
            exact
            component={DetailCorrectionReviewRoom}
            title="Correction"
            breadcrumb={<BreadcrumbReviewCorrection />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/review-master/correction/facility/:documentCode"
            exact
            component={DetailCorrectionReviewFacility}
            title="Correction"
            breadcrumb={<BreadcrumbReviewCorrection />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/review-master/correction/equipment/:documentCode"
            exact
            component={DetailCorrectionReviewEquipment}
            title="Correction"
            breadcrumb={<BreadcrumbReviewCorrection />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/review-master/correction/microba/:documentCode"
            exact
            component={DetailCorrectionReviewMicroba}
            title="Correction"
            breadcrumb={<BreadcrumbReviewCorrection />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/review-master/correction/production/:documentCode/:typeForm/:productionId"
            exact
            component={FormHierarchy}
            title="Correction"
            breadcrumb={<BreadcrumbReviewCorrection />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/review-master/correction/production/:documentCode/:typeForm/:productionId/procedure/:productionProcessId"
            exact
            component={FormProcedure}
            title="Correction"
            breadcrumb={<BreadcrumbReviewCorrection />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/review-master/correction/item/:documentCode/:typeForm/:itemId"
            exact
            component={FormItem}
            title="Correction"
            breadcrumb={<BreadcrumbReviewCorrection />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/review-master/correction/test-type/:documentCode/:typeOfForm/:testTypeId"
            exact
            component={FormTestType}
            title="Correction"
            breadcrumb={<BreadcrumbReviewCorrection />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/review-master/correction/test-type/:documentCode/:typeOfForm/:testTypeId/step/:stepId"
            exact
            component={FormTestTypeProcedure}
            title="Correction"
            breadcrumb={<BreadcrumbReviewCorrection />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/review-master/correction/auditor/:documentCode"
            exact
            component={DetailCorrectionAuditor}
            title="Correction"
            breadcrumb={<BreadcrumbReviewCorrection />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/review-master/correction/kitaudit/:documentCode"
            exact
            component={DetailCorrectionKitaudit}
            title="Correction"
            breadcrumb={<BreadcrumbReviewCorrection />}
            menu={all}
          />

          <PrivateRoute
            path="/qas/review-master/complete"
            exact
            component={Complete}
            title="Complete"
            breadcrumb={<BreadcrumbReviewComplete />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/review-master/complete/grade-room/:documentCode"
            exact
            component={DetailCompleteReviewGradeRoom}
            title="Complete"
            breadcrumb={<BreadcrumbReviewComplete />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/review-master/complete/room/:documentCode"
            exact
            component={DetailCompleteReviewRoom}
            title="Complete"
            breadcrumb={<BreadcrumbReviewComplete />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/review-master/complete/facility/:documentCode"
            exact
            component={DetailCompleteReviewFacility}
            title="Complete"
            breadcrumb={<BreadcrumbReviewComplete />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/review-master/complete/equipment/:documentCode"
            exact
            component={DetailCompleteReviewEquipment}
            title="Complete"
            breadcrumb={<BreadcrumbReviewComplete />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/review-master/complete/microba/:documentCode"
            exact
            component={DetailCompleteReviewMicroba}
            title="Complete"
            breadcrumb={<BreadcrumbReviewComplete />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/review-master/complete/auditor/:documentCode"
            exact
            component={DetailCompleteReviewAuditor}
            title="Complete"
            breadcrumb={<BreadcrumbReviewComplete />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/review-master/complete/kitaudit/:documentCode"
            exact
            component={DetailCompleteReviewKitaudit}
            title="Complete"
            breadcrumb={<BreadcrumbReviewComplete />}
            menu={all}
          />

          <PrivateRoute
            path="/qas/review-master/complete/production/:documentCode/:typeForm/:productionId"
            exact
            component={FormHierarchy}
            title="Complete"
            breadcrumb={<BreadcrumbReviewComplete />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/review-master/complete/production/:documentCode/:typeForm/:productionId/procedure/:productionProcessId"
            exact
            component={FormProcedure}
            title="Complete"
            breadcrumb={<BreadcrumbReviewComplete />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/review-master/complete/item/:documentCode/:typeForm/:itemId"
            exact
            component={FormItem}
            title="Complete"
            breadcrumb={<BreadcrumbReviewComplete />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/review-master/complete/test-type/:documentCode/:typeOfForm/:testTypeId"
            exact
            component={FormTestType}
            title="Complete"
            breadcrumb={<BreadcrumbReviewComplete />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/review-master/complete/test-type/:documentCode/:typeOfForm/:testTypeId/step/:stepId"
            exact
            component={FormTestTypeProcedure}
            title="Complete"
            breadcrumb={<BreadcrumbReviewComplete />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/master/organization"
            exacts
            component={Organization}
            title="Organization"
            breadcrumb={<BreadcrumbOrganization />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/master/grade-room"
            exact
            component={GradeRoom}
            title="Grade Room"
            breadcrumb={<BreadcrumbGradeRoom />}
            menu={all}
          />
          <PrivateRoute
            path={[
              "/qas/master/grade-room/create",
              "/qas/master/grade-room/detail/:gradeRoomId",
              "/qas/master/grade-room/update/:gradeRoomId",
            ]}
            exact
            component={AddGradeRoom}
            title="Grade Room"
            breadcrumb={<BreadcrumbGradeRoom />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/master/building"
            exact
            component={Building}
            title="Building"
            breadcrumb={<BreadcrumbBuilding />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/master/facility"
            exact
            component={Facility}
            title="Facility"
            breadcrumb={<BreadcrumbFacility />}
            menu={all}
          />
          <PrivateRoute
            path={[
              "/qas/master/facility/create",
              "/qas/master/facility/detail/:facilityId",
              "/qas/master/facility/update/:facilityId",
            ]}
            exact
            component={AddFacility}
            title="Facility"
            breadcrumb={<BreadcrumbFacility />}
            menu={all}
          />

          <PrivateRoute
            path="/qas/master/room"
            exact
            component={Room}
            title="Room"
            breadcrumb={<BreadcrumbRoom />}
            menu={all}
          />
          <PrivateRoute
            path={[
              "/qas/master/room/create",
              "/qas/master/room/detail/:roomId",
              "/qas/master/room/update/:roomId",
            ]}
            exact
            component={FormRoom}
            title="Room"
            breadcrumb={<BreadcrumbRoom />}
            menu={all}
          />

          <PrivateRoute
            path="/qas/master/equipment"
            exact
            component={Equipment}
            title="Equipment"
            breadcrumb={<BreadcrumbEquipment />}
            menu={all}
          />
          <PrivateRoute
            path={[
              "/qas/master/equipment/create",
              "/qas/master/equipment/detail/:toolId",
              "/qas/master/equipment/update/:toolId",
            ]}
            exact
            component={FormEquipment}
            title="Equipment"
            breadcrumb={<BreadcrumbEquipment />}
            menu={all}
          />

          <PrivateRoute
            path="/qas/master/test-type"
            exact
            component={TestType}
            title="Test Type"
            breadcrumb={<BreadcrumbTestType />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/master/test-type/create"
            exact
            component={FormTestType}
            title="Test Type"
            breadcrumb={<BreadcrumbTestType />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/master/test-type/:typeOfForm/:testTypeId"
            exact
            component={FormTestType}
            title="Test Type"
            breadcrumb={<BreadcrumbDetailTestType />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/master/test-type/:typeOfForm/:testTypeId/step/:stepId"
            exact
            component={FormTestTypeProcedure}
            title="Test Type"
            breadcrumb={<BreadcrumbTestTypeProcedure />}
            menu={all}
          />

          <PrivateRoute
            path="/qas/master/item"
            exact
            component={Item}
            title="Item"
            breadcrumb={<BreadcrumbMasterItem />}
            menu={all}
          />
          <PrivateRoute
            path={[
              "/qas/master/item/create",
              "/qas/master/item/update/:itemId",
              "/qas/master/item/detail/:itemId",
            ]}
            exact
            component={FormItem}
            title="Item"
            breadcrumb={<BreadcrumbMasterItem />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/master/auditor"
            exact
            component={Auditor}
            title="Auditor"
            breadcrumb={<BreadcrumbAuditor />}
            menu={all}
          />
          <PrivateRoute
            path={[
              "/qas/master/auditor/create",
              "/qas/master/auditor/update/:auditorId",
              "/qas/master/auditor/detail/:auditorId",
            ]}
            exact
            component={FormAuditor}
            title="Auditor"
            breadcrumb={<BreadcrumbAuditor />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/master/kitaudit"
            exact
            component={Kitaudit}
            title="Kitaudit"
            breadcrumb={<BreadcrumbKitaudit />}
            menu={all}
          />
          <PrivateRoute
            path={[
              "/qas/master/kitaudit/create",
              "/qas/master/kitaudit/update/:kitauditId",
              "/qas/master/kitaudit/detail/:kitauditId",
            ]}
            exact
            component={FormKitaudit}
            title="Kitaudit"
            breadcrumb={<BreadcrumbKitaudit />}
            menu={all}
          />

          <PrivateRoute
            path="/qas/master/production"
            exact
            component={Production}
            title="Production"
            breadcrumb={<BreadCrumbProduction />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/master/production/hierarchy-process/:typeForm/:productionId"
            exact
            component={FormHierarchy}
            title="Production"
            breadcrumb={<BreadCrumbHierarchy />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/master/production/hierarchy-process/:typeForm/:productionId/procedure/:productionProcessId"
            exact
            component={FormProcedure}
            title="Production"
            breadcrumb={<BreadCrumbCreateProcedure props />}
            menu={all}
          />

          <PrivateRoute
            path="/qas/master/personnel"
            exact
            component={Personnel}
            title="Personnel"
            breadcrumb={<BreadcrumbPersonnel />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/master/personnel/:typeOfForm/:personnelId"
            exact
            component={DetailPersonnel}
            title="Personnel"
            breadcrumb={<BreadcrumbPersonnel />}
            menu={all}
          />

          <PrivateRoute
            path="/qas/master/microba"
            exact
            component={Microba}
            title="Microba"
            breadcrumb={<BreadcrumbMicroba />}
            menu={all}
          />
          <PrivateRoute
            path={[
              "/qas/master/microba/create",
              "/qas/master/microba/update/:microbaId",
              "/qas/master/microba/detail/:microbaId",
            ]}
            exact
            component={FormMicroba}
            title="Microba"
            breadcrumb={<BreadcrumbMicroba />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/master/loop"
            exact
            component={Loop}
            title="Loop"
            breadcrumb={<BreadcrumbMasterLoop />}
            menu={all}
          />
          <PrivateRoute
            path={[
              "/qas/master/loop/create",
              "/qas/master/loop/:typeOfForm/:loopId",
            ]}
            exact
            component={FormLoop}
            title="Loop"
            breadcrumb={<BreadcrumbMasterLoop />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/master/water"
            exact
            component={Water}
            title="Water Type"
            breadcrumb={<BreadcrumbMasterWater />}
            menu={all}
          />
          <PrivateRoute
            path={[
              "/qas/master/water/create",
              "/qas/master/water/:typeOfForm/:waterId",
            ]}
            exact
            component={FormWater}
            title="Water Type"
            breadcrumb={<BreadcrumbMasterWater />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/master/use-point"
            exact
            component={UsePoint}
            title="Use Point"
            breadcrumb={<BreadcrumbMasterUsePoint />}
            menu={all}
          />
          <PrivateRoute
            path={[
              "/qas/master/use-point/create",
              "/qas/master/use-point/:typeOfForm/:usePointId",
            ]}
            exact
            component={FormUsePoint}
            title="Use Point"
            breadcrumb={<BreadcrumbMasterUsePoint />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/release/media"
            exact
            component={ReleaseMedia}
            title="Media"
            breadcrumb={<BreadcrumbReleaseMedia />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/release/production"
            exact
            component={ReleaseProduction}
            title="Release"
            breadcrumb={<BreadcrumbReleaseProduction />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/release/production/:requestId"
            exact
            component={DetailReleaseProduction}
            title="Detail Release"
            breadcrumb={<BreadcrumbDetailReleaseProduction />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/configuration/equipment-group"
            exact
            component={EquipmentGroup}
            title="Equipment Group"
            menu={all}
          />
          <PrivateRoute
            path="/qas/configuration/equipment-activity"
            exact
            component={Activity}
            title="Activity"
            menu={all}
          />
          <PrivateRoute
            path="/qas/configurationmeter"
            exact
            component={TestParameter}
            title="Test Parameter"
            menu={all}
          />
          <PrivateRoute
            path="/qas/configuration/uom"
            exact
            component={UoM}
            title="UoM"
            menu={all}
          />
          <PrivateRoute
            path="/qas/configuration/presentation"
            exact
            component={Presentation}
            title="Presentation"
            menu={all}
          />
          <PrivateRoute
            path="/qas/configuration/dosage-form"
            exact
            component={DosageForm}
            title="Dosage Form"
            menu={all}
          />
          <PrivateRoute
            path="/qas/configuration/auditor-external"
            exact
            component={AuditorExternal}
            title="Auditor External"
            menu={all}
          />
          <PrivateRoute
            path="/qas/configuration/job-description"
            exact
            component={JobDescription}
            title="Job Description"
            menu={all}
          />
          <PrivateRoute
            path="/qas/configuration/form"
            exact
            component={FormConfiguration}
            title="Form"
            menu={all}
            breadcrumb={<BreadcrumbFormConfiguration />}
          />
          <PrivateRoute
            path="/qas/configuration/manufacturer"
            exact
            component={Manufacturer}
            title="Manufacturer"
            menu={all}
          />
          <PrivateRoute
            path="/qas/configuration/procedure-sampling"
            exact
            component={ProcedureSampling}
            title="ProcedureSampling"
            menu={all}
          />

          {/* ====== PES Routes ====== */}

          <PrivateRoute
            path={["/pes/production/temporary-change/:formType/:id?"]}
            exact
            component={FormTc}
            title="Temporary Change"
            menu={all}
            breadcrumb={<BreadCrumbFormTc />}
          />

          <PrivateRoute
            path="/pes/configuration/presence"
            exact
            component={Presence}
            title="Presence List"
            menu={all}
          />
          <PrivateRoute
            path="/pes/production/schedule"
            exact
            component={ProductionPES}
            title="Schedule"
            menu={all}
            breadcrumb={<BreadCrumbProductionPES />}
          />
          <PrivateRoute
            path="/pes/production/execution"
            exact
            component={ProductionExecutionListPES}
            title="Production"
            menu={all}
            breadcrumb={<BreadcrumbProductionExecutionListPES />}
          />
          <PrivateRoute
            path="/pes/production/execution/:id"
            exact
            component={ProductionExecutionPES}
            title={"Production Execution"}
            menu={all}
            breadcrumb={<BreadCrumbProductionExecutionPES />}
          />
          <PrivateRoute
            path="/pes/production/review"
            exact
            component={ProductionReviewPES}
            title={"Review"}
            menu={all}
            breadcrumb={<BreadcrumbProductionReviewPes />}
          />
          <PrivateRoute
            path="/qas/to-do/production"
            exact
            component={ProductionReviewPES}
            title={"Review"}
            menu={all}
            breadcrumb={<BreadcrumbTodoProductionQas />}
          />
          <PrivateRoute
            path="/qas/to-do/production/:id"
            exact
            component={ProductionReviewDetailPES}
            title={"Production Execution"}
            menu={all}
            breadcrumb={<BreadcrumbTodoProductionQas />}
          />
          <PrivateRoute
            path="/pes/production/deviation"
            exact
            component={DeviationPES}
            title={"Deviation"}
            menu={all}
            breadcrumb={<BreadcrumbDeviationPes />}
          />
          <PrivateRoute
            path="/pes/production/review/:id"
            exact
            component={ProductionReviewDetailPES}
            title={"Production Execution"}
            menu={all}
            breadcrumb={<BreadcrumbProductionReviewDetailPes />}
          />
          <PrivateRoute
            path="/pes/production/deviation/:id"
            exact
            component={DeviationProcessPES}
            title={"Deviation"}
            menu={all}
            breadcrumb={<BreadcrumbDeviationDetailPes />}
          />
          <PrivateRoute
            path="/pes/production/deviation/parameter/:id"
            exact
            component={DeviationDetailPES}
            title={"Deviation"}
            menu={all}
            breadcrumb={<BreadcrumbDeviationParameterDetailPes />}
          />
          <PrivateRoute
            path="/pes/production/temporary-change"
            exact
            component={TemporaryChangePES}
            title="Temporary Change"
            menu={all}
            breadcrumb={<BreadCrumbProductionTemporaryChangePES />}
          />
          <PrivateRoute
            path="/qas/product-registration/registration"
            exact
            component={Registration}
            title="Registration"
            breadcrumb={<BreadcrumbProductRegistration />}
            menu={all}
          />
          <PrivateRoute
            path={[
              "/qas/product-registration/registration/create",
              "/qas/product-registration/registration/detail/:registrationId",
              "/qas/product-registration/registration/update/:registrationId",
            ]}
            exact
            component={FormRegistration}
            title="Registration"
            breadcrumb={<BreadcrumbProductRegistration />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/product-registration/certification"
            exact
            component={Certification}
            title="Facility Evaluation"
            breadcrumb={<BreadcrumbCertification />}
            menu={all}
          />
          <PrivateRoute
            path={[
              "/qas/product-registration/certification/create",
              "/qas/product-registration/certification/detail/:certificationId",
              "/qas/product-registration/certification/update/:certificationId",
            ]}
            exact
            component={FormCertification}
            title="Certification"
            breadcrumb={<BreadcrumbCertification />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/product-registration/sas"
            exact
            component={SasRegistration}
            title="SAS"
            breadcrumb={<BreadcrumbSas />}
            menu={all}
          />
          <PrivateRoute
            path={[
              "/qas/product-registration/sas/create",
              "/qas/product-registration/sas/detail/:certificationId",
              "/qas/product-registration/sas/update/:certificationId",
            ]}
            exact
            component={FormSasRegistration}
            title="SAS"
            breadcrumb={<BreadcrumbSas />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/product-registration/post-approval"
            exact
            component={PostApproval}
            title="Post Approval"
            breadcrumb={<BreadcrumbPostApproval />}
            menu={all}
          />
          <PrivateRoute
            path={[
              "/qas/product-registration/post-approval/create",
              "/qas/product-registration/post-approval/detail/:certificationId",
              "/qas/product-registration/post-approval/update/:certificationId",
            ]}
            exact
            component={FormPostApproval}
            title="Post Approval"
            breadcrumb={<BreadcrumbPostApproval />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/master/form-schedule"
            exact
            component={ListForm}
            title="Form"
            breadcrumb={<BreadcrumbFormSchedule />}
            menu={all}
          />
          <PrivateRoute
            path={[
              "/qas/master/form-schedule/create",
              "/qas/master/form-schedule/update/:formId",
              "/qas/master/form-schedule/detail/:formId",
            ]}
            exact
            component={FormSchedule}
            title="Form"
            breadcrumb={<BreadcrumbFormSchedule />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/master/authority"
            exact
            component={Authority}
            title="Authority"
            breadcrumb={<BreadcrumbAuthority />}
            menu={all}
          />
          <PrivateRoute
            path="/qas/product-registration/report-custom"
            exact
            component={ReportCustom}
            title="Report Custom"
            breadcrumb={<BreadcrumbReportCustom />}
            menu={all}
          />

          {/* Audit Management Routes */}
          <PrivateRoute
            path={"/qas/auditmanagement/auditscheduler"}
            exact
            component={AuditSchedule}
            title={"Schedule"}
            menu={all}
            breadcrumb={
              <DynamicBreadCrumb
                data={[
                  {
                    id: 1,
                    title: "Schedule",
                    isActive: true,
                    link: "#",
                  },
                ]}
              />
            }
          />
          <PrivateRoute
            path={["/qas/auditmanagement/auditscheduler/create"]}
            exact
            component={FormAuditSchedule}
            title={"Create Schedule"}
            menu={all}
            breadcrumb={
              <DynamicBreadCrumb
                data={[
                  {
                    id: 1,
                    title: "Schedule",
                    isActive: false,
                    link: "/qas/auditmanagement/auditscheduler",
                  },
                  {
                    id: 2,
                    title: "Create Schedule",
                    isActive: true,
                    link: "#",
                  },
                ]}
              />
            }
          />
          <PrivateRoute
            path={"/qas/auditmanagement/auditscheduler/confirmation/:auditId"}
            exact
            component={FormAuditSchedule}
            title={"Confirmation Schedule"}
            menu={all}
            breadcrumb={
              <DynamicBreadCrumb
                data={[
                  {
                    id: 1,
                    title: "Schedule",
                    isActive: false,
                    link: "/qas/auditmanagement/auditscheduler",
                  },
                  {
                    id: 2,
                    title: "Confirmation Schedule",
                    isActive: true,
                    link: "#",
                  },
                ]}
              />
            }
          />
          <PrivateRoute
            path={["/qas/auditmanagement/auditscheduler/detail/:auditId"]}
            exact
            component={FormAuditSchedule}
            title={"Detail Schedule"}
            menu={all}
            breadcrumb={
              <DynamicBreadCrumb
                data={[
                  {
                    id: 1,
                    title: "Schedule",
                    isActive: false,
                    link: "/qas/auditmanagement/auditscheduler",
                  },
                  {
                    id: 2,
                    title: "Detail Schedule",
                    isActive: true,
                    link: "#",
                  },
                ]}
              />
            }
          />
          <PrivateRoute
            path={"/qas/auditmanagement/auditscheduler/edit/:auditId"}
            exact
            component={FormAuditSchedule}
            title={"Edit Schedule"}
            menu={all}
            breadcrumb={
              <DynamicBreadCrumb
                data={[
                  {
                    id: 1,
                    title: "Schedule",
                    isActive: false,
                    link: "/qas/auditmanagement/auditscheduler",
                  },
                  {
                    id: 2,
                    title: "Edit Schedule",
                    isActive: true,
                    link: "#",
                  },
                ]}
              />
            }
          />
          <PrivateRoute
            path={"/qas/auditmanagement/auditscheduler/correction/:auditId"}
            exact
            component={FormAuditSchedule}
            title={"Correction Schedule"}
            menu={all}
            breadcrumb={
              <DynamicBreadCrumb
                data={[
                  {
                    id: 1,
                    title: "Schedule",
                    isActive: false,
                    link: "/qas/auditmanagement/auditscheduler",
                  },
                  {
                    id: 2,
                    title: "Correction Schedule",
                    isActive: true,
                    link: "#",
                  },
                ]}
              />
            }
          />
          <PrivateRoute
            path={"/qas/auditmanagement/findingaudit"}
            exact
            component={FindingAudit}
            title={"Audit"}
            menu={all}
            breadcrumb={
              <DynamicBreadCrumb
                data={[
                  {
                    id: 1,
                    title: "Audit",
                    isActive: true,
                    link: "#",
                  },
                ]}
              />
            }
          />
          <PrivateRoute
            path={"/qas/auditmanagement/findingaudit"}
            exact
            component={FindingAudit}
            title={"Audit"}
            menu={all}
            breadcrumb={
              <DynamicBreadCrumb
                data={[
                  {
                    id: 1,
                    title: "Audit",
                    isActive: true,
                    link: "#",
                  },
                ]}
              />
            }
          />
          <PrivateRoute
            path={"/qas/auditmanagement/findingaudit/create"}
            exact
            component={FindingSheetDetail}
            title={"Audit"}
            menu={all}
            breadcrumb={
              <DynamicBreadCrumb
                data={[
                  {
                    id: 1,
                    title: "Audit",
                    isActive: false,
                    link: "/qas/auditmanagement/findingaudit",
                  },
                  {
                    id: 2,
                    title: "Finding Audit",
                    isActive: true,
                    link: "#",
                  },
                ]}
              />
            }
          />
          <PrivateRoute
            path={"/qas/auditmanagement/findingaudit/:auditId"}
            exact
            component={FormFindingSheet}
            title={"Finding Audit"}
            menu={all}
            breadcrumb={
              <DynamicBreadCrumb
                data={[
                  {
                    id: 1,
                    title: "Audit",
                    isActive: false,
                    link: "/qas/auditmanagement/findingaudit",
                  },
                  {
                    id: 2,
                    title: "Audit",
                    isActive: true,
                    link: "/qas/auditmanagement/findingaudit/:auditId",
                  },
                ]}
              />
            }
          />
          <PrivateRoute
            path="/pes/production"
            exact
            component={ProductionPES}
            title="Production List"
            menu={all}
            breadcrumb={<BreadCrumbProductionPES />}
          />
          <PrivateRoute
            path="/pes/production/execution/:id"
            exact
            component={ProductionExecutionPES}
            title={"Production Execution"}
            menu={all}
            breadcrumb={<BreadCrumbProductionExecutionPES />}
          />

          <PrivateRoute
            path="/qas/em-physical/room"
            exact
            component={EMPhysicalRoom}
            title="EM - Physical"
            menu={all}
          />
          <PrivateRoute
            path="/qas/em-physical/deviation"
            exact
            component={EMPhysicalDeviation}
            title="EM - Physical | Deviation"
            menu={all}
          />
          <PrivateRoute
            path="/qas/em-physical/deviation/detail/:roomId"
            exact
            component={EMPhysicalDeviationDetail}
            title="EM - Physical | Deviation"
            menu={all}
          />
          <PrivateRoute
            path="/qas/em-physical/monitoring"
            exact
            component={EMPhysicalMonitoring}
            title="EM - Physical"
            menu={all}
          />
          <PrivateRoute
            path="/qas/em-physical/monitoring/:roomId"
            exact
            component={EMPhysicalMonitoring}
            title="EM-Physical"
            breadcrumb={<BreadcrumbEMP />}
            menu={all}
          />

          <PrivateRoute
            path="/temporary-change"
            exact
            component={TemporaryChangePES}
            title="Temporary Change"
            menu={all}
            breadcrumb={<BreadCrumbProductionTemporaryChangePES />}
          />
          <PrivateRoute
            path={["/temporary-change/:formType/:id?"]}
            exact
            component={FormTc}
            title="Temporary Change"
            menu={all}
            breadcrumb={<BreadCrumbFormTc />}
          />

          <PublicRoute path="/error/403" component={Forbidden} />
          <Route path="/healthz" exact component={() => <p>ok</p>} />
          <Route path="*" component={NotFound} />
        </Switch>
      </Suspense>
    </Router>
  );
};

export default Routes;
