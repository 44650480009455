import qasAPIInstance from "../APIQAS";

export const GetListMedia = async () => {
	try {
		const data = await qasAPIInstance.get('/v1/ItemBatch/ListMedia')
		return data.data.data
	} catch (error) {
		console.log(error)
		return error
	}
}

export const GetListActiveMedia = async () => {
	try {
		const data = await qasAPIInstance.get('/v1/Item/ListActiveMedia')
		return data.data.data
	} catch (error) {
		console.log(error)
		return error
	}
}

export const InsertMedia = async (payload) => {
  try {
    const response = await qasAPIInstance.post('/v1/ItemBatch/Insert', payload)
    return response
  } catch (error) {
    return error
  }
}

export const UpdateMedia = async (payload) => {
  try {
    const response = await qasAPIInstance.post('/v1/ItemBatch/Update', payload)
    return response
  } catch (error) {
    return error
  }
}

export const ArchiveMedia = async (payload) => {
  try {
    const response = await qasAPIInstance.post('/v1/ItemBatch/Archive', payload)
    return response
  } catch (error) {
    return error
  }
}

export const ActivateMedia = async (payload) => {
  try {
    const response = await qasAPIInstance.post('/v1/ItemBatch/Activate', payload)
    return response
  } catch (error) {
    return error
  }
}
