import { Spinner, Flex } from "@chakra-ui/react"


const SpinnerOnCardDetail = () => {
  
  return <Flex alignItems='center' justifyContent='center' w='100%' className='content-wrapper-spinner-on-card-detail'>
  <Spinner
    thickness='5px'
    speed='0.65s'
    emptyColor='gray.200'
    color='blue.500'
    size='xl'
  />
</Flex>
} 

export default SpinnerOnCardDetail