import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

import {
  getEquipmentGroupList,
  insertEquipmentGroup,
  updateEquipmentGroup,
  deleteEquipmentGroup,
} from "services/actions/QAS/EquipmentGroup";
import ConfigurationList from "../components/ConfigurationList";
import EquipmentGroupForm from "./EquipmentGroupForm";
import { getNikUser } from "utils/User";
import { useHasAllowedAction } from "utils/User";
import { EQUIPMENT_GROUP_ACTIONS } from "./constants";

const EquipmentGroup = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [totalPage, setTotalPage] = useState(1);
  const [isFetchingData, setIsFetchingData] = useState(1);
  const [data, setData] = useState([]);
  const userNIK = useSelector(getNikUser);

  useEffect(() => {
    (async () => {
      await getEquipmentGroupListData();
    })();
  }, []);

  const columns = [
    {
      Header: "EQUIPMENT GROUP ID",
      accessor: "toolGroupId",
      minWidth: 250,
      width: 250,
    },
    {
      Header: "EQUIPMENT GROUP NAME",
      accessor: "toolGroupName",
      minWidth: 250,
      width: 250,
    },
  ];

  const getEquipmentGroupListData = async (
    page = 1,
    search = "",
    limit = 10
  ) => {
    setIsFetchingData(true);
    const res = await getEquipmentGroupList({ search, page, limit });
    if (res && res.data?.data && res.data?.data.length > 0) {
      const resData = res.data.data;
      const metaPages = res.data.meta?.totalPages
        ? res.data.meta?.totalPages
        : 1;

      setData(resData);
      setTotalPage(metaPages);
      setIsFetchingData(false);
      return;
    }
    setData([]);
    setIsFetchingData(false);
  };

  const handleChangePage = (nextPage) => {
    setCurrentPage(nextPage);
    getEquipmentGroupListData(nextPage, searchValue);
  };

  const handleSearch = (value) => {
    getEquipmentGroupListData(1, value);
    setSearchValue(value);
    setCurrentPage(1);
    setTotalPage(1);
  };

  const handleDelete = async (deletedData) => {
    const res = await deleteEquipmentGroup({
      id: deletedData.id,
      updateBy: userNIK,
    });

    if (res.data.statusCode !== 200) {
      return toast.error(res.data.message);
    }

    setData((prev) => prev.filter((item) => item.id !== res.data.data[0].id));
  };

  const handleSubmit = async (type, formData) => {
    if (type === "create") {
      const res = await insertEquipmentGroup({
        ...formData,
        createdBy: userNIK,
      });

      if (res.data.statusCode !== 200) {
        return toast.error(res.data.message);
      }

      setData((prev) => [...(res?.data?.data ?? []), ...prev.slice(0, 9)]);
      return res.data;
    }

    if (type === "update") {
      const res = await updateEquipmentGroup({
        ...formData,
        createdBy: userNIK,
      });

      if (res.data.statusCode !== 200) {
        return toast.error(res.data.message);
      }

      const updatedData = res?.data?.data?.[0];
      setData((prev) =>
        prev.map((item) => (item.id === updatedData.id ? updatedData : item))
      );
      return res.data;
    }
  };

  return (
    <ConfigurationList
      columns={columns}
      currentPage={currentPage}
      data={data}
      isLoading={isFetchingData}
      totalPage={totalPage}
      formTitle="Equipment Group"
      handleChangePage={handleChangePage}
      handleSearch={handleSearch}
      onDelete={handleDelete}
      allowedAction={{
        create: useHasAllowedAction(EQUIPMENT_GROUP_ACTIONS.CREATE),
        edit: useHasAllowedAction(EQUIPMENT_GROUP_ACTIONS.EDIT),
        delete: useHasAllowedAction(EQUIPMENT_GROUP_ACTIONS.DELETE),
      }}
      formComponent={({ openedData, isEdit, onClose }) => (
        <EquipmentGroupForm
          formData={openedData}
          onClose={onClose}
          handleSubmit={handleSubmit.bind(this, isEdit ? "update" : "create")}
        />
      )}
    />
  );
};

export default EquipmentGroup;
