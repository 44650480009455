import { doc, setDoc, updateDoc } from "firebase/firestore";
import { TestParameter } from "./ConstantGlobal";
import { firestore } from "config/firebase";
import jwt_decode from "jwt-decode";
import imageCompression from "browser-image-compression";
import { getAuth, deleteUser, signInAnonymously } from "firebase/auth";
import { store } from "store";
import { RefreshToken } from "services/actions/Auth";
import moment from "moment";
import { Cookies } from "react-cookie";
import { REFRESH_TOKEN, SET_EXP_TOKEN } from "store/actions/Auth";

export const ConvertDateToString = (date, format = "yyyy-mm-dd") => {
  try {
    let today = "";
    const dd = String(date.getDate()).padStart(2, "0");
    const mm = String(date.getMonth() + 1).padStart(2, "0");
    const yyyy = date.getFullYear();

    if (format.toLowerCase() === "dd-mm-yyyy") today = `${dd}-${mm}-${yyyy}`;
    else today = `${yyyy}-${mm}-${dd}`;

    return today;
  } catch (error) {
    console.error(error.message);
  }
};

export const convertKeysToLowercase = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map((item) => convertKeysToLowercase(item));
  }

  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  const result = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const newKey = key.charAt(0).toLowerCase() + key.slice(1);
      result[newKey] = convertKeysToLowercase(obj[key]);
    }
  }

  return result;
};

export const ChangeFormatDate = (date, showingTime = false) => {
  const newDate = new Date(date);
  const mydate = newDate.getDate();
  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "Mei",
    "Jun",
    "Jul",
    "Agu",
    "Sep",
    "Okt",
    "Nov",
    "Des",
  ][newDate.getMonth()];
  const year = newDate.getFullYear();
  const hour = String(newDate.getHours()).padStart(2, "0");
  const minute = String(newDate.getMinutes()).padStart(2, "0");

  let Concat;

  if (showingTime) Concat = `${mydate} ${month} ${year} ${hour}:${minute}`;
  else Concat = `${mydate} ${month} ${year}`;

  return Concat;
};

export const titleCase = (str) => {
  var splitStr = str.toLowerCase().split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
};

export const deleteAllCookies = () => {
  document.cookie.split(";").forEach(function (c) {
    document.cookie = c
      .replace(/^ +/, "")
      .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });
};

export const deleteUserCookies = () => {
  document.cookie.split(";").forEach(function (c) {
    document.cookie = c
      .replace(/^ +/, "")
      .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });
};

export const chunkArray = (arr, size) =>
  Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size)
  );

export const convertToReactSimpleTreeMenuData = (originMenu) => {
  const tempTreeMenu = [];

  if (originMenu?.length !== 0) {
    const recursion = (item, index) => {
      if (item.parentId === null) {
        tempTreeMenu.push({
          ...item,
          key: item.id,
          label: item.name,
          parent: "",
          nodes: item.qcTransactionGroupProcess
            ? item.qcTransactionGroupProcess.map(recursion)
            : [],
        });

        return null;
      } else {
        return {
          ...item,
          key: item.id,
          label: item.name,
          parent: item.parentId,
          nodes: item.qcTransactionGroupProcess
            ? item.qcTransactionGroupProcess.map(recursion)
            : [],
        };
      }
    };

    originMenu?.forEach(recursion);

    return tempTreeMenu;
  }
};

export const convertTZ = (date, tzString) => {
  return new Date(
    (typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {
      timeZone: tzString,
    })
  );
};

export const testParamAbbreviation = (testParam) => {
  if (testParam.toLowerCase().includes(TestParameter.ContactAgar.toLowerCase()))
    return "CA";
  else if (
    testParam.toLowerCase().includes(TestParameter.SettlePLate.toLowerCase())
  )
    return "SA";
  else if (
    testParam.toLowerCase().includes(TestParameter.AirSampler.toLowerCase())
  )
    return "AS";
  else if (
    testParam.toLowerCase().includes(TestParameter.FingerDab.toLowerCase())
  )
    return "FD";
  else if (
    testParam
      .toLowerCase()
      .includes(TestParameter.ParticleCounter05mm.toLowerCase())
  )
    return "PC 0.5";
  else if (
    testParam
      .toLowerCase()
      .includes(TestParameter.ParticleCounter50mm.toLowerCase())
  )
    return "PC 5.0";
  else if (testParam.toLowerCase().includes(TestParameter.Gloves.toLowerCase()))
    return "G";
};

export const compressFile = async (file) => {
  const options = {
    maxSizeMB: 1,
    useWebWorker: true,
  };

  try {
    const compressedFile = await imageCompression(file, options);
    return compressedFile;
  } catch (error) {
    return "ERROR";
  }
};

export const getExtenstionFileFromName = (file) => {
  let nameLength = file?.name?.split(".").length;
  let extName = file?.name?.split(".")[nameLength - 1].toLowerCase();
  return extName;
};

export const getFileName = (file) => {
  let fileName = file.name.split(".");
  fileName.pop();
  return fileName.toString();
};

const unloadCallback = (event) => {
  event.preventDefault();
  event.returnValue = "";
  return null;
};

export const handleUnwantedRefresh = () => {
  window.addEventListener("beforeunload", unloadCallback);
};

export const removeUnwantedRefresh = () => {
  window.removeEventListener("beforeunload", unloadCallback);
};

export const getCookieAccessToken = () => {
  return document.cookie.replace(
    /(?:(?:^|.*;\s*)access_token\s*\=\s*([^;]*).*$)|^.*$/,
    "$1"
  );
};

export const removeItemOnce = (arr, index) => {
  if (arr.length > 0) {
    arr.splice(index, 1);
  }
  return arr;
};

export const storeDataToFirestore = async (
  path,
  pathSegment = [],
  type,
  data
) => {
  const docRef = doc(firestore, path, ...pathSegment);
  if (type === "create") {
    await setDoc(docRef, data);
    return;
  }

  await updateDoc(docRef, data);
};

export const loginFireBase = () => {
  const auth = getAuth();
  signInAnonymously(auth)
    .then(() => {
      //   TODO:Could be enchanced here
    })
    .catch((error) => {
      //   TODO:Could be enchanced here
    });
};

export const logoutFireBase = () => {
  const auth = getAuth();
  const user = auth.currentUser;

  deleteUser(user)
    .then(() => {
      //   TODO:Could be enchanced here
    })
    .catch((error) => {
      //   TODO:Could be enchanced here
    });
};

export const componentLoader = (lazyComponent, attemptsLeft = 3) => {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error) => {
        // let us retry after 1000 ms
        setTimeout(() => {
          if (attemptsLeft === 1) {
            reject(error);
            return;
          }
          componentLoader(lazyComponent, attemptsLeft - 1).then(
            resolve,
            reject
          );
        }, 1000);
      });
  });
};

export const generateToken = async () => {
  const {
    auth: { userData },
  } = store.getState();

  const dispatch = store.dispatch;
  const refreshToken = userData.refreshToken;

  const response = await RefreshToken({
    token: refreshToken,
  });

  if (response.status !== 200) return;

  const accessToken = response.data.data[0].accessToken;
  const decodeAccessToken = jwt_decode(accessToken);

  const expAccessToken = decodeAccessToken.exp;

  const cookies = new Cookies();

  cookies.set("access_token", accessToken, {
    path: "/",
    expires: new Date(moment.unix(expAccessToken).tz("Asia/Jakarta").format()),
  });

  dispatch(
    REFRESH_TOKEN({
      accessToken: accessToken,
      refreshToken: refreshToken,
    })
  );
  dispatch(
    SET_EXP_TOKEN({
      nbf: decodeAccessToken.nbf,
      exp: decodeAccessToken.exp,
      iat: decodeAccessToken.iat,
    })
  );

  return accessToken;
};

export const capitalizeWordsInString = (str) => {
  if (!str) return;

  return str
    .split(" ")
    .map((word) => {
      // Specific rule for the acronym "QA"
      if (word.toLowerCase() === "qa") {
        return "QA";
      }
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(" ");
};

export const capitalizeArrayItems = (arr) => {
  if (!Array.isArray(arr)) return;

  return arr.map(capitalizeWordsInString);
};

export const capitalizeNSeparate = (words) => {
  if (!Array.isArray(words)) return;

  let word = "";
  word = words.map(
    (word) =>
      word.reviewerName.charAt(0).toUpperCase() +
      word.reviewerName.replace(/_/g, " ").toLowerCase().substring(1)
  );

  let capitalizedArray = capitalizeArrayItems(word);

  if (word.length == 2) {
    word = capitalizedArray.join(" and ");
  } else if (word?.length > 2) {
    word = capitalizedArray.join(", ").replace(/, ([^,]*)$/, " and $1");
  } else {
    word = capitalizedArray.join();
  }

  return word;
};

export const getSimpleName = (name, maxLength = 20) => {
  if(name.length < maxLength) {
      return name
  }

  return `${name.slice(0, maxLength)}...`
}
