import React, { useState, useEffect } from 'react'
import {
  Grid,
  GridItem,
  Box,
  Input,
  ButtonGroup,
  Button,
  IconButton
} from "@chakra-ui/react"
import { FaTimes } from "react-icons/fa"
import PresenceIn from './PresenceIn'
import PresenceOut from './PresenceOut'
import Table from '../../../components/Sections/table'
import {getFacilityList, getListPresence} from '../../../services/actions/PES/Presence'
import moment from 'moment'
import PopOverFilter from '../Presence/PopOverFilter'
import {
  STATUS_PRESENCE_IN,
  STATUS_PRESENCE_IN_VALUE,
  STATUS_PRESENCE_OUT_VALUE
} from '../../../utils/ConstantPES'

const Presence = ({ t }) => {
  const [data, setData] = useState([])
  const [dataFiltered, setDataFiltered] = useState([])
  const [filter, setFilter] = useState({
    status: 1,
    date: new Date()
  })
  const [showResetFilter, setShowResetFilter] = useState(false)
  const [isFetching, setIsFetching] =  useState(false)
  const [inputSearch, setInputSearch] =  useState('')
  const [facilityData, setFacilityData] = useState([])

  const columns = [
    {
        Header: 'NO',
        accessor: 'posId',
        Cell: (row) => {
          return <Box key={row.value}>{row.row.index + 1}</Box>
        }
    },
    {
        Header: 'NAME',
        accessor: 'name'
    },
    {
      Header: 'FACILITY',
      accessor: 'facilityCode',
      Cell: (row) => {
        return <Box key={row.value}>{`${row.row.original.facilityCode} | ${row.row.original.facilityName}`}</Box>
      }
    },
    {
        Header: 'CHECK IN',
        accessor: 'checkIn',
        Cell: (row) => {
          return <Box>{moment(row.value).format('DD MMM YYYY, HH:mm:ss')}</Box>
        }
    },
    {
        Header: 'CHECK OUT',
        accessor: 'checkOut',
        Cell: (row) => {
          return <Box>{row.value ? moment(row.value).format('DD MMM YYYY, HH:mm:ss') : '-'}</Box>
        }
    },
  ]

  const handleOnSubmit = (data) => {
    setShowResetFilter(true)
    setFilter(data.data)
  }

  const handleChangeInputSearch = (e) => {
    setInputSearch(e.target.value)
    // set page active = 1
  }

  const resetFilter = () => {
    setShowResetFilter(false)
    setFilter({
      status: 1,
      date: new Date()
    })
    setInputSearch('')
  }

  async function GetList() {
    let status = filter.status === STATUS_PRESENCE_IN ? STATUS_PRESENCE_IN_VALUE : STATUS_PRESENCE_OUT_VALUE
    let response = await getListPresence(status, moment(filter.date).format('YYYY-MM-DD'))
    if (response?.status !== 200) {
      setData([])
      setDataFiltered([])
    } else if (response?.status === 200) {
      setData(response?.data?.data)
      setDataFiltered(response?.data?.data)
    }
    setIsFetching(false)
  }

  const getFacilityListData = async() =>{
    const response = await getFacilityList()
    if(response.data.statusCode === 200) {
      const getData = response.data.data
      const options = getData.map(d => ({
        value : d.id,
        label: `${d.facilityCode} | ${d.facilityName}`
      }))
      setFacilityData(options)
    }
  }

  useEffect(() => {
    let filteredData = data?.filter(value => {
      return (
        value.name.toLowerCase().includes(inputSearch.toLowerCase())
      )
    })
    setDataFiltered(filteredData)
  }, [data, inputSearch])

  useEffect(() => {
    async function GetList() {
      let status = filter.status === STATUS_PRESENCE_IN ? STATUS_PRESENCE_IN_VALUE : STATUS_PRESENCE_OUT_VALUE
      let response = await getListPresence(status, moment(filter.date).format('YYYY-MM-DD'))
      if (response?.status !== 200) {
        setData([])
        setDataFiltered([])
      } else if (response?.status === 200) {
        setData(response?.data?.data)
        setDataFiltered(response?.data?.data)
      }
      setIsFetching(false)
    }

    setIsFetching(true)
    GetList()
  }, [filter])

  useEffect(() =>{
    getFacilityListData()
  },[])

  return (
    <div className="main-content-wrapper" style={{ height: 'calc(100vh - 150px)' }}>
      <Grid templateColumns="repeat(9, 1fr)" gap={4} className="h-100">
        <GridItem colSpan={2} className="px-2 my-auto">
          <PresenceIn t={t} reloadPage={GetList} facilityData={facilityData}/>
          <PresenceOut t={t} reloadPage={GetList} facilityData={facilityData}/>
        </GridItem>
        <GridItem colStart={3} colEnd={10} className="px-2">
          <div className="mb-3 text-right">
            <Input
              name="inputSearch"
              value={ inputSearch || '' }
              onChange={handleChangeInputSearch}
              placeholder="Search"
              style={{ maxWidth: '50%', minWidth: '250px'}}
            />
            {
              showResetFilter ? (
                <ButtonGroup isAttached variant="outline" className="ml-3">
                  <Button mr="-px" className="btn-cm-primary text-white border-bio">
                    <span className="text-weight-normal">Filter</span>
                  </Button>
                  <IconButton aria-label="Reset filter" className="btn-cm-outline-primary" icon={<FaTimes />} onClick={resetFilter} />
                </ButtonGroup>
              ) : (
                <PopOverFilter onSubmit={handleOnSubmit} className="ml-3" />
              )
            }
          </div>

          {
            data.length < 1 ? (
              <Table columns={columns} data={dataFiltered} isLoading={isFetching} />
            ) : (
              <Table columns={columns} data={dataFiltered} isLoading={isFetching} pagination={true} itemsPerPage={5} inputSearch={inputSearch} emptyPlaceholder="No Data Found" />
            )
          }
        </GridItem>
      </Grid>
    </div>
  )
}

export default Presence
