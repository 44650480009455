import React from 'react'
import { NavLink } from "react-router-dom"
import {
  Grid,
  GridItem,
  Image,
  Text,
  Button,
  Box
} from '@chakra-ui/react'
import style from './style.module.css'

const Forbidden = () => {
  return (
    <div className='vh-100'>
      <Grid
        className='h-100 mx-lg-5 mx-0'
        templateColumns="repeat(4, 1fr)"
        gap={{ lg: '60px', md: '20px', base: 5}}
      >
        <GridItem colSpan={{ base: 4, lg: 2, md: 4 }} className="py-3" height='100vh' position='relative'>
          <Box className={`mx-auto ${style['img-header-401']}`} style={{
              margin: 0,
              position: 'absolute',
              top: '40%',
              left: '30%',
              transform: 'translateY(-50%)',
          }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 491 751" fill="none" className='h-100 mx-auto forbidden-image-size'>
              <path d="M397.06 633.65C390.535 649.6 346.745 647.57 341.67 661.2C336.595 674.83 381.255 673.525 379.805 690.635C378.355 707.745 351.385 710.355 302.955 713.11C254.525 715.865 243.795 728.625 195.365 744.43C146.935 760.235 125.62 739.79 98.505 725C71.535 710.355 19.77 737.325 3.53003 714.56C-12.71 691.65 31.5151 683.53 70.5201 674.685C109.67 665.695 91.98 661.49 90.965 642.495C90.82 638.58 91.98 635.535 94.155 633.07C103 623.5 128.665 623.355 159.405 623.935C198.12 624.805 207.835 611.9 261.775 613.06C311.51 614.075 389.52 617.12 396.625 630.025C397.495 631.185 397.64 632.345 397.06 633.65Z" fill="#99ADF9"/>
              <path d="M472.75 661.925C472.315 662.795 471.59 663.665 470.72 664.68C464.485 671.495 444.04 675.7 427.655 675.99C407.355 676.135 386.185 674.83 385.46 662.505C384.88 650.18 436.79 649.455 454.915 651.485C470.575 652.935 475.505 656.56 472.75 661.925Z" fill="#99ADF9"/>
              <path d="M71.535 659.895C71.245 660.62 70.665 661.345 69.94 662.07C65.01 667.435 48.915 670.915 36.01 671.06C20.06 671.205 3.23996 670.19 2.80496 660.185C2.36996 650.325 43.26 649.745 57.47 651.34C69.795 652.645 73.855 655.545 71.535 659.895Z" fill="#99ADF9"/>
              <path d="M331.23 737.18C317.02 742.835 263.95 743.125 264.095 736.165C264.095 735.295 264.385 734.425 264.965 733.7C268.88 728.19 285.12 725.145 299.765 724.565C317.02 723.985 328.765 725.87 333.985 728.625C338.77 731.235 338.045 734.425 331.23 737.18Z" fill="#99ADF9"/>
              <path d="M195.8 719.925C182.75 724.71 141.28 723.55 141.57 714.995C141.57 713.98 141.86 712.965 142.295 711.95C145.775 705.28 156.65 702.815 169.12 702.38C183.62 701.8 195.51 704.12 200.295 706.875C206.385 710.21 206.53 715.865 195.8 719.925Z" fill="#7C99F2"/>
              <path d="M356.605 638.58C348.92 642.35 320.935 642.06 321.08 637.13C321.08 636.55 321.225 635.97 321.515 635.39C323.69 631.62 332.245 629.59 340.075 629.3C349.21 629.01 355.445 630.46 358.055 632.49C360.665 634.375 360.23 636.695 356.605 638.58Z" fill="#7C99F2"/>
              <path d="M349.065 690.49C342.83 693.535 319.92 693.245 320.065 689.33C320.065 688.895 320.21 688.315 320.5 687.88C322.24 684.835 329.345 683.095 335.725 682.95C343.265 682.66 348.34 683.965 350.515 685.56C352.4 687.155 352.11 689.04 349.065 690.49Z" fill="#7C99F2"/>
              <path d="M279.755 688.025C213.925 706.15 115.18 675.99 116.05 658.735C116.195 656.56 117.065 654.675 118.66 652.935C129.97 640.465 176.08 636.985 217.985 639.45C267.14 642.495 300.345 650.325 314.845 658.735C328.04 666.13 313.395 678.745 279.755 688.025Z" fill="#7C99F2"/>
              <path d="M112.57 692.81C106.335 696.29 83.1351 696 83.4251 691.505C83.4251 690.925 83.5701 690.49 83.8601 689.91C85.6001 686.43 92.7051 684.545 99.0851 684.255C106.625 683.965 111.7 685.27 113.875 687.155C115.905 689.04 115.615 691.215 112.57 692.81Z" fill="#7C99F2"/>
              <path d="M323.98 183.28C323.98 183.28 320.21 171.535 333.26 170.81C346.31 170.23 333.26 150.945 346.31 139.78C359.36 128.615 374.875 133.545 375.455 148.48C376.035 163.415 373.57 172.695 375.455 176.465C377.34 180.235 382.85 169.65 389.665 177.045C396.48 184.44 354.285 327.265 354.285 327.265L331.375 321.61L323.98 183.28Z" fill="#D2E9FF"/>
              <path d="M365.45 206.045C359.215 208.945 346.455 294.205 357.04 285.795C359.505 283.91 381.4 198.65 365.45 206.045Z" fill="#E3F0FF"/>
              <path d="M473.476 116C472.896 120.06 471.881 123.83 470.866 127.165C460.716 157.905 433.021 159.065 432.586 163.27C432.151 167.91 452.596 183.28 433.456 201.985C414.316 220.69 375.311 196.91 365.016 196.04C350.516 194.88 320.356 220.69 299.621 193.865C286.281 176.61 307.741 166.895 302.811 162.11C297.881 157.325 259.311 156.455 259.311 113.1C259.311 112.23 259.311 111.36 259.311 110.49C260.761 67.135 299.331 70.18 301.216 61.625C303.101 53.07 302.666 2.90002 363.566 0.725019C424.611 -1.44998 422.726 60.32 428.236 63.22C434.036 66.265 480.001 74.24 473.476 116Z" fill="#E3F0FF"/>
              <path d="M425.045 152.105C438.095 146.74 457.67 137.17 470.72 127.165C460.57 157.905 432.875 159.065 432.44 163.27C432.005 167.91 452.45 183.28 433.31 201.985C414.17 220.69 375.165 196.91 364.87 196.04C350.37 194.88 320.21 220.69 299.475 193.865C286.135 176.61 307.595 166.895 302.665 162.11C297.735 157.325 259.165 156.455 259.165 113.1C262.5 119.915 269.46 129.63 284.395 136.445C309.48 147.9 327.17 135.14 336.015 147.465C344.86 159.79 326.3 167.475 334.565 174.725C342.975 181.975 356.025 163.705 376.905 173.42C397.93 183.135 410.98 189.515 419.245 180.67C427.945 171.68 406.485 159.79 425.045 152.105Z" fill="#D2E9FF"/>
              <path d="M326.01 90.48C326.01 90.48 325.865 90.335 325.43 90.045C324.995 89.755 324.56 89.32 323.835 88.595C322.53 87.29 320.79 85.26 319.34 82.505C318.615 81.2 317.89 79.605 317.31 77.865C316.73 76.125 316.44 74.24 316.15 72.21C316.005 70.18 316.005 68.15 316.295 66.12C316.44 65.105 316.585 64.09 316.875 63.075C317.02 62.495 317.02 62.205 317.31 61.335L317.745 59.885C318.18 58.145 319.34 55.97 320.5 54.085C321.66 52.345 323.255 50.605 324.85 49.3C326.59 47.995 328.33 46.98 330.07 46.255C331.81 45.53 333.695 45.095 335.29 44.805C336.885 44.515 338.48 44.515 339.785 44.515C341.09 44.515 342.25 44.515 343.265 44.66C344.28 44.805 345.005 44.805 345.44 44.95C346.02 45.095 346.31 45.095 346.31 45.095C348.195 45.53 349.355 47.27 348.92 49.155C348.485 51.04 346.745 52.2 344.86 51.765H344.715H344.425C344.425 51.765 344.28 51.765 343.99 51.62C343.555 51.475 343.12 51.33 342.25 51.33C341.525 51.185 340.655 51.04 339.495 51.04C338.48 51.04 337.32 50.895 336.015 51.04C334.71 51.185 333.405 51.33 332.1 51.91C330.795 52.345 329.345 52.925 328.185 53.795C326.88 54.665 325.72 55.825 324.705 57.13C323.69 58.435 322.965 59.595 322.24 61.625L321.66 62.93L321.37 64.09C321.08 64.96 320.79 65.83 320.79 66.7C320.5 68.44 320.355 70.18 320.355 71.775C320.355 73.37 320.645 75.11 320.935 76.56C321.225 78.01 321.805 79.46 322.385 80.62C323.545 83.085 324.995 84.97 326.01 86.13C326.59 86.71 327.025 87.145 327.315 87.435C327.605 87.725 327.75 87.87 327.75 87.87H327.895C328.475 88.45 328.62 89.465 328.04 90.045C327.605 90.77 326.735 90.915 326.01 90.48Z" fill="#D2E9FF"/>
              <path d="M343.555 47.85C343.555 47.85 343.555 47.56 343.7 46.835C343.845 46.255 344.135 45.385 344.425 44.225C345.15 42.05 346.31 39.005 348.775 35.67C349.935 34.075 351.53 32.335 353.415 31.03C355.3 29.58 357.475 28.42 359.795 27.405C360.955 26.97 362.26 26.68 363.565 26.245C364.87 25.955 366.175 25.81 367.48 25.665C368.785 25.52 370.09 25.52 371.395 25.52H372.41H372.555H372.99H373.135H373.57L375.31 25.665C375.89 25.81 376.325 25.81 377.05 25.955L379.225 26.535C379.95 26.825 380.675 26.97 381.4 27.26L383.285 28.13C384.59 28.71 385.75 29.435 386.765 30.305C387.925 31.03 388.795 32.045 389.81 32.915C393.435 36.685 395.465 41.18 396.625 44.805C397.785 48.575 398.365 51.765 398.655 53.94C398.8 55.1 398.8 55.97 398.945 56.55C398.945 57.275 398.945 57.565 398.945 57.565C399.09 60.175 397.06 62.35 394.45 62.35C391.84 62.495 389.665 60.465 389.665 57.855V57.71V57.42C389.665 57.42 389.665 57.275 389.665 56.84C389.665 56.405 389.665 55.68 389.52 54.81C389.375 53.07 389.085 50.315 388.215 47.415C387.345 44.515 385.895 41.18 383.575 38.57C382.995 37.99 382.415 37.265 381.69 36.685C380.965 36.105 380.24 35.525 379.515 35.235L378.355 34.51C377.92 34.365 377.63 34.22 377.195 34.075L376.035 33.64C375.6 33.495 374.875 33.495 374.44 33.35L372.7 33.06H372.265H372.12C371.83 33.06 372.555 33.06 372.41 33.06H372.265H371.54C370.525 33.06 369.51 32.915 368.495 33.06C367.48 33.205 366.465 33.205 365.595 33.35C364.725 33.64 363.71 33.785 362.84 34.075C361.1 34.655 359.505 35.38 358.2 36.395C356.895 37.265 355.735 38.425 354.865 39.585C352.98 41.905 351.965 44.225 351.385 45.965C351.095 46.835 350.95 47.415 350.805 47.85C350.66 48.285 350.66 48.43 350.66 48.43V48.72C350.225 50.605 348.485 51.62 346.6 51.33C344.28 51.33 343.265 49.59 343.555 47.85Z" fill="#D2E9FF"/>
              <path d="M415.62 69.165C415.62 69.165 416.635 69.165 418.23 69.31C419.825 69.455 422 69.745 424.61 70.47C427.22 71.195 430.265 72.355 433.165 74.675C434.615 75.835 435.92 77.14 437.08 78.735C437.66 79.605 438.095 80.33 438.53 81.345L439.11 82.65L439.4 84.1C439.835 85.55 440.27 87.725 440.27 89.465C440.415 91.35 440.27 93.09 439.835 94.685C439.11 98.02 437.805 100.92 436.355 103.095C434.905 105.27 433.31 106.865 432.295 107.88C431.135 108.895 430.41 109.475 430.41 109.475C429.685 110.055 428.525 109.91 427.945 109.185C427.365 108.46 427.51 107.3 428.235 106.72C428.09 106.865 430.7 104.835 432.875 100.92C434.035 99.035 434.905 96.57 435.195 93.815C435.34 92.51 435.34 91.06 435.195 89.755C435.05 88.305 434.76 87.29 434.18 85.55L433.89 84.535L433.455 83.665C433.165 83.085 432.73 82.505 432.44 82.07C431.715 81.055 430.7 80.33 429.685 79.605C427.51 78.3 425.19 77.43 423.015 76.995C420.84 76.56 419.1 76.415 417.795 76.415C416.49 76.415 415.91 76.415 415.91 76.415C413.88 76.415 412.14 74.82 412.14 72.79C411.995 70.905 413.59 69.165 415.62 69.165Z" fill="#D2E9FF"/>
              <path d="M319.34 86.855C319.34 86.855 319.195 86.855 319.05 86.855C318.76 86.855 318.47 87 318.035 87.145C317.02 87.435 315.715 87.87 314.265 88.885C313.54 89.32 312.815 89.9 312.235 90.625C311.51 91.35 310.93 92.22 310.495 93.235C310.06 94.25 309.625 95.265 309.335 96.57C309.045 97.73 308.9 99.18 308.9 100.34C308.9 101.935 309.045 102.95 309.335 104.11C309.625 105.27 310.06 106.43 310.785 107.445C312.09 109.475 313.83 110.925 315.57 111.94C317.31 112.955 318.905 113.535 320.065 113.825C321.225 114.115 321.95 114.26 321.805 114.26C322.53 114.26 323.11 114.985 322.965 115.71C322.965 116.435 322.24 117.015 321.515 116.87C321.515 116.87 320.645 116.87 319.34 116.58C318.035 116.29 316.15 115.855 313.83 114.985C311.655 113.97 309.19 112.375 307.16 109.91C306.145 108.605 305.42 107.155 304.84 105.705C304.55 104.98 304.405 103.965 304.115 103.24C303.825 102.37 303.825 101.79 303.68 100.92C303.68 100.485 303.535 100.34 303.535 99.615V98.455C303.535 97.585 303.535 96.86 303.68 95.99C303.825 94.395 304.115 92.655 304.695 91.205C305.275 89.61 306 88.16 307.015 87C307.885 85.695 308.9 84.68 310.06 83.81C312.09 82.07 314.12 81.2 315.57 80.62C316.295 80.33 316.875 80.185 317.31 80.04C317.745 79.895 318.035 79.895 318.035 79.895C320.065 79.46 322.095 80.62 322.53 82.65C322.965 84.68 321.805 86.71 319.775 87.145C319.63 86.855 319.485 86.855 319.34 86.855Z" fill="#D2E9FF"/>
              <path d="M408.225 130.645C408.225 130.645 408.225 131.66 407.935 133.255C407.645 134.85 407.21 137.025 406.34 139.635C405.47 142.245 404.165 145.29 401.7 148.045C400.54 149.35 399.09 150.655 397.35 151.67C396.48 152.105 395.61 152.685 394.74 152.975L393.29 153.41L391.985 153.7C390.535 154.135 388.36 154.28 386.62 154.28C384.735 154.28 382.995 153.99 381.4 153.7C378.065 152.83 375.31 151.38 373.135 149.785C370.96 148.19 369.51 146.595 368.495 145.435C367.48 144.275 367.045 143.55 367.045 143.405C366.465 142.68 366.755 141.52 367.48 140.94C368.205 140.36 369.365 140.65 369.945 141.375C369.945 141.23 371.685 143.84 375.6 146.305C377.485 147.465 379.95 148.625 382.56 149.06C383.865 149.35 385.315 149.35 386.62 149.205C388.07 149.06 389.085 148.915 390.825 148.335L391.84 148.045L392.71 147.61C393.29 147.32 393.87 147.03 394.305 146.595C395.32 145.87 396.19 144.855 396.915 143.985C398.365 141.955 399.235 139.635 399.815 137.46C400.395 135.285 400.54 133.545 400.685 132.24C400.83 130.935 400.685 130.355 400.685 130.355C400.685 128.325 402.425 126.73 404.455 126.73C406.63 126.875 408.37 128.47 408.225 130.645Z" fill="#D2E9FF"/>
              <path d="M293.095 250.56L287.005 233.595C287.005 233.595 301.07 231.42 293.675 213.005C289.47 202.71 277.58 210.83 277.58 210.83C277.58 210.83 275.55 195.895 260.035 199.23C247.855 201.84 247.565 221.415 252.35 227.65C256.99 233.885 261.775 233.305 261.775 233.305C261.775 233.305 251.625 233.015 253.22 242.295C254.815 251.575 262.79 252.01 268.88 248.965C271.78 247.515 273.955 243.89 273.955 243.89L287.15 254.765C288.31 255.78 290.05 255.925 291.5 255.2C293.095 254.475 293.82 252.445 293.095 250.56Z" fill="#D2E9FF"/>
              <path d="M437.805 288.55C437.225 291.305 436.21 293.77 435.05 295.075C431.57 299.135 426.06 299.57 426.06 299.57C426.06 299.57 430.99 302.325 427.365 308.27C424.61 312.62 416.49 310.01 414.315 307.255C411.995 304.5 411.995 301.31 411.995 301.31L402.135 309.72C400.54 311.17 397.93 311.025 396.48 309.285C395.175 307.835 395.175 305.515 396.48 304.065L397.785 302.615L405.76 293.19C405.76 293.19 394.45 288.55 398.51 280.14C402.135 272.455 409.385 276.08 411.27 278.11C413.01 279.995 413.3 283.185 413.3 283.185C413.3 283.185 416.635 270.28 428.67 273.18C437.66 275.21 438.965 282.605 437.805 288.55Z" fill="#D2E9FF"/>
              <path d="M129.535 465.885C131.855 469.075 132 473.425 129.68 476.76L128.81 477.92C127.94 479.08 126.635 479.66 125.33 479.66C124.315 479.66 123.445 479.37 122.575 478.645L108.655 466.465C108.655 466.465 101.84 482.27 83.2799 478.645C70.3748 476.035 67.0398 465.015 68.4898 455.88C69.0698 451.385 70.8098 447.325 73.1298 444.86C77.1898 440.51 82.4099 439.93 86.1799 440.075C82.4099 438.915 77.0449 435.87 75.0149 428.33C73.7098 423.545 73.9998 418.76 75.7399 414.99C78.4949 409.045 84.7299 405.275 93.5749 406.29C108.22 408.175 108.51 428.91 108.51 428.91C108.51 428.91 113.585 414.845 127.505 420.935C139.685 426.3 136.205 442.54 127.94 448.92C123.59 452.255 119.82 452.98 119.82 452.98L129.535 465.885Z" fill="#E1ECFF"/>
              <path d="M98.0701 442.685C98.0701 442.685 92.1251 441.525 86.3251 439.93C82.5551 438.77 77.1901 435.87 75.0151 428.185C73.7101 423.4 74.0001 418.615 75.7401 414.845C76.6101 419.92 78.9301 427.895 84.5851 433.115C93.4301 441.235 98.0701 442.685 98.0701 442.685Z" fill="#C5DFFF"/>
              <path d="M128.81 477.775C127.94 478.935 126.635 479.515 125.33 479.515C124.315 479.515 123.445 479.225 122.575 478.5L108.655 466.32C108.655 466.32 101.84 482.125 83.2799 478.5C70.3748 475.89 67.0398 464.87 68.4898 455.735C70.5198 460.085 75.0149 465.015 85.3099 466.755C105.9 470.38 106.48 457.475 106.48 457.475L128.81 477.775Z" fill="#C5DFFF"/>
              <path d="M184.055 433.84C182.17 433.84 175.935 433.405 170.28 428.185C164.625 422.965 161.725 414.7 161.58 403.825C161.29 378.16 156.36 374.68 150.995 373.085C143.455 370.91 135.335 373.81 135.335 373.81C132.435 374.97 129.245 373.52 128.085 370.62C126.925 367.72 128.375 364.53 131.275 363.37C131.71 363.225 142.73 359.02 154.04 362.21C169.12 366.56 172.455 381.205 172.6 403.535C172.745 411.075 174.485 416.73 177.53 419.775C180.43 422.53 183.765 422.53 183.765 422.53C186.81 422.24 189.42 424.85 189.565 427.895C189.71 430.94 187.39 433.55 184.345 433.695C184.49 433.84 184.345 433.84 184.055 433.84Z" fill="#FF97C9"/>
              <path d="M179.56 355.83C178.11 357.135 173.905 361.775 173.325 369.315C172.89 377 176.37 384.975 183.765 393.095C201.02 411.945 200.005 417.89 197.105 422.675C193.045 429.49 185.215 432.825 185.215 432.825C182.315 433.985 181.01 437.175 182.17 440.075C183.33 442.975 186.52 444.28 189.42 443.12C189.855 442.975 200.875 438.48 206.82 428.33C214.795 414.7 207.11 401.94 192.175 385.41C187.1 379.9 184.49 374.535 184.635 370.185C184.78 366.125 187.245 363.95 187.39 363.805C189.71 361.92 189.855 358.295 187.825 355.975C185.795 353.655 182.46 353.365 179.995 355.395C179.85 355.54 179.705 355.685 179.56 355.83Z" fill="#99ADF9"/>
              <path d="M436.5 389.035C430.99 389.035 424.9 387.295 419.39 382.945C409.965 375.695 403.73 380.625 403.73 380.77C401.41 382.8 397.785 382.51 395.9 380.045C393.87 377.725 394.16 374.1 396.625 372.215C401.12 368.445 413.155 363.95 426.35 374.1C433.455 379.61 440.995 378.16 443.895 375.405C446.65 372.94 448.245 365.835 448.535 361.63C448.68 358.585 451.435 356.12 454.48 356.41C457.525 356.555 459.845 359.31 459.7 362.355C459.555 363.805 458.54 377.29 451.435 383.67C447.81 387.005 442.445 389.035 436.5 389.035Z" fill="#99ADF9"/>
              <path d="M433.6 458.055L479.855 509.82C479.855 509.82 466.37 486.62 454.77 471.975C442.59 456.895 433.6 458.055 433.6 458.055Z" fill="#E1ECFF"/>
              <path d="M469.995 481.835C469.995 481.835 458.105 467.625 456.365 465.45C451.725 459.795 448.39 458.055 448.39 458.055C448.39 458.055 452.595 457.475 458.105 464.435C463.615 471.25 469.995 481.835 469.995 481.835Z" fill="#E1ECFF"/>
              <path d="M399.09 433.55L419.97 397.59L407.5 430.795L425.915 449.065L399.09 433.55Z" fill="#FEC272"/>
              <path d="M431.715 415.57L401.7 431.665L409.095 434.42L431.715 415.57Z" fill="#FEC272"/>
              <path d="M128.52 352.205L117.645 339.59C116.34 337.995 116.485 335.675 118.08 334.225C119.675 332.92 121.995 333.065 123.445 334.66C123.735 334.95 123.88 335.24 124.025 335.53L130.985 350.61C131.275 351.335 130.985 352.205 130.26 352.495C129.68 352.93 128.955 352.785 128.52 352.205Z" fill="#FEC272"/>
              <path d="M118.515 365.98L98.07 368.01C96.04 368.155 94.155 366.705 94.01 364.675C93.865 362.645 95.315 360.76 97.345 360.615C97.635 360.615 97.925 360.615 98.215 360.615L118.515 363.08C119.385 363.225 119.82 363.95 119.82 364.675C119.675 365.4 119.24 365.98 118.515 365.98Z" fill="#FEC272"/>
              <path d="M127.505 382.8L117.5 395.27C116.195 396.865 113.875 397.155 112.28 395.85C110.685 394.545 110.395 392.225 111.7 390.63C111.845 390.34 112.135 390.05 112.425 389.905L125.475 380.625C126.2 380.19 127.07 380.335 127.65 380.915C128.085 381.495 127.94 382.22 127.505 382.8Z" fill="#FEC272"/>
              <path d="M188.115 344.81L195.8 334.66C196.815 333.355 198.555 333.21 199.86 334.08C201.165 335.095 201.31 336.835 200.44 338.14C200.295 338.285 200.15 338.575 199.86 338.72L189.71 346.405C189.275 346.84 188.55 346.695 188.115 346.26C187.825 345.825 187.825 345.245 188.115 344.81Z" fill="#FEC272"/>
              <path d="M176.225 339.59L171.295 324.655C170.86 323.205 171.585 321.465 173.18 321.03C174.63 320.595 176.37 321.32 176.805 322.915C176.805 323.06 176.95 323.35 176.95 323.495L178.4 339.155C178.4 339.735 177.965 340.315 177.385 340.315C176.805 340.315 176.37 340.025 176.225 339.59Z" fill="#FEC272"/>
              <path d="M165.06 349.015L154.04 343.505C152.59 342.78 152.01 341.04 152.735 339.59C153.46 338.14 155.2 337.56 156.65 338.285C156.94 338.43 157.085 338.575 157.375 338.72L166.51 346.985C166.945 347.42 167.09 348.145 166.655 348.725C166.075 349.16 165.495 349.305 165.06 349.015Z" fill="#FEC272"/>
              <path d="M457.525 330.165L454.915 345.1C454.77 345.97 454.045 346.405 453.175 346.26C452.45 346.115 452.015 345.535 451.87 344.955L449.985 329.875C449.695 327.845 451.145 325.96 453.32 325.67C455.35 325.38 457.235 326.83 457.525 329.005C457.67 329.44 457.67 329.73 457.525 330.165Z" fill="#FEC272"/>
              <path d="M483.77 344.23L468.545 354.235C467.53 354.815 466.225 354.67 465.645 353.655C465.065 352.785 465.21 351.625 465.935 350.9L479.275 338.43C480.725 336.98 483.19 337.125 484.495 338.575C485.945 340.025 485.8 342.49 484.35 343.795C484.06 343.94 483.915 344.085 483.77 344.23Z" fill="#FEC272"/>
              <path d="M481.305 376.13L468.255 369.895C467.385 369.46 466.95 368.445 467.385 367.43C467.675 366.705 468.545 366.27 469.27 366.415L483.48 368.88C485.51 369.17 486.96 371.2 486.525 373.23C486.235 375.26 484.205 376.71 482.175 376.275C482.03 376.42 481.74 376.275 481.305 376.13Z" fill="#FEC272"/>
              <path d="M277 305.08C277 305.08 215.955 378.16 213.635 376.275C213.055 375.84 212.04 372.505 210.735 367.14C207.255 351.915 202.47 320.015 201.89 286.085C201.165 240.41 202.325 226.49 214.505 220.835C223.495 216.63 248 257.085 264.095 284.2C269.895 293.48 274.39 301.165 277 305.08Z" fill="#FF97C9"/>
              <path d="M277 305.08C277 305.08 215.955 378.16 213.635 376.275C213.055 375.84 212.04 372.505 210.735 367.14C216.68 351.915 223.93 335.82 231.905 323.64C244.81 304.21 256.41 291.595 264.095 284.2C269.895 293.48 274.39 301.165 277 305.08Z" fill="#FC75BC"/>
              <path d="M488.41 556.365C484.64 564.195 449.695 553.32 421.13 543.46C415.62 541.575 410.255 539.69 405.325 537.95C394.015 534.035 386.475 523.305 386.475 511.27C386.62 483.285 384.01 438.77 389.375 436.45C395.755 433.55 427.365 464.725 456.22 498.22C486.09 532.875 494.065 544.33 488.41 556.365Z" fill="#FF97C9"/>
              <path d="M421.13 543.462C415.62 541.577 410.255 539.692 405.325 537.952C394.015 534.037 386.475 523.307 386.475 511.272C386.62 483.286 384.01 438.771 389.375 436.451C389.52 436.451 401.41 473.281 409.53 502.281C414.17 519.102 418.085 532.877 421.13 543.462Z" fill="#FC75BC"/>
              <path d="M400.25 363.95C399.235 403.535 393.435 469.365 369.8 534.615C349.355 591.165 331.665 628.72 319.92 650.905C310.205 669.175 304.55 677.295 304.55 677.295C304.55 677.295 289.18 684.98 259.6 687.3C243.07 688.605 222.19 688.315 197.105 684.11C126.925 672.365 119.53 650.325 119.53 650.325C119.53 650.325 118.515 637.71 121.85 612.915C126.055 581.015 137.655 529.105 167.96 458.2C202.035 378.885 240.895 332.195 264.965 308.56C279.175 294.64 288.31 288.695 288.31 288.695C288.31 288.695 307.16 281.01 346.165 293.19C361.68 297.975 373.425 304.21 381.69 310.155C394.45 319 399.67 326.83 399.67 326.83C399.67 326.83 400.975 341.04 400.25 363.95Z" fill="#E1ECFF"/>
              <path d="M400.25 363.95C399.235 403.535 393.435 469.365 369.8 534.615C349.355 591.165 331.665 628.72 319.92 650.905C310.205 669.175 304.55 677.295 304.55 677.295C304.55 677.295 289.18 684.98 259.6 687.3C278.45 661.49 306.435 616.685 334.275 548.825C379.515 438.625 382.27 341.765 381.835 310.155C394.595 319 399.815 326.83 399.815 326.83C399.815 326.83 400.975 341.04 400.25 363.95Z" fill="#C5DFFF"/>
              <path d="M138.38 606.68C137.945 606.68 137.655 606.68 137.22 606.535C133.16 605.955 130.405 602.04 130.985 597.98C131.42 595.515 140.7 535.775 166.365 475.6C192.03 415.57 212.33 386.135 213.2 384.83C215.52 381.495 220.16 380.625 223.64 382.945C226.975 385.265 227.845 389.905 225.525 393.385C225.38 393.675 205.08 422.965 180.14 481.4C155.2 539.835 145.775 599.575 145.775 600.155C145.05 604.07 141.86 606.68 138.38 606.68Z" fill="#F8FBFF"/>
              <path d="M400.25 363.952C395.175 359.457 388.65 354.237 380.82 348.872C377.63 346.697 374.15 344.377 370.38 342.057C368.35 340.897 366.32 339.592 364.145 338.432C355.59 333.502 345.875 328.862 335.29 324.802C334.42 324.512 333.695 324.222 332.825 323.932C331.23 323.352 329.78 322.772 328.33 322.337C316.15 317.987 305.42 315.087 296.285 313.057C293.675 312.477 291.065 312.042 288.745 311.462C278.595 309.577 270.765 308.997 265.255 308.707C279.465 294.787 288.6 288.842 288.6 288.842C288.6 288.842 294.4 286.522 306.29 286.522C308.9 286.522 311.8 286.667 314.99 286.957C322.53 287.537 331.665 289.132 342.395 292.177C343.7 292.612 345.15 292.902 346.455 293.337C347.47 293.627 348.485 293.917 349.5 294.352C357.91 297.252 365.16 300.442 371.25 303.632C373.715 304.937 375.89 306.242 378.065 307.692C379.515 308.562 380.675 309.432 381.98 310.302C394.74 319.147 399.96 326.977 399.96 326.977C399.96 326.977 400.975 341.042 400.25 363.952Z" fill="#1C3177"/>
              <path d="M400.25 363.95C395.175 359.455 388.65 354.235 380.82 348.87C381.98 331.905 381.98 318.71 381.98 310.3C394.74 319.145 399.96 326.975 399.96 326.975C399.96 326.975 400.975 341.04 400.25 363.95Z" fill="#142966"/>
              <path d="M378.065 307.69L371.395 340.315C371.25 341.04 370.96 341.62 370.38 342.2C368.35 341.04 366.32 339.735 364.145 338.575L371.25 303.775C373.715 305.08 376.035 306.385 378.065 307.69Z" fill="#142966"/>
              <path d="M349.5 294.35L335.29 324.8C334.42 324.51 333.695 324.22 332.825 323.93C331.23 323.35 329.78 322.77 328.33 322.335L342.25 292.175C343.555 292.61 345.005 292.9 346.31 293.335C347.47 293.77 348.485 294.06 349.5 294.35Z" fill="#142966"/>
              <path d="M314.99 286.955C314.845 287.245 314.7 287.535 314.555 287.825L296.285 313.055C293.675 312.475 291.065 312.04 288.745 311.46C288.89 310.88 289.035 310.3 289.47 309.72L306.29 286.375C308.9 286.52 311.8 286.665 314.99 286.955Z" fill="#142966"/>
              <path d="M210.074 632.233C244.161 643.926 284.94 615.088 301.155 567.821C317.371 520.554 302.883 472.756 268.796 461.062C234.709 449.369 193.931 478.207 177.715 525.474C161.5 572.741 175.987 620.539 210.074 632.233Z" fill="#C5DFFF"/>
              <path d="M214.743 628.521C248.83 640.215 289.461 611.807 305.495 565.07C321.528 518.333 306.893 470.965 272.806 459.271C238.719 447.577 198.088 475.986 182.055 522.723C166.021 569.46 180.656 616.827 214.743 628.521Z" fill="#1C3177"/>
              <path d="M218.12 612.336C245.163 621.613 277.597 598.492 290.564 560.693C303.531 522.895 292.121 484.732 265.079 475.455C238.036 466.178 205.602 489.299 192.635 527.098C179.668 564.896 191.078 603.059 218.12 612.336Z" fill="#99ADF9"/>
              <path d="M231.832 521.914C239.558 524.565 248.834 517.932 252.55 507.1C256.266 496.268 253.015 485.338 245.289 482.687C237.563 480.037 228.287 486.669 224.571 497.501C220.855 508.333 224.106 519.263 231.832 521.914Z" fill="#C5DFFF"/>
              <path d="M214.112 569.551C218.278 570.98 223.256 567.472 225.231 561.715C227.206 555.958 225.43 550.133 221.264 548.703C217.098 547.274 212.119 550.782 210.144 556.539C208.169 562.296 209.946 568.122 214.112 569.551Z" fill="#C5DFFF"/>
              <path d="M290.63 560.715C277.58 598.56 245.245 621.615 218.13 612.335C203.92 607.405 193.915 594.5 189.71 577.825C193.19 580.145 196.96 582.175 201.02 583.48C234.66 595.08 275.115 566.225 291.21 519.1C292.225 516.2 293.095 513.3 293.82 510.4C297.445 525.335 296.72 543.17 290.63 560.715Z" fill="#7C99F2"/>
              <path d="M320.065 651.05C310.35 669.32 304.695 677.44 304.695 677.44C304.695 677.44 289.325 685.125 259.745 687.445C243.215 688.75 222.335 688.46 197.25 684.255C171.295 679.905 154.04 674.105 142.44 668.595C122.72 659.17 119.675 650.47 119.675 650.47C119.675 650.47 118.66 637.855 121.995 613.06C128.085 618.715 136.205 625.095 146.935 631.04C163.465 640.175 185.94 648.73 216.535 653.225C239.445 656.56 261.34 656.995 280.915 655.69C295.415 654.965 308.61 653.08 320.065 651.05Z" fill="#FF97C9"/>
              <path d="M320.065 651.05C310.35 669.32 304.695 677.44 304.695 677.44C304.695 677.44 289.325 685.125 259.745 687.445C265.98 678.89 273.085 668.45 280.915 655.69C295.415 654.965 308.61 653.08 320.065 651.05Z" fill="#FC75BC"/>
              <path d="M146.935 631.186L142.44 668.741C122.72 659.316 119.675 650.616 119.675 650.616C119.675 650.616 118.66 638.001 121.995 613.206C128.085 618.861 136.205 625.096 146.935 631.186Z" fill="#FCB6DA"/>
              </svg>
          </Box>
        </GridItem>
        <GridItem colSpan={{ base: 4, lg: 2, md: 4 }} className={`m-auto py-5 ${style['section-text-401']}`}>
          <Text style={{ fontSize: '4rem', lineHeight: '75px', color: '#404040', fontWeight: 'bold' }} className='mb-5'>We are Sorry...</Text>
          <Text style={{ fontSize: '1.375rem', color: '#ABABAB' }}>The page you're trying to access has resricted access.</Text>
          <Text style={{ fontSize: '1.375rem', color: '#ABABAB' }} className='mb-5'>If you feel this is a mistake, contact your admin.</Text>
          <NavLink to='/'>
            <Button className={`${style['btn-errorpage']}`}>Go Back</Button>
          </NavLink>
        </GridItem>
      </Grid>
    </div>
  )
}

export default Forbidden