import React from "react";
import {
  Breadcrumb as BreadcrumbChakra,
  BreadcrumbItem,
  BreadcrumbLink,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

import { BiHomeAlt, BiChevronRight } from "react-icons/bi";

export default function Breadcrumb() {
  return (
    <div className="breadcrumb-container">
      <BreadcrumbChakra
        spacing="8px"
        separator={<BiChevronRight color="gray.500" />}
      >
        <BreadcrumbItem>
          &nbsp;{" "}
          <BreadcrumbLink href="/">
            <BiHomeAlt color="gray.500" />
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink href="#">Production</BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink href="#">Temporary Change</BreadcrumbLink>
        </BreadcrumbItem>
      </BreadcrumbChakra>
    </div>
  );
}
