import React from "react";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import { BiHomeAlt, BiChevronRight } from "react-icons/bi";

export default function BreadcrumbRegistration() {
  return (
    <div className="breadcrumb-container">
      <Breadcrumb spacing="8px" separator={<BiChevronRight color="gray.500" />}>
        <BreadcrumbItem>
          &nbsp;{" "}
          <BreadcrumbLink href="/">
            <BiHomeAlt color="gray.500" />
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to="/qas/product-registration/registration">
            Product Registration
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink href="#">Registration</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
    </div>
  );
}
