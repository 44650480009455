
import React from 'react'
import { Field, ErrorMessage } from 'formik'
import { Select, InputGroup, InputRightAddon, InputLeftAddon, FormLabel } from "@chakra-ui/react"
import withTrans from '../../../config/i18n/withTrans'

const SelectField = ({
    t,
    title,
    required,
    type,
    placeholder,
    name,
    id,
    leftAddon,
    rightAddon,
    leftIcon,
    rightIcon,
    toggle,
    disabled,
    width,
    data = [],
    index,
    hidden,
    bg,
    SelectedColor,
    ListDisabledOption,
    children,
    variant,
    className,
    parentMarginTop,
    leftAddOnBorderRadius,
    selectBorderRadius,
    errorMessageAllowed = true,
    isReadOnly,
    value,
    isFormikForm = true,
    ...props
}) => {

    return (
      <div style={{ marginTop: title && !parentMarginTop ? 20 : parentMarginTop, width: width ?? '100%' }} hidden={hidden}>
        {
          isFormikForm ?
            <Field name={name}>
              {({ field, form }) => (
                    <>
                      {title && (index === 0 || index === undefined) && <FormLabel htmlFor={id} className="form-label">{title} {required ? <span style={{ color: 'red' }}> *</span> : ''}</FormLabel>}
                      <InputGroup isDisabled={disabled || isReadOnly} color={value ? '#000' : '#98a7b7'}>

                          {leftAddon && 
                          <InputLeftAddon 
                            backgroundColor={isReadOnly || disabled ? '#ECECEC' : '#fff'} 
                            children={leftAddon} 
                            onClick={toggle ? toggle : ''}
                            borderRadius={leftAddOnBorderRadius}
                          />}

                          {leftIcon && (
                            <div onClick={toggle ? toggle : ''}>
                                {leftIcon}
                            </div>
                          )}
                          <Select
                            {...props}
                            id={id}
                            name={name}
                            className={form.errors[name] && form.touched[name] ? `${className} is-invalid` : `${className}`}
                            pointerEvents={disabled || isReadOnly ? 'none' :  'auto'}
                            borderRadius={selectBorderRadius}
                            value={value}
                            bg={isReadOnly || disabled  ? '#ECECEC' : bg}
                          >
                              <option value='' selected disabled>{placeholder}</option>
                              {children}
                          </Select>
                          {rightIcon && (
                              <div onClick={toggle ? toggle : ''}>
                                  {rightIcon}
                              </div>
                          )}
                          {rightAddon && <InputRightAddon children={rightAddon} onClick={toggle ? toggle : ''} />}
                      </InputGroup>
                      {
                        errorMessageAllowed && 
                        <ErrorMessage
                        name={name}
                        component="div"
                        className="invalid-feedback"
                      />
                      }

                    </>
              )}
            </Field> :
            <>
              {title && (index === 0 || index === undefined) && <FormLabel htmlFor={id} className="form-label">{title} {required ? <span style={{ color: 'red' }}> *</span> : ''}</FormLabel>}
              <InputGroup isDisabled={disabled || isReadOnly} color={value ? '#000' : '#98a7b7'}>

                  {leftAddon && 
                  <InputLeftAddon 
                    backgroundColor={isReadOnly || disabled ? '#ECECEC' : '#fff'} 
                    children={leftAddon} 
                    onClick={toggle ? toggle : ''}
                    borderRadius={leftAddOnBorderRadius}
                  />}

                  {leftIcon && (
                    <div onClick={toggle ? toggle : ''}>
                        {leftIcon}
                    </div>
                  )}
                  <Select
                    {...props}
                    id={id}
                    name={name}
                    pointerEvents={disabled || isReadOnly ? 'none' :  'auto'}
                    borderRadius={selectBorderRadius}
                    bg={isReadOnly || disabled  ? '#ECECEC' : bg}
                  >
                      <option value=''>{placeholder}</option>
                      {children}
                  </Select>
                  {rightIcon && (
                      <div onClick={toggle ? toggle : ''}>
                          {rightIcon}
                      </div>
                  )}
                  {rightAddon && <InputRightAddon children={rightAddon} onClick={toggle ? toggle : ''} />}
              </InputGroup>
            </>
      }
      
      </div>
    )
}

export default withTrans(SelectField)