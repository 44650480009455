import { Box, Grid, GridItem } from '@chakra-ui/react'
import Skeleton from 'react-loading-skeleton'

function ContentListSkeleton4Col() {
  return <Box mt={5}>
    <Grid templateColumns='repeat(4, 1fr)' gap={6}>
      <GridItem>
        <Skeleton height={20} width="50%" style={{ marginBottom: '10px' }} />
        <Skeleton height={35} />
      </GridItem>
      <GridItem>
        <Skeleton height={20} width="50%" style={{ marginBottom: '10px' }} />
        <Skeleton height={35} />
      </GridItem>
      <GridItem>
        <Skeleton height={20} width="50%" style={{ marginBottom: '10px' }} />
        <Skeleton height={35} />
      </GridItem>
      <GridItem>
      <Skeleton height={20} width="50%" style={{ marginBottom: '10px' }} />
        <Skeleton height={35} />
      </GridItem>
      <GridItem>
        <Skeleton height={35} />
      </GridItem>
      <GridItem>
        <Skeleton height={35} />
      </GridItem>
      <GridItem>
        <Skeleton height={35} />
      </GridItem>
      <GridItem>
        <Skeleton height={35} />
      </GridItem>
      <GridItem>
        <Skeleton height={35} />
      </GridItem>
      <GridItem>
        <Skeleton height={35} />
      </GridItem>
      <GridItem>
        <Skeleton height={35} />
      </GridItem>
      <GridItem>
        <Skeleton height={35} />
      </GridItem>
      <GridItem>
        <Skeleton height={35} />
      </GridItem>
      <GridItem>
        <Skeleton height={35} />
      </GridItem>
      <GridItem>
        <Skeleton height={35} />
      </GridItem>
      <GridItem>
        <Skeleton height={35} />
      </GridItem>
      <GridItem>
        <Skeleton height={35} />
      </GridItem>
      <GridItem>
        <Skeleton height={35} />
      </GridItem>
      <GridItem>
        <Skeleton height={35} />
      </GridItem>
      <GridItem>
        <Skeleton height={35} />
      </GridItem>
      <GridItem>
        <Skeleton height={35} />
      </GridItem>
      <GridItem>
        <Skeleton height={35} />
      </GridItem>
      <GridItem>
        <Skeleton height={35} />
      </GridItem>
      <GridItem>
        <Skeleton height={35} />
      </GridItem>
    </Grid>
  </Box>
}

export default ContentListSkeleton4Col
