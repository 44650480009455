import qasAPIInstance from "../APIQAS";

export const getEquipmentGroupList = async (params) => {
  try {
    const response = await qasAPIInstance.get("/v1/ToolGroup/List", { params });
    return response;
  } catch (error) {
    return error.response;
  }
};

export const insertEquipmentGroup = async (payload) => {
  try {
    const response = await qasAPIInstance.post("/v1/ToolGroup/Insert", payload);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const updateEquipmentGroup = async (payload) => {
  try {
    const response = await qasAPIInstance.post("/v1/ToolGroup/Update", payload);
    return response;
  } catch (error) {
    return error.response;
  }
};

export const deleteEquipmentGroup = async (payload) => {
  try {
    const response = await qasAPIInstance.post("/v1/ToolGroup/Delete", payload);
    return response;
  } catch (error) {
    return error.response;
  }
};
