import React, { forwardRef, useRef, useEffect, useState } from "react";
import { useTable, useRowSelect, useSortBy } from "react-table";
import styles from "./Table.module.css";
import LoaderTable from "../loader/LoaderTable";
import ReactPaginate from "react-paginate";

const Index = ({
  id,
  columns,
  data,
  clickableRow = false,
  isLoading,
  isClickable = true,
  selection,
  selectionAlign = "left",
  customHeaderSelection,
  onSelection,
  selectionRow,
  length,
  headerTextAlign = "center",
  columnTextAlign = "left",
  emptyPlaceholder,
  pagination,
  itemsPerPage,
  inputSearch = "",
  handleClickableRow,
}) => {
  const IndeterminateCheckbox = forwardRef(({ value, row }, ref) => {
    const defaultRef = useRef();

    useEffect(() => {
      defaultRef.current.checked = selection?.indexOf(value) > -1;
    }, [selection, row]);

    return (
      <>
        <label className="checkbox-container" style={{ display: "initial" }}>
          <input
            type="checkbox"
            ref={defaultRef}
            value={value}
            onChange={(e) => handleSelect(e)}
          />
          <span className="checkmark"></span>
        </label>
      </>
    );
  });

  useEffect(() => {
    let selectedData = selection ?? [];
    const index = selectedData.indexOf("all");
    if (index > -1) {
      data.forEach((item) => {
        const index = selectedData.indexOf(item.id || item.no);
        if (index === -1) {
          selectedData = [...selectedData, item.id || item.no];
        }
      });
      onSelection(selectedData);
    }
  }, [data]);

  const handleSelect = (event) => {
    let selectedData = selection ?? [];
    if (event.target.value === "all") {
      const index = selectedData.indexOf(event.target.value);
      if (index > -1) {
        selectedData = [];
      } else {
        selectedData = ["all", ...selectedData];
        data.forEach((item) => {
          selectedData = [...selectedData, item.id || item.no];
        });
      }
    } else {
      if (event.target.value) {
        const indexAll = selectedData.indexOf("all");
        if (indexAll > -1) {
          selectedData.splice(indexAll, 1);
        }
        const index = selectedData.indexOf(event.target.value);
        if (index > -1) {
          selectedData.splice(index, 1);
        } else {
          selectedData = [...selectedData, event.target.value];
        }
      }
    }
    let uniqArr = [...new Set(selectedData)];
    onSelection(uniqArr);
  };

  const { getTableProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: data ? data : [{}],
    },
    useSortBy,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        if (selectionRow) {
          let headSelection = {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                {customHeaderSelection ? (
                  customHeaderSelection
                ) : (
                  <IndeterminateCheckbox value="all" />
                )}
              </div>
            ),
            Cell: ({ row }) => {
              const id = row?.original?.id;
              return (
                <div>
                  <IndeterminateCheckbox value={id} row={rows} />
                </div>
              );
            },
          };
          if (selectionAlign === "left") {
            return [headSelection, ...columns];
          } else if (selectionAlign === "right") {
            return [...columns, headSelection];
          } else {
            return [...columns];
          }
        } else {
          return [...columns];
        }
      });
    }
  );

  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [searchPage, setSearchPage] = useState(null);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(rows.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(rows.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, rows]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % rows.length;
    setItemOffset(newOffset);
    setSearchPage(event.selected);
  };

  useEffect(() => {
    if (inputSearch !== "" || inputSearch !== null) {
      setItemOffset(0);
      setSearchPage(0);
    }
  }, [inputSearch, rows.length]);

  return (
    <div>
      <div
        className={`table-responsive ${clickableRow && styles["table-hover"]}`}
        style={{ position: "relative", minHeight: "115px" }}
      >
        <table {...getTableProps()} className="table">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps({
                      style: {
                        minWidth: column.minWidth,
                        maxWidth: column.maxWidth,
                        width: column.width,
                        textAlign: "left",
                      },
                    })}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {isLoading && (
              <tr>
                <td colSpan={columns.length} className="text-center">
                  <LoaderTable />
                </td>
              </tr>
            )}
            {!isLoading && !pagination && isClickable && (
              <>
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr
                      {...row.getRowProps()}
                      onClick={() => {
                        handleClickableRow(row.original);
                      }}
                    >
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            style={{ fontSize: 14, textAlign: columnTextAlign }}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
                {rows?.length < 1 && !isLoading && (
                  <tr>
                    <td
                      id="data-not-found"
                      colSpan={
                        headerGroups ? headerGroups[0]?.headers?.length + 1 : 3
                      }
                      style={{ textAlign: "center" }}
                    >
                      {emptyPlaceholder ? emptyPlaceholder : `Data Not Found`}
                    </td>
                  </tr>
                )}
              </>
            )}
            {!isLoading && !pagination && !isClickable && (
              <>
                {rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            style={{ fontSize: 14, textAlign: columnTextAlign }}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
                {rows?.length < 1 && !isLoading && (
                  <tr>
                    <td
                      colSpan={
                        headerGroups ? headerGroups[0]?.headers?.length + 1 : 3
                      }
                      style={{ textAlign: "center" }}
                    >
                      {emptyPlaceholder ? emptyPlaceholder : `Data Not Found`}
                    </td>
                  </tr>
                )}
              </>
            )}
            {!isLoading && pagination && (
              <>
                {currentItems.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps()}
                            style={{ fontSize: 14, textAlign: columnTextAlign }}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
                {currentItems?.length < 1 && !isLoading && (
                  <tr>
                    <td
                      colSpan={
                        headerGroups ? headerGroups[0]?.headers?.length + 1 : 3
                      }
                      style={{ textAlign: "center" }}
                    >
                      {emptyPlaceholder ? emptyPlaceholder : `Data Not Found`}
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
      <div className="ml-auto mt-3" style={{ width: "fit-content" }}>
        {pagination && itemsPerPage && !isLoading && (
          <>
            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              nextLinkClassName={"page-link next"}
              onPageChange={handlePageClick}
              pageRangeDisplayed={1}
              pageCount={pageCount}
              forcePage={searchPage}
              previousLabel="<"
              previousLinkClassName={"page-link previous"}
              renderOnZeroPageCount={null}
              className="pagination"
              containerClassName={"pagination justify-content-end"}
              pageClassName={"page-item non-active"}
              breakClassName={"page-item non-active"}
              pageLinkClassName={"page-link"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Index;
