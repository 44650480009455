import {
  Button,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalHeader,
  ModalContent,
  ModalFooter,
  useDisclosure,
  Box,
  Text,
  ModalCloseButton,
} from "@chakra-ui/react";
import Input from "pages/PES/components/form/Input";
import { Form, Formik } from "formik";
import React, { useState, useRef } from "react";
import * as Yup from "yup";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { PostPresenceOut } from "../../../services/actions/PES/Presence";
import { toast } from "react-toastify";

const PresenceOut = ({ t, reloadPage }) => {
  const formikRef = useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [show, setShow] = useState(false);
  const [toastObject, setToastObject] = useState("");

  const initialRef = useRef();
  const finalRef = useRef();

  const requiredMessage = t("commons.requiredField");

  const TogglePassword = () => setShow(!show);

  const initial = {
    username: username,
    password: password,
  };

  const validation = Yup.object().shape({
    username: Yup.string().required(requiredMessage).nullable(),
    password: Yup.string().required(requiredMessage).nullable(),
  });

  async function handleSubmitPresenceOut(actions) {
    let data = { username, password };
    let response = await PostPresenceOut(data);
    if (response?.status && response?.status !== 200) {
      setToastObject(
        <div style={{ height: "0", overflow: "hidden" }}>
          {toast.error(response.data.message)}
        </div>
      );
      actions.setSubmitting(false);
    } else if (response?.status && response?.status === 200) {
      setToastObject(
        <div style={{ height: "0", overflow: "hidden" }}>
          {toast.success(t("pes.presence.successOut"))}
        </div>
      );
      reloadPage();
      handleClose();
    }
  }

  const handleClose = () => {
    setUsername("");
    setPassword("");
    onClose();
  };

  return (
    <>
      <div>{toastObject}</div>
      <Box
        onClick={onOpen}
        className="px-3 py-4 text-center"
        style={{ border: "6px solid rgba(255, 0, 0, 1)", borderRadius: "22px" }}
      >
        <svg
          className="mx-auto"
          xmlns="http://www.w3.org/2000/svg"
          width="50"
          height="69"
          viewBox="0 0 70 69"
          fill="none"
        >
          <path
            d="M50.6667 38.3725V30.6276H18.0952V19.0103L0 34.5L18.0952 49.9898V38.3725H50.6667Z"
            fill="#FF0000"
          />
          <path
            d="M62.8217 0H30.514C26.5545 0 23.3345 3.4385 23.3345 7.66667V23H30.514V7.66667H62.8217V61.3333H30.514V46H23.3345V61.3333C23.3345 65.5615 26.5545 69 30.514 69H62.8217C66.7811 69 70.0011 65.5615 70.0011 61.3333V7.66667C70.0011 3.4385 66.7811 0 62.8217 0Z"
            fill="#FF0000"
          />
        </svg>
        <Text style={{ color: "#FF0000" }}>PRESENCE OUT</Text>
      </Box>

      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={handleClose}
        closeOnOverlayClick={true}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Presence Out</ModalHeader>
          <Formik
            innerRef={formikRef}
            enableReinitialize
            initialValues={initial}
            initialTouched={{
              username: true,
              password: false,
            }}
            validationSchema={validation}
            onSubmit={(values, actions) => {
              handleSubmitPresenceOut(actions);
            }}
          >
            {({ touched, errors, isSubmitting }) => (
              <Form>
                <ModalCloseButton />
                <ModalBody>
                  <Input
                    name="username"
                    id="username"
                    title={t("pes.presence.username")}
                    classNameField="mt-0"
                    placeholder="Enter username"
                    onChange={(e) => {
                      setUsername(e.target.value);
                    }}
                    required
                  />
                  <Input
                    name="password"
                    id="password"
                    title={t("pes.presence.password")}
                    placeholder="Enter password"
                    type={show ? "text" : "password"}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    required
                    toggleIcon={
                      show ? (
                        <BsEye color="inherit" />
                      ) : (
                        <BsEyeSlash color="inherit" />
                      )
                    }
                    toggle={TogglePassword}
                  />
                </ModalBody>
                <ModalFooter>
                  <Button
                    isLoading={isSubmitting}
                    loadingText="Submitting"
                    spinnerPlacement="start"
                    type="submit"
                    className="w-100 btn-cm-primary font-14 text-weight-normal"
                  >
                    Check Out
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PresenceOut;
