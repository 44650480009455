import { Field, ErrorMessage } from "formik";
import {
  Input,
  InputGroup,
  InputRightAddon,
  InputLeftAddon,
  FormLabel,
  InputRightElement,
  Box,
  Textarea,
} from "@chakra-ui/react";
import withTrans from "../../../config/i18n/withTrans";

const InputLogin = ({
  t,
  title,
  required,
  type,
  placeholder,
  name,
  id,
  leftAddon,
  rightAddon,
  leftIcon,
  toggleIcon,
  toggle,
  disabled,
  width,
  ref,
  classNameField,
  value,
  index,
  onChange,
  BackgroundColor,
  isReadOnly,
  className = "",
  style,
  onWheel,
  isOnFocus,
  parentMarginTop,
  leftAddOnBorderRadius,
  isErrorAbsolute,
  centerFeedback,
  ...rest
}) => {
  return (
    <div style={{ width: width ?? "100%", marginTop: parentMarginTop }}>
      <Field name={name}>
        {({ field, form }) => (
          <div style={{ marginTop: title ? 20 : 0 }} className={classNameField}>
            {title && (
              <FormLabel htmlFor={id} className="form-label">
                {title}{" "}
                {required ? <span style={{ color: "red" }}> *</span> : ""}
              </FormLabel>
            )}
            <InputGroup style={{ borderColor: "#CBD5E0" }}>
              {leftAddon && (
                <InputLeftAddon
                  backgroundColor={isReadOnly ? "#F8F8F8" : BackgroundColor}
                  children={leftAddon}
                  onClick={toggle}
                  borderRadius={leftAddOnBorderRadius}
                />
              )}
              {leftIcon && <div onClick={toggle}>{leftIcon}</div>}
              {type === "textarea" ? (
                <Textarea
                  {...field}
                  id={id}
                  color={value ? "#000" : "#98a7b7"}
                  backgroundColor={isReadOnly ? "#F8F8F8" : BackgroundColor}
                  readOnly={isReadOnly}
                  disabled={disabled}
                  className={
                    form.errors[name] && form.touched[name]
                      ? `${className} is-invalid`
                      : `${className}`
                  }
                  placeholder={
                    placeholder ?? t("commons.placeholder") + " " + title
                  }
                  type={type ?? "text"}
                  ref={ref}
                  value={value}
                  style={style}
                  onChange={onChange}
                  onWheel={onWheel}
                  {...rest}
                />
              ) : (
                <Input
                  {...field}
                  id={id}
                  color={value ? "#000" : "#98a7b7"}
                  backgroundColor={isReadOnly ? "#F8F8F8" : BackgroundColor}
                  readOnly={isReadOnly}
                  disabled={disabled}
                  className={
                    form.errors[name] && form.touched[name]
                      ? `${className} is-invalid`
                      : `${className}`
                  }
                  placeholder={
                    placeholder ?? t("commons.placeholder") + " " + title
                  }
                  type={type ?? "text"}
                  ref={ref}
                  value={value}
                  style={style}
                  onChange={onChange}
                  onWheel={onWheel}
                  {...rest}
                />
              )}
              {toggleIcon && (
                <InputRightElement width="5rem" zIndex={isOnFocus ? "1" : "0"}>
                  <Box onClick={toggle}>{toggleIcon}</Box>
                </InputRightElement>
              )}
              {rightAddon && (
                <InputRightAddon children={rightAddon} onClick={toggle} />
              )}
            </InputGroup>
            <ErrorMessage
              name={name}
              component="div"
              className="invalid-feedback"
              style={{
                position: isErrorAbsolute ? "absolute" : "",
                width: "100%",
                textAlign: centerFeedback ? "center" : "",
              }}
            />
          </div>
        )}
      </Field>
    </div>
  );
};

export default withTrans(InputLogin);
