import React from 'react'
import {
    Modal,
    ModalOverlay,
    ModalHeader,
    ModalCloseButton,
    ModalContent,
    ModalBody,
    ModalFooter,
} from '@chakra-ui/react'

const ModalConfirmationQCS = ({
    isOpen,
    onClose,
    Header,
    blockScrollOnMount,
    Body,
    footer,
    size,
    headerContentAlign,
    headerContentAlignItems,
    bodyContentAlign,
    bodyContentAlignItems,
    footerContentAlign,
    footerContentAlignItems,
    textAlignBody = 'center',
    minWidthModal,
}) => {

    return (
        <Modal blockScrollOnMount={blockScrollOnMount} isOpen={isOpen} onClose={onClose} size={size} >
            <ModalOverlay />
            <ModalContent minWidth={minWidthModal}>
                <ModalHeader justifyContent={headerContentAlign} alignItems={headerContentAlignItems}>
                  {Header}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody justifyContent={bodyContentAlign} alignItems={bodyContentAlignItems} textAlign={textAlignBody}>
                  {Body}
                </ModalBody>
                <ModalFooter pb={8} justifyContent={footerContentAlign} alignItems={footerContentAlignItems} >
                  {footer}
                </ModalFooter>
            </ModalContent>
        </Modal> 
    )
}

export default ModalConfirmationQCS