import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getUser } from "../../../utils/User";
import { useSelector, useDispatch } from 'react-redux'
import { useCookies } from 'react-cookie'
import { POST_AUTH_LOGIN_SUCCESS } from "../../../store/actions/Auth";

const PublicRoute = ({
    component: Component,
    ...rest
}) => {
  const isLogin = useSelector((state) => state.auth.isLogin)
  const dataUser = useSelector(getUser)
  const dispatch = useDispatch()
  const [cookies] = useCookies(['access_token', 'refresh_token'])

  return (
  <Route
    {...rest}
    render={(props) => {

      if(!isLogin && dataUser.accessToken === '') {
        if((cookies.access_token && cookies.refresh_token) &&
        (cookies.access_token !== 'undefined' && cookies.refresh_token !== 'undefined')) {
          const newUserData = {
          accessToken: cookies.access_token,
          endpoint: null,
          menu: null,
          refreshToken: cookies.refresh_token
          }

          dispatch(POST_AUTH_LOGIN_SUCCESS(newUserData))

          return (
            <Redirect to={{ pathname: "/dashboard", state: { from: props.location } }} />
          )
        }
      }

        return (
            <Component {...props} />
        );
    }}
  />);
}

export default PublicRoute;