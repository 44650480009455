import React from "react";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import { Link, useHistory } from "react-router-dom";

import { BiHomeAlt, BiChevronRight } from "react-icons/bi";

export default function BreadCrumbProductionExecutionPES() {
  const history = useHistory();
  const parentPage = history.location.pathname
    .split("/")
    .slice(0, -1)
    .join("/");
  return (
    <div className="breadcrumb-container">
      <Breadcrumb spacing="8px" separator={<BiChevronRight color="gray.500" />}>
        <BreadcrumbItem>
          &nbsp;{" "}
          <BreadcrumbLink as={Link} to="/">
            <BiHomeAlt color="gray.500" />
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem>
          <BreadcrumbLink as={Link} to={parentPage}>
            Production
          </BreadcrumbLink>
        </BreadcrumbItem>

        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink to="#">Production Execution</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
    </div>
  );
}
