import { Menu, MenuButton, MenuItem, MenuList } from "@chakra-ui/react";
import NotificationBellSvg from "../../../assets/img/icons/NotificationBellSvg";
import React, { useEffect, useRef, useState } from "react";
import {
  collection,
  onSnapshot,
  query,
  updateDoc,
  limit,
  orderBy,
  doc,
  where,
} from "firebase/firestore";
import { firestore } from "../../../config/firebase";
import { useHistory } from "react-router-dom";
import "./notification.css";
import moment from "moment";
import { updateNotificationStatusById } from "../../../services/actions/Notification";
import { useSelector } from "react-redux";
import { getNikUser } from "../../../utils/User";

const Notification = (props) => {
  const { NIK } = props;
  const history = useHistory();
  const myRef = useRef();
  const [notificationData, setNotificationData] = useState([]);
  const [areThereUnviewedData, setAreThereUnviewedData] = useState(false);

  useEffect(async () => {
    const unsubscribe = handleListenSnapshot();
    return () => unsubscribe();
  }, []);

  useEffect(async () => {
    const unsubscribe = handleListenUnviewedData();
    return () => unsubscribe();
  }, []);

  const handleListenUnviewedData = () => {
    try {
      let queryData = query(
        collection(firestore, "users", ...[NIK, "notifications"]),
        where("isViewed", "==", false)
      );

      return onSnapshot(queryData, (snapshot) => {
        let newData = snapshot?.docs?.map((doc) => {
          return doc.data();
        });

        if (!newData || newData.length == 0) {
          setAreThereUnviewedData(false);
        } else {
          setAreThereUnviewedData(true);
        }
      });
    } catch (e) {
      setAreThereUnviewedData(false);
    }
  };

  const handleListenSnapshot = () => {
    try {
      let queryData = query(
        collection(firestore, "users", ...[NIK, "notifications"]),
        orderBy("createdAt", "desc"),
        limit(25)
      );

      return onSnapshot(queryData, (snapshot) => {
        let newData = snapshot?.docs?.map((doc) => {
          return doc.data();
        });

        setNotificationData(newData);
      });
    } catch (e) {}
  };

  const handleRenderNotification = () => {
    if (notificationData && notificationData.length > 0) {
      return notificationData.map((item, index) => {
        let date = moment.unix(item.createdAt?.seconds).format("D MMMM yyyy");
        let time = moment.unix(item.createdAt?.seconds).format("H:mm");

        return (
          <div
            onClick={() => handleClickNotificationData(item)}
            style={{
              cursor: "pointer",
              zIndex: "100",
              marginRight: "0",
              backgroundColor: !item.isViewed ? "aliceblue" : "",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "15px",
                  height: "15px",
                  minHeight: "15px",
                  minWidth: "15px",
                  borderRadius: 999,
                  backgroundColor: item.colour,
                  marginLeft: ".8em",
                }}
              ></div>
              <div
                style={{
                  borderBottom:
                    index != notificationData.length - 1
                      ? "1px solid rgba(196, 196, 196, 0.43)"
                      : "",
                  padding: ".8em",
                  width: "100%",
                }}
              >
                <h1 style={{ fontWeight: "600", color: "#2e2e2e" }}>
                  {item.header}
                </h1>
                <p className={"notification-subline-text"}>{item.subLine}</p>
                <div
                  style={{
                    display: "flex",
                    fontSize: ".8em",
                    alignItems: "center",
                    marginTop: "5px",
                  }}
                >
                  {date} <div className={"time-circle"} /> {time}
                </div>
              </div>
            </div>
          </div>
        );
      });
    }

    return (
      <div style={{ padding: "1em" }}> There are no notification available</div>
    );
  };
  const handleClickNotificationData = async (data) => {
    if (data.path) {
      history.push(data.path);
    }
    if (!data.isViewed) {
      let payload = {
        id: data.dataId,
        nik: NIK,
      };
      await updateNotificationStatusById(payload);
    }
  };

  return (
    <Menu placement="bottom-end" autoSelect={false} isLazy>
      <MenuButton className="notif-global">
        <NotificationBellSvg
          style={{ cursor: "pointer" }}
          areThereNewData={areThereUnviewedData}
        />
      </MenuButton>
      <div className={"notification-popup"} ref={myRef}>
        <MenuList
          className="menu-topbar"
          zIndex={3}
          style={{
            zIndex: "100",
            maxWidth: "23vw",
            padding: "0",
          }}
        >
          <div
            style={{
              maxHeight: "30vh",
              overflowY: "auto",
              padding: "0",
              marginRight: "0",
            }}
          >
            {handleRenderNotification()}
          </div>
          {notificationData && notificationData.length > 0 ? (
            <MenuItem
              style={{
                height: "5vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderTop: "1px solid rgba(196, 196, 196, 0.43)",
              }}
              closeOnSelect
              onClick={() => history.push("/notifications")}
            >
              <span style={{ color: "#1FB4D2", cursor: "pointer" }}>
                View all notification
              </span>
            </MenuItem>
          ) : (
            ""
          )}
        </MenuList>
      </div>
    </Menu>
  );
};

export default Notification;
