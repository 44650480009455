
import { 
  Button,  Modal, Box,
  ModalOverlay, ModalContent, ModalHeader,
  ModalFooter, ModalBody,
  Grid, GridItem
} from '@chakra-ui/react'
import moment from 'moment'

const ModalRejectedNotif = ({
  isOpenReject, onCloseReject, initialRefReject,
  reviewerName, rejectedDate, rejectedMessage
}) => {

  return <Modal isOpen={isOpenReject} onClose={onCloseReject} initialFocusRef={initialRefReject}>
  <ModalOverlay />
  <ModalContent className='modalSamplingReject'>
    <ModalHeader textAlign='center'>Notification</ModalHeader>
    <ModalBody>
      <Box textAlign='center' mb={4}>{rejectedMessage}</Box>
    </ModalBody>
    <Grid templateColumns='repeat(2, 1fr)' gap={8} style={{padding: '10px 1.9em', borderTop:'1px solid #cacaca', borderBottom:'1px solid #cacaca'}}
    >
      <GridItem w='100%' mt={4} mb={4}>
        <Box mb={3}>
          <b>Reviewer</b>
        </Box>
        {reviewerName}
      </GridItem>
      <GridItem w='100%' mt={4} mb={4}>
        <Box mb={3}>
          <b>Tanggal</b>
        </Box>
        {moment(rejectedDate).format('D MMM yyyy')}
      </GridItem>
    </Grid>
    <ModalFooter pt={4} pb={4}>
      <Button 
        colorScheme='blue' 
        variant='outline' 
        onClick={onCloseReject}
        style={{margin:'0 auto', color:'#1fb4d2', border:'2px solid #1fb4d2'}}
      >
        Close
      </Button>
    </ModalFooter>
  </ModalContent>
</Modal>
}

export default ModalRejectedNotif