import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  productionPlan: null,
  isLoading: true,
};

export const productionPlanSlice = createSlice({
  name: "productionPlan",
  initialState,
  reducers: {
    setProductionPlan(state, action) {
      state.productionPlan = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
  },
});

export const { setProductionPlan, setIsLoading } = productionPlanSlice.actions;
export default productionPlanSlice.reducer;
