import React from "react";
import { Field } from "formik";
import {
  FormLabel,
  HStack,
  Button,
  Box,
  NumberInput,
  NumberInputField,
  Text,
  InputGroup,
  InputRightElement,
  InputRightAddon,
} from "@chakra-ui/react";
import withTrans from "../../config/i18n/withTrans";
import { toast } from "react-toastify";
import { TestList, TestParameter } from "../../utils/ConstantGlobal";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";

const InputNumber = ({
  t,
  title,
  required,
  placeholder,
  name,
  id,
  disabled,
  width,
  value,
  onChange,
  index,
  LeftFormat,
  onCLickDec,
  onCLickInc,
  isReadOnly,
  BackgroundColor,
  minValue = 0,
  maxValue,
  testParameter,
  numberInputTitle,
  btnIncrementDecrement = true,
  rightFormLabelIcon,
  childredRightElement,
  childredRightAddOn,
  parentMarginTop,
  clampValueOnBlur,
  upperCaseLabel = true,
  ...otherProps
}) => {
  const format = (val) => val + ` ${LeftFormat}`;
  return (
    <div style={{ width: width ?? "100%", marginTop: parentMarginTop }}>
      <div style={{ marginTop: title ? 20 : 0 }}>
        <Field name={name} disabled={disabled}>
          {({ field, form }) => (
            <Box>
              {title && (index === 0 || index === undefined) && (
                <FormLabel
                  htmlFor={id}
                  fontSize="0.75rem"
                  fontWeight="600"
                  textTransform={upperCaseLabel && "uppercase"}
                >
                  <Text>
                    {title}{" "}
                    {required ? <span style={{ color: "red" }}> *</span> : ""}
                    <span>{rightFormLabelIcon}</span>
                  </Text>
                </FormLabel>
              )}
              <HStack>
                {btnIncrementDecrement && (
                  <Button
                    onClick={onCLickDec}
                    borderBottomRightRadius={0}
                    borderTopRightRadius={0}
                    disabled={
                      disabled ||
                      isReadOnly ||
                      (minValue !== 0 && value !== 0
                        ? minValue && value <= minValue
                        : testParameter === TestParameter.FingerDab ||
                          testParameter === TestParameter.SettlePLate
                        ? value <= minValue
                        : value > minValue
                        ? false
                        : true)
                    }
                    backgroundColor="#fff"
                    borderTop="1px solid #e2e8f0"
                    borderBottom="1px solid #e2e8f0"
                    borderLeft="1px solid #e2e8f0"
                  >
                    <AiOutlineMinus size="1.3rem" />
                  </Button>
                )}
                <InputGroup w="100%" style={{ marginInlineStart: "0rem" }}>
                  <NumberInput
                    clampValueOnBlur={
                      clampValueOnBlur !== null ? clampValueOnBlur : true
                    }
                    w="100%"
                    pointerEvents={isReadOnly ? "none" : "auto"}
                    onChange={onChange}
                    value={LeftFormat ? format(value) : value}
                    backgroundColor={isReadOnly ? "#ECECEC" : BackgroundColor}
                    isReadOnly={isReadOnly}
                    min={minValue}
                    max={maxValue ? maxValue : undefined}
                    disabled={disabled}
                    onBlur={(e) => {
                      const number = e.target.value;

                      if (
                        minValue &&
                        numberInputTitle &&
                        number < minValue &&
                        numberInputTitle.toLowerCase() ===
                          TestList.SamplingPoint.toLowerCase()
                      ) {
                        toast.error(
                          `Sampling Point at ${testParameter} can't be less than ${minValue}`
                        );
                      } else if (
                        minValue &&
                        numberInputTitle &&
                        number > maxValue &&
                        numberInputTitle.toLowerCase() ===
                          TestList.SamplingPoint.toLowerCase()
                      ) {
                        toast.error(
                          `Sampling Point at ${testParameter} can't be more than ${maxValue}`
                        );
                      } else if (
                        maxValue &&
                        numberInputTitle &&
                        number > maxValue &&
                        numberInputTitle.toLowerCase() ===
                          TestList.SamplingPoint.toLowerCase()
                      ) {
                        toast.error(
                          `Sampling Point at ${testParameter} can't be more than ${maxValue}`
                        );
                      } else if (
                        maxValue === 0 &&
                        number > maxValue &&
                        numberInputTitle.toLowerCase() ===
                          TestList.SamplingPoint.toLowerCase()
                      ) {
                        toast.error(
                          `Sampling Point at ${testParameter} can't be more than ${maxValue}`
                        );
                      } else if (
                        maxValue === 0 &&
                        parseInt(number) === 0 &&
                        numberInputTitle.toLowerCase() ===
                          TestList.SamplingPoint.toLowerCase()
                      ) {
                        toast.error(
                          `Sampling Point at ${testParameter} can't be more than ${maxValue}`
                        );
                      }
                    }}
                    {...otherProps}
                  >
                    <NumberInputField
                      borderStartRadius={btnIncrementDecrement && 0}
                      borderEndRadius={btnIncrementDecrement && 0}
                    />
                  </NumberInput>
                  {childredRightElement && (
                    <InputRightElement width="3rem">
                      {childredRightElement}
                    </InputRightElement>
                  )}
                  {childredRightAddOn && (
                    <InputRightAddon borderRadius={0}>
                      {childredRightAddOn}
                    </InputRightAddon>
                  )}
                </InputGroup>
                {btnIncrementDecrement && (
                  <Button
                    style={{ marginInlineStart: "0rem" }}
                    onClick={onCLickInc}
                    borderBottomLeftRadius={0}
                    borderTopLeftRadius={0}
                    disabled={
                      disabled ||
                      isReadOnly ||
                      (maxValue !== 0 && value !== 0
                        ? maxValue && value >= maxValue
                        : testParameter === TestParameter.FingerDab ||
                          testParameter === TestParameter.SettlePLate
                        ? false
                        : maxValue !== 0
                        ? false
                        : true)
                    }
                    backgroundColor="#fff"
                    borderTop="1px solid #e2e8f0"
                    borderBottom="1px solid #e2e8f0"
                    borderRight="1px solid #e2e8f0"
                  >
                    <AiOutlinePlus size="1.3rem" />
                  </Button>
                )}
              </HStack>
            </Box>
          )}
        </Field>
      </div>
    </div>
  );
};

export default withTrans(InputNumber);
