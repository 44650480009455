import React, { useState, useRef, useEffect } from "react";
import { map, isNil, isEmpty } from "lodash";
import { MdDateRange } from "react-icons/md";
import {
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  SimpleGrid,
  Text,
  useOutsideClick,
  VStack,
  InputGroup,
  InputRightElement,
  FormLabel,
  IconButton,
  Stack,
} from "@chakra-ui/react";
import { useDayzed } from "dayzed";
import { format, set } from "date-fns";
import moment from "moment-timezone";
import { BsFillCaretLeftFill, BsFillCaretRightFill } from "react-icons/bs";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { BiTimeFive } from "react-icons/bi";

const DAY_NAMES = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const DATE_FORMAT = "dd MMM yyyy";
const DATE_FORMAT_TIME = "dd MMM yyyy HH:mm";
const MONTH_FORMAT = "MMMM yyyy";
const YEAR_FORMAT = "yyyy";
const TIME_ONLY_FORMAT = "HH:mm";
const ONLY_DATE_FORMAT = "yyyy-MM-dd";
// TODO-UnUse: Format Ini masih belum ke pake
// const DATE_TIME_FORMAT = "yyyy-MM-dd HH:mm:ss";

const SingleDatepickerBackMonthButtons = (props) => {
  const { calendars, getBackProps } = props;
  return (
    <Button
      id="button-prevDate"
      {...getBackProps({ calendars })}
      variant="ghost"
      size="sm"
    >
      <BsFillCaretLeftFill />
    </Button>
  );
};

const SingleDatepickerForwardMonthButtons = (props) => {
  const { calendars, getForwardProps } = props;

  return (
    <Button
      id="button-nextDate"
      {...getForwardProps({ calendars })}
      variant="ghost"
      size="sm"
    >
      <BsFillCaretRightFill />
    </Button>
  );
};

const SingleDatepickerBackYearButtons = (props) => {
  const {
    calendars,
    getBackProps,
    type,
    onResetMonth,
    handleChangeYear,
    minDate,
  } = props;
  return (
    <Button
      id="button-prevDate"
      variant="ghost"
      size="sm"
      onClick={(e) => {
        onResetMonth();
        getBackProps({
          calendars,
          offset: type === "year" ? 120 : 12,
        }).onClick(e);
        handleChangeYear(calendars[0].year, minDate);
      }}
    >
      <BsFillCaretLeftFill />
    </Button>
  );
};

const SingleDatepickerForwardYearButtons = (props) => {
  const {
    calendars,
    getForwardProps,
    type,
    onResetMonth,
    handleChangeYear,
    minDate,
  } = props;

  return (
    <Button
      id="button-nextDate"
      variant="ghost"
      size="sm"
      onClick={(e) => {
        getForwardProps({
          calendars,
          offset: type === "year" ? 120 : 12,
        }).onClick(e);
        onResetMonth();
        handleChangeYear(calendars[0].year, minDate);
      }}
    >
      <BsFillCaretRightFill />
    </Button>
  );
};

const SingleDatepickerCalendar = (props) => {
  const {
    calendars,
    getDateProps,
    getBackProps,
    getForwardProps,
    currentDate,
    monthNames,
    setMonthNames,
    type,
    setCurrentDate,
    years,
    setYears,
    handleChangeYear,
    minDate,
  } = props;
  let year1 = [];
  if (isEmpty(calendars)) {
    return null;
  }
  // if (type === "year") {
  //   year1 = [];
  //   const formattedDate = moment(currentDate).format("YYYY");
  //   const getfirst2digitYear = formattedDate.substring(0, 2);
  //   let getThirdDigitYear = formattedDate.slice(2, 3);
  //   for (let index = 0; index < 12; index++) {
  //     if (index > 9) {
  //       year1.push({
  //         label: `${getfirst2digitYear}${parseInt(getThirdDigitYear) + 1}${
  //           index - 10
  //         }`,
  //         selected: false,
  //       });
  //     } else {
  //       year1.push({
  //         label: `${getfirst2digitYear}${getThirdDigitYear}${index}`,
  //         selected: false,
  //       });
  //     }
  //   }
  // }
  const onResetMonth = () => {
    setMonthNames((monthsName) =>
      monthsName.filter((month) => {
        month.selected = false;
        return true;
      })
    );
  };
  return (
    <Box>
      <HStack spacing={6} alignItems="baseline">
        {map(calendars, (calendar) => {
          return (
            <VStack key={`${calendar.month}${calendar.year}`} className="w-100">
              <HStack>
                {type === "day" && (
                  <>
                    <SingleDatepickerBackMonthButtons
                      calendars={calendars}
                      getBackProps={getBackProps}
                    />
                    <Heading size="xs" textAlign="center">
                      {monthNames[calendar.month].label}
                    </Heading>
                    <SingleDatepickerForwardMonthButtons
                      calendars={calendars}
                      getForwardProps={getForwardProps}
                    />
                  </>
                )}

                <SingleDatepickerBackYearButtons
                  calendars={calendars}
                  getBackProps={getBackProps}
                  type={type}
                  onResetMonth={onResetMonth}
                  handleChangeYear={handleChangeYear}
                  minDate={minDate}
                />
                <Heading size="xs" textAlign="center">
                  {type === "year"
                    ? `${years?.[0]?.label} - ${years?.[11]?.label}`
                    : calendar.year}
                </Heading>
                <SingleDatepickerForwardYearButtons
                  calendars={calendars}
                  getForwardProps={getForwardProps}
                  type={type}
                  onResetMonth={onResetMonth}
                  handleChangeYear={handleChangeYear}
                  minDate={minDate}
                />
              </HStack>
              <Divider />
              <SimpleGrid
                columns={type === "day" ? 7 : 4}
                spacing={1}
                textAlign="center"
              >
                {type === "day" &&
                  map(DAY_NAMES, (day) => (
                    <Box key={`${calendar.month}${calendar.year}${day}`}>
                      <Text fontSize="xs" fontWeight="normal">
                        {day}
                      </Text>
                    </Box>
                  ))}
                {type === "day" &&
                  map(calendar.weeks, (week, weekIndex) => {
                    return map(week, (dateObj, index) => {
                      const { date, today, prevMonth, nextMonth, selectable } =
                        dateObj;

                      let isSelected = false;

                      if (
                        date.getDate() === currentDate.getDate() &&
                        date.getMonth() === currentDate.getMonth() &&
                        date.getFullYear() === currentDate.getFullYear()
                      ) {
                        isSelected = true;
                      }

                      const key = `${calendar.month}${calendar.year}${weekIndex}${index}`;
                      const isDisabled = prevMonth || nextMonth;

                      const style = () => {
                        const obj = {
                          variant: "outline",
                          borderColor: "transparent",
                          color: "inherit",
                          borderRadius: "50%",
                          height: "32px",
                          fontWeight: 500,
                          bg: "inherit",
                        };

                        if (!selectable) {
                          obj.disabled = true;
                        }

                        if (today) {
                          obj.borderColor = "#1fb4d2";
                        }

                        if (isSelected) {
                          obj.bg = "#1fb4d2";
                          obj.color = "#fff";
                        }

                        return obj;
                      };

                      return (
                        <Button
                          {...getDateProps({
                            dateObj,
                            disabled: isDisabled,
                          })}
                          className="date-chakra"
                          key={key}
                          size="xs"
                          {...style()}
                        >
                          {date.getDate()}
                        </Button>
                      );
                    });
                  })}
                {type === "month" &&
                  map(monthNames, (month, monthIndex) => {
                    const key = `${month.label}${calendar.year}${monthIndex}`;

                    const style = () => {
                      const obj = {
                        variant: "outline",
                        borderColor: "transparent",
                        color: "inherit",
                        // borderRadius: "50%",
                        height: "32px",
                        fontWeight: 500,
                        bg: "inherit",
                      };

                      // if (!selectable) {
                      //   obj.disabled = true;
                      // }

                      // if (today) {
                      //   obj.borderColor = "#1fb4d2";
                      // }

                      if (month.selected) {
                        obj.bg = "#1fb4d2";
                        obj.color = "#fff";
                      }

                      return obj;
                    };

                    return (
                      <Button
                        id={`button-month-${month.label}`}
                        // {...getDateProps({
                        //   dateObj,
                        //   disabled: isDisabled,
                        // })}
                        className="date-chakra"
                        key={key}
                        size="xs"
                        {...style()}
                        onClick={() => {
                          setMonthNames((data) =>
                            data.filter((monthname) => {
                              monthname.selected =
                                month.label === monthname.label;
                              return true;
                            })
                          );
                          setCurrentDate(
                            moment(
                              month.label + calendar.year,
                              "MMMYYYY"
                            ).toDate()
                          );
                        }}
                      >
                        {month.label}
                      </Button>
                    );
                  })}
                {type === "year" &&
                  map(years, (year, yearIndex) => {
                    const key = `${year.label}${yearIndex}`;

                    const style = () => {
                      const obj = {
                        variant: "outline",
                        borderColor: "transparent",
                        color: "inherit",
                        // borderRadius: "50%",
                        height: "32px",
                        fontWeight: 500,
                        bg: "inherit",
                      };

                      if (year.disabled) {
                        obj.disabled = true;
                      }

                      // if (today) {
                      //   obj.borderColor = "#1fb4d2";
                      // }

                      if (year.selected) {
                        obj.bg = "#1fb4d2";
                        obj.color = "#fff";
                      }

                      return obj;
                    };

                    return (
                      <Button
                        id={`button-year-${year.label}`}
                        className="date-chakra"
                        key={key}
                        size="xs"
                        {...style()}
                        onClick={() => {
                          setYears((data) =>
                            data.filter((yearValue) => {
                              yearValue.selected =
                                yearValue.label === year.label;
                              return true;
                            })
                          );
                          setCurrentDate(moment(year.label, "YYYY").toDate());
                        }}
                      >
                        {year.label}
                      </Button>
                    );
                  })}
              </SimpleGrid>
            </VStack>
          );
        })}
      </HStack>
    </Box>
  );
};

export const CustomDatePicker = ({
  value,
  disabled = false,
  onChange,
  type = "day",
  title,
  required,
  id,
  minDate,
  maxDate,
  size,
  BackgroundColor,
  width,
  inlineInput = false,
  placeHolder,
  zIndexs,
  index,
  timePicker = false,
  placement = "bottom-start",
  isError,
  borderColor,
  name,
  errorMessageAllowed = true,
  parentMarginTop,
  timePickerOnly,
  initialTime,
  onFocus,
  defaultValue,
  ...rest
}) => {
  const [currentDate, setCurrentDate] = useState(
    value
      ? new Date(moment(value).tz("Asia/Jakarta").format())
      : new Date(value)
  );

  const [monthNames, setMonthNames] = useState([
    { label: "Jan", selected: false },
    { label: "Feb", selected: false },
    { label: "Mar", selected: false },
    { label: "Apr", selected: false },
    { label: "May", selected: false },
    { label: "Jun", selected: false },
    { label: "Jul", selected: false },
    { label: "Aug", selected: false },
    { label: "Sep", selected: false },
    { label: "Oct", selected: false },
    { label: "Nov", selected: false },
    { label: "Dec", selected: false },
  ]);

  const [years, setYears] = useState([]);

  const ref = useRef(null);
  const initialFocusRef = useRef(null);
  const initialRender = useRef(true);

  const [proposedDate, setProposedDate] = useState(
    value && !isNaN(value)
      ? format(
          value,
          timePicker
            ? timePickerOnly
              ? TIME_ONLY_FORMAT
              : DATE_FORMAT_TIME
            : type === "day"
            ? DATE_FORMAT
            : type === "month"
            ? MONTH_FORMAT
            : YEAR_FORMAT
        )
      : ""
  );

  const initialValue =
    value && !isNaN(value)
      ? new Date(value)
      : minDate
      ? new Date(minDate)
      : new Date();

  const [popoverOpen, setPopoverOpen] = useState(false);
  const [timePopover, setTimePopover] = useState(false);
  const [hour, setHour] = useState(new Date().getHours());
  const [minute, setMinute] = useState(new Date().getMinutes());
  const [maxHour, setMaxHour] = useState(0);
  const [maxMinute, setMaxMinute] = useState(0);
  const [minHour, setMinHour] = useState(0);
  const [minMinute, setMinMinute] = useState(0);

  const onChangePrime = (date) => {
    const today = new Date();
    const current = new Date(date);
    const newDate =
      timePicker || timePickerOnly
        ? moment(date).tz("Asia/Jakarta").format()
        : format(
            set(current, {
              hours: today.getHours(),
              minutes: today.getMinutes(),
              seconds: today.getSeconds(),
            }),
            ONLY_DATE_FORMAT
          );

    if (newDate && !initialRender.current) {
      onChange(newDate);
    }
  };

  useOutsideClick({
    ref: ref,
    handler: () => {
      setCurrentDate(initialValue);
      setPopoverOpen(false);
    },
  });

  useEffect(() => {
    setProposedDate(
      value && !isNaN(moment(value, "YYYY-MM-DD").toDate())
        ? format(
            moment(value, "YYYY-MM-DD").toDate(),
            type !== "day"
              ? type !== "month"
                ? YEAR_FORMAT
                : MONTH_FORMAT
              : DATE_FORMAT
          )
        : ""
    );
  }, [value, type]);

  useEffect(() => {
    if (popoverOpen === false) {
      setTimeout(() => {
        setTimePopover(false);
      }, 500);
    }
    if (popoverOpen && type === "year") {
      let year1 = [];
      const formattedDate = moment(value || currentDate).format("YYYY");
      const getfirst3digitYear = formattedDate.substring(0, 3);
      for (let index = 0; index < 12; index++) {
        year1.push({
          label: `${parseInt(getfirst3digitYear.padEnd(4, "0")) + index}`,
          selected: value
            ? moment(value).format("YYYY") ===
              (parseInt(getfirst3digitYear.padEnd(4, "0")) + index).toString()
            : false,
          disabled:
            moment(minDate).toDate() >
            moment(
              `${parseInt(getfirst3digitYear.padEnd(4, "0")) + index}`,
              "YYYY"
            ).toDate(),
        });
      }
      setYears(year1);
    }
  }, [popoverOpen]);

  useEffect(() => {
    const minimumDate = new Date(minDate);
    const maximumDate = new Date(maxDate);
    const current = new Date(currentDate);

    if (current.getDate() === minimumDate.getDate()) {
      setMinHour(minimumDate.getHours());
      setMinMinute(minimumDate.getMinutes());
    } else if (current.getDate() === maximumDate.getDate()) {
      setMaxHour(maximumDate.getHours());
      setMaxMinute(maximumDate.getMinutes());
    }
  }, [minDate, maxDate, currentDate]);

  useEffect(() => {
    if (initialTime) {
      const initTime = new Date(initialTime);

      setHour(initTime.getHours());
      setMinute(initTime.getMinutes());
    }
  }, [initialTime]);

  const onDateSelected = (options) => {
    const { selectable, date } = options;

    if (!selectable) {
      return;
    }

    if (!isNil(date)) {
      setCurrentDate(date);
      return date;
    }
  };

  const onSelectYear = (payload) => {};

  const handleChangeYear = (payload, minDate) => {
    let year1 = [];
    const formattedDate = payload.toString();
    const getfirst3digitYear = formattedDate.substring(0, 3);
    for (let index = 0; index < 12; index++) {
      year1.push({
        label: `${parseInt(getfirst3digitYear.padEnd(4, "0")) + index}`,
        selected: false,
        disabled:
          moment(minDate).toDate() >
          moment(
            `${parseInt(getfirst3digitYear.padEnd(4, "0")) + index}`,
            "YYYY"
          ).toDate(),
      });
    }
    setYears(year1);
  };

  const dayzedData = useDayzed({
    showOutsideDays: true,
    onDateSelected,
    selected: value,
    minDate:
      new Date(minDate).getHours() !== 0
        ? set(new Date(minDate), { date: new Date(minDate).getDate() - 1 })
        : new Date(minDate),
    maxDate: new Date(maxDate),
  });

  return (
    <Box
      width={width}
      style={{ marginTop: title ? 20 : parentMarginTop }}
      {...rest}
    >
      {title && (index === 0 || index === undefined) && (
        <FormLabel htmlFor={id} className="form-label">
          {title} {required ? <span style={{ color: "red" }}> *</span> : ""}
        </FormLabel>
      )}
      <Popover
        placement={placement}
        variant="responsive"
        isOpen={popoverOpen}
        initialFocusRef={initialFocusRef}
        isLazy
        onClose={() => {
          setPopoverOpen(false);
        }}
      >
        <PopoverTrigger>
          <Box>
            <InputGroup style={{ borderColor: "#CBD5E0" }}>
              <Input
                bg={disabled ? "#ECECEC" : BackgroundColor ?? "white"}
                readOnly={true}
                placeholder={placeHolder ? placeHolder : "Please select date"}
                className={disabled ? "disabled-input" : ""}
                value={proposedDate}
                size={size}
                ref={initialFocusRef}
                onFocus={onFocus}
                onClick={() => !disabled && setPopoverOpen(!popoverOpen)}
                borderColor={borderColor}
              />
              <InputRightElement
                children={<MdDateRange style={{ top: "-4px" }} />}
                onClick={() => !disabled && setPopoverOpen(!popoverOpen)}
                style={zIndexs ? { zIndex: zIndexs } : { zIndex: "0" }}
              />
            </InputGroup>
            {isError && errorMessageAllowed && (
              <Box className="invalid-feedback">Required</Box>
            )}
          </Box>
        </PopoverTrigger>
        <PopoverContent
          ref={ref}
          borderColor="#CBD5E0"
          width={
            timePicker || timePickerOnly
              ? timePickerOnly
                ? "230px"
                : "460px"
              : undefined
          }
        >
          <PopoverBody>
            {timePicker || timePickerOnly ? (
              <Box marginTop="10px">
                <HStack>
                  {!timePickerOnly && (
                    <SingleDatepickerCalendar
                      {...dayzedData}
                      currentDate={currentDate ? currentDate : new Date()}
                      onClose={() => setPopoverOpen(false)}
                      monthNames={monthNames}
                      setMonthData={setMonthNames}
                      type={type}
                    />
                  )}
                  {!timePickerOnly && (
                    <div
                      style={{
                        width: "1px",
                        height: "230px",
                        backgroundColor: "#CBD5E0",
                        margin: "0 15px",
                        opacity: "0.6",
                      }}
                    ></div>
                  )}
                  <Stack spacing={0} width={!timePickerOnly ? "20%" : "40%"}>
                    <IconButton
                      variant="outline"
                      icon={<IoIosArrowUp />}
                      borderRadius="0.375rem 0.375rem 0px 0px"
                      disabled={
                        maxDate &&
                        currentDate &&
                        currentDate.getDate() >= maxDate.getDate() &&
                        hour === maxHour
                      }
                      onClick={() => {
                        if (hour >= 23) {
                          setHour(0);
                        } else {
                          setHour(hour + 1);
                        }
                      }}
                    />
                    <Input
                      type="number"
                      value={String(hour).padStart(2, "0")}
                      textAlign="center"
                      borderBottom="0px"
                      borderTop="0px"
                      borderRadius={0}
                      height="40px"
                      onBlur={(e) => {
                        const val = e.target.value;

                        if (
                          minDate &&
                          currentDate &&
                          currentDate.getDate() <= minDate.getDate()
                        ) {
                          let currentDateTime = currentDate.setHours(val);
                          currentDateTime = currentDate.setMinutes(minute);

                          if (currentDateTime < minDate) {
                            setHour(minDate.getHours());
                          }
                        } else if (
                          maxDate &&
                          currentDate &&
                          currentDate.getDate() >= maxDate.getDate()
                        ) {
                          let currentDateTime = currentDate.setHours(val);
                          currentDateTime = currentDate.setMinutes(minute);

                          if (currentDateTime > maxDate) {
                            setHour(maxDate.getHours());
                          }
                        } else if (val >= 23) {
                          setHour(23);
                        } else if (val <= 0) {
                          setHour(0);
                        } else if (!val) {
                          setHour(0);
                        }
                      }}
                      onChange={(e) => {
                        if (parseInt(e.target.value) < 24) {
                          setHour(parseInt(e.target.value));
                        }
                      }}
                      pattern="[0-9]*"
                      onWheelCapture={(e) => {
                        e.target.blur();
                      }}
                    />
                    <IconButton
                      variant="outline"
                      disabled={
                        minDate &&
                        currentDate &&
                        currentDate.getDate() <= minDate.getDate() &&
                        hour <= minHour
                      }
                      icon={<IoIosArrowDown />}
                      borderRadius="0px 0px 0.375rem 0.375rem"
                      onClick={() => {
                        if (
                          minDate &&
                          currentDate &&
                          currentDate.getDate() <= minDate.getDate() &&
                          hour - 1 <= minHour &&
                          minute <= minMinute
                        ) {
                          setMinute(minMinute);
                        }

                        if (hour <= 0) {
                          setHour(23);
                        } else {
                          setHour(hour - 1);
                        }
                      }}
                    />
                  </Stack>
                  <Box
                    textAlign="center"
                    width={!timePickerOnly ? "4%" : "20%"}
                    fontSize="xl"
                    fontWeight="extrabold"
                  >
                    :
                  </Box>
                  <Stack spacing={0} width={!timePickerOnly ? "20%" : "40%"}>
                    <IconButton
                      variant="outline"
                      icon={<IoIosArrowUp />}
                      borderRadius="0.375rem 0.375rem 0px 0px"
                      disabled={
                        maxDate &&
                        currentDate &&
                        currentDate.getDate() >= maxDate.getDate() &&
                        minute === maxMinute
                      }
                      onClick={() => {
                        if (minute >= 59) {
                          setMinute(0);
                        } else {
                          setMinute(minute + 1);
                        }
                      }}
                    />
                    <Input
                      type="number"
                      value={String(minute).padStart(2, "0")}
                      textAlign="center"
                      borderBottom="0px"
                      borderTop="0px"
                      borderRadius={0}
                      height="40px"
                      onBlur={(e) => {
                        const val = e.target.value;

                        if (
                          minDate &&
                          currentDate &&
                          currentDate.getDate() <= minDate.getDate()
                        ) {
                          let currentDateTime = currentDate.setMinutes(val);

                          currentDateTime = currentDate.setHours(hour);

                          if (currentDateTime < minDate) {
                            setMinute(minDate.getMinutes());
                          }
                        } else if (
                          maxDate &&
                          currentDate &&
                          currentDate.getDate() <= maxDate.getDate()
                        ) {
                          let currentDateTime = currentDate.setMinutes(val);

                          currentDateTime = currentDate.setHours(hour);

                          if (currentDateTime > maxDate) {
                            setMinute(maxDate.getMinutes());
                          }
                        } else if (val >= 59) {
                          setMinute(59);
                        } else if (val <= 0) {
                          setMinute(0);
                        } else if (!val) {
                          setMinute(0);
                        }
                      }}
                      onChange={(e) => {
                        if (parseInt(e.target.value) < 60) {
                          setMinute(parseInt(e.target.value));
                        }
                      }}
                      pattern="[0-9]*"
                      onWheelCapture={(e) => {
                        e.target.blur();
                      }}
                    />
                    <IconButton
                      variant="outline"
                      disabled={
                        minDate &&
                        currentDate &&
                        currentDate.getDate() <= minDate.getDate() &&
                        hour === minHour &&
                        minute === minMinute
                      }
                      icon={<IoIosArrowDown />}
                      borderRadius="0px 0px 0.375rem 0.375rem"
                      onClick={() => {
                        if (minute <= 0) {
                          setMinute(59);
                        } else {
                          setMinute(minute - 1);
                        }
                      }}
                    />
                  </Stack>
                </HStack>
              </Box>
            ) : (
              <SingleDatepickerCalendar
                {...dayzedData}
                currentDate={currentDate ? currentDate : new Date()}
                onClose={() => setPopoverOpen(false)}
                monthNames={monthNames}
                setMonthNames={setMonthNames}
                type={type}
                setCurrentDate={setCurrentDate}
                years={years}
                setYears={setYears}
                handleChangeYear={handleChangeYear}
                minDate={minDate}
              />
            )}
            <Stack
              direction="row"
              padding={timePopover ? "0px 0px" : "0px 25px"}
              align="center"
              justifyContent="center"
              mt={5}
              mb={timePopover ? "10px" : 5}
            >
              <Button
                borderColor="#1fb4d2"
                color="#1fb4d2"
                variant="outline"
                size="sm"
                w="100px"
                onClick={() => {
                  onChangePrime(initialValue);
                  setCurrentDate(initialValue);
                  setPopoverOpen(false);
                }}
              >
                Close
              </Button>
              {timePicker || timePickerOnly ? (
                <Button
                  backgroundColor="#1fb4d2"
                  color="white"
                  variant="solid"
                  size="sm"
                  w="100px"
                  _hover="#1fb4d2"
                  onClick={() => {
                    const newFormat = new Date(
                      currentDate.getFullYear(),
                      currentDate.getMonth(),
                      currentDate.getDate(),
                      hour,
                      minute,
                      0
                    );
                    initialRender.current = false;

                    onChangePrime(newFormat);
                    setPopoverOpen(false);
                  }}
                >
                  Set Time
                </Button>
              ) : (
                <Button
                  id="button-setDate"
                  backgroundColor="#1fb4d2"
                  color="white"
                  variant="solid"
                  size="sm"
                  w="100px"
                  _hover="#1fb4d2"
                  onClick={() => {
                    initialRender.current = false;
                    onChangePrime(currentDate);
                    setPopoverOpen(false);
                  }}
                >
                  Set Date
                </Button>
              )}
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

export const TimePicker = ({
  value,
  disabled = false,
  onChange,
  title,
  required,
  id,
  minDate,
  maxDate,
  size,
  BackgroundColor,
  width,
  inlineInput = false,
  placeHolder,
  index,
  timePicker = false,
  placement = "bottom-start",
  isError,
  borderColor,
  name,
  errorMessageAllowed = true,
  parentMarginTop,
  timePickerOnly,
  initialTime,
  onFocus,
  defaultValue,
  ...rest
}) => {
  const [currentDate, setCurrentDate] = useState(
    value
      ? new Date(moment(value).tz("Asia/Jakarta").format())
      : new Date(value)
  );

  const ref = useRef(null);
  const initialFocusRef = useRef(null);
  const initialRender = useRef(true);

  const [proposedDate, setProposedDate] = useState(
    value && !isNaN(value) ? format(value, TIME_ONLY_FORMAT) : ""
  );

  const initialValue =
    value && !isNaN(value)
      ? new Date(value)
      : minDate
      ? new Date(minDate)
      : new Date();

  const [popoverOpen, setPopoverOpen] = useState(false);
  const [timePopover, setTimePopover] = useState(false);
  const [hour, setHour] = useState(new Date().getHours());
  const [minute, setMinute] = useState(new Date().getMinutes());
  const [maxHour, setMaxHour] = useState(0);
  const [maxMinute, setMaxMinute] = useState(0);
  const [minHour, setMinHour] = useState(0);
  const [minMinute, setMinMinute] = useState(0);

  const onChangePrime = (date) => {
    const newDate = moment(date).tz("Asia/Jakarta").format();

    if (newDate && !initialRender.current) {
      onChange(newDate);
      setProposedDate(format(new Date(newDate), TIME_ONLY_FORMAT));
    }
  };

  useOutsideClick({
    ref: ref,
    handler: () => {
      setCurrentDate(initialValue);
      setPopoverOpen(false);
    },
  });

  useEffect(() => {
    setProposedDate(
      value && !isNaN(value) ? format(value, TIME_ONLY_FORMAT) : ""
    );
  }, [value]);

  useEffect(() => {
    if (popoverOpen === false) {
      setTimeout(() => {
        setTimePopover(false);
      }, 500);
    }
  }, [popoverOpen]);

  useEffect(() => {
    const minimumDate = new Date(minDate);
    const maximumDate = new Date(maxDate);
    const current = new Date(currentDate);

    if (current.getDate() === minimumDate.getDate()) {
      setMinHour(minimumDate.getHours());
      setMinMinute(minimumDate.getMinutes());
    } else if (current.getDate() === maximumDate.getDate()) {
      setMaxHour(maximumDate.getHours());
      setMaxMinute(maximumDate.getMinutes());
    }
  }, [minDate, maxDate, currentDate]);

  useEffect(() => {
    if (initialTime) {
      const initTime = new Date(initialTime);

      setHour(initTime.getHours());
      setMinute(initTime.getMinutes());
    }
  }, [initialTime]);

  return (
    <Box
      width={width}
      style={{ marginTop: title ? 20 : parentMarginTop }}
      {...rest}
    >
      {title && (index === 0 || index === undefined) && (
        <FormLabel htmlFor={id} className="form-label">
          {title} {required ? <span style={{ color: "red" }}> *</span> : ""}
        </FormLabel>
      )}
      <Popover
        placement={placement}
        variant="responsive"
        isOpen={popoverOpen}
        initialFocusRef={initialFocusRef}
        isLazy
        onClose={() => {
          setPopoverOpen(false);
        }}
      >
        <PopoverTrigger>
          <Box>
            <InputGroup style={{ borderColor: "#CBD5E0" }}>
              <Input
                bg={disabled ? "#ECECEC" : BackgroundColor ?? "white"}
                readOnly={true}
                placeholder={placeHolder ? placeHolder : "Please select date"}
                className="disabled-input"
                value={proposedDate}
                size={size}
                ref={initialFocusRef}
                onFocus={onFocus}
                onClick={() => !disabled && setPopoverOpen(!popoverOpen)}
                borderColor={borderColor}
              />
              <InputRightElement
                children={<BiTimeFive />}
                onClick={() => !disabled && setPopoverOpen(!popoverOpen)}
              />
            </InputGroup>
            {isError && errorMessageAllowed && (
              <Box className="invalid-feedback">Required</Box>
            )}
          </Box>
        </PopoverTrigger>
        <PopoverContent ref={ref} borderColor="#CBD5E0" width={"310px"}>
          <PopoverBody>
            <Box marginTop="10px">
              <HStack style={{ justifyContent: "center" }}>
                <Stack spacing={0} width="25%">
                  <IconButton
                    variant="outline"
                    icon={<IoIosArrowUp />}
                    borderRadius="0.375rem 0.375rem 0px 0px"
                    disabled={
                      maxDate &&
                      currentDate &&
                      currentDate.getDate() >= maxDate.getDate() &&
                      hour === maxHour
                    }
                    onClick={() => {
                      if (hour >= 23) {
                        setHour(0);
                      } else {
                        setHour(hour + 1);
                      }
                    }}
                  />
                  <Input
                    type="number"
                    value={String(hour).padStart(2, "0")}
                    textAlign="center"
                    borderBottom="0px"
                    borderTop="0px"
                    borderRadius={0}
                    height="40px"
                    onBlur={(e) => {
                      const val = e.target.value;

                      if (
                        minDate &&
                        currentDate &&
                        currentDate.getDate() <= minDate.getDate()
                      ) {
                        let currentDateTime = currentDate.setHours(val);
                        currentDateTime = currentDate.setMinutes(minute);

                        if (currentDateTime < minDate) {
                          setHour(minDate.getHours());
                        }
                      } else if (
                        maxDate &&
                        currentDate &&
                        currentDate.getDate() >= maxDate.getDate()
                      ) {
                        let currentDateTime = currentDate.setHours(val);
                        currentDateTime = currentDate.setMinutes(minute);

                        if (currentDateTime > maxDate) {
                          setHour(maxDate.getHours());
                        }
                      } else if (val >= 23) {
                        setHour(23);
                      } else if (val <= 0) {
                        setHour(0);
                      } else if (!val) {
                        setHour(0);
                      }
                    }}
                    onChange={(e) => setHour(parseInt(e.target.value))}
                  />
                  <IconButton
                    variant="outline"
                    disabled={
                      minDate &&
                      currentDate &&
                      currentDate.getDate() <= minDate.getDate() &&
                      hour <= minHour
                    }
                    icon={<IoIosArrowDown />}
                    borderRadius="0px 0px 0.375rem 0.375rem"
                    onClick={() => {
                      if (
                        minDate &&
                        currentDate &&
                        currentDate.getDate() <= minDate.getDate() &&
                        hour - 1 <= minHour &&
                        minute <= minMinute
                      ) {
                        setMinute(minMinute);
                      }

                      if (hour <= 0) {
                        setHour(23);
                      } else {
                        setHour(hour - 1);
                      }
                    }}
                  />
                </Stack>
                <Box
                  textAlign="center"
                  width="4%"
                  fontSize="xl"
                  fontWeight="extrabold"
                >
                  :
                </Box>
                <Stack spacing={0} width="25%">
                  <IconButton
                    variant="outline"
                    icon={<IoIosArrowUp />}
                    borderRadius="0.375rem 0.375rem 0px 0px"
                    disabled={
                      maxDate &&
                      currentDate &&
                      currentDate.getDate() >= maxDate.getDate() &&
                      minute === maxMinute
                    }
                    onClick={() => {
                      if (minute >= 59) {
                        setMinute(0);
                      } else {
                        setMinute(minute + 1);
                      }
                    }}
                  />
                  <Input
                    type="number"
                    value={String(minute).padStart(2, "0")}
                    textAlign="center"
                    borderBottom="0px"
                    borderTop="0px"
                    borderRadius={0}
                    height="40px"
                    onBlur={(e) => {
                      const val = e.target.value;

                      if (
                        minDate &&
                        currentDate &&
                        currentDate.getDate() <= minDate.getDate()
                      ) {
                        let currentDateTime = currentDate.setMinutes(val);

                        currentDateTime = currentDate.setHours(hour);

                        if (currentDateTime < minDate) {
                          setMinute(minDate.getMinutes());
                        }
                      } else if (
                        maxDate &&
                        currentDate &&
                        currentDate.getDate() <= maxDate.getDate()
                      ) {
                        let currentDateTime = currentDate.setMinutes(val);

                        currentDateTime = currentDate.setHours(hour);

                        if (currentDateTime > maxDate) {
                          setMinute(maxDate.getMinutes());
                        }
                      } else if (val >= 59) {
                        setMinute(59);
                      } else if (val <= 0) {
                        setMinute(0);
                      } else if (!val) {
                        setMinute(0);
                      }
                    }}
                    onChange={(e) => setMinute(parseInt(e.target.value))}
                  />
                  <IconButton
                    variant="outline"
                    disabled={
                      minDate &&
                      currentDate &&
                      currentDate.getDate() <= minDate.getDate() &&
                      hour === minHour &&
                      minute === minMinute
                    }
                    icon={<IoIosArrowDown />}
                    borderRadius="0px 0px 0.375rem 0.375rem"
                    onClick={() => {
                      if (minute <= 0) {
                        setMinute(59);
                      } else {
                        setMinute(minute - 1);
                      }
                    }}
                  />
                </Stack>
              </HStack>
            </Box>
            <Stack
              direction="row"
              padding={timePopover ? "0px 0px" : "0px 25px"}
              align="center"
              justifyContent="center"
              mt={5}
              mb={timePopover ? "10px" : 5}
            >
              <Button
                borderColor="#1fb4d2"
                color="#1fb4d2"
                variant="outline"
                size="sm"
                w="100px"
                onClick={() => {
                  onChangePrime(initialValue);
                  setCurrentDate(initialValue);
                  setPopoverOpen(false);
                }}
              >
                Close
              </Button>

              <Button
                backgroundColor="#1fb4d2"
                color="white"
                variant="solid"
                size="sm"
                w="100px"
                _hover="#1fb4d2"
                onClick={() => {
                  const newFormat = new Date(
                    currentDate.getFullYear(),
                    currentDate.getMonth(),
                    currentDate.getDate(),
                    hour,
                    minute,
                    0
                  );
                  initialRender.current = false;

                  onChangePrime(newFormat);
                  setPopoverOpen(false);
                }}
              >
                Set Time
              </Button>
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};
