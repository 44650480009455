import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isSidebarDisplayed: true,
  isHeaderDisplayed: true,
};

export const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    SET_SIDEBAR(state, action) {
      state.isSidebarDisplayed = action.payload;
    },
    SET_HEADER(state, action) {
      state.isHeaderDisplayed = action.payload;
    },
    ENTER_FULLSCREEN(state) {
      state.isHeaderDisplayed = false;
      state.isSidebarDisplayed = false;
    },
    EXIT_FULLSCREEN(state) {
      state.isHeaderDisplayed = true;
      state.isSidebarDisplayed = true;
    },
  },
});

export const { SET_SIDEBAR, SET_HEADER, ENTER_FULLSCREEN, EXIT_FULLSCREEN } =
  layoutSlice.actions;

export default layoutSlice.reducer;
