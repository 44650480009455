import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Flex,
  Text,
} from "@chakra-ui/react";
import { BiArchiveIn } from "react-icons/bi";
import { BsExclamationTriangle } from "react-icons/bs";
import { AiOutlineSave } from "react-icons/ai";
import { IoIosArrowDropup } from "react-icons/io";
import { IoWarningOutline } from "react-icons/io5";
import { FiSlash } from "react-icons/fi";

const ModalConfirm = ({
  action = "",
  isOpen,
  onClose,
  loadingButton,
  handleSubmitAdd,
}) => {
  const initialRefAdd = React.useRef();
  const finalRefAdd = React.useRef();

  let modalTitle = "Are You Sure?";
  let btnClass = "btn-success-modal";

  switch (action.toUpperCase()) {
    case "SUBMIT":
      modalTitle =
        "You are about to leave this page, continue to procedure will save your latest data ";
      btnClass = "btn-success-modal";
      break;
    case "CANCEL":
      modalTitle =
        "You are about to leave this page, continue to procedure will cancel your latest data, this data cannot be used permanently ";
      btnClass = "btn-error-modal";
      break;
    case "WARNING":
      modalTitle =
        "You are about to leave this page, continue to procedure will save your latest data ";
      btnClass = "btn-warning-modal";
      break;

    default:
      modalTitle =
        "You are about to leave this page, continue to procedure will save your latest data ";
      btnClass = "btn-success-modal";
      break;
  }

  const icon = (action) => {
    switch (action.toUpperCase()) {
      case "SUBMIT":
        return (
          <IoIosArrowDropup
            size={80}
            style={{ color: "#21C306" }}
            className="mx-auto mb-4"
          />
        );
      case "CANCEL":
        return (
          <IoWarningOutline
            size={80}
            style={{ color: "#dd0000" }}
            className="mx-auto mb-4"
          />
        );
      default:
        return (
          <IoIosArrowDropup
            size={80}
            style={{ color: "#21C306" }}
            className="mx-auto mb-4"
          />
        );
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      initialFocusRef={initialRefAdd}
      finalFocusRef={finalRefAdd}
      id="review-confirm"
      size="sm"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          {icon(action)}
          <Text mb={5} fontSize={16} className="text-center" fontWeight="600">
            {modalTitle} <br />
          </Text>
        </ModalBody>
        <ModalFooter mt={3} pb={0}>
          <Flex
            className="text-center"
            mt={4}
            w={"100%"}
            style={{ justifyContent: "center" }}
          >
            <Button
              onClick={onClose}
              size="md"
              mr={3}
              style={{ minWidth: "150px" }}
              isDisabled={loadingButton}
            >
              No, Stay
            </Button>
            <Button
              onClick={() => {
                handleSubmitAdd();
              }}
              isLoading={loadingButton}
              isDisabled={loadingButton}
              loadingText="Please Wait"
              spinnerPlacement="start"
              mr={3}
              type="submit"
              className={btnClass}
              size="md"
              style={{ minWidth: "150px" }}
            >
              Yes, Continue
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalConfirm;
