import { lazy } from "react";
import { componentLoader } from "utils/Helpers";

/*
  Note: i cant find way to make promise function with params because the imports path cant be dynamic.
  maybe the problem is we still using webpack 4, because when i try dynamic imports path in NEXT with webpack 5, it works. Or maybe because NEXT JS is Server Side not Client Side like CRA.
*/

// QAS Components Master Data
export const Organization = lazy(() =>
  componentLoader(() => import("../../pages/QAS/master-data/Organization"))
);
export const Building = lazy(() =>
  componentLoader(() => import("../../pages/QAS/master-data/Building"))
);
export const Facility = lazy(() =>
  componentLoader(() => import("../../pages/QAS/master-data/Facility"))
);
export const AddFacility = lazy(() =>
  componentLoader(() =>
    import("../../pages/QAS/master-data/Facility/AddFacility")
  )
);
export const Equipment = lazy(() =>
  componentLoader(() => import("../../pages/QAS/master-data/Equipment"))
);
export const FormEquipment = lazy(() =>
  componentLoader(() =>
    import("../../pages/QAS/master-data/Equipment/FormEquipment")
  )
);
export const Auditor = lazy(() =>
  componentLoader(() => import("../../pages/QAS/master-data/Auditor"))
);
export const FormAuditor = lazy(() =>
  componentLoader(() =>
    import("../../pages/QAS/master-data/Auditor/Form/FormAuditor")
  )
);
export const Item = lazy(() =>
  componentLoader(() => import("../../pages/QAS/master-data/Item"))
);
export const FormItem = lazy(() =>
  componentLoader(() =>
    import("../../pages/QAS/master-data/Item/Form/FormItem")
  )
);
export const Production = lazy(() =>
  componentLoader(() => import("../../pages/QAS/master-data/Production"))
);
export const FormHierarchy = lazy(() =>
  componentLoader(() =>
    import("../../pages/QAS/master-data/Production/Hierarchy/FormHierarchy")
  )
);
export const FormProcedure = lazy(() =>
  componentLoader(() =>
    import("../../pages/QAS/master-data/Production/Procedure/FormProcedure")
  )
);
export const GradeRoom = lazy(() =>
  componentLoader(() => import("../../pages/QAS/master-data/GradeRoom"))
);
export const AddGradeRoom = lazy(() =>
  componentLoader(() =>
    import("../../pages/QAS/master-data/GradeRoom/AddGradeRoom")
  )
);
export const Room = lazy(() =>
  componentLoader(() => import("../../pages/QAS/master-data/Room"))
);
export const FormRoom = lazy(() =>
  componentLoader(() => import("../../pages/QAS/master-data/Room/FormRoom"))
);
export const TestType = lazy(() =>
  componentLoader(() => import("../../pages/QAS/master-data/TestType"))
);
export const FormTestType = lazy(() =>
  componentLoader(() =>
    import("../../pages/QAS/master-data/TestType/FormTestType")
  )
);
export const FormTestTypeProcedure = lazy(() =>
  componentLoader(() =>
    import("../../pages/QAS/master-data/TestType/Procedure/FormProcedure")
  )
);
export const Personnel = lazy(() =>
  componentLoader(() => import("../../pages/QAS/master-data/Personnel"))
);
export const DetailPersonnel = lazy(() =>
  componentLoader(() => import("../../pages/QAS/master-data/Personnel/Detail"))
);
export const Microba = lazy(() =>
  componentLoader(() => import("../../pages/QAS/master-data/Microba"))
);
export const FormMicroba = lazy(() =>
  componentLoader(() =>
    import("../../pages/QAS/master-data/Microba/FormMicroba")
  )
);

export const Kitaudit = lazy(() =>
  componentLoader(() => import("../../pages/QAS/master-data/Kitaudit"))
);
export const FormKitaudit = lazy(() =>
  componentLoader(() =>
    import("../../pages/QAS/master-data/Kitaudit/Form/FormKitaudit")
  )
);

// QCS Components Master Data
export const Loop = lazy(() =>
  componentLoader(() => import("../../pages/QAS/master-data/Loop"))
);

// QCS Components Master Data Loop Create
export const FormLoop = lazy(() =>
  componentLoader(() =>
    import("../../pages/QAS/master-data/Loop/Form/FormLoop")
  )
);

export const Water = lazy(() =>
  componentLoader(() => import("../../pages/QAS/master-data/Water"))
);

export const FormWater = lazy(() =>
  componentLoader(() =>
    import("../../pages/QAS/master-data/Water/Form/FormWater")
  )
);

export const UsePoint = lazy(() =>
  componentLoader(() => import("../../pages/QAS/master-data/UsePoint"))
);

export const FormUsePoint = lazy(() =>
  componentLoader(() =>
    import("../../pages/QAS/master-data/UsePoint/Form/FormUsePoint")
  )
);

// QAS Components Review Master Data
export const Pending = lazy(() =>
  componentLoader(() => import("../../pages/QAS/review-master-data/Pending"))
);
export const DetailReviewGradeRoom = lazy(() =>
  componentLoader(() =>
    import(
      "../../pages/QAS/review-master-data/Pending/GradeRoom/DetailReviewGradeRoom"
    )
  )
);
export const DetailReviewFacility = lazy(() =>
  componentLoader(() =>
    import(
      "../../pages/QAS/review-master-data/Pending/Facility/DetailReviewFacility"
    )
  )
);
export const DetailReviewRoom = lazy(() =>
  componentLoader(() =>
    import("../../pages/QAS/review-master-data/Pending/Room/DetailReviewRoom")
  )
);
export const DetailReviewEquipment = lazy(() =>
  componentLoader(() =>
    import(
      "../../pages/QAS/review-master-data/Pending/Equipment/DetailReviewEquipment"
    )
  )
);
export const DetailReviewMicroba = lazy(() =>
  componentLoader(() =>
    import(
      "../../pages/QAS/review-master-data/Pending/Microba/DetailReviewMicroba"
    )
  )
);
export const DetailReviewAuditor = lazy(() =>
  componentLoader(() =>
    import(
      "../../pages/QAS/review-master-data/Pending/Auditor/DetailReviewAuditor"
    )
  )
);

export const DetailReviewKitaudit = lazy(() =>
  componentLoader(() =>
    import(
      "../../pages/QAS/review-master-data/Pending/Kitaudit/DetailReviewKitaudit"
    )
  )
);

export const Correction = lazy(() =>
  componentLoader(() => import("../../pages/QAS/review-master-data/Correction"))
);
export const DetailCorrectionReviewGradeRoom = lazy(() =>
  componentLoader(() =>
    import(
      "../../pages/QAS/review-master-data/Correction/GradeRoom/DetailCorrectionReviewGradeRoom"
    )
  )
);
export const DetailCorrectionReviewFacility = lazy(() =>
  componentLoader(() =>
    import(
      "../../pages/QAS/review-master-data/Correction/Facility/DetailCorrectionReviewFacility"
    )
  )
);
export const DetailCorrectionReviewRoom = lazy(() =>
  componentLoader(() =>
    import(
      "../../pages/QAS/review-master-data/Correction/Room/DetailCorrectionReviewRoom"
    )
  )
);
export const DetailCorrectionReviewEquipment = lazy(() =>
  componentLoader(() =>
    import(
      "../../pages/QAS/review-master-data/Correction/Equipment/DetailCorrectionReviewEquipment"
    )
  )
);
export const DetailCorrectionReviewMicroba = lazy(() =>
  componentLoader(() =>
    import(
      "../../pages/QAS/review-master-data/Correction/Microba/DetailCorrectionReviewMicroba"
    )
  )
);
export const DetailCorrectionAuditor = lazy(() =>
  componentLoader(() =>
    import(
      "../../pages/QAS/review-master-data/Correction/Auditor/DetailCorrectionAuditor"
    )
  )
);

export const DetailCorrectionKitaudit = lazy(() =>
  componentLoader(() =>
    import(
      "../../pages/QAS/review-master-data/Correction/Kitaudit/DetailCorrectionKitaudit"
    )
  )
);

export const Complete = lazy(() =>
  componentLoader(() => import("../../pages/QAS/review-master-data/Complete"))
);
export const DetailCompleteReviewGradeRoom = lazy(() =>
  componentLoader(() =>
    import(
      "../../pages/QAS/review-master-data/Complete/GradeRoom/DetailCompleteReviewGradeRoom"
    )
  )
);
export const DetailCompleteReviewFacility = lazy(() =>
  componentLoader(() =>
    import(
      "../../pages/QAS/review-master-data/Complete/Facility/DetailCompleteReviewFacility"
    )
  )
);
export const DetailCompleteReviewRoom = lazy(() =>
  componentLoader(() =>
    import(
      "../../pages/QAS/review-master-data/Complete/Room/DetailCompleteReviewRoom"
    )
  )
);
export const DetailCompleteReviewEquipment = lazy(() =>
  componentLoader(() =>
    import(
      "../../pages/QAS/review-master-data/Complete/Equipment/DetailCompleteReviewEquipment"
    )
  )
);
export const DetailCompleteReviewMicroba = lazy(() =>
  componentLoader(() =>
    import(
      "../../pages/QAS/review-master-data/Complete/Microba/DetailCompleteReviewMicroba"
    )
  )
);

export const DetailCompleteReviewAuditor = lazy(() =>
  componentLoader(() =>
    import(
      "../../pages/QAS/review-master-data/Complete/Auditor/DetailCompleteReviewAuditor"
    )
  )
);

export const DetailCompleteReviewKitaudit = lazy(() =>
  componentLoader(() =>
    import(
      "../../pages/QAS/review-master-data/Complete/Kitaudit/DetailCompleteReviewKitaudit"
    )
  )
);

// QAS Components Release Media
export const ReleaseMedia = lazy(() =>
  componentLoader(() => import("../../pages/QAS/release/Media"))
);

// QAS Component Release Production
export const ReleaseProduction = lazy(() =>
  componentLoader(() => import("../../pages/QAS/release/Production/index"))
);

export const DetailReleaseProduction = lazy(() =>
  componentLoader(() =>
    import("../../pages/QAS/release/Production/DetailProduction")
  )
);

// QAS Components Configurarion
export const EquipmentGroup = lazy(() =>
  componentLoader(() => import("../../pages/QAS/Configuration/EquipmentGroup"))
);
export const Activity = lazy(() =>
  componentLoader(() => import("../../pages/QAS/Configuration/Activity"))
);
export const TestParameter = lazy(() =>
  componentLoader(() => import("../../pages/QAS/Configuration/TestParameter"))
);
export const UoM = lazy(() =>
  componentLoader(() => import("../../pages/QAS/Configuration/UoM"))
);
export const Presentation = lazy(() =>
  componentLoader(() => import("../../pages/QAS/Configuration/Presentation"))
);
export const DosageForm = lazy(() =>
  componentLoader(() => import("../../pages/QAS/Configuration/DosageForm"))
);
export const AuditorExternal = lazy(() =>
  componentLoader(() => import("../../pages/QAS/Configuration/AuditorExternal"))
);
export const ProcedureSampling = lazy(() =>
  componentLoader(() =>
    import("../../pages/QAS/Configuration/ProcedureSampling")
  )
);

export const EMPhysicalRoom = lazy(() =>
  componentLoader(() => import("../../pages/QAS/em-physical/Room"))
);
export const EMPhysicalDeviation = lazy(() =>
  componentLoader(() => import("../../pages/QAS/em-physical/Deviation"))
);
export const EMPhysicalDeviationDetail = lazy(() =>
  componentLoader(() => import("../../pages/QAS/em-physical/Deviation/Detail"))
);
export const EMPhysicalMonitoring = lazy(() =>
  componentLoader(() => import("../../pages/QAS/em-physical/monitoring"))
);
export const JobDescription = lazy(() =>
  componentLoader(() => import("../../pages/QAS/Configuration/JobDescription"))
);

// QAS Components Form Master Data
export const ListForm = lazy(() =>
  componentLoader(() => import("../../pages/QAS/master-data/Form"))
);

export const FormSchedule = lazy(() =>
  componentLoader(() => import("../../pages/QAS/master-data/Form/FormSchedule"))
);

// QAS Product Registration Certification
export const Certification = lazy(() =>
  componentLoader(() =>
    import("../../pages/QAS/product-registration/Certification")
  )
);
export const FormCertification = lazy(() =>
  componentLoader(() =>
    import(
      "../../pages/QAS/product-registration/Certification/FormCertification"
    )
  )
);

// QAS Product Registration Post Approval
export const PostApproval = lazy(() =>
  componentLoader(() =>
    import("../../pages/QAS/product-registration/PostApproval")
  )
);
export const FormPostApproval = lazy(() =>
  componentLoader(() =>
    import("../../pages/QAS/product-registration/PostApproval/FormPostApproval")
  )
);

// QAS Product Registration Post Approval
export const Registration = lazy(() =>
  componentLoader(() =>
    import("../../pages/QAS/product-registration/Registration")
  )
);
export const FormRegistration = lazy(() =>
  componentLoader(() =>
    import("../../pages/QAS/product-registration/Registration/FormRegistration")
  )
);
export const Manufacturer = lazy(() =>
  componentLoader(() => import("../../pages/QAS/Configuration/Manufacturer"))
);

// QAS Product Registration Post Approval
export const SasRegistration = lazy(() =>
  componentLoader(() => import("../../pages/QAS/product-registration/SAS"))
);
export const FormSasRegistration = lazy(() =>
  componentLoader(() =>
    import("../../pages/QAS/product-registration/SAS/FormSasRegistration")
  )
);

// QAS Master Data Authority
export const Authority = lazy(() =>
  componentLoader(() => import("../../pages/QAS/master-data/Authority"))
);

// QAS Master Data Authority
export const FormConfiguration = lazy(() =>
  componentLoader(() => import("../../pages/QAS/Configuration/Form"))
);

// QAS Product Registration Custom Report
export const ReportCustom = lazy(() =>
  componentLoader(() =>
    import("../../pages/QAS/product-registration/ReportCustom")
  )
);

// QAS Audit Management
export const AuditSchedule = lazy(() =>
  componentLoader(() => import("../../pages/QAS/audit-management/Schedule"))
);

export const FindingAudit = lazy(() =>
  componentLoader(() =>
    import("../../pages/QAS/audit-management/Finding/ListFindingSheet")
  )
);

export const FindingAuditForm = lazy(() =>
  componentLoader(() =>
    import("../../pages/QAS/audit-management/Finding/FormFindingSheet")
  )
);

export const FindingAuditDetail = lazy(() =>
  componentLoader(() =>
    import("../../pages/QAS/audit-management/Finding/FindingSheet")
  )
);

export const FormAuditSchedule = lazy(() =>
  componentLoader(() =>
    import("../../pages/QAS/audit-management/Schedule/FormAuditSchedule")
  )
);

export const FormFindingSheet = lazy(() =>
  componentLoader(() =>
    import("../../pages/QAS/audit-management/Finding/FormFindingSheet")
  )
);
