import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "../../../assets/css/Layout.css";
import {
  getNameUser,
  getPositionId,
  getUserRole,
  getToken,
  getNikUser,
  userHasAccess,
} from "../../../utils/User";
import { Role } from "../../../utils/ConstantGlobal";
import {
  Menu,
  MenuButton,
  MenuList,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  Text,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import ModalLogout from "../modal/ModalLogout";
import ModalDigitalSignature from "../modal/ModalDigitalSignature";
import { ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { LogoutAction } from "../../../store/actions/Auth/actions";
import { useSelector } from "react-redux";
import imgAlert from "../../../assets/img/icons/icon-alert-digital-signature.svg";
import { CheckValidityTimeDigitalSignature } from "../../../services/actions/Auth/index";
import imgDefaultProfile from "../../../assets/img/default-profile.svg";
import { firestore } from "config/firebase";
import Notification from "../notification/Notification";

const Header = ({ toggle, title, breadcrumb }) => {
  const isLoading = useSelector((state) => state.auth.isLoading);
  const Roles = useSelector(getUserRole);
  const dispatch = useDispatch();
  const nameUser = useSelector(getNameUser);
  const positionId = useSelector(getPositionId);
  const token = useSelector(getToken);
  const userNIK = useSelector(getNikUser);
  const NIK = useSelector(getNikUser);
  const [alertMessage, setAlertMessage] = useState("");
  const [showCloseButton, setShowCloseButton] = useState(false);
  const [createSignature, setCreateSignature] = useState(false);
  const [isFirst, setIsFirst] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialRef = useRef();
  const finalRef = useRef();
  const childRef = useRef();

  const isOneOfUserHasDigitalSignature = () => {
    return true;
    // return (
    //   userHasAccess(Roles, Role.KabagPemilikFasilitas) ||
    //   userHasAccess(Roles, Role.KasiePemilikFasilitas) ||
    //   userHasAccess(Roles, Role.KabagUji) ||
    //   userHasAccess(Roles, Role.AhliMudaUji) ||
    //   userHasAccess(Roles, Role.OperatorUji) ||
    //   userHasAccess(Roles, Role.ReviewerQAEM) ||
    //   userHasAccess(Roles, Role.ReviewerOrganization) ||
    //   userHasAccess(Roles, Role.ReviewerBuilding) ||
    //   userHasAccess(Roles, Role.ReviewerFacility) ||
    //   userHasAccess(Roles, Role.ReviewerGradeRoom) ||
    //   userHasAccess(Roles, Role.ReviewerRoom) ||
    //   userHasAccess(Roles, Role.ReviewerEquipment) ||
    //   userHasAccess(Roles, Role.MonitoringQAEM) ||
    //   userHasAccess(Roles, Role.ReviewerMicroba) ||
    //   userHasAccess(Roles, Role.ReviewerMasterProduction) ||
    //   userHasAccess(Roles, Role.OperatorProduction) ||
    //   userHasAccess(Roles, Role.ReviewEMPhysical) ||
    //   userHasAccess(Roles, Role.ReviewKabagEMPhysical) ||
    //   userHasAccess(Roles, Role.ReviewQAEMPhysical)
    // );
  };

  useEffect(() => {
    const checkValidityTime = async () => {
      let response = await CheckValidityTimeDigitalSignature(userNIK);
      if (response.data) {
        if (response.data.statusCode === 403) {
          if (!sessionStorage.getItem("checkSignature")) {
            if (response.data.message.includes("signature not registered")) {
              setCreateSignature(true);
              setIsFirst(true);
            } else {
              setAlertMessage(response.data.message);
              setShowCloseButton(false);
              onOpen();
            }
          }
        } else if (response.data.statusCode === 301) {
          if (!sessionStorage.getItem("checkSignature")) {
            setAlertMessage(response.data.message);
            setShowCloseButton(true);
            onOpen();
          }
        } else if (response.data.statusCode === 200) {
          setCreateSignature(false);
        }
      }
    };
    checkValidityTime();
  }, []);

  return (
    <>
      <div className="header-content">
        {isOneOfUserHasDigitalSignature() && (
          <>
            <Modal
              initialFocusRef={initialRef}
              finalFocusRef={finalRef}
              isOpen={isOpen}
              onClose={() => {
                sessionStorage.setItem("checkSignature", true);
                onClose();
              }}
              closeOnOverlayClick={showCloseButton}
              size={"lg"}
              closeOnEsc={false}
            >
              <ModalOverlay />
              <ModalContent>
                {showCloseButton && <ModalCloseButton />}
                <ModalBody py={10}>
                  <img
                    src={imgAlert}
                    className="mx-auto mb-4"
                    alt="Alert change Digital Signature"
                  />
                  <Text
                    className="text-center"
                    style={{ fontSize: "1.25em", fontWeight: "600" }}
                    mb={6}
                    mx={15}
                  >
                    {alertMessage}
                  </Text>
                  <Button
                    size="md"
                    backgroundColor="#1FB4D2"
                    color="#fff"
                    style={{
                      display: "block",
                      width: "80%",
                      textAlign: "center",
                      margin: "0 auto",
                    }}
                    ref={initialRef}
                    onClick={() => {
                      childRef.current.openModalChangeDigitalSignature();
                      // onClose()
                    }}
                  >
                    Change Digital Signature
                  </Button>
                </ModalBody>
              </ModalContent>
            </Modal>
          </>
        )}
        <div className="header-title">
          <div>
            {title}
            {breadcrumb}
          </div>
        </div>
        <div className="header-menu">
          <ul className="navbar-nav">
            <div>
              <Notification NIK={NIK} />
            </div>
            <div
              className="topbar-divider d-none d-sm-block"
              style={{
                borderLeft: "1px solid rgba(64, 64, 64, 0.15)",
                height: "2em",
              }}
            ></div>
            <li className="nav-item my-auto">
              <Menu placement="bottom-end" autoSelect={false}>
                <MenuButton className="user-profile">
                  <Link to="#" className="nav-link">
                    <img
                      src={
                        nameUser
                          ? `https://ui-avatars.com/api/?name=${nameUser}`
                          : imgDefaultProfile
                      }
                      alt="Profile User"
                      className="profile-image rounded-circle"
                    />
                    <div className="header-information">
                      <span className="header-name">{nameUser}</span>
                    </div>
                  </Link>
                </MenuButton>
                <MenuList className="menu-topbar">
                  {isOneOfUserHasDigitalSignature() && (
                    <ModalDigitalSignature
                      username={nameUser}
                      ref={childRef}
                      closeModalReminder={onClose}
                      openModal={createSignature}
                      isFirst={isFirst}
                      setIsFirst={setIsFirst}
                    />
                  )}
                  <ModalLogout
                    isLoading={isLoading}
                    onClick={() => {
                      const payload = {
                        positionId: positionId,
                        token: token,
                        createdBy: nameUser,
                      };
                      sessionStorage.removeItem("checkSignature");
                      dispatch(LogoutAction(payload, "manual"));
                    }}
                  />
                </MenuList>
              </Menu>
            </li>
          </ul>
        </div>
      </div>

      <ToastContainer
        enableMultiContainer
        theme="colored"
        containerId="logout"
        position="top-center"
        autoClose={2000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};

export default Header;
