export const SET_CURRENT_REQUEST_DATE = "SET_CURRENT_REQUEST_DATE";
export const SET_CURRENT_REQUEST_ID = "SET_CURRENT_REQUEST_ID";
export const SET_CURRENT_REQUEST_NUMBER = "SET_CURRENT_REQUEST_NUMBER";
export const SET_CURRENT_ORGANIZATION = "SET_CURRENT_ORGANIZATION";
export const SET_CURRENT_ITEM = "SET_CURRENT_ITEM";
export const SET_CURRENT_TYPE_FORM = "SET_CURRENT_TYPE_FORM";
export const SET_CURRENT_BATCHNUMBER = "SET_CURRENT_BATCHNUMBER";
export const SET_CURRENT_REQUEST_TYPE = "SET_CURRENT_REQUEST_TYPE";
export const SET_CURRENT_PRODUCT_FORM = "SET_CURRENT_PRODUCT_FORM";
export const SET_CURRENT_PRODUCT_PHASE = "SET_CURRENT_PRODUCT_PHASE";
export const SET_CURRENT_TEST_SCENARIO = "SET_CURRENT_TEST_SCENARIO";
export const SET_CURRENT_EM_PHASE = "SET_CURRENT_EM_PHASE";
export const SET_CURRENT_STORAGE_TEMPERATURE =
  "SET_CURRENT_STORAGE_TEMPERATURE";
export const SET_CURRENT_PURPOSE = "SET_CURRENT_PURPOSE";
export const SET_CURRENT_EM_ROOM = "SET_CURRENT_EM_ROOM";
export const SET_CURRENT_EM_ROOM_GRADE = "SET_CURRENT_EM_ROOM_GRADE";
export const SET_TEST_TYPE_QCS = "SET_TEST_TYPE_QCS";
export const SET_CURRENT_PRODUCTION_ROOM = "SET_CURRENT_PRODUCTION_ROOM";
export const SET_CURRENT_NEW_TEST_SCENARIO = "SET_CURRENT_NEW_TEST_SCENARIO";
export const SET_CURRENT_AHU = "SET_CURRENT_AHU";
export const SET_CURRENT_FACILITY = "SET_CURRENT_FACILITY";
export const SET_CURRENT_MULTI_ROOM = "SET_CURRENT_MULTI_ROOM";
export const SET_CURRENT_PRODUCT_GROUP = "SET_CURRENT_PRODUCT_GROUP";
export const SET_CURRENT_PRODUCT_PRESENTATION =
  "SET_CURRENT_PRODUCT_PRESENTATION";
export const SET_CURRENT_LOCATION = "SET_CURRENT_LOCATION";
export const SET_CURRENT_ITEM_TEMPERATURE = "SET_CURRENT_ITEM_TEMPERATURE";
export const SET_CURRENT_PROCESS = "SET_CURRENT_PROCESS";
export const SET_CURRENT_BATCH = "SET_CURRENT_BATCH";
export const SET_CURRENT_LINES = "SET_CURRENT_LINES";

export const TEST_DATA = "TEST_DATA"


// Actions FromRequest QCS 
export const POST_REQUEST_QCS = "POST_REQUEST_QCS"
export const POST_REQUEST_QCS_SUCCESS = "POST_REQUEST_QCS_SUCCESS";
export const POST_REQUEST_QCS_ERROR = "POST_REQUEST_QCS_ERROR";
