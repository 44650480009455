import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  acknowledgeApprovedOn: null,
};

export const productionReviewDetailSlice = createSlice({
  name: "productionReviewDetail",
  initialState,
  reducers: {
    setAcknowledgeApprovedOn(state, action) {
      state.acknowledgeApprovedOn = action.payload;
    },
  },
});

export const { setAcknowledgeApprovedOn } = productionReviewDetailSlice.actions;
export default productionReviewDetailSlice.reducer;
