import { GridItem } from '@chakra-ui/react'
import React from 'react'
import Skeleton from 'react-loading-skeleton'

function WorkflowCardDetailSkeleton() {
  return (
    <>
      <GridItem borderWidth="0px" borderRadius="lg" overflow="hidden" className='bg-white shadow w-100 p-4 content-wrapper' style={{ transition: '850ms' }}>
        <Skeleton height={24} />
        <Skeleton height={20} width="20%" className="mb-4" />
        <Skeleton height='80%' />
      </GridItem>
    </>
  )
}

export default WorkflowCardDetailSkeleton
