import qasamAPIInstance from "../APIQASAM";

export const getAuditScheduleList = async (payload) => {
  try {
    const response = await qasamAPIInstance.get(
      "/qas-audit-management/v1/AuditSchedule/Get",
      { params: payload }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getAuditScheduleById = async (id, userId) => {
  try {
    const response = await qasamAPIInstance.get(
      "/qas-audit-management/v1/AuditSchedule/GetById",
      {
        params: {
          id,
          isPopulated: true,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getInternalAuditorList = async (payload) => {
  try {
    const response = await qasamAPIInstance.get(
      "/qas-audit-management/v1/AuditSchedule/GetListAuditor"
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 *  Cancel/Discard Audit Schedule
 *
 * @param {{historyId,nik,workflowDocumentCode}} payload
 * @returns response.data.data
 */
export const cancelAuditSchedule = async (payload) => {
  try {
    const response = await qasamAPIInstance.post(
      "/qas-audit-management/v1/AuditSchedule/Cancel",
      payload
    );
    return response.data.data;
  } catch (error) {
    return error;
  }
};

export const approveAuditSchedule = async (payload) => {
  try {
    const response = await qasamAPIInstance.post(
      "/qas-audit-management/v1/AuditSchedule/Approve",
      payload
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const rejectAuditSchedule = async (payload) => {
  try {
    const response = await qasamAPIInstance.post(
      "/qas-audit-management/v1/AuditSchedule/Reject",
      payload
    );
    return response;
  } catch (error) {
    return error;
  }
};

// Finding Audit
export const getFindingAuditList = async (payload) => {
  try {
    const response = await qasamAPIInstance.get(
      "/qas-audit-management/v1/FindingAudit/Get",
      { params: payload }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getFindingAuditById = async (payload) => {
  try {
    const response = await qasamAPIInstance.get(
      "/qas-audit-management/v1/FindingAudit/GetById",
      { params: payload }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const postFindingAuditPreparation = async (payload) => {
  try {
    const response = await qasamAPIInstance.post(
      "/qas-audit-management/v1/FindingAudit/Prepare",
      payload
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const generateFindingNumber = async (payload) => {
  try {
    const response = await qasamAPIInstance.post(
      "/qas-audit-management/v1/FindingAudit/GenerateFindingNumber",
      payload
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const postSummary = async (payload) => {
  try {
    const response = await qasamAPIInstance.post(
      "/qas-audit-management/v1/FindingAudit/Summarize",
      payload
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getFindings = async (payload) => {
  try {
    const response = await qasamAPIInstance.get(
      "/qas-audit-management/v1/FindingAudit/GetFindings",
      { params: payload }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const assignInspectionScope = async (payload) => {
  try {
    const response = await qasamAPIInstance.post(
      "/qas-audit-management/v1/FindingAudit/InspectionScopeAssignment",
      payload
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Get item details from the inspectionScope
 *
 * @param {*} payload - { FindingAuditId }
 * @returns {*} FindingAuditItemViewModel
 */
export const findingAuditItemGet = async (payload) => {
  try {
    const response = await qasamAPIInstance.get(
      "/qas-audit-management/v1/FindingAuditItem/Get",
      { params: payload }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const findingAuditItemUpdate = async (payload) => {
  try {
    const response = await qasamAPIInstance.post(
      "/qas-audit-management/v1/FindingAuditItem/Update",
      payload
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

/**
 * Get item details from the inspectionScope
 *
 * @param {*} payload - { FindingAuditId }
 * @returns {*} FindingAuditItemViewModel
 */

export const findingAuditFindingGet = async (payload) => {
  try {
    const response = await qasamAPIInstance.get(
      "/qas-audit-management/v1/FindingAuditFinding/Get",
      { params: payload }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const findingAuditFindingInspect = async (payload) => {
  try {
    const response = await qasamAPIInstance.post(
      "/qas-audit-management/v1/FindingAuditFinding/Inspect",
      payload
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const findingAuditUploadDocumentReport = async (file, fileName) => {
  try {
    const formData = new FormData();
    const fileExtension = file.name.split(".").pop();
    const newFileName = fileName ? `${fileName}.${fileExtension}` : file.name;

    formData.append("attachment", file, newFileName);
    const response = await qasamAPIInstance.post(
      "/qas-audit-management/v1/FindingAudit/UploadDocumentReport",
      formData
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getFindingAuditAssignment = async (payload) => {
  try {
    const response = await qasamAPIInstance.get(
      "/qas-audit-management/v1/FindingAuditAssignment/Get",
      { params: payload }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const findingAuditAssignmentCreate = async (payload) => {
  try {
    const response = await qasamAPIInstance.post(
      "/qas-audit-management/v1/FindingAuditAssignment/Create",
      payload
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const findingAuditAssignmentDelete = async (payload) => {
  try {
    const response = await qasamAPIInstance.post(
      "/qas-audit-management/v1/FindingAuditAssignment/Delete",
      payload
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const approveFindingAudit = async (payload) => {
  try {
    const response = await qasamAPIInstance.post(
      "/qas-audit-management/v1/FindingAudit/Approve",
      payload
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const rejectFindingAudit = async (payload) => {
  try {
    const response = await qasamAPIInstance.post(
      "/qas-audit-management/v1/FindingAudit/Reject",
      payload
    );
    return response;
  } catch (error) {
    return error;
  }
};
