import React, { useState, useEffect, useRef } from "react";
import { SingleDatepicker } from "../../../components";
import { RiArrowDropDownLine } from "react-icons/ri";
import {
  Button,
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  FormControl,
  FormLabel,
  Flex,
  Spacer,
  Checkbox,
  Stack,
  CheckboxGroup,
  InputGroup,
  InputRightElement,
  Input,
  ButtonGroup,
  IconButton,
  Divider,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { BiChevronDown } from "react-icons/bi";
import { FaTimes } from "react-icons/fa";
import { ConvertDateToString } from "../../../utils/Helpers";
import Select from "react-select";

const STATUS = "Status";
const DATE_RANGE = "DateRange";
const TYPE_REQUEST = "TypeRequest";
const FACILITY_ID = "FacilityId";

// TODO-TBD : Check The Use Effect

const PopoverFilterWaterMonitoring = ({
  onSubmit,
  buttonSize,
  statusObj,
  typeRequestObj,
  listStatus,
  width,
  hideDateFilter = false,
  limitData,
  listFilter = [STATUS, DATE_RANGE],
  defaultStatus = statusObj,
  menu = "",
  organizationList,
  samplingTypeList,
}) => {
  const defaultFilter = {
    search: "",
    limit: 5,
    page: 1,
    date: ConvertDateToString(new Date(new Date().getFullYear(), 0, 1)),
    status: [],
  };

  const sortedObject = (unordered) => {
    if (unordered) {
      return Object.keys(unordered)
        .sort()
        .reduce((obj, key) => {
          obj[key] = unordered[key];
          return obj;
        }, {});
    } else {
      return null;
    }
  };

  const getFilterData = () => {
    const filterLocalStorage = JSON.parse(localStorage.getItem("filter"));
    let listFilter;
    switch (menu) {
      case "report":
        return filterLocalStorage?.report ?? null;
      case "request":
        listFilter = filterLocalStorage?.request ?? null;
        if (listFilter) delete listFilter.facilityId; // condition reset filtering after reload pages
        return listFilter;
      case "sampling":
        listFilter = filterLocalStorage?.sampling ?? null;
        if (listFilter) delete listFilter.facilityId;
        return listFilter;
      case "release":
        listFilter = filterLocalStorage?.release ?? null;
        if (listFilter) delete listFilter.facilityId;
        return listFilter;
      case "todo":
        listFilter = filterLocalStorage?.todo ?? null;
        if (listFilter) delete listFilter.facilityId;
        return listFilter;
      case "monitoring":
        listFilter = filterLocalStorage?.monitoring ?? null;
        if (listFilter) delete listFilter.facilityId;
        return listFilter;
      case "transfer":
        listFilter = filterLocalStorage?.transfer ?? null;
        if (listFilter) delete listFilter.facilityId;
        return listFilter;
      case "late-transfer":
        listFilter = filterLocalStorage?.lateTransfer ?? null;
        if (listFilter) delete listFilter.facilityId;
        return listFilter;
      case "testing":
        listFilter = filterLocalStorage?.testing ?? null;
        if (listFilter) delete listFilter.facilityId;
        return listFilter;
      default:
        return null;
    }
  };

  const [status, setStatus] = useState([]);
  const [statusText, setStatusText] = useState([]);
  const [typeRequestId, setTypeRequestId] = useState([]);
  const [typeRequestText, setTypeRequestText] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [shouldReset, setShouldReset] = useState(false);
  const filterData = getFilterData();
  const [data, setData] = useState(defaultFilter);

  const isSubmitted = useRef(false);

  useEffect(() => {
    isSubmitted.current =
      JSON.stringify(sortedObject(filterData)) !==
        JSON.stringify(sortedObject(defaultFilter)) && filterData;
  }, [filterData]);
  //const { onOpen, onClose, isOpen } = useDisclosure()
  const [isOpen, setIsOpen] = useState(false);
  const open = () => setIsOpen(!isOpen);
  const close = () => setIsOpen(false);
  const [isFetching, setIsFetching] = useState(false);

  const isInitialRender = useRef(true);

  const handleStatusOnChange = (e) => {
    const value = e.target.value;

    if (e.target.checked === true) {
      if (value === "99") {
        const statusEnum = statusObj.map((d) => d.enum);
        const statusLabel = statusObj.map((d) => d.label);

        statusEnum.unshift("99");

        setStatus(statusEnum);
        setStatusText(statusLabel);
      } else {
        setStatus((oldState) => [...oldState, value]);

        statusObj.forEach((item) => {
          if (value === item.enum) {
            setStatusText((oldState) => [
              ...oldState,
              statusObj.find((d) => d.enum === item.enum).label,
            ]);
          }
        });
      }
    } else {
      const tempArray = [...status];
      const tempArrayText = [...statusText];
      const index = tempArray.indexOf(value);

      if (index !== -1) {
        tempArray.splice(index, 1);
        setStatus(tempArray);
      }

      if (value === "99") {
        setStatus([]);
        setStatusText([]);
      } else {
        statusObj.forEach((item) => {
          if (value === item.enum) {
            const indexText = tempArrayText.indexOf(
              statusObj.find((d) => d.enum === item.enum).label
            );

            if (indexText !== -1) {
              tempArrayText.splice(indexText, 1);
              setStatusText(tempArrayText);
            }
          }
        });
      }
    }
  };

  const customStyles = {
    option: (base, state) => ({
      ...base,
      color: state.isDisabled ? "#98a7b7" : "#000",
      backgroundColor: state.isDisabled
        ? undefined
        : state.isSelected
        ? "#1FB4D2"
        : state.isFocused
        ? "rgba(70, 162, 225, 0.16)"
        : "#fff",
      ":active": {
        ...base[":active"],
        backgroundColor: !state.isDisabled
          ? state.isDisabled
            ? "#1FB4D2"
            : "rgba(70, 162, 225, 0.16)"
          : undefined,
      },
    }),
    control: (base, state) => ({
      ...base,
      borderColor: "#CBD5E0",
      borderRadius: "0.375rem 0.375rem 0.375rem 0.375rem",
      width: "100%",
    }),
    placeholder: (base, state) => ({
      ...base,
      color: state.isSelected ? "#000" : "#98a7b7",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    }),
    singleValue: (base, state) => ({
      ...base,
      color: "#000",
    }),
  };

  const handletypeRequestOnChange = (e) => {
    const value = e.target.value;

    if (e.target.checked === true) {
      if (value === "99") {
        const Enum = typeRequestObj.map((d) => d.enum);
        const Label = typeRequestObj.map((d) => d.label);

        Enum.unshift("99");

        setTypeRequestId(Enum);
        setTypeRequestText(Label);
      } else {
        setTypeRequestId((oldState) => [...oldState, value]);

        typeRequestObj.forEach((item) => {
          if (value === item.enum) {
            setTypeRequestText((oldState) => [
              ...oldState,
              typeRequestObj.find((d) => d.enum === item.enum).label,
            ]);
          }
        });
      }
    } else {
      const tempArray = [...typeRequestId];
      const tempArrayText = [...typeRequestText];
      const index = tempArray.indexOf(value);

      if (index !== -1) {
        tempArray.splice(index, 1);
        setTypeRequestId(tempArray);
      }

      if (value === "99") {
        setTypeRequestId([]);
        setTypeRequestText([]);
      } else {
        typeRequestObj.forEach((item) => {
          if (value === item.enum) {
            const indexText = tempArrayText.indexOf(
              typeRequestObj.find((d) => d.enum === item.enum).label
            );

            if (indexText !== -1) {
              tempArrayText.splice(indexText, 1);
              setTypeRequestText(tempArrayText);
            }
          }
        });
      }
    }
  };

  const handleFormOnSubmit = (e) => {
    e.preventDefault();
    handleOnSubmit();
    isSubmitted.current = true;
  };

  const handleOnSubmit = () => {
    if (!isFiltered) {
      setIsFiltered(true);
    } else {
      setIsFiltered(false);
    }
    if (data) onSubmit({ data });
  };

  const handleResetFilter = (e) => {
    if (Array.isArray(statusObj) && statusObj.length !== 0) {
      const Enum = statusObj
        .filter((d) => defaultStatus.some((d2) => d2.enum === d.enum))
        .map((d) => d.enum);
      const Label = statusObj
        .filter((d) => defaultStatus.some((d2) => d2.enum === d.enum))
        .map((d) => d.label);

      if (statusObj.length === defaultStatus) {
        Enum.unshift("99");
      }

      setStatus(Enum);
      setStatusText(Label);
    }

    if (Array.isArray(typeRequestObj) && typeRequestObj.length !== 0) {
      const Enum = typeRequestObj.map((d) => d.enum);
      const Label = typeRequestObj.map((d) => d.label);

      Enum.unshift("99");

      setTypeRequestId(Enum);
      setTypeRequestText(Label);
    }

    isSubmitted.current = false;
    setIsFiltered(true);
    setShouldReset(true);
    setData({
      search: "",
      limit: 5,
      page: 1,
      startDate: ConvertDateToString(new Date(new Date().getFullYear(), 0, 1)),
      endDate: ConvertDateToString(new Date()),
      status: "",
      facilityId: "",
      typeRequestId: "",
      organizationVal: null,
      samplingTypeVal: null,
    });
  };

  useEffect(() => {
    if (shouldReset) {
      handleOnSubmit();
      setShouldReset(false);
    }
  }, [shouldReset, data]);

  useEffect(() => {
    if (Array.isArray(statusObj) && statusObj.length !== 0) {
      const Enum = statusObj
        .filter((d) => defaultStatus.some((d2) => d2.enum === d.enum))
        .map((d) => d.enum);
      const Label = statusObj
        .filter((d) => defaultStatus.some((d2) => d2.enum === d.enum))
        .map((d) => d.label);

      if (
        Array.isArray(statusObj) &&
        Array.isArray(defaultStatus) &&
        statusObj.length === defaultStatus.length
      )
        Enum.unshift("99");

      setStatus(Enum);
      setStatusText(Label);
    }

    if (Array.isArray(typeRequestObj) && typeRequestObj.length !== 0) {
      const Enum = typeRequestObj.map((d) => d.enum);
      const Label = typeRequestObj.map((d) => d.label);

      Enum.unshift("99");

      setTypeRequestId(Enum);
      setTypeRequestText(Label);
    }
  }, []);

  useEffect(() => {
    let statusFiltered = [];
    let statusTextFiltered = [];
    if (filterData && filterData?.status !== "") {
      statusFiltered = filterData?.status?.split(",");
      statusTextFiltered = defaultStatus
        .filter((d) => statusFiltered.some((d2) => d2 === d.enum))
        .map((d) => d.label);
      setStatusText(statusTextFiltered);
      setStatus(statusFiltered);
    }
  }, []);

  useEffect(() => {
    const ConcatStatus = status.join();

    setData({ ...data, status: ConcatStatus });
  }, [status]);

  useEffect(() => {
    const ConcatStatus = typeRequestId.join();

    setData({ ...data, typeRequestId: ConcatStatus });
  }, [typeRequestId]);

  return (
    <>
      <Popover
        isOpen={isOpen}
        //  onClose={close}
        placement="bottom-start"
      >
        <PopoverTrigger>
          {isSubmitted.current ? (
            <ButtonGroup isAttached variant="outline" width={width} ml="30px">
              <Button
                className="btn-cm-primary text-white border-bio w100"
                onClick={open}
              >
                <span className="text-weight-normal" w="80%" p={0}>
                  Filter
                </span>
              </Button>
              <IconButton
                aria-label="Reset filter"
                className="btn-cm-outline-primary"
                icon={<FaTimes />}
                onClick={handleResetFilter}
                size={buttonSize}
              />
            </ButtonGroup>
          ) : (
            <ButtonGroup isAttached variant="outline" w={width} ml="30px">
              <Button
                className="text-weight-normal w100"
                borderColor="#CBD5E0"
                p={0}
                textAlign="left"
                onClick={open}
              >
                Filter
              </Button>
              <IconButton
                aria-label="Reset filter"
                className="caret-dropdown-filter"
                icon={<RiArrowDropDownLine size={30} />}
                size={buttonSize}
                onClick={open}
              />
            </ButtonGroup>
          )}
        </PopoverTrigger>
        <PopoverContent p={5} borderColor="#CBD5E0" w={350}>
          <form onSubmit={handleFormOnSubmit}>
            <PopoverBody className="popUpQCStyle">
              <Flex flexWrap="wrap">
                <Popover placement="bottom-start" matchWidth>
                  <CheckboxGroup
                    colorScheme="blue"
                    value={data.status}
                    defaultValue={defaultFilter.status}
                  >
                    <Grid gap={6}>
                      <GridItem>
                        <h5 className="text-bold">Status</h5>
                      </GridItem>
                      <GridItem>
                        <Stack>
                          {listStatus.length > 0 &&
                            listStatus.map((status) => (
                              <>
                                <Checkbox
                                  value={status.value}
                                  // onChange={(e) =>
                                  //   handleFilter(
                                  //     e.target.value,
                                  //     e.target.checked,
                                  //     "status"
                                  //   )
                                  // }
                                >
                                  {status.label}
                                </Checkbox>
                              </>
                            ))}
                        </Stack>
                      </GridItem>
                    </Grid>
                  </CheckboxGroup>
                </Popover>
                <FormControl mt={5} w="100%">
                  <FormLabel>Schedule</FormLabel>
                  <SingleDatepicker
                    value={
                      data.startDate === ""
                        ? new Date(new Date().getFullYear(), 0, 1)
                        : new Date(data.startDate)
                    }
                    size={"sm"}
                    onChange={(date) => setData({ ...data, startDate: date })}
                  />
                </FormControl>

                <Box w="100%" mt={5}>
                  <Button
                    type="submit"
                    className="text-weight-normal"
                    id="btn-submit-filter"
                    color="white"
                    bgColor="#1fb4d2"
                    size="sm"
                    float="right"
                    onClick={close}
                  >
                    Apply
                  </Button>
                </Box>
              </Flex>
            </PopoverBody>
          </form>
        </PopoverContent>
      </Popover>
    </>
  );
};

export default PopoverFilterWaterMonitoring;
