import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalHeader,
  ModalContent,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Box,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import { getNameUser, getNikUser } from "../../../../utils/User";
import { titleCase } from "../../../../utils/Helpers";
import { useSelector } from "react-redux";
import { SingleDatepicker } from "../../../../components/index";
import Select from "react-select";
import moment from "moment";
import style from "./style.module.css";

const CreateModalDialog = ({
  handleSubmitAdd,
  viewType,
  data,
  isOpen,
  onClose,
  blockScrollOnMount,
  listActiveMedia,
}) => {
  const NIK = useSelector(getNikUser);
  const [typeForm, setTypeForm] = useState("create");

  let initialData = {
    itemId: "",
    itemBatch: "",
    quantity: "",
    itemDate: "",
    releaseDate: "",
    createdBy: NIK,
    type: viewType,
  };

  if (viewType !== "create") {
    const selectedIds = listActiveMedia.filter((things) =>
      things.value.toString().includes(data.itemId)
    );
    const dataDate = moment(data.itemDate).format("YYYY-MM-DD");
    const dataReleaseDate = moment(data.releaseDate).format("YYYY-MM-DD");

    initialData = {
      id: data.id,
      itemId: { label: selectedIds[0].label, value: data.itemId },
      itemBatch: data.itemBatch,
      quantity: data.quantity,
      releaseDate: dataReleaseDate,
      itemDate: dataDate,
      updatedBy: NIK,
      type: "update",
    };
  }

  // Fungsi untuk Validasi Kolom Item ID
  function validateId(value) {
    let error;
    if (!value) {
      error = "Required";
    }
    return error;
  }

  // Fungsi untuk Validasi Kolom Batch Number
  function validateBatch(value) {
    let error;
    let value2 = value.replace(/\s{2,}/g, " ").trim();
    if (!value) {
      error = "Required";
    }
    if (value2 === "") {
      error = "Required";
    }
    return error;
  }

  // Fungsi untuk Validasi Kolom Quantity
  function validateQuantity(value) {
    let error;
    if (value === "") {
      error = "Required";
    }
    return error;
  }

  // Fungsi untuk Validasi Kolom Expired Date
  function validateDate(value) {
    let error;
    if (!value) {
      error = "Required";
    }
    return error;
  }

  const renderForm = (name, field, form) => {
    if (name === "itemBatch") {
      return (
        <FormControl
          className="mb-4"
          isInvalid={form.errors.itemBatch && form.touched.itemBatch}
        >
          <FormLabel htmlFor="itemBatch">
            BATCH NUMBER <span style={{ color: "red" }}>*</span>
          </FormLabel>
          <Input
            {...field}
            background="#ffff"
            type="text"
            className="form-control"
            placeholder="Input Batch Number"
            id="itemBatch"
            maxLength="20"
          />
          <FormErrorMessage>
            {form.errors.itemBatch && form.touched.itemBatch
              ? form.errors.itemBatch
              : ""}
          </FormErrorMessage>
        </FormControl>
      );
    }

    if (name == "itemDate") {
      return (
        <FormControl
          className="mb-4"
          isInvalid={form.errors.itemDate && form.touched.itemDate}
        >
          <FormLabel htmlFor="itemDate">
            EXPIRED DATE <span style={{ color: "red" }}>*</span>
          </FormLabel>
          <SingleDatepicker
            disabled={false}
            BackgroundColor={typeForm === "detail" ? "#ECECEC" : "white"}
            width="100%"
            name="date"
            id="date"
            minDate={moment(
              moment(form.values.releaseDate, "YYYY-MM-DD").add(1, "days")
            ).format("YYYY-MM-DD")}
            inlineInput={true}
            className={
              form.errors.itemDate && form.touched.itemDate
                ? `${style["form-error2"]}`
                : ""
            }
            value={field.value === "" ? "" : new Date(field.value)}
            onChange={(date) => {
              form.setFieldValue(field.name, date);
            }}
            required
          />
          <FormErrorMessage>
            {form.errors.itemDate && form.touched.itemDate
              ? form.errors.itemDate
              : ""}
          </FormErrorMessage>
        </FormControl>
      );
    }

    if (name == "releaseDate") {
      return (
        <FormControl
          className="mb-4"
          isInvalid={form.errors.releaseDate && form.touched.releaseDate}
        >
          <FormLabel htmlFor="releaseDate">
            RELEASE DATE <span style={{ color: "red" }}>*</span>
          </FormLabel>
          <SingleDatepicker
            disabled={false}
            BackgroundColor={typeForm === "detail" ? "#ECECEC" : "white"}
            width="100%"
            name="date"
            id="date"
            maxDate={moment(
              moment(form.values.itemDate, "YYYY-MM-DD").subtract(1, "days")
            ).format("YYYY-MM-DD")}
            inlineInput={true}
            className={
              form.errors.releaseDate && form.touched.releaseDate
                ? `${style["form-error2"]}`
                : ""
            }
            value={field.value === "" ? "" : new Date(field.value)}
            onChange={(date) => {
              form.setFieldValue(field.name, date);
            }}
            required
          />
          <FormErrorMessage>
            {form.errors.releaseDate && form.touched.releaseDate
              ? form.errors.releaseDate
              : ""}
          </FormErrorMessage>
        </FormControl>
      );
    }

    if (name == "itemId") {
      return (
        <FormControl
          className="mb-4"
          isInvalid={form.errors.itemId && form.touched.itemId}
        >
          <FormLabel htmlFor="itemId">
            ITEM ID <span style={{ color: "red" }}>*</span>
          </FormLabel>
          <Select
            isMulti={false}
            className={
              form.errors.itemId && form.touched.itemId
                ? `${style["form-error"]}`
                : "mb-4"
            }
            options={listActiveMedia}
            placeholder="Choose Item ID"
            {...field}
            onChange={(options) => {
              form.setFieldValue(field.name, options);
            }}
            onBlur={field.onBlur}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                neutral50: "#a5b2c3", // Placeholder color
              },
            })}
          />
          <FormErrorMessage>
            {form.errors.itemId && form.touched.itemId
              ? form.errors.itemId
              : ""}
          </FormErrorMessage>
        </FormControl>
      );
    }

    if (name == "quantity") {
      return (
        <FormControl
          className="mb-4"
          isInvalid={form.errors.quantity && form.touched.quantity}
        >
          <FormLabel htmlFor="quantity">
            QUANTITY <span style={{ color: "red" }}>*</span>
          </FormLabel>
          <Input
            {...field}
            background="#ffff"
            type="number"
            className="form-control"
            placeholder="Input Quantity"
            id="quantity"
            maxLength="20"
          />
          <FormErrorMessage>
            {form.errors.quantity && form.touched.quantity
              ? form.errors.quantity
              : ""}
          </FormErrorMessage>
        </FormControl>
      );
    }
    return;
  };

  return (
    <>
      <Modal
        blockScrollOnMount={blockScrollOnMount}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{titleCase(viewType)} Media</ModalHeader>
          <ModalBody pb={6}>
            <Formik
              initialValues={initialData}
              validateOnChange={true}
              validateOnBlur={false}
              onSubmit={handleSubmitAdd}
            >
              {(props) => (
                <Form className="add-org-modal">
                  <Field
                    name="itemId"
                    validate={viewType === "update" ? "" : validateId}
                  >
                    {({ field, form }) => renderForm("itemId", field, form)}
                  </Field>
                  <Field name="itemBatch" validate={validateBatch}>
                    {({ field, form }) => renderForm("itemBatch", field, form)}
                  </Field>
                  <Field name="quantity" validate={validateQuantity}>
                    {({ field, form }) => renderForm("quantity", field, form)}
                  </Field>
                  <Field name="releaseDate" validate={validateDate}>
                    {({ field, form }) =>
                      renderForm("releaseDate", field, form)
                    }
                  </Field>
                  <Field name="itemDate" validate={validateDate}>
                    {({ field, form }) => renderForm("itemDate", field, form)}
                  </Field>

                  <ModalFooter mt={3} pb={0} style={{ paddingRight: "0" }}>
                    <Button className="c-modal-cancel" onClick={onClose}>
                      Close
                    </Button>
                    <Button
                      isLoading={props.isSubmitting}
                      loadingText={viewType === "create" ? "Submit" : "Saving"}
                      spinnerPlacement="start"
                      mr={3}
                      type="submit"
                      className="submitnext action-button c-modal-add"
                      style={{ margin: "0 0 0 15px" }}
                    >
                      {viewType === "create" ? "Submit" : "Save"}
                    </Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateModalDialog;
