import React, { useState, useEffect, useRef } from 'react'
import { SingleDatepicker } from '../../../components'
import { FaChevronDown } from 'react-icons/fa'
import { 
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  FormControl,
  FormLabel,
  Flex,
  useDisclosure,
  Select
} from '@chakra-ui/react'
import {
  STATUS_PRESENCE_IN,
  STATUS_PRESENCE_OUT,
} from '../../../utils/ConstantPES'

function useComponentVisible(initialIsVisible) {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
          setIsComponentVisible(false);
      }
  };

  useEffect(() => {
      document.addEventListener('click', handleClickOutside, true);
      return () => {
          document.removeEventListener('click', handleClickOutside, true);
      };
  });

  return { ref, isComponentVisible, setIsComponentVisible };
}

const PopOverFilter = ({onSubmit, className}) => {
  const [data, setData] = useState({ 
    status: STATUS_PRESENCE_IN,
    date: new Date()
  })
  const { onOpen, onClose } = useDisclosure()
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false)

  const handleStatusChanges = (e, values) => {
    setData({...data, status: values})
    setIsComponentVisible(true)
  }

  const handleDateChanges = (date) => {
    setData({...data, date: date})
  }

  const showPopOver = () => {
    setIsComponentVisible(true)
  }

  const closePopOver = () => {
    setIsComponentVisible(false)
  }

  const handleOnSubmit = (e) => {
    e.preventDefault()
    if (data) onSubmit({data})
  }

  return (
    <>
      <Popover isOpen={isComponentVisible} onOpen={onOpen} onClose={onClose} placement="bottom-end">
        <PopoverTrigger>
          <Button
            className={`text-weight-normal ` + className}
            borderColor="#CBD5E0" 
            variant="outline"
            rightIcon={<FaChevronDown />}
            onClick={showPopOver}
          >
            <span className="mr-3">Filter</span>
          </Button>
        </PopoverTrigger>
        <PopoverContent p={4} borderColor="#CBD5E0" ref={ref}>
          <form onSubmit={handleOnSubmit} className="text-left">
            <PopoverBody>
              <Flex flexWrap="wrap">
                <FormControl w="100%">
                  <FormLabel>Presence Type</FormLabel>
                  <Select onChange={handleStatusChanges}>
                    <option value={STATUS_PRESENCE_IN}>Check In</option>
                    <option value={STATUS_PRESENCE_OUT}>Check Out</option>
                  </Select>
                </FormControl>
                <FormControl mt={5}>
                  <FormLabel>Date</FormLabel>
                  <SingleDatepicker value={data.date} onChange={(date => handleDateChanges(date))} />               
                </FormControl>
              </Flex>
              <div className="w-100 text-right">
                <Button
                  type="submit"
                  className="btn-cm-primary font-14 text-weight-normal mb-1 mt-4 px-4"
                  onClick={closePopOver}
                >
                  Apply
                </Button>
              </div>
            </PopoverBody>
          </form>
        </PopoverContent>
      </Popover>
    </>
  )
}

export default PopOverFilter