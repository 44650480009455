import { lazy } from "react";

const fallbackDelay = 1000;

export const Notifications = lazy(() => {
  return new Promise((resolve, reject) => {
    setTimeout(
      () => resolve(import("../../pages/Notification/index")),
      fallbackDelay
    );
  });
});
