import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  process: null,
  startTime: null,
  databaseTime: null,
  currentDate: null,
  endTime: null,
  isResubmit: false,
  isStarted: false,
  procedures: [],
  isGoOrNoGo: false,
  goOrNoGoNames: [],
};

export const productionExecutionDetailSlice = createSlice({
  name: "productionExecutionDetail",
  initialState,
  reducers: {
    setProcess(state, action) {
      state.process = action.payload;
    },
    setStartTime(state, action) {
      state.startTime = action.payload;
    },
    setDatabaseTime(state, action) {
      state.databaseTime = action.payload;
    },
    setCurrentDate(state, action) {
      state.currentDate = action.payload;
    },
    setEndTime(state, action) {
      state.endTime = action.payload;
    },
    setIsResubmit(state, action) {
      state.isResubmit = action.payload;
    },
    setIsStarted(state, action) {
      state.isStarted = action.payload;
    },
    setProcedures(state, action) {
      state.procedures = action.payload;
    },
    setIsGoOrNoGo(state, action) {
      state.isGoOrNoGo = action.payload;
    },
    setGoOrNoGoNames(state, action) {
      state.goOrNoGoNames = action.payload;
    },
  },
});

export const {
  setProcess,
  setStartTime,
  setDatabaseTime,
  setCurrentDate,
  setEndTime,
  setIsResubmit,
  setIsStarted,
  setProcedures,
  setIsGoOrNoGo,
  setGoOrNoGoNames,
} = productionExecutionDetailSlice.actions;
export default productionExecutionDetailSlice.reducer;
